/**
 * @ngdoc controller
 * @name smartpager.endUser.main:profilePictureUploadModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.main')
    .controller('profilePictureUploadModalCtrl', ["$scope", "modalOptions", function ($scope, modalOptions) {
        var $ctrl = this;

        // var reader = new FileReader();
        // reader.onloadend = function () {
        //     $ctrl.img = reader.result;
        // };
        // reader.readAsDataURL(modalOptions.addedItems[0]._file);

    }]);
