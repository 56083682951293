/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:downloadsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('downloadsCtrl', ["$scope", function($scope){

}]);
