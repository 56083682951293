/**
 * @ngdoc controller
 * @name threadListCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.messages")
  .controller("threadListCtrl", ["$scope", "$state", "$stateParams", "$rootScope", "messageService", "userService", "configuration", "inboxEvents", "$log", "modalHelper", "inboxType", function(
    $scope,
    $state,
    $stateParams,
    $rootScope,
    messageService,
    userService,
    configuration,
    inboxEvents,
    $log,
    modalHelper,
    inboxType
  ) {
    $scope.messageService = messageService;
    $scope.inboxType = angular.copy(inboxType);

    $scope.currentInboxType = $stateParams.inboxType;

    $scope.loadMore = function() {
      $log.debug("checking if should loadMore");
      //in this case count increases and i don't know about it
      if ($scope.inboxCtrl.count > $scope.inboxCtrl.threads.length) {
        $log.debug("loadMore");
        $scope.inboxCtrl.refreshThreadsUsingCurrentFilter();
      }
    };

    $scope.newThread = function() {
      messageService.showComposeModal();
    };

    function loadObservedUsers() {
      userService
        .getObservedUsers(configuration.getUserId())
        .then(function(response) {
          $rootScope.$broadcast(inboxEvents.observedUsersChange, response);
        });
    }

    $scope.monitorThreads = function() {
      messageService.showMonitorModal().finally(function() {
        loadObservedUsers();
      });
    };

    $scope.selectInbox = function(inboxType) {
      $state.go("root.inbox", { inboxType: inboxType });
    };
  }]);
