/**
 * @ngdoc directive
 * @name smartpager.endUser.messages:threadListItemSubscribersDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .component('threadListItemSubscribers', {
        templateUrl: '/smartpager/angular/endUser/messages/components/threadListItem/threadListItemSubscribers.html',
        bindings: {
            subscribers: '<',
            threadId: '<',
            subscriberCount: '<'
        },
        controller: ["$scope", "configuration", "inboxEvents", "$log", function ($scope, configuration, inboxEvents, $log) {
            var $ctrl = this;

            $ctrl.otherCount = $ctrl.subscriberCount - 1;

            $ctrl.subscriberDisplay = _.find($ctrl.subscribers, function(subscriber){
                return subscriber.user_id !== configuration.getUserId();
            });

            $scope.$onRootScope(inboxEvents.newThreadSubscriber + $ctrl.threadId, function (event, subscriber) {
                var existingSubscriber = _.find($ctrl.subscribers, {id: subscriber.id});
                if (!existingSubscriber) {
                    $ctrl.subscriberCount += 1;
                    $ctrl.otherCount += 1;
                }
            });
        }]
    });
