/**
 * @ngdoc service
 * @name smartpager.endUser.shared:integrationsService
 *
 * @description
 *
 *
 * */
angular
  .module('smartpager.endUser.shared')
  .service('integrationsService', ["baseService", function(baseService) {
    var Service = Object.create(baseService);

    Service.getConfiguredIntegrations = function(type, name) {
      var params = $.param({
        type: type,
        search: name
      });

      var url = String.format(
        '/api/v1/integrations/{0}',
        params ? '?' + params : ''
      );
      return Service.getData(url);
    };

    Service.getRemoteIdsForGrid = function(params) {
      var url = String.format(
        '/api/v1/integrations/remoteIds/{0}',
        Service.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.addRemoteId = function(
      integrationType,
      contactId,
      remoteId,
      remoteBuid,
      integrationId,
      division
    ) {
      var url = String.format('/api/v1/integrations/remoteIds/');
      var data = {
        remote_id_type: integrationType,
        remote_id: remoteId,
        remote_buid: remoteBuid,
        contact_id: contactId,
        integration_id: integrationId,
        division: division
      };
      return Service.postData(url, data);
    };

    Service.removeRemoteId = function(id) {
      var url = String.format('/api/v1/integrations/remoteIds/{0}', id);
      return Service.deleteData(url);
    };

    //region qgenda
    Service.createQgendaConfiguration = function(configuration) {
      var url = '/api/v1/integrations/qgenda/';
      return Service.postData(url, configuration);
    };

    Service.updateQgendaConfiguration = function(configuration) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/',
        configuration.id
      );
      return Service.putData(url, configuration);
    };

    Service.getQgendaConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/qgenda/{0}/', id);
      return Service.getData(url);
    };

    Service.deleteQgendaConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/qgenda/{0}/', id);
      return Service.deleteData(url);
    };

    Service.forceSyncQgenda = function(configId) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/refresh/',
        configId
      );
      return Service.postData(url, { 'run_async': false });
    };

    Service.getQgendaTasks = function(configId, params) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/tasks/{1}',
        configId,
        params ? this.toUrl(params) : ''
      );
      return Service.getData(url);
    };

    Service.getQgendaSchedulesForGrid = function(id, params) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/',
        id,
        this.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.createQgendaScheduleConfig = function(id, config) {
      var url = String.format('/api/v1/integrations/qgenda/{0}/schedules/', id);
      return Service.postData(url, config);
    };

    Service.getQgendaScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.getData(url);
    };

    Service.deleteQgendaScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.deleteData(url);
    };

    Service.refreshQgendaSchedule = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/refresh/',
        id,
        scheduleId
      );
      return Service.postData(url, { 'run_async': true });
    };

    Service.getQgendaTaskMappingsForGrid = function(id, scheduleId, params) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/tasks/{2}',
        id,
        scheduleId,
        this.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.getQgendaTaskMappings = function(id, scheduleId, params) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/tasks/',
        id,
        scheduleId
      );
      return Service.getData(url);
    };

    Service.createQgendaTaskMapping = function(id, scheduleId, taskId) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/tasks/',
        id,
        scheduleId
      );
      return Service.postData(url, { task_id: taskId });
    };

    Service.deleteQgendaTaskMapping = function(id, scheduleId, taskId) {
      var url = String.format(
        '/api/v1/integrations/qgenda/{0}/schedules/{1}/tasks/{2}/',
        id,
        scheduleId,
        taskId
      );
      return Service.deleteData(url);
    };

    //endregion

    //region tangier
    Service.createTangierConfiguration = function(configuration) {
      var url = '/api/v1/integrations/tangier/';
      return Service.postData(url, configuration);
    };

    Service.updateTangierConfiguration = function(configuration) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/',
        configuration.id
      );
      return Service.putData(url, configuration);
    };

    Service.getTangierConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/tangier/{0}/', id);
      return Service.getData(url);
    };

    Service.deleteTangierConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/tangier/{0}/', id);
      return Service.deleteData(url);
    };

    Service.forceSyncTangier = function(id) {
      var url = String.format('/api/v1/integrations/tangier/{0}/refresh/', id);
      return Service.postData(url, { 'run_async': true });
    };

    Service.getTangierShifts = function(configId, params) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/shifts/{1}',
        configId,
        params ? this.toUrl(params) : ''
      );
      return Service.getData(url);
    };

    Service.getTangierSchedulesForGrid = function(id, parameters) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/',
        id,
        this.toUrl(parameters)
      );
      return Service.getData(url);
    };

    Service.createTangierScheduleConfig = function(id, config) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/',
        id
      );
      return Service.postData(url, config);
    };

    Service.getTangierScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.getData(url);
    };

    Service.deleteTangierScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.deleteData(url);
    };

    Service.refreshTangierSchedule = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/refresh/',
        id,
        scheduleId
      );
      return Service.postData(url, { 'run_async': true });
    };

    Service.getTangierShiftMappingsForGrid = function(id, scheduleId, params) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/shifts/{2}',
        id,
        scheduleId,
        this.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.getTangierShiftMappings = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/shifts/',
        id,
        scheduleId
      );
      return Service.getData(url);
    };

    Service.createTangierShiftMapping = function(id, scheduleId, shiftId) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/shifts/',
        id,
        scheduleId
      );
      return Service.postData(url, { shift_id: shiftId });
    };

    Service.deleteTangierShiftMapping = function(id, scheduleId, shiftId) {
      var url = String.format(
        '/api/v1/integrations/tangier/{0}/schedules/{1}/shifts/{2}/',
        id,
        scheduleId,
        shiftId
      );
      return Service.deleteData(url);
    };

    //endregion

    //region amion
    Service.createAmionConfiguration = function(configuration) {
      var url = '/api/v1/integrations/amion/';
      return Service.postData(url, configuration);
    };

    Service.updateAmionConfiguration = function(configuration) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/',
        configuration.id
      );
      return Service.putData(url, configuration);
    };

    Service.getAmionConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/amion/{0}/', id);
      return Service.getData(url);
    };

    Service.deleteAmionConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/amion/{0}/', id);
      return Service.deleteData(url);
    };

    Service.forceSyncAmion = function(configId) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/refresh/',
        configId
      );
      return Service.postData(url, { 'run_async': true });
    };

    Service.getAmionSchedulesForGrid = function(id, parameters) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/schedules/{1}',
        id,
        this.toUrl(parameters)
      );
      return Service.getData(url);
    };

    Service.getAmionDivisions = function(id, parameters) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/divisions/',
        id,
        this.toUrl(parameters)
      );
      return Service.getData(url);
    };

    Service.createAmionScheduleConfig = function(id, config) {
      var url = String.format('/api/v1/integrations/amion/{0}/schedules/', id);
      return Service.postData(url, config);
    };

    Service.getAmionScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.getData(url);
    };

    Service.deleteAmionScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.deleteData(url);
    };

    Service.refreshAmionSchedule = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/schedules/{1}/refresh/',
        id,
        scheduleId
      );
      return Service.postData(url);
    };

    Service.getAmionAssignmentOptionsForGrid = function(configId, params) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/assignments/{1}',
        configId,
        Service.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.getAmionShiftMappings = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/schedules/{1}/mappings/',
        id,
        scheduleId
      );
      return Service.getData(url);
    };

    Service.createAmionShiftMapping = function(id, scheduleId, assignmentId) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/schedules/{1}/mappings/',
        id,
        scheduleId
      );
      return Service.postData(url, {
        assignment_id: assignmentId
      });
    };

    Service.deleteAmionShiftMapping = function(id, scheduleId, mappingId) {
      var url = String.format(
        '/api/v1/integrations/amion/{0}/schedules/{1}/mappings/{2}/',
        id,
        scheduleId,
        mappingId
      );
      return Service.deleteData(url);
    };

    //endregion

    //region shiftadmin
    Service.createShiftadminConfiguration = function(configuration) {
      var url = '/api/v1/integrations/shiftadmin/';
      return Service.postData(url, configuration);
    };

    Service.updateShiftadminConfiguration = function(configuration) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/',
        configuration.id
      );
      return Service.putData(url, configuration);
    };

    Service.getShiftadminConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/shiftadmin/{0}/', id);
      return Service.getData(url);
    };

    Service.deleteShiftadminConfiguration = function(id) {
      var url = String.format('/api/v1/integrations/shiftadmin/{0}/', id);
      return Service.deleteData(url);
    };

    Service.getShiftadminSchedulesForGrid = function(id, parameters) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/{1}',
        id,
        this.toUrl(parameters)
      );
      return Service.getData(url);
    };

    Service.createShiftadminScheduleConfig = function(id, config) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/',
        id
      );
      return Service.postData(url, config);
    };

    Service.getShiftadminScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.getData(url);
    };

    Service.deleteShiftadminScheduleConfig = function(id, scheduleId) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/{1}/',
        id,
        scheduleId
      );
      return Service.deleteData(url);
    };

    Service.syncShiftadminSchedule = function(id, scheduleId, params) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/{1}/sync/{2}',
        id,
        scheduleId,
        this.toUrl(params)
      );
      return Service.postData(url, { 'run_async': true });
    };

    Service.getShiftadminShifts = function(id, params) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/shifts/{1}',
        id,
        this.toUrl(params)
      );
      return Service.getData(url, { 'run_async': true });
    };

    Service.syncShiftadminShifts = function(id, params) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/shifts/sync/{1}',
        id,
        this.toUrl(params)
      );
      return Service.postData(url, { 'run_async': true });
    };

    Service.getShiftadminScheduleShiftsForGrid = function(
      id,
      scheduleId,
      params
    ) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/{1}/shifts/{2}',
        id,
        scheduleId,
        this.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.addShiftadminScheduleShifts = function(id, scheduleId, groupIds) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/{1}/shifts/',
        id,
        scheduleId
      );
      return Service.postData(url, { ids: groupIds });
    };

    Service.removeShiftadminScheduleShift = function(id, scheduleId, groupId) {
      var url = String.format(
        '/api/v1/integrations/shiftadmin/{0}/schedules/{1}/shifts/{2}/',
        id,
        scheduleId,
        groupId
      );
      return Service.deleteData(url);
    };

    //endregion

    //region usersync
    Service.getAdAgents = function() {
      var url = String.format('/api/v1/identities/ad/agent/');
      return Service.getData(url);
    };

    Service.getAdAgentsForGrid = function(params) {
      var url = String.format(
        '/api/v1/identities/ad/agent/{0}',
        Service.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.createAdAgentConfiguration = function(config) {
      var url = String.format('/api/v1/identities/ad/agent/');
      return Service.postData(url, config);
    };

    Service.updateAdAgentConfiguration = function(config) {
      var url = String.format('/api/v1/identities/ad/agent/{0}/', config.id);
      return Service.putData(url, config);
    };

    Service.deleteAdAgentConfig = function(id) {
      var url = String.format('/api/v1/identities/ad/agent/{0}/', id);
      return Service.deleteData(url);
    };

    Service.getIdentityProviders = function() {
      var url = String.format('/api/v1/identities/');
      return Service.getData(url);
    };

    Service.getAdUserSyncConfig = function(configId) {
      var url = String.format('/api/v1/identities/ad/{0}', configId);
      return Service.getData(url);
    };

    Service.getAdUserSyncConfigFields = function(configId) {
      var url = String.format('/api/v1/identities/ad/{0}/fields/', configId);
      return Service.getData(url);
    };

    Service.createFieldMapping = function(configId, mapping) {
      var url = String.format('/api/v1/identities/ad/{0}/fields/', configId);
      return Service.postData(url, mapping);
    };

    Service.editFieldMapping = function(configId, mapping) {
      var url = String.format(
        '/api/v1/identities/ad/{0}/fields/{1}/',
        configId,
        mapping.id
      );
      return Service.putData(url, mapping);
    };

    Service.createDepartmentsMapping = function(configId, mapping) {
      var url = String.format(
        '/api/v1/identities/ad/{0}/departments/',
        configId
      );
      return Service.postData(url, mapping);
    };

    Service.editDepartmentsMapping = function(configId, mapping) {
      var url = String.format(
        '/api/v1/identities/ad/{0}/departments/{1}/',
        configId,
        mapping.id
      );
      return Service.putData(url, mapping);
    };

    Service.createGroupMapping = function(configId, mapping) {
      var url = String.format('/api/v1/identities/ad/{0}/groups/', configId);
      return Service.postData(url, mapping);
    };

    Service.editGroupMapping = function(configId, mapping) {
      var url = String.format(
        '/api/v1/identities/ad/{0}/groups/{1}/',
        configId,
        mapping.id
      );
      return Service.putData(url, mapping);
    };

    Service.deleteFieldMapping = function(configId, mappingId) {
      var url = String.format(
        '/api/v1/identities/ad/{0}/fields/{1}/',
        configId,
        mappingId
      );
      return Service.deleteData(url);
    };

    Service.deleteDepartmentMapping = function(configId, mappingId) {
      var url = String.format(
        '/api/v1/identities/ad/{0}/departments/{1}/',
        configId,
        mappingId
      );
      return Service.deleteData(url);
    };

    Service.deleteGroupMapping = function(configId, mappingId) {
      var url = String.format(
        '/api/v1/identities/ad/{0}/groups/{1}/',
        configId,
        mappingId
      );
      return Service.deleteData(url);
    };

    Service.getAdUserSyncConfigGroupMappings = function(configId) {
      var url = String.format('/api/v1/identities/ad/{0}/groups/', configId);
      return Service.getData(url);
    };

    Service.getAdUserSyncConfigDepartments = function(configId) {
      var params = { count: 250 };
      var url = String.format(
        '/api/v1/identities/ad/{0}/departments/{1}',
        configId,
        Service.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.createAdUserSyncConfig = function(config) {
      var url = String.format('/api/v1/identities/ad/');
      return Service.postData(url, config);
    };

    Service.updateAdUserSyncConfig = function(config) {
      var url = String.format('/api/v1/identities/ad/{0}/', config.id);
      return Service.putData(url, config);
    };

    Service.deleteAdUserSyncConfig = function(configId) {
      var url = String.format('/api/v1/identities/ad/{0}/', configId);
      return Service.deleteData(url);
    };

    //endregion

    //region cas
    Service.getCasAgents = function() {
      var url = String.format('/api/v1/integrated_messages/cas/agent/');
      return Service.getData(url);
    };

    Service.getCasAgentsForGrid = function(params) {
      var url = String.format(
        '/api/v1/integrated_messages/cas/agent/{0}',
        Service.toUrl(params)
      );
      return Service.getData(url);
    };

    Service.createCasAgentConfiguration = function(config) {
      var url = String.format('/api/v1/integrated_messages/cas/agent/');
      return Service.postData(url, config);
    };

    Service.updateCasAgentConfiguration = function(config) {
      var url = String.format(
        '/api/v1/integrated_messages/cas/agent/{0}/',
        config.id
      );
      return Service.putData(url, config);
    };

    Service.deleteCasAgentConfig = function(id) {
      var url = String.format('/api/v1/integrated_messages/cas/agent/{0}/', id);
      return Service.deleteData(url);
    };

    Service.downloadCasDeviceCsv = function(id) {
      var url = String.format(
        '/api/v1/integrated_messages/cas/agent/{0}/devices_csv/',
        id
      );
      return Service.getData(url).then(function(response) {
        var file = new Blob([response], { type: 'text/plain;charset=utf-8' });
        //TODO: Sc-3057 - gulp says does not exist
        // saveAs(file, 'cas_devices.csv');
      });
    };
    //endregion

    return Service;
  }]);
