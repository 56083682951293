/**
 * @ngdoc service
 * @name smartpager.endUser.messages:subscriberReasonTextFilter
 *
 * @description
 *
 *
 * */

angular.module('smartpager.endUser.messages')
    .filter('subscriberReasonText', function () {
        return function (input) {
            var display;
            switch (input) {

                case 'escalation':
                    display = 'Escalation';
                    break;
                case 'forward':
                    display = 'Forwarded';
                    break;
                case 'new_message':
                    display = 'Original Recipient';
                    break;
                case 'subscribed':
                    display = 'Added to Thread';
                    break;
                case 'sender':
                    display = 'Original Sender';
                    break;
                case 'elevated_scope':
                    display = 'Added Self';
                    break;
                case 'monitoring':
                    display = 'Monitoring';
                    break;
                default:
                    display = 'Unknown';
                    break;
            }

            return display;
        };
    });
