/**
 * @ngdoc controller
 * @name messageHistoryModalCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('messageHistoryModalCtrl', ["$scope", "$state", "messageService", "modalOptions", function ($scope, $state, messageService, modalOptions) {

        $scope.loading = true;

        messageService.getMessageHistory($state.params.threadId, modalOptions.messageId)
            .then(function (result) {
                $scope.messageHistory = result;
            })
            .finally(function () {
                $scope.loading = false;
            });
    }]);
