/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:noteTemplatesDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('noteTemplatesDetailCtrl', ["$scope", "$state", "templateId", "patientsService", "Notification", "modalHelper", function ($scope, $state, templateId, patientsService, Notification, modalHelper) {
        var $templateCtrl = this;

        $templateCtrl.$onInit = function () {
            patientsService.requestGetTemplate(templateId);
        };

        $templateCtrl.updateTemplate = function (updatedFields) {
            $templateCtrl.isLoading = true;
            var updatedKeys = _.keys(updatedFields);
            _.forEach(updatedKeys, function (key) {
                $templateCtrl.template[key] = updatedFields[key];
            });
            $templateCtrl.template.priority_value = priorityMap[$templateCtrl.template.priority];
            $templateCtrl.template.field_ids = _.map($templateCtrl.template.fields, 'id');
            patientsService.updateNoteTemplate($templateCtrl.template)
                .then(function (response) {
                    Notification.success('Successfully updated ' + $templateCtrl.template.name);
                    patientsService.requestGetTemplate(templateId);
                })
                .catch(function(response){
                    $templateCtrl.serverErrors = response.data;
                });
        };

        $templateCtrl.openDeleteTemplateModal = function () {
            return modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/modals/deleteTemplateModal.html',
                controller: 'deleteTemplateModalCtrl as $ctrl',
                backdrop: 'static',
                template: $templateCtrl.template
            }).result
                .then(function () {
                    $state.go('root.accountSettings.noteTemplates.list');
                });
        };

        $scope.$onRootScope(patientsService.fluxEvents.getTemplate.key, function (event, response) {
            $templateCtrl.template = response;
        });

        var priorityMap = {
            low: '0',
            normal: '1',
            high: '2'
        };


    }]);
