/**
 * @ngdoc directive
 * @name groupMembersEdit
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.pagingGroups')
    .directive('groupMembersEdit', function () {
        return {
            restrict: 'E',
            templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/components/groupMembersEdit/groupMembersEdit.html',
            link: function (scope, elem, attr) {

            }
        };
    });
