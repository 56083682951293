/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:createRoleModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('createRoleModalCtrl', ["$scope", "roleService", "Notification", "alertsHelper", "configuration", "focus", function($scope, roleService, Notification, alertsHelper, configuration, focus){
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');
        $ctrl.role = {};

        $ctrl.reset = function(){
            $ctrl.role.name = '';
            focus('firstInput');
        };

        $ctrl.saveRole = function(){
            $ctrl.isSaving = true;
            roleService.createRole(configuration.getAccountId(), $ctrl.role)
                .then(function(response){
                    if(!$ctrl.addAnother){
                        Notification.success(String.format('Successfully created role {0}', $ctrl.role.name));
                        $scope.$close(response.id);
                    }else{
                        $ctrl.alerts.success(String.format('Succesfully created role {0}', $ctrl.role.name));
                        $ctrl.reset();
                    }
                })
                .catch(function(){
                    $ctrl.alerts.error('Could not create role');
                })
                .finally(function(){
                    $ctrl.isSaving = false;
                });
        };
}]);
