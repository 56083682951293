/**
 * @ngdoc directive
 * @name msgStatusIcon
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module("smartpager.endUser.messages").component("responseOptions", {
  bindings: {
    message: "<",
    subscribers: "<"
  },
  templateUrl:
    "/smartpager/angular/endUser/messages/components/responseOptions/responseOptions.html",
  controller: ["$scope", "$rootScope", "messageService", "$stateParams", "inboxEvents", "$log", "configuration", function(
    $scope,
    $rootScope,
    messageService,
    $stateParams,
    inboxEvents,
    $log,
    configuration
  ) {
    var $ctrl = this;

    $ctrl.myId = configuration.getUserId();

    //map subscribersIds to actual subscribers
    _.forEach($ctrl.message.history, function(item) {
      item.subscriber = _.find($ctrl.subscribers, { id: item.subscriber_id });
      if (!item.subscriber) {
        item.subscriber = { display_name: "(Name not found)" };
      }

      if (item.subscriber.user_id === $ctrl.myId) {
        $ctrl.myHistory = item;
      }
    });

    $ctrl.respond = function(optionIndex) {
      $ctrl.submitting = true;
      messageService
        .respond($stateParams.threadId, $ctrl.message.id, optionIndex)
        .then(function(response) {
          $rootScope.$emit(inboxEvents.responseSelected, response);
        });
    };

    $scope.$onRootScope(inboxEvents.messageResponse, function($event, msg) {
      if (msg.message_id === $ctrl.message.id) {
        var subscriberHistory = _.find($ctrl.message.history, {
          subscriber_id: msg.subscriber_id
        });

        if (subscriberHistory) {
          subscriberHistory.replied = msg.replied;
        } else {
          $log.warn("didn't find subscriber");
        }
      }
    });
  }]
});
