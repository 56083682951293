angular.module("smartpager.endUser.shared").constant("inboxEvents", {
  newThread: "newThread",
  threadInView: "threadInView",
  threadSearch: "threadSearch",
  subscribersLoaded: "subscribersLoaded",
  subscriberStateChange: "subscriberStateChange",
  newThreadSubscriber: "newThreadSubscriber",
  threadPatientLoaded: "threadPatientLoaded",
  observedUsersChange: "observedUsersChange",

  //used to refresh thread item last_updated date on thread list
  newMessage: "newMessage",
  messageConfirmation: "messageConfirmation",
  messageResponse: "messageResponse",
  responseSelected: "responseSelected",

  scrollBottom: "scrollBottom",
  lockScroll: "lockScroll",
  restoreScroll: "restoreScroll"
});
