/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupMembersCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('savedFilterItemsCtrl', ["$scope", "modalHelper", "savedFiltersService", "Notification", "NgTableParams", "$stateParams", "ngTableHelper", function ($scope, modalHelper, savedFiltersService, Notification, NgTableParams, $stateParams, ngTableHelper) {
        var $ctrl = this;

        $ctrl.memberTableParams = new NgTableParams({
            sorting: {
                classified_name: 'asc'
            }
        }, {
            getData: function (params) {
                $scope.isLoading = true;
                return savedFiltersService.getSavedFilterTagsForGrid($stateParams.savedFilterId, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.memberTableParams, 'search')
        };

        $ctrl.openAddTagModal = function () {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/savedFilters/modals/addTagToSavedFilterModal.html',
                'controller': 'addTagToSavedFilterModalCtrl as ctrl',
                'savedFilterId': $scope.savedFilter.id,
                'contentType': $scope.savedFilter.content_type
            }).result
                .finally(function (result) {
                    $ctrl.memberTableParams.reload();
                });
        };


        $ctrl.removeTag = function (tag) {
            tag.removing = true;
            savedFiltersService.removeTag($scope.savedFilter.id, tag.id)
                .then(function () {
                    Notification.success(String.format('Tag `{0}` has been removed from savedFilter `{1}`', tag.classified_name, $scope.savedFilter.name));
                    $ctrl.memberTableParams.reload();
                })
                .finally(function () {
                    tag.removing = false;
                });
        };
    }]);
