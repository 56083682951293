/**
 * @ngdoc directive
 * @name dynamicField
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('dynamicField', ["$templateRequest", "$compile", "$templateCache", function ($templateRequest, $compile, $templateCache) {

        var getTemplateUrl = function (field) {
            var type = field.field_type || field.type;
            var templateUrl = '/smartpager/angular/endUser/messages/components/dynamicField/';

            switch (type) {
                case 'text':
                    templateUrl += 'textarea.html';
                    break;
                case 'char':
                    templateUrl += 'text.html';
                    break;
                default:
                    templateUrl += String.format('{0}.html', type);
            }
            return templateUrl;
        };

        var linker = function (scope, element) {
            function compileTemplate(templateHtml) {
                element.html(templateHtml);
                $compile(element.contents())(scope);
            }

            // GET template content from path
            var templateUrl = getTemplateUrl(scope.field);
            var templateHtml = $templateCache.get(templateUrl);
            if (templateHtml) {
                compileTemplate(templateHtml);
            } else {
                $templateRequest.get(templateUrl)
                    .success(compileTemplate);
            }

            scope.$on('fileUploaded', function ($event, val) {
                scope.field.value = val.id;
                $event.stopPropagation();
            });

            scope.$on('fileRemoved', function ($event, val) {
                scope.field.value = undefined;
                $event.stopPropagation();
            });
        };

        return {
            restrict: 'E',
            scope: {
                field: '='
            },
            link: linker,
            controller: ["$scope", function ($scope) {
                if ($scope.field.default_value) {
                    var default_value = $scope.field.default_value;

                    if (!isNaN(default_value)) {
                        default_value = parseInt($scope.field.default_value);
                    }

                    $scope.field.value = default_value;
                }
            }]
        };
    }]);
