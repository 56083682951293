/**
 * @ngdoc directive
 * @name smartpager.endUser.main:chnagePasswordDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.main')
    .controller('changePasswordCtrl', ["$log", "configuration", "userService", "modalHelper", function ($log, configuration, userService, modalHelper) {
        var $ctrl = this;
        $ctrl.userId = configuration.getUserId();
        $ctrl.ownPassword = true;

        $ctrl.openChangePasswordModal = function(){
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/users/controllers/modals/changePasswordModal.html',
                controller: 'changePasswordModalCtrl as $ctrl',
                userId: $ctrl.userId,
                ownPassword: $ctrl.ownPassword
            });
        };
    }])
    .component('changePassword', {
        controller: 'changePasswordCtrl as $ctrl',
        templateUrl: '/smartpager/angular/endUser/_main/components/changePassword/changePassword.html'
    });
