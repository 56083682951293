/**
 * @ngdoc controller
 * @name smartpager.endUser.main:userProfileCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module("smartpager.endUser.main")
  .controller("userProfileCtrl", ["$scope", "$state", "userService", "permissionsService", "modalHelper", "fileUploadHelper", "Notification", "$log", function($scope, $state, userService, permissionsService, modalHelper, fileUploadHelper, Notification, $log) {
    var $ctrl = this;
    if (permissionsService.hasFeature('tmq-2824-user-profile-page')) {
      window.location.replace('/v2/users/profile');
    }

    $ctrl.activate = function() {
      $ctrl.isLoading = true;
      userService.requestGetProfile();
    };
    $ctrl.activate();

    $scope.$onRootScope(userService.fluxEvents.getProfile.key, function($event, result) {
      $ctrl.user = result;
      $ctrl.isLoading = false;
      createUploader();
    });

    $ctrl.clearPhoto = function() {
      userService.clearPhoto($ctrl.user.id)
        .then(function() {
          //have to relaod because profile picture uses one time binding, which is important on messaging screen
          $state.reload("root.userProfile");
        });
    };

    $ctrl.openEditProfileModal = function() {
      modalHelper.openModal({
        templateUrl: "/smartpager/angular/endUser/_main/controllers/userProfile/modals/userProfileModal.html",
        controller: "userProfileModalCtrl as $ctrl",
        user: angular.copy($ctrl.user)
      }).result
        .then(function() {
          $ctrl.activate();
        });
    };


    function createUploader() {
      $ctrl.fileUploader = fileUploadHelper.getProfilePictureUploaded($ctrl.user.id);
      $ctrl.fileUploader.autoUpload = true;

      $ctrl.fileUploader.onCompleteAll = function() {
        Notification.success("Successfully updated profile picture");
        $state.reload("root.userProfile");
      };

      $ctrl.fileUploader.onWhenAddingFileFailed = function(item, filter, options) {
        $log.warn("Could not add file", item);
        if (filter.name === "enforceMaxFileSize") {
          Notification.error("File size limit is 512kb");
        } else if (filter.name === "invalidFileType") {
          Notification.error(String.format("Only {0} are allowed", $ctrl.fileUploader.allowedExtensions));
        }
      };
    }
  }]);
