/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addGroupMappingCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addGroupMappingCtrl', ["$scope", "integrationsService", "alertsHelper", "Notification", "modalOptions", function ($scope, integrationsService, alertsHelper, Notification, modalOptions) {

        var $ctrl = this;

        alertsHelper.create($ctrl, 'alerts');
        $ctrl.base_dn = modalOptions.base_dn;

        $ctrl.activate = function () {
            $ctrl.edit = !!modalOptions.groupMapping;
            if ($ctrl.edit) {
                $ctrl.mapping = modalOptions.groupMapping;
            } else {
                $ctrl.reset();
            }
        };



        $ctrl.reset = function () {
            $ctrl.mapping = {
                search_query: '(&(objectCategory=person)(mail=*)(objectClass=user)(!(userAccountControl:1.2.840.113556.1.4.803:=2)))'
            };
        };

        $ctrl.createMapping = function () {
            $ctrl.isSaving = true;
            integrationsService.createGroupMapping(modalOptions.configId, $ctrl.mapping)
                .then(function () {
                    if (!$ctrl.addAnother) {
                        Notification.success('Successfully added group mapping');
                        $scope.$close();
                    }
                    else {
                        $ctrl.alerts.success('Successfully added group mapping');
                        $ctrl.reset();
                    }
                })
                .catch(function () {
                    $ctrl.alerts.error('Error creating group mapping');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.editMapping = function () {
            $ctrl.isSaving = true;
            integrationsService.editGroupMapping(modalOptions.configId, $ctrl.mapping)
                .then(function () {
                    Notification.success('Successfully updated group mapping');
                    $scope.$close();
                })
                .catch(function () {
                    $ctrl.alerts.error('Error updating group mapping');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
        $ctrl.activate();
    }]);
