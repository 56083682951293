/**
 * @ngdoc directive
 * @name fileUpload
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.shared')
    .component('fileUpload', {
        bindings: {
            uploader: '<',
            multiple: '@'
        },
        templateUrl: '/smartpager/angular/endUser/shared/components/fileUpload/fileUpload.html',
        controller: ['$scope', 'fileUploadHelper', '$log', function ($scope, fileUploadHelper, $log) {
            var $ctrl = this;
            $ctrl.uploader.onAfterAddingFile = function (item, filter, options) {
                $ctrl.error = '';
            };
            $ctrl.uploader.onWhenAddingFileFailed = function (item, filter, options) {
                $log.warn('could not add file', item);
                if (filter.name === 'enforceMaxFileSize') {
                    $ctrl.error = 'File size limit is 10mb';
                } else if (filter.name === 'invalidFileType') {
                    $ctrl.error = String.format('Only {0} are allowed', $ctrl.uploader.allowedExtensions);
                } else if (filter.name === 'queueLimit') {
                    $ctrl.error = 'It\'s possible to attach only single file';
                }
            };

            $scope.$on('$destroy', function () {
                $ctrl.uploader.onAfterAddingFile = fileUploadHelper.noop;
                $ctrl.uploader.onWhenAddingFileFailed = fileUploadHelper.noop;
            });
        }]
    });
