/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupMembersCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('groupMembersCtrl', ["$scope", "modalHelper", "groupsService", "Notification", "NgTableParams", "$stateParams", "ngTableHelper", function ($scope, modalHelper, groupsService, Notification, NgTableParams, $stateParams, ngTableHelper) {
        var $ctrl = this;

        $ctrl.memberTableParams = new NgTableParams({
            sorting: {
                display_name: 'asc'
            }
        }, {
            getData: function (params) {
                $scope.isLoading = true;
                return groupsService.getGroupMembersForGrid($stateParams.groupId, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.memberTableParams, 'search')
        };

        $ctrl.openAddMemberModal = function () {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/groups/modals/addMemberToGroupModal.html',
                'controller': 'addMemberToGroupModalCtrl as ctrl',
                'groupId': $scope.group.id
            }).result
                .finally(function (result) {
                    $ctrl.memberTableParams.reload();
                });
        };


        $ctrl.removeMember = function (member) {
            member.removing = true;
            groupsService.removeMember($scope.group.id, member.id)
                .then(function () {
                    Notification.success(String.format('User `{0}` has been removed from group `{1}`', member.display_name, $scope.group.display_name));
                })
                .finally(function () {
                    member.removing = false;
                    $ctrl.memberTableParams.reload();
                });
        };
    }]);
