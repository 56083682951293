/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:departmentMessageTypesCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('messageTypeListCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('messageTypeListCache');
    }])
    .controller('departmentMessageTypesCtrl', ["$scope", "messageTypeListCache", "NgTableParams", "ngTableHelper", "userService", "departmentId", "modalHelper", "Notification", function($scope, messageTypeListCache, NgTableParams, ngTableHelper, userService, departmentId, modalHelper, Notification){
        var $ctrl = this;

        $ctrl.messageTypeTableParams = new NgTableParams(messageTypeListCache.get('parameters') || {
                count: 10,
                sorting: {name: 'asc'}
            }, {
            getData: function(params){
                messageTypeListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;
                return userService.getDepartmentMessageTypesForGrid(departmentId, params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        $ctrl.message_type_ids = _.map(response.results, 'id');
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.messageTypeSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.messageTypeTableParams, 'search')
        };

        $ctrl.removeMessageType = function(messageType){
            userService.removeDepartmentMessageType(departmentId, messageType.id)
                .then(function(){
                    Notification.success('Successfully removed ' + messageType.name + ' from ' + $scope.$depCtrl.department.name);
                    $ctrl.message_type_ids = _.without($ctrl.message_type_ids, messageType.id);
                })
                .catch(function(){
                    Notification.error('Could not remove ' + messageType.name + ' from ' + $scope.$depCtrl.department.name);
                })
                .finally(function(){
                    $ctrl.messageTypeTableParams.reload();
                });
        };

        $ctrl.openAddMessageTypeModal = function(){
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/modals/addMessageTypeToDepartmentModal.html',
                controller: 'addMessageTypeToDepartmentModalCtrl as $ctrl',
                department: $scope.$depCtrl.department
            }).result.finally(function(result){
                $ctrl.messageTypeTableParams.reload();
                // Since message type ids are also put by department save, we need to make sure it knows they've changed
                $scope.$emit('departmentDetail.updated');
            });
        };

}]);
