/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:addPatientsChoiceModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('addPatientsChoiceModalCtrl', ["$scope", function($scope){
        var $ctrl = this;
}]);
