/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:messageRoutingConditionCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .controller('messageRoutingConditionSetCtrl', ["$scope", "$rootScope", "routingModalsFactory", function ($scope, $rootScope, routingModalsFactory) {
        var $ctrl = this;

        $ctrl.openEditConditionSetModal = function () {
            return routingModalsFactory.showAddEditConditionSetModal($ctrl.conditionSet)
                .then(function (conditionSet) {
                    $ctrl.conditionSet = conditionSet;
                    $rootScope.$emit('reloadMessageRoutingConditionSets');
                });
        };

        $ctrl.openEditConditionModal = function (condition) {
            routingModalsFactory.showCreateEditConditionModal(condition)
                .then(function (condition) {
                    _.remove($ctrl.conditionSet.conditions_list, {id: condition.id});
                    $ctrl.conditionSet.conditions_list.push(condition);

                    $rootScope.$emit('reloadMessageRoutingConditions');
                });
        };
    }]);
