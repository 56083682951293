/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:userSyncListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('userSyncListCtrl', ["$scope", "integrationsService", "$state", function ($scope, integrationsService, $state) {

        var $ctrl = this;

        $ctrl.activate = function () {
            integrationsService.getIdentityProviders()
                .then(function (response) {
                    $ctrl.integrations = response.results;
                });
        };
        $ctrl.activate();

        $ctrl.createAdUserSyncModal = function () {
            $state.go('root.accountSettings.userSync.detail', {configId: 'new'});
        };
    }]);
