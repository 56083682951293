/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:userSyncDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('userSyncDetailCtrl', ["$scope", "configId", "integrationsService", "$q", "$state", "Notification", "modalHelper", "groupsService", "userService", function ($scope, configId, integrationsService, $q, $state, Notification, modalHelper, groupsService, userService) {

        var $ctrl = this;
        $ctrl.isCreate = configId === 'new';
        $ctrl.agents = [];

        function activate() {
            if (!$ctrl.isCreate) {
                $ctrl.isLoading = true;
                integrationsService.getAdUserSyncConfig(configId)
                    .then(function (response) {
                        $ctrl.config = response;
                        return $q.all([$ctrl.getAdUserSyncConfigFields(configId),
                            $ctrl.getAdUserSyncConfigGroupMappings(configId),
                            $ctrl.getAdUserSyncConfigDepartments(configId)]);
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
            var agent = $ctrl.getAdAgents();

            function reloadBaseDn() {
                if (!$ctrl.config) {
                    return;
                }
                var agent = _.find($ctrl.agents, {id: $ctrl.config.ad_agent_id});
                if (agent) {
                    $ctrl.base_dn = agent.base_dn;
                }
            }

            $scope.$watch('$ctrl.config.ad_agent_id', reloadBaseDn);
            $scope.$watch('$ctrl.agents', reloadBaseDn);
        }


        $ctrl.getAdUserSyncConfigFields = function (configId) {
            return integrationsService.getAdUserSyncConfigFields(configId)
                .then(function (response) {
                    $ctrl.config.fields = response.results;
                });
        };
        $ctrl.getAdUserSyncConfigGroupMappings = function (configId) {
            return integrationsService.getAdUserSyncConfigGroupMappings(configId)
                .then(function (response) {
                    $ctrl.config.groups = response.results;
                    var permission_group_ids = _.map($ctrl.config.groups, 'permission_group_id');

                    // link permission groups to their group mappings
                    groupsService.getGroups({ids: permission_group_ids.join()})
                        .then(function (response) {
                            var groups = response.results;
                            var groupMap = _.groupBy(groups, 'id');
                            _.forEach($ctrl.config.groups, function (groupMapping) {
                                if (groupMap.hasOwnProperty(groupMapping.permission_group_id)) {
                                    groupMapping.permission_group = groupMap[groupMapping.permission_group_id][0];
                                }
                            });
                        });
                });
        };
        $ctrl.getAdUserSyncConfigDepartments = function (configId) {
            return integrationsService.getAdUserSyncConfigDepartments(configId)
                .then(function (response) {
                    $ctrl.config.departments = response.results;
                    var department_ids = _.map($ctrl.config.departments, 'department_id');
                    userService.getDepartments({ids: department_ids.join(), page_size: $ctrl.config.departments.length})
                        .then(function (response) {
                            var departments = response.results;
                            var departmentMap = _.groupBy(departments, 'id');
                            _.forEach($ctrl.config.departments, function (departmentMapping) {
                                if (departmentMap.hasOwnProperty(departmentMapping.department_id)) {
                                    departmentMapping.department = departmentMap[departmentMapping.department_id][0];
                                }
                            });
                        });

                });
        };
        $ctrl.getAdAgents = function () {
            return integrationsService.getAdAgents()
                .then(function (response) {
                    $ctrl.agents = response.results;
                });
        };
        activate();

        $ctrl.saveConfig = function () {
            $ctrl.isSaving = true;
            var savePromise;
            if ($ctrl.isCreate) {
                savePromise = integrationsService.createAdUserSyncConfig($ctrl.config);
            } else {
                savePromise = integrationsService.updateAdUserSyncConfig($ctrl.config);
            }

            savePromise
                .then(function (response) {
                    Notification.success(String.format('Successfully created config {0}', $ctrl.config.name));
                    $state.go('root.accountSettings.userSync.detail', {configId: response.id});
                })
                .catch(function () {
                    Notification.error(String.format('Error creating config {0}.', $ctrl.config.name));
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.deleteConfig = function () {
            integrationsService.deleteAdUserSyncConfig(configId)
                .then(function () {
                    Notification.success(String.format('Successfully deleted config {0}', $ctrl.config.name));
                    $state.go('root.accountSettings.userSync.list');
                });
        };

        $ctrl.deleteFieldMapping = function (fieldId) {
            integrationsService.deleteFieldMapping(configId, fieldId)
                .then(function () {
                    $ctrl.getAdUserSyncConfigFields(configId);
                });
        };

        $ctrl.deleteGroupMapping = function (groupMappingId) {
            integrationsService.deleteGroupMapping(configId, groupMappingId)
                .then(function () {
                    $ctrl.getAdUserSyncConfigGroupMappings(configId);
                });
        };

        $ctrl.deleteDepartmentMapping = function (departmentMappingId) {
            integrationsService.deleteDepartmentMapping(configId, departmentMappingId)
                .then(function () {
                    $ctrl.getAdUserSyncConfigDepartments(configId);
                });
        };

        $ctrl.showAddFieldMappingModal = function () {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/userSync/modals/addFieldMapping.html',
                'controller': 'addFieldMappingCtrl as $ctrl',
                configId: configId,
                fields: _.map($ctrl.config.fields, 'local_field')
            }).result
                .finally(function () {
                    $ctrl.getAdUserSyncConfigFields(configId);
                });
        };

        $ctrl.showEditFieldMappingModal = function (field) {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/userSync/modals/addFieldMapping.html',
                controller: 'addFieldMappingCtrl as $ctrl',
                configId: configId,
                fields: _.without(_.map($ctrl.config.fields, 'local_field'), field.local_field),
                field: field
            }).result
                .finally(function () {
                    $ctrl.getAdUserSyncConfigFields(configId);
                });
        };

        $ctrl.showAddGroupMappingModal = function (groupMapping) {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/userSync/modals/addGroupMapping.html',
                'controller': 'addGroupMappingCtrl as $ctrl',
                configId: configId,
                groupMapping: groupMapping,
                base_dn: $ctrl.base_dn
            }).result
                .finally(function () {
                    $ctrl.getAdUserSyncConfigGroupMappings(configId);
                });
        };

        $ctrl.showAddDepartmentMappingModal = function (mapping) {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/userSync/modals/addDepartmentMapping.html',
                'controller': 'addDepartmentMappingCtrl as $ctrl',
                configId: configId,
                base_dn: $ctrl.base_dn,
                mapping: mapping
            }).result
                .finally(function () {
                    $ctrl.getAdUserSyncConfigDepartments(configId);
                });
        };

        $ctrl.canDeleteFieldMapping = function (localField) {
            var requiredFields = ['username', 'email', 'first_name', 'last_name'];
            if (requiredFields.indexOf(localField) !== -1) {
                var localFields = _.filter($ctrl.config.fields, ['localField', localField]);
                return localFields.length > 1;
            }
            return true;
        };
    }]);
