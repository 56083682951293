/**
 * @ngdoc directive
 * @name iqDirectoryObjectSearchCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.shared")
  .constant("iqDirectoryObjectSearchEvents", {
    addDirObject: "iqDirectoryObjectSearchEvents.addDirObject",
    addContactable: "iqDirectoryObjectSearchEvents.addContactable"
  })
  .controller("iqDirectoryObjectSearchCtrl", ["$scope", "$rootScope", "modalHelper", "directoryService", "$log", "$timeout", "iqDirectoryObjectSearchEvents", "eventsBus", "iqContactSearchEvents", "baseService", "contentTypes", function(
    $scope,
    $rootScope,
    modalHelper,
    directoryService,
    $log,
    $timeout,
    iqDirectoryObjectSearchEvents,
    eventsBus,
    iqContactSearchEvents,
    baseService,
    contentTypes
  ) {
    $scope.iqDirectoryObjectSearchCtrl = {
      availableDirObjects: [],
      dirObjects: []
    };

    $scope.count = 0;
    var lastQuery = "";

    function getParam(query) {
      if (query !== lastQuery) {
        $scope.iqDirectoryObjectSearchCtrl.availableDirObjects = [];
      }

      lastQuery = query;

      var param = {
        q: query,
        managed: true,
        page: baseService.pickPage(
          $scope.iqDirectoryObjectSearchCtrl.availableDirObjects.length,
          25
        )
      };

      if ($scope.searchUsers) {
        param.type = "directories.person";
      }

      return param;
    }

    $scope.searchDirectoryObjects = function(query) {
      var param = getParam(query);
      if (
        $scope.count &&
        $scope.count ===
          $scope.iqDirectoryObjectSearchCtrl.availableDirObjects.length
      ) {
        return;
      }
      var promise = directoryService.getUserDirectoryEntries(param);

      promise
        .then(function(response) {
          $scope.count = response.count;
          var availableDirObjects = _.uniq(
            $scope.iqDirectoryObjectSearchCtrl.availableDirObjects.concat(
              response.results
            ),
            "id"
          );

          $scope.iqDirectoryObjectSearchCtrl.availableDirObjects = availableDirObjects;

          var existingObjects = $scope.iqDirectoryObjectSearchCtrl.dirObjects;
          existingObjects = _.map(existingObjects, "id");

          var unfetchedObjects = _.difference(
            existingObjects,
            _.map($scope.iqDirectoryObjectSearchCtrl.availableDirObjects, "id")
          );

          if (unfetchedObjects.length) {
            _.forEach(unfetchedObjects, function(id) {
              directoryService.getDirectoryEntry(id).then(function(response) {
                $scope.iqDirectoryObjectSearchCtrl.availableDirObjects.push(
                  response
                );
              });
            });
          }
        })
        .finally(function() {
          $scope.isLoading = false;
        });
    };

    $scope.getAvatarWidth = function(selected) {
      /*
      For single directory object select only
       */
      if (!selected) {
        return 0;
      } else if (selected.avatar) {
        return 30;
      } else {
        return 35;
      }
    };

    function addToObjects(object) {
      var availableDirObjects = $scope.iqDirectoryObjectSearchCtrl.availableDirObjects;
      var objectAdded = false;

      if ($scope.propertyToMatch) {
        // Property To Match provided -- we're dealing with ids.
        var dirObjectIds = $scope.iqDirectoryObjectSearchCtrl.dirObjects;
        var idToAdd = object[$scope.propertyToMatch];
        if (!idToAdd) {
          return;
        }

        if (!_.find(dirObjectIds, function(id){ return id === idToAdd})) {
          // It's not already in dirObjectIds, add it.
          dirObjectIds.push(idToAdd);
          objectAdded = true;
        }
      } else {
        var dirObjects = $scope.iqDirectoryObjectSearchCtrl.dirObjects;

        if (!_.find(dirObjects, { id: object.id })) {
          // Add directory object to list of available directory objects for rendering
          dirObjects.push(object);
          objectAdded = true;
        }
      }

      if (objectAdded && !_.find(availableDirObjects, {id: object.id}) && object.contact_id) {
        availableDirObjects.push(object);
      }
    }

    $scope.$on(
      "$destroy",
      eventsBus.subscribe(iqDirectoryObjectSearchEvents.addDirObject, function(
        $event,
        dirObject
      ) {
        addToObjects(dirObject);
      })
    );

    $scope.$on(
      "$destroy",
      eventsBus.subscribe(
        iqDirectoryObjectSearchEvents.addContactable,
        function($event, contactable) {
          function add(obj) {
            if (obj) {
              addToObjects(obj);
            }
          }

          // If the contactable is not a care team, get their directory object
          // Else, create a 'directory object' like object
          if (contactable.type === contentTypes.contactables.careTeam) {
            directoryService
              .getDirectoryObjectForCareTeamContactId(contactable.id)
              .then(add);
          } else {
            directoryService
              .getDirectoryObjectForContactable(contactable.id)
              .then(add);
          }
        }
      )
    );

    $scope.showAdvancedSearch = function() {
      var currentlySelected;
      if ($scope.propertyToMatch) {
        currentlySelected = angular.copy(
          $scope.iqDirectoryObjectSearchCtrl.dirObjects
        );
      } else {
        currentlySelected = _.map(
          $scope.iqDirectoryObjectSearchCtrl.dirObjects,
          "id"
        );
      }
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/shared/components/iqDirectoryObjectSearch/iqAdvancedDirectoryObjectSearchModal.html",
          controller: "iqAdvancedDirectoryObjectSearchCtrl",
          size: "lg",
          currentlySelected: currentlySelected,
          showFavorites: $scope.showFavorites
        })
        .result.then(function(objects) {
          $rootScope.$broadcast(iqDirectoryObjectSearchEvents.searchComplete);
          _.forEach(objects, function(object) {
            eventsBus.publish(
              iqDirectoryObjectSearchEvents.addDirObject,
              object
            );
          });
        });
    };

    $scope.showGroupLookupModal = function() {
      var results;

      var modal = modalHelper.openModal({
        templateUrl:
          "/smartpager/angular/endUser/shared/components/iqContactSearch/iqGroupLookupModal.html",
        controller: "iqGroupLookupModalCtrl as $ctrl"
      });

      modal.result.then(function(objects) {
        results = objects;
      });

      modal.closed.then(function(objects) {
        _.forEach(results, function(contactable) {
          eventsBus.publish(
            iqDirectoryObjectSearchEvents.addContactable,
            contactable
          );
        });
      });
    };
  }]);
