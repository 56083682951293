/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:createGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('createSavedFilterModalCtrl', ["$scope", "savedFiltersService", function ($scope, savedFiltersService) {
        var self = this;

        self.savedFilter = {};
        self.contentTypes = [];

        self.$onInit = function () {
            $scope.isLoading = true;
            savedFiltersService.getContentTypes()
                .then(function (response) {
                    self.contentTypes = response;
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };

        self.createSavedFilter = function () {
            savedFiltersService.createSavedFilter(self.savedFilter.name, self.savedFilter.content_type)
                .then(function () {
                    $scope.$close();
                });
        };
    }]);
