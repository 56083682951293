/**
 * @ngdoc controller
 * @name smartpager.endUser.main:messagingCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.main')
    .controller('messagingCtrl', ["$scope", "userService", "$log", "Notification", function ($scope, userService, $log, Notification) {
        var $ctrl = this;

        $scope.$onRootScope(userService.fluxEvents.getProfile.key, function ($event, result) {
            $ctrl.user = {
                default_message_type_id: result.default_message_type_id,
                auto_response_enabled: result.auto_response_enabled,
                auto_response_text: result.auto_response_text,
                forwarding_enabled: result.forwarding_enabled,
                forward_to_id: result.forward_to_id
            };
            $ctrl.isLoading = false;
        });

        $ctrl.activate = function () {
            $ctrl.isLoading = true;
            userService.requestGetProfile();
        };

        $ctrl.activate();

        $ctrl.saveUser = function () {
            $ctrl.isLoading = true;

            if(!$ctrl.user.default_message_type_id) {
                $ctrl.user.default_message_type_id = null;
            }
            userService.updateProfile($ctrl.user)
                .then(function () {
                    Notification.success('Successfully updated profile');
                    $ctrl.activate();
                })
                .catch(function (response) {
                    $log.debug('Error with status code', response.status);
                    Notification.error('Could not update profile. Please try again.');
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };
    }]);
