/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:createGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('createGroupModalCtrl', ["$scope", "groupsService", function ($scope, groupsService) {
        var self = this;

        self.group = {};

        self.createGroup = function () {
            groupsService.createGroup(self.group.display_name)
                .then(function () {
                    $scope.$close();
                });
        };
    }]);
