/**
 * @ngdoc directive
 * @name smartpager.endUser.patients:locationDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.patients')
    .directive('locationSearch', ["$log", function ($log) {
        return {
            restrict: 'E',
            require: 'ngModel',
            transclude: true,
            scope: {
                label: '@',
                onSelect: '&'
            },
            controller: 'locationSearchCtrl as $ctrl',
            templateUrl: '/smartpager/angular/endUser/patients/components/locationSelect/locationSelect.html',
            link: function (scope, elem, attr, ngModelCtrl) {
                ngModelCtrl.$formatters.push(function(modelValue){
                    return angular.copy(modelValue);
                });

                ngModelCtrl.$parsers.push(function(viewValue){
                    return angular.copy(viewValue);
                });

                scope.$watch('locationSearchCtrl.location', function(newLocation){
                    $log.debug('locationSearch updates ngModel binding', newLocation);
                    ngModelCtrl.$setViewValue(angular.copy(newLocation));
                });
            }
        };
}]);
