/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:forwardMessageModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module('smartpager.endUser.messages')
  .controller('forwardMessageModalCtrl', ["$controller", "$scope", "alertsHelper", "$log", "modalOptions", "messageService", function(
    $controller,
    $scope,
    alertsHelper,
    $log,
    modalOptions,
    messageService
  ) {
    var $ctrl = this;
    $controller('composeMessageBaseCtrl', { $ctrl: $ctrl });

    alertsHelper.create($ctrl, 'alerts');

    $ctrl.isThreadForward = !modalOptions.messageId;
    $ctrl.modalTitle = !$ctrl.isThreadForward ? 'Forward Message' : 'Forward Thread';

    $ctrl.forwardMessage = function(recipients) {
      $log.debug(
        String.format(
          'Forwarding thread {0} message {1} to {2}',
          modalOptions.threadId,
          modalOptions.messageId,
          recipients
        )
      );

      $ctrl.forwarding = true;

      var recipientIds = !$ctrl.usesDirectoryComposition ? _.map(recipients, 'id') : _.map(recipients, 'contact_id');

      messageService
        .forwardMessage(
          modalOptions.threadId,
          modalOptions.messageId,
          recipientIds
        )
        .then(function(response) {
          messageService.getThreadById(response.id).then(function(thread) {
            $scope.$close(thread);
          });
        })
        .catch(function() {
          $ctrl.alerts.error('Could not forward a message');
        })
        .finally(function() {
          $ctrl.forwarding = false;
        });
    };

    $ctrl.forwardThread = function(recipients) {
      $log.debug(
        String.format(
          'Forwarding thread {0} to {1}',
          modalOptions.threadId,
          recipients
        )
      );

      $ctrl.forwarding = true;
      var recipientIds = !$ctrl.usesDirectoryComposition ? _.map(recipients, 'id') : _.map(recipients, 'contact_id');

      messageService
        .forwardThread(modalOptions.threadId, recipientIds)
        .then(function(response) {
          messageService.getThreadById(response.id).then(function(thread) {
            $scope.$close(thread);
          });
        })
        .catch(function() {
          $ctrl.alerts.error('Could not forward a thread');
        })
        .finally(function() {
          $ctrl.forwarding = false;
        });
    };

    $ctrl.$onInit = function() {
      $ctrl.reloadSavedContacts();
      $ctrl.reloadLocalGroups();
    };
  }]);
