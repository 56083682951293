/**
 * @ngdoc controller
 * @name smartpager.endUser.main:userProfileModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.main')
    .controller('userProfileModalCtrl', ["$scope", "userService", "Notification", "alertsHelper", "modalOptions", function ($scope, userService, Notification, alertsHelper, modalOptions) {

        var $ctrl = this;
        $ctrl.user = {
            first_name: modalOptions.user.first_name,
            last_name: modalOptions.user.last_name,
            title: modalOptions.user.title,
            home_number: modalOptions.user.home_number
        };

        alertsHelper.create($ctrl, 'alerts');

        $ctrl.saveUser = function () {
            $ctrl.isSaving = true;
            userService.updateProfile($ctrl.user)
                .then(function () {
                    Notification.success('Successfully updated profile');
                    $scope.$close();
                })
                .catch(function (response) {
                    $ctrl.alerts.error('Could not save profile');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
