angular
  .module('smartpager.endUser.main', [
    'smartpager.endUser.messages',
    'smartpager.endUser.messageRouting',
    'smartpager.endUser.patients',
    'smartpager.endUser.pagingGroups',
    'smartpager.endUser.accountSettings',
    'smartpager.endUser.users',
    'smartpager.endUser.reports'
  ])
  .config(["$stateProvider", "$urlRouterProvider", function($stateProvider, $urlRouterProvider) {

    //root state of application
    $stateProvider
      .state('root', {
        abstract: true,
        url: '/',
        templateUrl:
          '/smartpager/angular/endUser/_main/controllers/rootCtrl.html',
        controller: 'rootCtrl',
        data: {
          title: 'Telmediq',
          requiredPermissions: []
        }
      })
      .state('root.userProfile', {
        url: 'userProfile',
        abstract: true,
        templateUrl:
          '/smartpager/angular/endUser/_main/controllers/userProfile/userProfile.html',
        controller: 'userProfileCtrl as $ctrl',
        data: {
          title: 'Telmediq - User Profile',
          requiredPermissions: []
        }
      })
      .state('root.userProfile.notifications', {
        url: '/notifications',
        templateUrl:
          '/smartpager/angular/endUser/_main/controllers/userProfile/notifications.html',
        controller: 'notificationsCtrl as $ctrl'
      })
      .state('root.userProfile.messaging', {
        url: '/messaging',
        templateUrl:
          '/smartpager/angular/endUser/_main/controllers/userProfile/messaging.html',
        controller: 'messagingCtrl as $ctrl'
      });
  }]);

// this was done to prevent legacy pages from going to /#/inbox automatically
angular
  .module('smartpager.endUser.spa', ['smartpager.endUser.main'])
  .config(function() {
    // Polyfill Object.assign because IE is IE.
    if (typeof Object.assign !== 'function') {
      // Must be writable: true, enumerable: false, configurable: true
      Object.defineProperty(Object, 'assign', {
        value: function assign(target, varArgs) {
          // .length of function is 2
          'use strict';
          if (target === null) {
            // TypeError if undefined or null
            throw new TypeError('Cannot convert undefined or null to object');
          }

          var to = Object(target);

          for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];

            if (nextSource !== null) {
              // Skip over if undefined or null
              for (var nextKey in nextSource) {
                // Avoid bugs when hasOwnProperty is shadowed
                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                  to[nextKey] = nextSource[nextKey];
                }
              }
            }
          }
          return to;
        },
        writable: true,
        configurable: true
      });
    }
  })
  .config(["$stateProvider", "$urlRouterProvider", function($stateProvider, $urlRouterProvider) {
    // when invalid url redirect to inbox
    $urlRouterProvider.otherwise('/inbox');
  }]);
