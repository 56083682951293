/**
 * @ngdoc controller
 * @name scheduleNewEventModalCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.pagingGroups')
    .controller('scheduleNewEventModalCtrl', ["$scope", "$state", "modalOptions", "$log", "eventService", "alertsHelper", function ($scope, $state, modalOptions, $log, eventService, alertsHelper) {
        var self = this;
        alertsHelper.create($scope, 'alerts');
        $scope.modalOptions = modalOptions;

        $scope.title = modalOptions.event.event_id ? modalOptions.event.title : 'Add a shift to schedule';
        $scope.buttonText = modalOptions.event.event_id ? 'Update' : 'Create';

        $scope.save = function () {
            $log.debug('saving', $scope.modalOptions.event);
            $scope.saving = true;

            eventService.getUpdateFor($scope.modalOptions.event)
                .then(function (update_for) {
                    modalOptions.event.update_for = update_for;

                    (modalOptions.event.event_id ? eventService.updateEvent : eventService.createEvent)($scope.modalOptions.group.id, $scope.modalOptions.event)
                        .then(function (result) {
                            result.update_for = update_for;
                            $scope.$close(result);
                        })
                        .catch(function () {
                            $scope.alerts.error('Error saving event');
                        })
                        .finally(function () {
                            $scope.saving = false;
                        });
                });

        };

        $scope.delete = function () {
            eventService.getUpdateFor($scope.modalOptions.event)
                .then(function (update_for) {
                    modalOptions.event.update_for = update_for;

                    eventService.deleteEvent($scope.modalOptions.group.id, $scope.modalOptions.event)
                        .then(function () {
                            $scope.modalOptions.event.deleted = true;

                            $scope.$close($scope.modalOptions.event);
                        });
                });

        };

        $scope.edit = function () {
            $log.debug('editEvent');

            $state.go('root.pagingGroups.scheduleGroup.editEvent', {
                user_id: $scope.modalOptions.event.target_id,
                start: $scope.modalOptions.event.start,
                end: $scope.modalOptions.event.end,
                escalation_policy_id: $scope.modalOptions.event.escalation_policy_id,

                groupId: $scope.modalOptions.group.id,
                eventId: $scope.modalOptions.event.event_id,
                originalStart: $scope.modalOptions.event.original_start ? $scope.modalOptions.event.original_start.toISOString() : $scope.modalOptions.event.start.toISOString()
            });
            $scope.$dismiss();
        };

    }]);
