/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addMemberToGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addTagToSavedFilterModalCtrl', ["$scope", "savedFiltersService", "modalOptions", "Notification", function ($scope, savedFiltersService, modalOptions, Notification) {
        var self = this;

        self.selected = [];
        self.contentType = modalOptions.contentType;

        $scope.addSelected = function () {
            console.log(self.selected);
            savedFiltersService.addTags(modalOptions.savedFilterId, self.selected)
                .then(function (response) {
                    var addedTags = _.map(response, 'classified_name').join(', ');
                    Notification.success('Successfully added ' + addedTags+ ' to the saved filter.');
                    $scope.$close();
                });
        };
    }]);
