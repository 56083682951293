/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('groupDetailCtrl', ["$scope", "$stateParams", "groupsService", "Notification", "modalHelper", "NgTableParams", "ngTableHelper", "$q", "$log", "$state", function ($scope, $stateParams, groupsService, Notification, modalHelper, NgTableParams, ngTableHelper, $q, $log, $state) {

        $scope.group = {};

        this.$onInit = function () {
            $scope.isLoading = true;
            groupsService.getGroup($stateParams.groupId)
                .then(function (group) {
                    $scope.originalName = group.display_name;
                    $scope.group = _.merge($scope.group, group);
                })
                .catch(function () {
                    $state.go('root.accountSettings.groups.list');
                    Notification.warning(String.format('Group with id `{0}` does not exist', $stateParams.groupId));
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };

        $scope.saveGroup = function () {
            groupsService.saveGroup($scope.group.id, $scope.group.display_name)
                .then(function () {
                    Notification.success(String.format('Successfully saved changes to group `{0}`', $scope.group.display_name));
                });
        };

        $scope.deleteGroup = function () {
            var response = modalHelper.openModal({
                title: 'Delete group',
                content: String.format('Are you sure you want to delete group {0}?', $scope.originalName),
                buttons: [
                    {id: 'yes', type: 'primary', label: 'Delete group'},
                    {id: 'cancel', type: 'default', label: 'Cancel'}
                ]
            }).result
                .then(function () {
                    return groupsService.deleteGroup($scope.group.id);
                })
                .then(function () {
                    $state.go('root.accountSettings.groups.list');
                });
        };
    }]);
