/**
 * @ngdoc directive
 * @name infiniteBottomScroll
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('infiniteBottomScroll', ["$rootScope", "inboxEvents", "$timeout", "$log", function ($rootScope, inboxEvents, $timeout, $log) {
        return {
            restrict: 'A',
            scope: {
                disabled: '=infiniteBottomScrollDisabled',
                callback: '&infiniteBottomScroll'
            },
            link: function (scope, elem) {

                var registerScrollOnce = _.once(function () {
                    $log.debug('setting up scroll handler once');

                    elem.on('scroll', _.throttle(function ($event) {
                        //$log.info($event.target.clientHeight, $event.target.scrollTop, $event.target.scrollHeight);

                        var shouldScroll = $event.target.clientHeight < $event.target.scrollHeight;
                        var isAtTheTop = $event.target.scrollTop === 0;

                        if (shouldScroll && isAtTheTop && !scope.disabled) {
                            scope.callback();
                        }
                    }, 250));
                });


                var scrollToTheBottom = function () {
                    $timeout(function () {
                        elem.scrollTop(elem[0].scrollHeight - elem[0].clientHeight);
                    });

                    //register after initial load
                    registerScrollOnce();
                };


                var lastScrollHeigth;
                var unregisterNewMessage = $rootScope.$on(inboxEvents.newMessage, scrollToTheBottom);
                var unregisterScrollBottom = $rootScope.$on(inboxEvents.scrollBottom, scrollToTheBottom);

                var lockScroll = $rootScope.$on(inboxEvents.lockScroll, function () {
                    lastScrollHeigth = elem[0].scrollHeight;
                    $log.debug('lastScrollHeight', lastScrollHeigth);
                });

                var restoreScroll = $rootScope.$on(inboxEvents.restoreScroll, function () {
                    $timeout(function () {
                        var delta = elem[0].scrollHeight - lastScrollHeigth;
                        $log.debug('restoreScroll', delta);
                        elem.scrollTop(elem.scrollTop() + delta);
                    });
                });

                scope.$on('$destroy', function () {
                    unregisterNewMessage();
                    unregisterScrollBottom();
                    lockScroll();
                    restoreScroll();
                    elem.off('scroll');
                });
            }
        };
    }]);
