/**
 * @ngdoc directive
 * @name smartpager.endUser.messages:infiniteScroll
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('infiniteScroll', ["$log", function ($log) {
        return {
            restrict: 'A',
            scope: {
                disabled: '=infiniteScrollDisabled',
                callback: '&infiniteScroll'
            },
            link: function (scope, elem, attr) {
                elem.on('scroll', _.throttle(function ($event) {

                    var shouldScroll = $event.target.clientHeight < $event.target.scrollHeight;
                    var isAtTheBottom = $event.target.scrollTop + $event.target.clientHeight === $event.target.scrollHeight;

                    if (shouldScroll && isAtTheBottom && !scope.disabled) {
                        scope.callback();
                    }
                }, 250));

                scope.$on('$destroy', function () {
                    elem.off('scroll');
                });
            }
        };
    }]);
