/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:deviceReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .factory('deviceReportCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('deviceReportCache');
    }])
    .controller('deviceReportCtrl', ["$scope", "reportSlug", "reportService", "NgTableParams", "ngTableHelper", "deviceReportCache", function($scope, reportSlug, reportService, NgTableParams, ngTableHelper, deviceReportCache){
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams(deviceReportCache.get('parameters') || {}, {
            getData: function(params){
                $ctrl.isLoading = true;
                deviceReportCache.put('parameters', params.parameters());
                return reportService.runReport(reportSlug, params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search'),
            type: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'type')
        };

        $ctrl.downloadCsv = function(){
            reportService.downloadReportAsCSV(reportSlug, deviceReportCache.get('parameters'), 'Device Report.csv');
        };
}]);
