/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:editPatientModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('editPatientModalCtrl', ["$scope", "modalOptions", "patientsService", "Notification", function($scope, modalOptions, patientsService, Notification){
        var $ctrl = this;

        $ctrl.activate = function(){
            $ctrl.isLoading = true;
            patientsService.getPatientById(modalOptions.patientId)
                .then(function(response){
                    $ctrl.patient = response;
                })
                .finally(function(){
                    $ctrl.isLoading = false;
                });
        };
        $ctrl.activate();

        $ctrl.save = function(){
            $ctrl.isLoading = true;
            $ctrl.patient.dob = new Date($ctrl.patient.date_of_birth);
            patientsService.updatePatient($ctrl.patient)
                .then(function(response){
                    Notification.success('Successfully updated patient');
                    $scope.$close(response);
                })
                .catch(function(){
                    Notification.error('Could not update patient');
                })
                .finally(function(){
                    $ctrl.isLoading = false;
                });
        };
}]);
