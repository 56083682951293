/**
 * @ngdoc directive
 * @name smartpager.endUser.messageRouting:messageRoutingConditionSetDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messageRouting')
    .directive('messageRoutingConditionSet', function () {
        return {
            restrict: 'E',
            scope: {
                conditionSet: '=obj',
                onRemove: '&'
            },
            bindToController: true,
            controller: 'messageRoutingConditionSetCtrl as $ctrl',
            templateUrl: '/smartpager/angular/endUser/messageRouting/components/messageRoutingConditionSet.html',
            link: function(scope, elem, attrs){

            }
        };
});
