/**
 * @ngdoc directive
 * @name smartpager.endUser.shared:voiceRecorderDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.shared')
    .directive('voiceRecorderDirective', ["$sce", function ($sce) {
        return {
            restrict: 'E',
            templateUrl: '/smartpager/angular/endUser/shared/components/voiceRecorder/voiceRecorder.html',
            scope: {
                uploader: '=',
                saveCallback: '=',
                recorderId: '=',
                audioSrc: '='
            },
            link: function (scope, elem, attr) {
            }
        };
}]);
