/**
 * @ngdoc directive
 * @name smartpager.endUser.patients:csvUploadDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.patients')
    .component('csvUpload', {
        templateUrl: '/smartpager/angular/endUser/patients/components/csvUpload/csvUpload.html',
        bindings: {
            uploader: '<',
            multiple: '@'
        },
        controller: ['$scope', function ($scope) {
            var $ctrl = this;

            $ctrl.uploader.allowedExtensions = '.csv';

            $ctrl.uploader.filters.push({
                name: 'invalidFileType',
                fn: function (item, options) {
                    return item.name.indexOf($ctrl.uploader.allowedExtensions) !== -1;
                }
            });

            $ctrl.uploader.onAfterAddingFile = function (item, filter, options) {
                $ctrl.error = '';
            };

            $ctrl.uploader.onWhenAddingFileFailed = function (item, filter, options) {
                if (filter.name === 'invalidFileType') {
                    $ctrl.error = String.format('Only {0} files are allowed', $ctrl.uploader.allowedExtensions);
                }
            };
        }]
    });
