/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:addMessageRoutingPolicyModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('addMessageRoutingPolicyModalCtrl', ["$scope", "alertsHelper", "messageRoutingService", "Notification", "focus", "eventsBus", function($scope, alertsHelper, messageRoutingService, Notification, focus, eventsBus){
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');
        $ctrl.policy = {};

        $ctrl.createRoutingPolicy = function(){
            if(!$ctrl.form.$valid){
                $ctrl.form.$setSubmitted();
                return;
            }
            $ctrl.isSaving = true;
            messageRoutingService.createRoutingPolicy($ctrl.policy)
                .then(function(response){
                    if($ctrl.addAnother){
                        $ctrl.form.$setUntouched();
                        $ctrl.alerts.success('Successfully created ' + response.name);
                        $ctrl.template = {};
                    } else {
                        Notification.success('Successfully created note template');
                        $scope.$close(response);
                    }
                })
                .catch(function(response){
                    $ctrl.serverErrors = response.data;
                    eventsBus.publish('serverError', $ctrl.serverErrors);
                    $ctrl.alerts.error('Could not create routing policy');
                })
                .finally(function(){
                    $ctrl.isSaving = false;
                });
        };
}]);
