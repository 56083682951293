/**
 * @ngdoc controller
 * @name smarpager.endUser.shared:iqAdvancedDirectoryObjectSearchCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.shared")
  .factory("iqAdvancedDirectoryObjectSearchModalCache", ["$cacheFactory", function(
    $cacheFactory
  ) {
    return $cacheFactory("iqAdvancedContactSearchModalCache");
  }])
  .controller("iqAdvancedDirectoryObjectSearchCtrl", ["$scope", "modalOptions", "userService", "directoryService", "NgTableParams", "ngTableHelper", "$filter", "iqAdvancedDirectoryObjectSearchModalCache", "configuration", "$log", "contentTypes", function(
    $scope,
    modalOptions,
    userService,
    directoryService,
    NgTableParams,
    ngTableHelper,
    $filter,
    iqAdvancedDirectoryObjectSearchModalCache,
    configuration,
    $log,
    contentTypes
  ) {
    var self = this;

    $scope.showFavorites = modalOptions.showFavorites;
    $scope.favoriteIds = [];
    $scope.pinnedContactIds = [];
    self.favorites = {};

    function getFavorites() {
      directoryService.getFavoritedDirectoryObjects().then(function(resp) {
        $scope.favoriteIds = _.map(resp.results, "object_id");
        _.forEach(resp.results, function(favorite) {
          self.favorites[favorite.object_id] = favorite.id;
        });
      });
    }

    function getPinnedContacts() {
      userService.getPinnedContacts().then(function(resp) {
        $scope.pinnedContactIds = _.map(resp.results, "id");
      });
    }

    $scope.tableParams = new NgTableParams(
      iqAdvancedDirectoryObjectSearchModalCache.get("parameters") || {
        sorting: {
          name: "asc"
        }
      },
      {
        getData: function(params) {
          iqAdvancedDirectoryObjectSearchModalCache.put(
            "parameters",
            params.parameters()
          );

          $scope.isLoading = true;

          getPinnedContacts();
          getFavorites();

          return directoryService
            .getUserDirectoryObjectsForGrid(params.parameters())
            .then(function(response) {
              params.total(response.count);

              response.results = _.filter(response.results, 'managed');

              _.forEach(response.results, function(item) {
                item.alreadySelected =
                  modalOptions.currentlySelected.indexOf(item.id) >= 0;
              });

              return response.results;
            })
            .finally(function() {
              $scope.isLoading = false;
            });
        }
      }
    );

    $scope.types = [
      { id: contentTypes.directories.person, name: "Person" },
      { id: contentTypes.directories.group, name: "Group" }
    ];

    $scope.searchParams = {
      search: ngTableHelper.generateGetterSetter($scope.tableParams, "search"),
      department: ngTableHelper.generateGetterSetter(
        $scope.tableParams,
        "department"
      ),
      type: ngTableHelper.generateGetterSetter($scope.tableParams, "type"),
      title: ngTableHelper.generateGetterSetter($scope.tableParams, "title")
    };

    self.addSelected = function() {
      $scope.$close($scope.selected.objects);
    };

    self.close = function() {
      $scope.$close([]);
    };

    $scope.selected = {
      objects: [],
      all: false
    };

    // Watch for "select all" checkbox
    $scope.$watch("selected.all", function(value) {
      if (value) {
        angular.forEach($scope.tableParams.data, function(item) {
          // add all that aren't yet on the list.
          if (!_.some($scope.selected.objects, { id: item.id })) {
            $scope.selected.objects.push(item);
          }
        });
      } else {
        $scope.selected.objects = [];
      }
    });

    // Watch for data checkboxes
    $scope.$watchCollection("selected.objects", function() {
      if (!$scope.tableParams.data) {
        return;
      }
      var checked = $scope.selected.objects.length;
      var total = $scope.tableParams.data.length;

      // grayed checkbox
      angular
        .element(document.getElementById("select_all_objects"))
        .prop("indeterminate", checked > 0 && checked !== total);
    });

    $scope.addFavorite = function(object) {
      directoryService.favoriteDirectoryObject(object.id).then(function(resp) {
        $scope.favoriteIds.push(object.id);
        self.favorites[object.id] = resp.id;
      });
    };

    $scope.removeFavorite = function(object) {
      directoryService
        .unfavoriteDirectoryObject(self.favorites[object.id])
        .then(function() {
          var idx = $scope.favoriteIds.indexOf(object.id);
          var ret = $scope.favoriteIds.splice(idx, 1);
          delete self.favorites[object.id];
        });
    };
  }]);
