/**
 * @ngdoc controller
 * @name editRecurringRuleModalCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.pagingGroups')
    .constant('angular_ui_scheduler_frequencyOptions', [
        {name: 'None (run once)', value: 'none', intervalLabel: ''},
        {name: 'Day', value: 'daily', intervalLabel: 'day(s)'},
        {name: 'Week', value: 'weekly', intervalLabel: 'week(s)'},
        {name: 'Month', value: 'monthly', intervalLabel: 'month(s)'}
    ])
    .controller('editRecurringRuleModalCtrl', ["$scope", "modalOptions", "rRuleService", "$log", function ($scope, modalOptions, rRuleService, $log) {
        var self = this;

        self.modalOptions = modalOptions;

        $scope.saveRRule = function () {
            if (self.rule.options.frequency === 'none') {
                $scope.$dismiss();
            }
            self.isSaving = true;
            rRuleService.create(self.rule)
                .then(function (result) {
                    $scope.$close(result);
                })
                .finally(function () {
                    self.isSaving = false;
                });
        };
    }]);
