/**
 * @ngdoc service
 * @name smartpager.endUser.shared:roleService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .factory('roleService', ["baseService", "configuration", function (baseService, configuration) {
        var Service = new Object(baseService);

        Service.getRolesForAccount = function (accountId, params) {
            var url = String.format('/api/v1/accounts/{0}/roles/{1}', accountId, this.toUrl(params));
            return this.getData(url);
        };

        Service.getRole = function (roleId) {
            var accountId = configuration.getAccountId();
            var url = String.format('/api/v1/accounts/{0}/roles/{1}/', accountId, roleId);
            return this.getData(url);
        };

        Service.createRole = function (accountId, role) {
            var url = String.format('/api/v1/accounts/{0}/roles/', accountId);
            return this.postData(url, role);
        };

        Service.updateRole = function (role) {
            var accountId = configuration.getAccountId();
            var url = String.format('/api/v1/accounts/{0}/roles/{1}/', accountId, role.id);
            return this.putData(url, role);
        };

        Service.deleteRole = function (roleId) {
            var accountId = configuration.getAccountId();
            var url = String.format('/api/v1/accounts/{0}/roles/{1}/', accountId, roleId);
            return this.deleteData(url);
        };

        Service.getPermissions = function (scope) {
            var url = String.format('/api/v1/auth/permissions/', scope);
            return this.getData(url, {cache: true});
        };

        return Service;
    }]);
