/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:deleteTemplateModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('deleteTemplateModalCtrl', ["$scope", "modalOptions", "patientsService", "Notification", "alertsHelper", function ($scope, modalOptions, patientsService, Notification, alertsHelper) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.activate = function () {
            $ctrl.template = modalOptions.template;
        };
        $ctrl.activate();

        $ctrl.delete = function () {
            $ctrl.isDeleting = true;
            patientsService.deleteNoteTemplate($ctrl.template.id)
                .then(function () {
                    Notification.success('Successfully deleted note field');
                    $scope.$close();
                })
                .catch(function () {
                    $ctrl.alerts.error('Could not delete note field');
                })
                .finally(function () {
                    $ctrl.isDeleting = false;
                });
        };
    }]);
