/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:baseSendControllerCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.messages")
  .controller("composeMessageBaseCtrl", ["$ctrl", "$rootScope", "$q", "pagingGroupService", "modalHelper", "userService", "directoryService", "alertsHelper", "iqDirectoryObjectSearchEvents", "iqContactSearchEvents", "eventsBus", function(
    $ctrl,
    $rootScope,
    $q,
    pagingGroupService,
    modalHelper,
    userService,
    directoryService,
    alertsHelper,
    iqDirectoryObjectSearchEvents,
    iqContactSearchEvents,
    eventsBus
  ) {
    $ctrl.recipients = [];
    $ctrl.usesDirectoryComposition =
      $rootScope.hasOwnProperty("hasFeature") &&
      $rootScope.hasFeature("directoryComposition");
    function isContactable(contact) {
      return contact.id.startsWith("CO");
    }

    $ctrl.addManyToRecipients = function(contacts) {
      _.forEach(contacts, function(contact) {
        $ctrl.addToRecipients(contact);
      });
    };

    $ctrl.addToRecipients = function(contact) {
      if (isContactable(contact)) {
        if ($ctrl.usesDirectoryComposition) {
          eventsBus.publish(
            iqDirectoryObjectSearchEvents.addContactable,
            contact
          );
        } else {
          eventsBus.publish(iqContactSearchEvents.addRecipient, contact);
        }
      } else {
        eventsBus.publish(iqDirectoryObjectSearchEvents.addDirObject, contact);
      }
    };

    $ctrl.getOnCallUsers = function(contact) {
      if (contact.type === "ScheduleGroup") {
        if (
          !contact.onCallUsersFetched ||
          (contact.onCallUsersFetched &&
            moment()
              .subtract(30, "s")
              .isAfter(contact.onCallUsersFetched))
        ) {
          contact.onCallUsersDisplay = "loading";
          pagingGroupService.getOnCall(contact.group).then(function(result) {
            _.merge(contact, result);
          });
        }
      }
    };

    $ctrl.saveAsLocalGroup = function(recipients) {
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/messages/controllers/modals/createLocalGroupModal.html",
          controller: "createLocalGroupModalCtrl as $ctrl",
          members: recipients,
          useDirectory: $ctrl.usesDirectoryComposition
        })
        .result.then(function() {
          $ctrl.reloadLocalGroups();
        });
    };

    $ctrl.deleteLocalGroup = function(localGroup) {
      userService.deleteLocalGroup(localGroup.id).then(function() {
        _.remove($ctrl.localGroups, { id: localGroup.id });
        $ctrl.alerts.success(
          String.format("Removed local group {0}", localGroup.name)
        );
      });
    };

    $ctrl.reloadSavedContacts = function() {
      $ctrl.isLoadingSavedContacts = true;
      $ctrl.savedContacts = [];
      var promises = [];
      var pinnedObjects = [];
      var favoritedObjects = [];

      var pinnedContactsPromise = userService
        .getPinnedContacts()
        .then(function(response) {
          if (!$ctrl.usesDirectoryComposition) {
            pinnedObjects = response.results;
          } else {
            var pinnedContactIds = _.map(response.results, "id");

            if (pinnedContactIds.length) {
              return directoryService
                .getDirectoryObjectsForContactableIds(pinnedContactIds)
                .then(function(response) {
                  pinnedObjects = response.results;
                });
            }
          }
        });
      promises.push(pinnedContactsPromise);

      if ($ctrl.usesDirectoryComposition) {
        var favoritedDirectoryObjectPromise = directoryService
          .getFavoritedDirectoryObjects({ managed: true })
          .then(function(response) {
            var objectIds = _.map(response.results, "object_id");
            if (!objectIds.length) {
              return;
            }
            return directoryService
              .getUserDirectoryObjectsForGrid({
                uids: objectIds.join(),
                managed: true
              })
              .then(function(response) {
                favoritedObjects = response.results;
                favoritedObjects = _.filter(favoritedObjects, "managed");
              });
          });
        promises.push(favoritedDirectoryObjectPromise);
      }

      $q.all(promises)["finally"](function() {
        $ctrl.isLoadingSavedContacts = false;
        var savedContacts = _.concat(pinnedObjects, favoritedObjects);
        savedContacts = _.uniq(savedContacts);
        $ctrl.savedContacts = _.sortBy(savedContacts, "name");
      });
    };

    $ctrl.reloadLocalGroups = function() {
      $ctrl.isLoadingLocalGroups = true;
      return userService
        .getLocalGroups()
        .then(function(response) {
          $ctrl.localGroups = response.results;
        })
        ["finally"](function() {
          $ctrl.isLoadingLocalGroups = false;
        });
    };
  }]);
