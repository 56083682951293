/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:actionListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .factory('actionListCtrlCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('actionListCtrl');
    }])
    .controller('actionListCtrl', ["$scope", "actionListCtrlCache", "NgTableParams", "messageRoutingService", "ngTableHelper", "routingModalsFactory", "Notification", function ($scope, actionListCtrlCache, NgTableParams, messageRoutingService, ngTableHelper, routingModalsFactory, Notification) {
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams(actionListCtrlCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                actionListCtrlCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;

                return messageRoutingService.getActionsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.clearSearch = function () {
            $ctrl.searchParams.search(null);
        };

        $ctrl.openCreateActionModal = function (action) {
            return routingModalsFactory.showCreateEditActionModal(action)
                .finally(function () {
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.delete = function (action) {
            messageRoutingService.deleteAction(action.url_prefix, action.id)
                .then(function () {
                    Notification.success(String.format('Successfully deleted {0}', action.name));
                    $ctrl.tableParams.reload();
                })
                .catch(function () {
                    Notification.error(String.format('Could not deleted {0}, it is in use', action.name));
                });
        };
    }]);
