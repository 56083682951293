/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:monitorThreadModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module('smartpager.endUser.messages')
  .controller('monitorModalCtrl', ["$scope", "userService", "alertsHelper", "configuration", "Notification", function(
    $scope,
    userService,
    alertsHelper,
    configuration,
    Notification
  ) {
    var $ctrl = this;

    function init() {
      $ctrl.observedUsers = [];
      $ctrl.monitor = null;
      $ctrl.duration = null;
      $ctrl.isLoading = false;
      alertsHelper.create($ctrl, 'alerts');

      getObservedUsers();
    }

    function getObservedUsers() {
      userService
        .getObservedUsers(configuration.getUserId())
        .then(function(response) {
          for (var i = 0; i < response.length; i++) {
            response[i].expiryString = moment().to(moment(response[i].expiry));
          }
          $ctrl.observedUsers = _.sortBy(response, 'display_name');
        });
    }

    $ctrl.stopMonitoring = function(userId, id) {
      userService
        .deleteMonitor(userId, id)
        .then(function() {
          $ctrl.observedUsers = _.filter($ctrl.observedUsers, function(o) {
            return o.id !== id;
          });
        })
        .catch(function() {
          $ctrl.alerts.error('Could not end monitoring');
        });
    };

    $ctrl.submit = function() {
      $ctrl.isLoading = true;

      userService
        .monitorUser($ctrl.monitor.id, {
          monitor_id: configuration.getUserId(),
          duration: $ctrl.duration * 60 // Server expects duration in minutes, but for now will only be configured in hours
        })
        .then(function() {
          Notification.success(
            String.format(
              'Successfully started to monitor {0}.',
              $ctrl.monitor.display_name
            )
          );
          $scope.$close();
        })
        .catch(function(response) {
          if (response.status === 400 && response.data.non_field_errors) {
            $ctrl.alerts.error(response.data.non_field_errors);
          } else if (response.status === 403 && response.data.detail) {
            $ctrl.alerts.error(response.data.detail);
          } else {
            $ctrl.alerts.error('Could not monitor user');
          }
        })
        .finally(function() {
          $ctrl.isLoading = false;
        });
    };

    init();
  }]);
