/**
 * @ngdoc directive
 * @name smartpager.endUser.messages:responseOptionsSelect
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('responseOptionsSelect', ["$log", function ($log) {
        return {
            restrict: 'E',
            require: 'ngModel',
            templateUrl: '/smartpager/angular/endUser/messages/components/responseOptionsSelect/responseOptionsSelect.html',
            scope: {},
            link: function (scope, elem, iAttrs, ngModelCtrl) {
                //region handle ngModelCtrl

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return angular.copy(modelValue);
                });

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return angular.copy(viewValue);
                });

                scope.$watchCollection('$ctrl.options', function (item) {
                    $log.debug('reponseOptionsSelect updates ngModel binding', item);
                    ngModelCtrl.$setViewValue(angular.copy(item));
                });

                ngModelCtrl.$render = function () {
                    scope.$ctrl.options = ngModelCtrl.$viewValue;
                };
                //endregion
            },
            controller: ["$scope", function ($scope) {
                var $ctrl = this;
                $ctrl.options = [];

                $ctrl.addOption = function () {
                    $ctrl.options.push('');
                    $ctrl.verifyDuplicatedResponseOptions();
                };

                $ctrl.removeOption = function (index) {
                    $ctrl.options.splice(index, 1);
                    $ctrl.verifyDuplicatedResponseOptions();
                };

                $ctrl.verifyDuplicatedResponseOptions = function () {
                    $ctrl.counts = {};
                    $ctrl.isDuplicate = {};

                    if ($ctrl.options) {
                        _.forEach($ctrl.options, function (item, index) {
                            if (!$ctrl.counts[item]) {
                                $ctrl.counts[item] = 1;
                            } else {
                                $ctrl.counts[item] += 1;
                            }
                        });

                        _.forEach($ctrl.options, function (item, index) {
                            $ctrl.isDuplicate[index] = $ctrl.counts[item] > 1;
                        });

                        var isValid = _.every(_.values($ctrl.isDuplicate), function (item) {
                            return item === false;
                        });

                        $scope.responseOptionsForm.$setValidity('duplicate', isValid);
                    }
                };

            }],
            controllerAs: '$ctrl'
        };
    }]);
