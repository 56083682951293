/**
 * @ngdoc directive
 * @name smartpager.endUser.messages:keypressEnterDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('keypressEnter', function () {
        return {
            restrict: 'A',
            scope: {
                callback : '&keypressEnter'
            },
            link: function (scope, elem, attr) {
                elem.bind('keypress', function (event) {
                    if (event.which === 13) {

                        scope.$apply(function () {
                            scope.callback({$event: event});
                        });
                    }
                });
            }
        };
    });
