/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:rolloutReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .controller('rolloutReportCtrl', ["$scope", "reportSlug", "reportService", "NgTableParams", "ngTableHelper", "usageReportCache", function ($scope, reportSlug, reportService, NgTableParams, ngTableHelper, usageReportCache) {
        var $ctrl = this;

        $ctrl.activate = function () {
            $ctrl.isLoading = true;

            return reportService.runReport(reportSlug)
                .then(function (response) {
                    $ctrl.isEmptyReport = !response.length;
                    $ctrl.options = reportService.getRolloutReportChartConfig(response);
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };
        $ctrl.activate();

        $ctrl.downloadCsv = function () {
            reportService.downloadReportAsCSV(reportSlug, {}, 'Rollout Report.csv');
        };
    }]);
