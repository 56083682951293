/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addShiftMappingModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('addShiftMappingCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('addShiftMappingListCache');
    }])
    .controller('addShiftMappingModalCtrl', ["$scope", "Notification", "integrationsService", "modalOptions", "NgTableParams", "ngTableHelper", "addShiftMappingCache", function($scope, Notification, integrationsService, modalOptions, NgTableParams, ngTableHelper, addShiftMappingCache){
        var $ctrl = this;
        var configId = modalOptions.configId;
        var scheduleId = modalOptions.scheduleId;
        var addedShifts = modalOptions.addedShifts;

        $ctrl.$onInit = function(){
            integrationsService.getTangierShifts(configId)
                .then(function(response){
                    $ctrl.shifts = response.results;
                });
        };

        $ctrl.inAddedShifts = function(id){
            return addedShifts.indexOf(id) !== -1;
        };

        $ctrl.addShiftTableParams = new NgTableParams(addShiftMappingCache.get('parameters') || {
                count: 10
            }, {
                getData: function(params){
                    addShiftMappingCache.put('parameters', params.parameters());
                    $ctrl.isLoading = true;
                    return integrationsService.getTangierShifts(configId, params.parameters())
                        .then(function(response){
                            params.total(response.count);
                            return response.results;
                        })
                        .finally(function(){
                            $ctrl.isLoading = false;
                        });
                }
        });

        $ctrl.addShiftSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.addShiftTableParams, 'search')
        };


        $ctrl.addShiftMapping = function (shiftId) {
            $ctrl.isLoading = true;
            integrationsService.createTangierShiftMapping(configId, scheduleId, shiftId)
                .then(function(){
                    Notification.success('Successfully added shift to schedule mapping');
                    addedShifts.push(shiftId);
                })
                .catch(function(){
                    Notification.error('Could not add shift to schedule mapping');
                })
                .finally(function(){
                    $ctrl.isLoading = false;
                    return $ctrl.tableParams.reload();
                });
        };

        $ctrl.deleteShiftMapping = function(shiftId){
            $ctrl.isLoading = true;
            integrationsService.deleteTangierShiftMapping(configId, scheduleId, shiftId)
                .then(function(){
                    Notification.success('Successfully removed shift from schedule mapping');
                    var idx = addedShifts.indexOf(shiftId);

                    if (idx > -1){
                        addedShifts.splice(idx, 1);
                    }
                })
                .catch(function(){
                    Notification.error('Could not remove shift from schedule mapping');
                })
                .finally(function(){
                    $ctrl.tableParams.reload();
                    $ctrl.isLoading = false;
                });
        };


}]);
