/**
 * @ngdoc controller
 * @name accountSettingsCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('accountSettingsCtrl', ["$scope", "$state", function($scope, $state){
        $scope.$state = $state;
}]);
