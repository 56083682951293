/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:shiftadminScheduleConfigurationCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('shiftadminScheduleConfigurationCtrlCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('shiftadminConfigurationCtrlCache');
    }])
    .controller('shiftadminScheduleConfigurationCtrl', ["$scope", "$state", "configId", "scheduleId", "integrationsService", "Notification", "NgTableParams", "ngTableHelper", "shiftadminScheduleConfigurationCtrlCache", "modalHelper", function ($scope, $state, configId, scheduleId, integrationsService, Notification, NgTableParams, ngTableHelper, shiftadminScheduleConfigurationCtrlCache, modalHelper) {
        var $ctrl = this;

        $ctrl.loading = true;

        $ctrl.$onInit = function () {
            $ctrl.configId = configId;
            $ctrl.isCreate = scheduleId === 'add' || scheduleId === 'new' || scheduleId === undefined;
            $ctrl.scheduleConfig = {};

            integrationsService.getShiftadminConfiguration(configId)
                .then(function(configuration){
                    $ctrl.configuration = configuration;
                    $ctrl.loading = false;
                });

            if(!$ctrl.isCreate){
                integrationsService.getShiftadminScheduleConfig(configId, scheduleId)
                    .then(function(response){
                        $ctrl.scheduleConfig = response;
                    });
            }
        };

        $ctrl.createScheduleMapping = function () {
            $ctrl.isSaving = true;
            integrationsService.createShiftadminScheduleConfig(configId, $ctrl.scheduleConfig)
                .then(function (result) {
                    Notification.success('Successfully created schedule mapping');
                    $state.go('root.accountSettings.integrations.shiftadminScheduleConfig', {
                        configId: configId,
                        scheduleId: result.id
                    });
                })
                .catch(function (result) {
                    var errorMsg = 'Could not create schedule mapping';
                    if (result.data.schedule_group_name) {
                        errorMsg = result.data.schedule_group_name.join(' ');
                    }
                    Notification.error(errorMsg);
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.tableParams = new NgTableParams(shiftadminScheduleConfigurationCtrlCache.get('parameters') || {
                count: 10,
                sorting: {
                    short_name: 'asc'
                }
            }, {
            getData: function (params) {
                shiftadminScheduleConfigurationCtrlCache.put('parameters', params.parameters());

                $ctrl.isLoading = true;

                integrationsService.cancel($ctrl.lastRequest);

                $ctrl.lastRequest = integrationsService.getShiftadminScheduleShiftsForGrid(configId, scheduleId, params.parameters());

                return $ctrl.lastRequest
                    .then(function (response) {
                        params.total(response.count);
                        $ctrl.addedShifts = _.map(response.results, 'id');
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.removeShift = function(shiftId){
            $ctrl.isLoading = true;
            integrationsService.removeShiftadminScheduleShift(configId, scheduleId, shiftId)
                .then(function(){
                    Notification.success('Successfully removed shift from schedule');
                })
                .catch(function(){
                    Notification.error('Could not remove shift from schedule');
                })
                .finally(function(){
                    $ctrl.tableParams.reload();
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.openAddShiftModal = function(){
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/shiftadmin/modals/addShiftModal.html',
                controller: 'addShiftModalCtrl as $ctrl',
                scheduleId: scheduleId,
                configId: configId,
                addedShifts: $ctrl.addedShifts
            }).result
                .finally(function(){
                    $ctrl.tableParams.reload();
                });
        };




    }]);
