/**
 * @ngdoc service
 * @name smartpager.endUser.messages:threadListItem
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.messages')
    .component('threadListItem', {
        templateUrl: '/smartpager/angular/endUser/messages/components/threadListItem/threadListItem.html',
        bindings: {
            thread: '<'
        },
        controller: ["$stateParams", function ($stateParams) {
            this.inboxType = $stateParams.inboxType || 'inbox';
        }]
});
