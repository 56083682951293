/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:departmentListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('departmentListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('departmentListCache');
    }])
    .controller('departmentListCtrl', ["$scope", "NgTableParams", "ngTableHelper", "$log", "modalHelper", "departmentListCache", "userService", function ($scope, NgTableParams, ngTableHelper, $log, modalHelper, departmentListCache, userService) {
        $scope.tableParams = new NgTableParams(departmentListCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                departmentListCache.put('parameters', params.parameters());

                $scope.isLoading = true;
                return userService.getDepartmentsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });

        $scope.searchParams = {
            search: ngTableHelper.generateGetterSetter($scope.tableParams, 'search')
        };

        $scope.openCreateGroupModal = function () {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/departments/modals/createDepartmentModal.html',
                'controller': 'createDepartmentModalCtrl as $ctrl'
            }).result
                .then(function () {
                    $scope.tableParams.reload();
                });
        };
    }]);
