/**
 * @ngdoc directive
 * @name scrollToBottom
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('scrollToBottom', ["$rootScope", "inboxEvents", "$timeout", function ($rootScope, inboxEvents, $timeout) {
        return {
            restrict: 'A',
            link: function (scope, elem) {

                var scroll = function () {
                    $timeout(function () {
                        elem.scrollTop(elem[0].scrollHeight);
                    });
                };

                var unregisterNewMessage = $rootScope.$on(inboxEvents.newMessage, scroll);
                var unregisterThreadInView = $rootScope.$on(inboxEvents.threadInView, scroll);

                scope.$on('$destroy', function () {
                    unregisterNewMessage();
                    unregisterThreadInView();
                });
            }
        };
    }]);
