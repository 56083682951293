/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addFieldMappingCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addFieldMappingCtrl', ["$scope", "integrationsService", "alertsHelper", "Notification", "modalOptions", function ($scope, integrationsService, alertsHelper, Notification, modalOptions) {

        var $ctrl = this;
        var fields = ['username', 'first_name', 'last_name', 'email', 'title', 'mobile_number', 'home_number', 'pager_email'];

        // Since a field can only have one mapping, remove those that have been already set from the options
        $ctrl.availableFieldMappings = _.filter(fields, function(field){
            return modalOptions.fields.indexOf(field) === -1;
        });

        $ctrl.mapping = modalOptions.field;
        $ctrl.edit = typeof modalOptions.field !== 'undefined';

        alertsHelper.create($ctrl, 'alerts');

        $ctrl.reset = function () {
            $ctrl.mapping = {};
        };

        $ctrl.createMapping = function () {
            $ctrl.isSaving = true;
            integrationsService.createFieldMapping(modalOptions.configId, $ctrl.mapping)
                .then(function () {
                    if (!$ctrl.addAnother) {
                        Notification.success('Successfully added field mapping');
                        $scope.$close();
                    }
                    else {
                        $ctrl.alerts.success('Successfully added field mapping');
                        $ctrl.reset();
                    }
                })
                .catch(function () {
                    $ctrl.alerts.error('Error creating field mapping');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.editMapping = function(){
            $ctrl.isSaving = true;
            integrationsService.editFieldMapping(modalOptions.configId, $ctrl.mapping)
                .then(function(){
                    Notification.success('Successfully updated field mapping');
                    $scope.$close();
                })
                .catch(function(){
                    $ctrl.alerts.error('Error updating field mapping');
                })
                .finally(function(){
                    $ctrl.isSaving = false;
                });
        };

    }]);
