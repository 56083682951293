angular.module('smartpager.endUser.reports')
.controller('selectDepartmentsModalCtrl', ["$scope", "NgTableParams", "ngTableHelper", "userService", function($scope, NgTableParams, ngTableHelper, userService){
    var $ctrl = this;
    $ctrl.departments = [];
    $ctrl.selectedDepartmentIds = [];
    $ctrl.allDepartmentsSelected = false;

    $ctrl.onInit = function(){
        // Fetch all of the departments in the account to handle bulk selections
        function fetchPageOfDepartments(page){
            return userService.getDepartments({page_size: 250, page: page})
                .then(function(response){
                    $ctrl.departments = $ctrl.departments.concat(response.results);

                    if(response.next){
                        fetchPageOfDepartments(page+1);
                    }
                });
        }
        fetchPageOfDepartments(1);
    };
    $ctrl.onInit();

    $scope.tableParams = new NgTableParams({
        sorting: {
            name: 'asc'
        }
    }, {
        defaultSort: 'asc',
        getData: function(params) {
            $scope.isLoading = true;
            return userService.getDepartmentsForGrid(params.parameters())
                .then(function(response){
                    params.total(response.count);
                    return response.results;
                })
                .finally(function(){
                    $scope.isLoading = false;
                });
        }
    });

    $scope.searchParams = {
        search: ngTableHelper.generateGetterSetter($scope.tableParams, 'search')
    };

    $ctrl.runReport = function(){
        $scope.$close($ctrl.selectedDepartmentIds);
    };

    $ctrl.toggleSelectAllDepartments = function(){
        $ctrl.allDepartmentsSelected = !$ctrl.allDepartmentsSelected;

        if ($ctrl.allDepartmentsSelected){
            $ctrl.selectedDepartmentIds = _.map($ctrl.departments, function(dep){
                return dep.id;
            });
        } else {
            $ctrl.selectedDepartmentIds = [];
        }
    };

    $ctrl.handleDepartmentSelected = function(departmentId){
        var idx = $ctrl.selectedDepartmentIds.indexOf(departmentId);

        // If the department is already selected, remove it and ensure
        // that allDepartmentsSelected is false;
        if (idx > -1){
            $ctrl.selectedDepartmentIds.splice(idx, 1);
            $ctrl.allDepartmentsSelected = false;
        } else {
            $ctrl.selectedDepartmentIds.push(departmentId);

            if ($ctrl.selectedDepartmentIds.length === $ctrl.departments.length){
                $ctrl.allDepartmentsSelected = true;
            }
        }
    };
}]);
