/**
 * @ngdoc directive
 * @name contactIcon
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular
  .module('smartpager.endUser.messages')
  .directive('contactIcon', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        contact: '=',
        hideStatus: '='
      },
      template:
        '<i class="fa fa-fw" ng-class="iconClass" ng-attr-title="{{title}}" />',
      controller: ["$scope", function($scope) {
        $scope.$watch('contact', function(newVal, oldVal) {
          if (newVal) {
            setIconForContact(newVal);
          }
        });

      function setIconForContact(contact) {
        var iconClass, title;

        if (!contact) {
          return;
        }

        if (contact.is_locked) {
          iconClass = 'fa-lock';
          title = 'User is locked';
        } else {
          switch (contact.type) {
            case 'ScheduleGroup':
              iconClass = 'fa-calendar';
              title = 'Schedule Group';
              break;
            case 'BroadcastGroup':
              iconClass = 'fa-wifi';
              title = 'Broadcast Group';
              break;
            case 'CareTeam':
              iconClass = 'fa-group';
              title = 'Care team';
              break;
            case 'User':
              if (contact.device_type === 'sms') {
                iconClass = 'fa-comment ';
              } else if (contact.device_type === 'pager') {
                iconClass = 'fa-hdd-o ';
              } else {
                iconClass = 'fa-user ';
              }

              if (!$scope.hideStatus) {
                iconClass += !(contact.is_online && !$scope.hideStatus) ?
                  'offline' :
                  'online';
                title = (contact.is_online ? 'Online' : 'Offline') + ' User';
              }
              break;
            case 'Department':
              iconClass = 'fa-sitemap';
              title = 'Department';
              break;
            default:
              iconClass = 'fa-circle-o-notch fa-spin';
              break;
          }
        }

        $scope.iconClass = iconClass;
        $scope.title = title;
      }
    }]
  };
});
