angular.module('smartpager.endUser.reports', [
    'smartpager.endUser.shared'
])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('root.reports', {
                url: 'reports',
                abstract: true,
                template: '<div ui-view></div>',
                data: {
                    title: 'Telmediq - Reports',
                    requiredPermissions: [],
                    pageClass: 'reports'
                }
            })
            .state('root.reports.list', {
                url: '/',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/reportList.html',
                controller: 'reportListCtrl as $ctrl'
            })
            .state('root.reports.rollout', {
                url: '/rollout',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/rolloutReport.html',
                controller: 'rolloutReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.rollout']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'rollout';
                    }]
                }
            })
            .state('root.reports.usage-report', {
                url: '/usage-report',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/usageReport.html',
                controller: 'usageReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.usage-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'usage-report';
                    }]
                }
            })
            .state('root.reports.call-log-report', {
                url: '/call-log-report',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/callLogReport.html',
                controller: 'callLogReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.call-log-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'call-log-report';
                    }]
                }
            })
            .state('root.reports.adoption-report', {
                url: '/adoption-report',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/adoptionTracking.html',
                controller: 'adoptionTrackingCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.adoption-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'adoption-report';
                    }]
                }
            })
            .state('root.reports.msg-volume-over-time', {
                url: '/msg-volume-over-time',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/msgVolumeOverTime.html',
                controller: 'msgVolumeOverTimeCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.msg-volume-over-time']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'msg-volume-over-time';
                    }]
                }
            })
            .state('root.reports.user-mt-report', {
                url: '/user-mt-report/:userId',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/userMtReport.html',
                controller: 'userMtReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.user-mt-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'user-mt-report';
                    }],
                    userId: ["$stateParams", function ($stateParams) {
                        return $stateParams.userId;
                    }]
                }
            })
           .state('root.reports.user-monitoring-report', {
                url: '/user-monitoring-report/:userId',
               templateUrl: '/smartpager/angular/endUser/reports/controllers/userMonitoringReport.html',
                controller: 'userMonitoringReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.user-monitoring-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'user-monitoring-report';
                    }],
                    userId: ["$stateParams", function ($stateParams) {
                        return $stateParams.userId;
                    }]
                }
            })
            .state('root.reports.mt-usage-report', {
                url: '/mt-usage-report',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/mtUsageReport.html',
                controller: 'mtUsageReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.mt-usage-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function ($stateParams) {
                        return 'mt-usage-report';
                    }]
                }
            })
            .state('root.reports.device-report', {
                url: '/device-report',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/deviceReport.html',
                controller: 'deviceReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.device-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function($stateParams) {
                        return 'device-report';
                    }]
                }
            })
            .state('root.reports.device-breakdown-report', {
                url: '/device-breakdown-report?:departmentIds',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/deviceBreakdownReport.html',
                controller: 'deviceBreakdownReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.device-breakdown-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function($stateParams){
                        return 'device-breakdown-report';
                    }],
                    departmentIds: ['$stateParams', function($stateParams){
                        return $stateParams.departmentIds;
                    }]
                }
            })
            .state('root.reports.onboarding-report', {
                url: '/onboarding-report',
                templateUrl: '/smartpager/angular/endUser/reports/controllers/onboardingReport.html',
                controller: 'onboardingReportCtrl as $ctrl',
                data: {
                    requiredPermissions: ['reporting.onboarding-report']
                },
                resolve: {
                    reportSlug: ["$stateParams", function($stateParams){
                        return 'onboarding-report';
                    }]
                }
            });
    }]);
