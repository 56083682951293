angular.module('smartpager.endUser.shared')
    .factory('eventService', ["baseService", "$q", "$log", "modalHelper", function (baseService, $q, $log, modalHelper) {


        var Service = Object.create(baseService);

        Service.getEvents = function (groupId, start, end) {
            var url = String.format('/api/v1/schedulegroups/{0}/events/?start={1}&end={2}', groupId, encodeURIComponent(start.format()), encodeURIComponent(end.format()));
            return Service.getData(url)
                .then(function (result) {

                    result = _.map(result, function (current) {
                        return Service.buildEventObject(current);
                    });

                    return result;
                });
        };

        Service.getEventOccurrence = function (groupId, eventId, originalStart) {
            var url = String.format('/api/v1/schedulegroups/{0}/events/{1}/?original_start={2}', groupId, eventId, originalStart);
            return Service.getData(url)
                .then(convertDatesToMoment);
        };

        Service.createEvent = function (groupId, event) {
            $log.debug('saveEvent', event);

            var url = String.format('/api/v1/schedulegroups/{0}/events/', groupId);
            return Service.postData(url, event)
                .then(Service.buildEventObject);
        };

        Service.getNativeMoment = function (date) {
            return date.hasZone && date.hasZone() ? date : moment(date.format());
        };

        Service.updateEvent = function (groupId, event) {
            $log.debug('updateEvent', event);

            var url = String.format('/api/v1/schedulegroups/{0}/events/{1}/', groupId, event.event_id);

            var tmp = angular.copy(event);
            if (tmp.allDay) {
                tmp.start = Service.getNativeMoment(tmp.start);
                tmp.end = Service.getNativeMoment(tmp.end);
            }

            return Service.putData(url, tmp)
                .then(Service.buildEventObject);
        };

        Service.deleteEvent = function (groupId, event) {
            $log.debug('delete event', event);

            var url = String.format('/api/v1/schedulegroups/{0}/events/{1}/cancel/', groupId, event.event_id);
            return Service.postData(url, {update_for: event.update_for, original_start: event.original_start});
        };

        Service.isAllDay = function (eventObject) {
            return eventObject.start.hours() === 0 && eventObject.end.hours() === 0 &&
                eventObject.start.minutes() === 0 && eventObject.end.minutes() === 0 &&
                eventObject.start.seconds() === 0 && eventObject.end.seconds() === 0;
        };

        function resetColor() {
            return [
                '#82CA9D',
                '#F9AD81',
                '#6ECFF6',
                '#C4DF9B',
                '#8493CA',
                '#F49AC2',
                '#A187BE',
                '#7BCDC8',
                '#BC8DBF',
                '#FDC68A',
                '#7EA7D8',
                '#A2D39C',
                '#F7977A',
                '#F6989D'
            ];
        }

        var colors = resetColor();

        Service.assignedColors = {};

        Service.assignColorToUser = function (id) {
            if (id in Service.assignedColors) {
                return Service.assignedColors[id];
            } else {
                var color = colors.shift();
                if (!color) {
                    colors = resetColor();
                    color = colors.pop();
                }
                Service.assignedColors[id] = color;
                return color;
            }
        };

        function convertDatesToMoment(event) {
            event.start = moment(event.start);
            event.end = moment(event.end);
            event.original_start = moment(event.original_start);
            event.original_end = moment(event.original_end);

            return event;
        }

        Service.eventRender = function(event, element){
            /*
            Create a custom rendered event instance. This format does the following:
            - Event and target name in bold
            - Target title italicized
            - Escalation policy in brackets
             */
            element.find('.fc-event-title').remove();

            var title = '';
            if (event.title === 'blank' || !event.title){
                title = '<strong>'+event.target.display_name+'</strong>';
            } else {
                title = '<strong>' + event.title + ' - ' + event.target.display_name + '</strong>';
            }
            title += '<br/>';

            var extra = '';
            if(event.target.title) {
                extra += '<i>' + event.target.title + '</i><br/>';
            }

            if (event.escalation_policy_name){
                extra += '(' + event.escalation_policy_name + ')';
            } else if (Service.escallationPolicyNameForCurrentlySelectedBroadcastGroup){
                extra += '[' + Service.escallationPolicyNameForCurrentlySelectedBroadcastGroup + ']';
            }

            element.append(title + extra);
        };

        Service.buildEventObject = function (eventObject) {
            if (eventObject.cancelled) {
                return;
            }

            eventObject.occurence_id = eventObject.id;
            eventObject.id = eventObject.event_id;
            convertDatesToMoment(eventObject);
            eventObject.editable = true;
            eventObject.textColor = '#333333';
            eventObject.backgroundColor = Service.assignColorToUser(eventObject.target_id);
            eventObject.allDay = Service.isAllDay(eventObject);

            return eventObject;
        };

        Service.getUpdateFor = function (eventOccurrence, initiallyRepeating) {
            if (_.isUndefined(initiallyRepeating)) {
                initiallyRepeating = true;
            }

            var updateForPromise;

            if (eventOccurrence.occurence_id) {
                return $q.when('once');
            }

            if (eventOccurrence.rule_id && initiallyRepeating) {
                updateForPromise = modalHelper.openModal({
                    templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/modals/editRecurringEventModal.html'
                }).result;
            } else {
                updateForPromise = $q.when('once');
            }
            return updateForPromise;
        };

        Service.escallationPolicyNameForCurrentlySelectedBroadcastGroup = '';

        return Service;
    }]);
