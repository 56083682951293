/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupRolesCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('groupRolesCtrl', ["$scope", "modalHelper", "groupsService", "$stateParams", "Notification", function ($scope, modalHelper, groupsService, $stateParams, Notification) {
        var $ctrl = this;

        $ctrl.$onInit = function(){
            reloadRoles();
        };

        $ctrl.openAddRoleModal = function () {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/groups/modals/addRoleToGroupModal.html',
                'controller': 'addRoleToGroupModalCtrl as ctrl',
                'groupId': $scope.group.id
            }).result
                .finally(function (result) {
                    reloadRoles();
                });
        };

        $ctrl.removeRole = function (role) {
            role.removing = true;
            groupsService.removeRole($scope.group.id, role.id)
                .then(function () {
                    Notification.success(String.format('Role `{0}` has been removed from group `{1}`', role.name, $scope.group.display_name));

                    reloadRoles();
                })
                .finally(function () {
                    role.removing = false;
                });
        };

        function reloadRoles() {
            return groupsService.getGroupRoles($stateParams.groupId)
                .then(function (response) {
                    $scope.group.rolesCount = response.count;
                    $scope.group.roles = response.results;

                    return response.results;
                });
        }
    }]);
