/**
 * @ngdoc directive
 * @name iqDirectorySelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular
  .module("smartpager.endUser.shared")
  .controller("iqDirectoryAccessSelectCtrl", ["$scope", "modalHelper", "directoryService", "$log", "$timeout", function(
    $scope,
    modalHelper,
    directoryService,
    $log,
    $timeout
  ) {
    $scope.iqDirectoryAccessSelectCtrl = {
      availableDirectories: [],
      directories: []
    };
    console.log("ctrl");

    // for regular type-ahead searches
    $scope.searchDirectories = function(query) {
      var param = {
        search: query
      };
      $scope.isLoading = true;
      directoryService
        .getDirectoriesAssignableToObject($scope.ct, $scope.obj, param)
        .then(function(response) {
          $scope.iqDirectoryAccessSelectCtrl.availableDirectories =
            response.results;
        })
        .finally(function() {
          $scope.isLoading = false;
        });
    };
  }]);
