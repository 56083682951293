/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:departmentContactsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('contactListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('contactListCache');
    }])
    .controller('departmentContactsCtrl', ["$scope", "departmentId", "NgTableParams", "ngTableHelper", "contactListCache", "userService", "modalHelper", "Notification", function ($scope, departmentId, NgTableParams, ngTableHelper, contactListCache, userService, modalHelper, Notification) {
        var $ctrl = this;

        $ctrl.contactTableParams = new NgTableParams(contactListCache.get('parameters') || {
                count: 10,
                sorting: {is_pinned: 'desc'}
            }, {
            getData: function (params) {
                contactListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;
                return userService.getDepartmentContactsForGrid(departmentId, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        $ctrl.contact_ids = _.map(response.results, function(c){
                            return c.contactable.id;
                        });
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.contactSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.contactTableParams, 'name')
        };

        $ctrl.removeContact = function (contact) {
            userService.removeDepartmentContact(departmentId, contact.id)
                .then(function (response) {
                    $ctrl.contactTableParams.reload();
                    $scope.$depCtrl.department.contact_ids = _.without($scope.$depCtrl.department.contact_ids, contact.id);
                    Notification.success('Successfully removed ' + contact.display_name + ' from ' + $scope.$depCtrl.department.name);
                })
                .catch(function () {
                    Notification.error('Could not remove ' + contact.display_name + ' from ' + $scope.$depCtrl.department.name);
                });
        };

        $ctrl.togglePinContact = function (contact) {
            var contactCopy = angular.copy(contact);
            contactCopy.is_pinned = !contactCopy.is_pinned;
            userService.updateDepartmentContact($scope.$depCtrl.department.id, contactCopy)
                .then(function (response) {
                    $ctrl.contactTableParams.reload();
                    Notification.success('Successfully pinned ' + contact.contactable.display_name + ' to ' + $scope.$depCtrl.department.name);
                })
                .catch(function () {
                    Notification.error('Could not pin ' + contact.contactable.display_name + ' to ' + $scope.$depCtrl.department.name);
                });
        };
        $ctrl.openAddContactModal = function () {
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/modals/addContactToDepartmentModal.html',
                controller: 'addContactToDepartmentModalCtrl as $ctrl',
                department: $scope.$depCtrl.department
            }).result
                .finally(function (result) {
                    $ctrl.contactTableParams.reload();
                });
        };


    }]);
