/**
 * @ngdoc service
 * @name escalationsService
 * @description
 * _Please update the description and dependencies._
 *
 * */
angular.module('smartpager.endUser.shared')
    .factory('escalationsService', ["baseService", function (baseService) {

        var Service = Object.create(baseService);

        Service.getEscalationPolicies = function () {
            var url = '/api/v1/escalationpolicies/';
            return Service.getData(url);
        };

        return Service;
    }]);
