angular.module('smartpager.endUser.pagingGroups', [
        'smartpager.endUser.shared'
    ])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        //root state of application
        $stateProvider
            .state('root.pagingGroups', {
                url: 'pagingGroups/',
                template: '<div ui-view></div>',
                abstract: true,
                data: {
                    title: 'Telmediq - Paging groups',
                    requiredPermissions: []
                }
            })
            .state('root.pagingGroups.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/pagingGroupList.html',
                controller: 'pagingGroupListCtrl as ctrl'
            })
            .state('root.pagingGroups.broadcastGroup', {
                url: 'broadcast/:groupId',
                templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/broadcastGroup.html',
                controller: 'broadcastGroupCtrl',
                controllerAs: 'ctrl',
                data: {
                    title: 'Telmediq - Paging groups',
                    requiredPermissions: []
                }
            })
            .state('root.pagingGroups.scheduleGroup', {
                url: 'schedule/{groupId:int}',
                templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/scheduleGroup.html',
                controller: 'scheduleGroupCtrl',
                abstract: true,
                data: {
                    title: 'Telmediq - Paging groups',
                    requiredPermissions: []
                }
            })
            .state('root.pagingGroups.scheduleGroup.calendar', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/scheduleGroupSchedule.html',
                controller: 'scheduleGroupScheduleCtrl'
            })

            .state('root.pagingGroups.scheduleGroup.settings', {
                url: '/settings',
                templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/scheduleGroupSettings.html',
                controller: 'scheduleGroupSettingsCtrl',
                controllerAs: 'ctrl'
            })

            .state('root.pagingGroups.scheduleGroup.editEvent', {
                url: '/events/:eventId?originalStart',

                views: {
                    '@root': {
                        template: '<div ui-view></div>'
                    },
                    '@root.pagingGroups.scheduleGroup.editEvent': {
                        templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/editEvent.html',
                        controller: 'editEventCtrl as ctrl'
                    }
                },
                params: {
                    start: '',
                    end: '',
                    user_id: '',
                    escalation_policy_id: ''
                },
                data: {
                    title: 'Telmediq - Paging groups',
                    requiredPermissions: []
                }
            });
    }]);
