/**
 * @ngdoc service
 * @name smartpager.endUser.shared:patientsService
 *
 * @description
 *
 *
 * */
angular
  .module("smartpager.endUser.shared")
  .service("patientsService", ["$rootScope", "baseService", "modalHelper", "configuration", function(
    $rootScope,
    baseService,
    modalHelper,
    configuration
  ) {
    var Service = Object.create(baseService);

    Service.getProvidersForGrid = function(params) {
      var url = "/api/v1/pcm/providers/" + baseService.toUrl(params);

      return this.getData(url);
    };

    Service.getPatients = function(query) {
      query = query ? query : {};
      var params = $.param(query);

      var url = "/api/v1/pcm/patients/" + (params ? "?" + params : "");
      return this.getData(url);
    };

    Service.getPatientsForGrid = function(params) {
      var url = "/api/v1/pcm/patients/" + baseService.toUrl(params);

      return this.getData(url);
    };

    Service.getPatientById = function(patientId) {
      var url = String.format("/api/v1/pcm/patients/{0}/", patientId);
      return this.getData(url);
    };

    Service.addPatient = function(patient) {
      var url = "/api/v1/pcm/patients/";
      return this.postData(url, patient);
    };

    Service.updatePatient = function(patient) {
      var url = String.format("/api/v1/pcm/patients/{0}/", patient.id);
      return this.putData(url, patient);
    };

    Service.dischargePatient = function(patientId) {
      var url = String.format("/api/v1/pcm/patients/{0}/", patientId);
      return this.deleteData(url);
    };

    Service.getLocations = function(query) {
      var params = $.param(query);
      var url = String.format(
        "/api/v1/pcm/locations/{0}",
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.createLocation = function(location) {
      var url = "/api/v1/pcm/locations/";
      return this.postData(url, location);
    };

    Service.getCareTeamRequests = function(patientId) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/careteamrequests/",
        patientId
      );
      return this.getData(url);
    };

    Service.assign = function(patientId, providerId) {
      var url = String.format("/api/v1/pcm/patients/assign/");
      return this.postData(url, {
        provider_id: providerId,
        patient_ids: [patientId]
      });
    };

    Service.unassign = function(patientId, userId) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/careteam/{1}/unassign/",
        patientId,
        userId
      );
      return this.postData(url);
    };

    Service.getPatientUpdates = function(patientId, params) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/updates/{1}",
        patientId,
        Service.objToParam(params)
      );
      return this.getData(url);
    };

    Service.getPatientNotes = function(patientId, params) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/notes/{1}",
        patientId,
        Service.objToParam(params)
      );
      return this.getData(url);
    };

    Service.getPatientThreads = function(patientId, params) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/threads/{1}",
        patientId,
        Service.objToParam(params)
      );
      return this.getData(url);
    };

    Service.getCareTeamRequests = function() {
      var url = "/api/v1/pcm/careteamrequests/";
      return this.getData(url);
    };

    Service.follow = function(patientId) {
      var url = String.format("/api/v1/pcm/patients/{0}/followers/", patientId);
      return this.postData(url);
    };

    Service.unfollow = function(patientId, followerId) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/followers/{1}/",
        patientId,
        followerId
      );
      return this.deleteData(url);
    };

    Service.take = function(patientId, user_id) {
      var url = String.format("/api/v1/pcm/patients/take/");
      return this.postData(url, {
        patient_ids: [patientId],
        provider_id: user_id
      });
    };

    Service.handoff = function(patientId, providerId) {
      var url = String.format("/api/v1/pcm/patients/handoff/");
      return this.postData(url, {
        provider_id: providerId,
        patient_ids: [patientId]
      });
    };

    Service.getNoteTemplates = function(params) {
      var url = String.format(
        "/api/v1/pcm/notetemplates/{0}",
        baseService.toUrl(params)
      );
      return this.getData(url);
    };

    Service.fluxEvents = {
      getTemplate: {
        key: "patientService.getTemplate"
      }
    };

    Service.requestGetTemplate = function(templateId) {
      if (Service.fluxEvents.getTemplate.isLoading) {
        return;
      }
      Service.fluxEvents.getTemplate.isLoading = true;
      Service.getNoteTemplate(templateId)
        .then(function(result) {
          $rootScope.$emit(Service.fluxEvents.getTemplate.key, result);
        })
        .finally(function() {
          Service.fluxEvents.getTemplate.isLoading = false;
        });
    };

    Service.getNoteTemplate = function(templateId) {
      var url = String.format("/api/v1/pcm/notetemplates/{0}/", templateId);
      return this.getData(url);
    };

    Service.createNoteTemplate = function(template) {
      var url = String.format("/api/v1/pcm/notetemplates/");
      return this.postData(url, template);
    };

    Service.updateNoteTemplate = function(template) {
      var url = String.format("/api/v1/pcm/notetemplates/{0}/", template.id);
      return this.putData(url, template);
    };

    Service.deleteNoteTemplate = function(templateId) {
      var url = String.format("/api/v1/pcm/notetemplates/{0}/", templateId);
      return this.deleteData(url);
    };

    Service.getNoteFields = function(params) {
      var url = String.format("/api/v1/pcm/notefields/{0}", this.toUrl(params));
      return this.getData(url);
    };

    Service.getNoteField = function(fieldId) {
      var url = String.format("/api/v1/pcm/notefields/{0}/", fieldId);
      return this.getData(url);
    };

    Service.createNoteField = function(field) {
      var url = String.format("/api/v1/pcm/notefields/");
      return this.postData(url, field);
    };

    Service.updateNoteField = function(field) {
      var url = String.format("/api/v1/pcm/notefields/{0}/", field.id);
      return this.putData(url, field);
    };

    Service.deleteNoteField = function(fieldId) {
      var url = String.format("/api/v1/pcm/notefields/{0}/", fieldId);
      return this.deleteData(url);
    };

    Service.createNote = function(patientId, note) {
      var url = String.format("/api/v1/pcm/patients/{0}/notes/", patientId);
      return this.postData(url, note);
    };

    Service.deleteNote = function(patientId, noteId) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/notes/{1}/",
        patientId,
        noteId
      );
      return this.deleteData(url);
    };

    Service.updateNote = function(note) {
      var url = String.format(
        "/api/v1/pcm/patients/{0}/notes/{1}/",
        note.patient_id,
        note.id
      );
      return this.putData(url, note);
    };

    Service.showUploadPatientsModal = function() {
      return modalHelper.openModal({
        templateUrl:
          "/smartpager/angular/endUser/patients/controllers/modals/uploadPatientsModal.html",
        controller: "uploadPatientsModalCtrl as $ctrl",
        backdrop: "static",
        size: "lg"
      }).result;
    };

    Service.getPatientFromThreadId = function(threadId) {
      var url = String.format("/api/v1/threads/{0}/patient/", threadId);
      return this.getData(url);
    };

    return Service;
  }]);
