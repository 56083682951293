/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addMemberToGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addMemberToGroupModalCtrl', ["$scope", "groupsService", "modalOptions", "Notification", function ($scope, groupsService, modalOptions, Notification) {
        var self = this;

        self.selected = [];

        $scope.addSelected = function () {
            groupsService.addMembers(modalOptions.groupId, self.selected)
                .then(function (response) {
                    var addedUsers = _.map(response, 'display_name').join(', ');
                    Notification.success('Successfully added ' + addedUsers + ' to the group');
                    $scope.$close();
                });
        };
    }]);
