/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:selectUserModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .controller('selectUserModalCtrl', ["$scope", function ($scope) {
        var $ctrl = this;

        $ctrl.runReport = function () {
            $scope.$close($ctrl.selected.id);
        };
    }]);
