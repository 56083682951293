/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:shiftadminConfigurationCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.accountSettings")
  .factory("shiftadminScheduleCtrlCache", ["$cacheFactory", function($cacheFactory) {
    return $cacheFactory("shiftadminScheduleCtrlCache");
  }])
  .controller("shiftadminConfigurationCtrl", ["$scope", "$state", "shiftadminScheduleCtrlCache", "configId", "integrationsService", "Notification", "NgTableParams", "ngTableHelper", "modalHelper", "$log", function(
    $scope,
    $state,
    shiftadminScheduleCtrlCache,
    configId,
    integrationsService,
    Notification,
    NgTableParams,
    ngTableHelper,
    modalHelper,
    $log
  ) {
    var $ctrl = this;

    $ctrl.$onInit = function() {
      $ctrl.isCreate = configId === "add" || configId === undefined;
      $ctrl.configuration = { fail_over_user: null };
      $log.info("isCreate", $ctrl.isCreate);
      if (!$ctrl.isCreate) {
        $ctrl.reload(configId);
      }
    };

    $ctrl.tableParams = new NgTableParams(
      shiftadminScheduleCtrlCache.get("parameters") || {
        count: 10
      },
      {
        getData: function(params) {
          shiftadminScheduleCtrlCache.put("parameters", params.parameters());
          $ctrl.isLoading = true;

          integrationsService.cancel($ctrl.lastProvidersRequest);

          $ctrl.lastProvidersRequest = integrationsService.getShiftadminSchedulesForGrid(
            configId,
            params.parameters()
          );

          return $ctrl.lastProvidersRequest
            .then(function(response) {
              params.total(response.count);
              return response.results;
            })
            .catch(function() {
              return [];
            })
            .finally(function() {
              $ctrl.isLoading = false;
            });
        }
      }
    );

    $ctrl.reload = function(id) {
      integrationsService
        .getShiftadminConfiguration(id)
        .then(function(result) {
          $ctrl.configuration = result;
        })
        .catch(function() {
          window.location = "/accounts/integrations/";
        });
    };

    $ctrl.update = function(form) {
      if (form.$invalid) {
        return;
      }
      $ctrl.isLoading = true;
      var promise;

      if ($ctrl.isCreate) {
        promise = integrationsService.createShiftadminConfiguration(
          $ctrl.configuration
        );
      } else {
        promise = integrationsService.updateShiftadminConfiguration(
          $ctrl.configuration
        );
      }

      promise
        .then(function(result) {
          if ($ctrl.isCreate) {
            Notification.success("Configuration created");
          } else {
            Notification.success("Configuration saved");
          }
          setTimeout(function() {
            $state.go("root.accountSettings.integrations.shiftadmin", {
              configId: result.id
            });
          }, 500);
        })
        .catch(function() {
          Notification.error("Could not save configuration");
          $ctrl.isLoading = false;
        });
    };

    $ctrl.delete = function() {
      modalHelper
        .openModal({
          title: "Delete ShiftAdmin Integration",
          content:
            "Are you sure you want to delete this ShiftAdmin integration?",
          buttons: [
            { id: "yes", type: "danger", label: "Delete" },
            { id: "cancel", type: "default", label: "Cancel" }
          ]
        })
        .result.then(function() {
          integrationsService
            .deleteShiftadminConfiguration($ctrl.configuration.id)
            .then(function() {
              Notification.success("Configuration deleted");
              window.location = "/accounts/integrations/";
            })
            .catch(function() {
              Notification.error("Could not delete configuration.");
            });
        });
    };

    $ctrl.deleteMapping = function(mapping) {
      modalHelper
        .openModal({
          title: "Delete schedule mapping",
          content: String.format(
            "Are you sure you want to delete the schedule mapping {0}?",
            mapping.schedule_group_name
          ),
          buttons: [
            { id: "yes", type: "danger", label: "Delete" },
            { id: "cancel", type: "default", label: "Cancel" }
          ]
        })
        .result.then(function() {
          return integrationsService.deleteShiftadminScheduleConfig(
            configId,
            mapping.id
          );
        })
        .then(function() {
          Notification.success("Schedule mapping deleted");
          $ctrl.tableParams.reload();
        });
    };

    $ctrl.syncSchedule = function(schedule) {
      schedule.isRefreshed = true;
      $ctrl.isLoading = true;
      integrationsService
        .syncShiftadminSchedule(configId, schedule.id, { 'run_async': true })
        .then(function() {
          setTimeout(function() {
            Notification.success("Schedule sync started");
          }, 2500);
        })
        .catch(function(response) {
          if (response.data.error) {
            Notification.error(
              "Could not refresh schedule mapping: " + response.data.error
            );
          } else {
            Notification.error("Could not refresh schedule mapping");
          }
        })
        .finally(function() {
          setTimeout(function() {
            $ctrl.isLoading = false;
          }, 2500);
          schedule.isRefreshed = false;
        });
    };

    $ctrl.addSchedule = function() {
      $state.go("root.accountSettings.integrations.shiftadminScheduleConfig", {
        configId: configId,
        scheduleId: "new"
      });
    };

    $ctrl.syncShifts = function() {
      $ctrl.isLoading = true;

      integrationsService
        .syncShiftadminShifts(configId, { 'run_async': true })
        .then(function() {
          setTimeout(function() {
            Notification.success("Started sync");
          }, 2500);
        })
        .catch(function(response) {
          if (response.data.error) {
            Notification.error(
              "Could not refresh shifts: " + response.data.error
            );
          } else {
            Notification.error("Could not refresh shifts");
          }
        })
        .finally(function() {
          setTimeout(function() {
            $ctrl.isLoading = false;
          }, 2500);
        });
    };
  }]);
