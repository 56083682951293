/**
 * @ngdoc directive
 * @name smartpager.endUser.shared:departmentSearch
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('departmentSearch', ["$log", function ($log) {
        return {
            restrict: 'E',
            require: 'ngModel',
            templateUrl: '/smartpager/angular/endUser/shared/components/departmentSearch/departmentSearch.html',
            controller: 'departmentSearchCtrl',
            scope: {
                propertyToMatch: '@',
                hideLabel: '=',
                ngModel: '=',
                ngModelOptions: '=',
                disabled: '=',
            },
            link: function (scope, elem, iAttrs, ngModelCtrl) {

                //region handle ngModelCtrl

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return angular.copy(modelValue);
                });

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return angular.copy(viewValue);
                });

                scope.$watchCollection('filter.department', function (item) {
                    $log.debug('departmentSearchCtrl updates ngModel binding', item);
                    ngModelCtrl.$setViewValue(angular.copy(item));
                });

                ngModelCtrl.$render = function () {
                    scope.filter.department = ngModelCtrl.$viewValue;
                };

                if (iAttrs.required) {
                   ngModelCtrl.$validators.required = function (modelValue, viewValue) {
                        if(iAttrs.multiple && iAttrs.multiple === 'true') {
                            return !!(modelValue && modelValue.length !== 0);
                        } else {
                            return !!modelValue;
                        }
                    };
                }
                //endregion
            }
        };
    }]);
