/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:addPatientModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('addPatientModalCtrl', ["$scope", "patientsService", "alertsHelper", "Notification", function ($scope, patientsService, alertsHelper, Notification) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.reset = function () {
            $ctrl.patient = {
                middle_name: ''
            };
            $ctrl.today = new Date().toISOString();
        };
        $ctrl.reset();

        $ctrl.goBack = function () {
            $scope.$dismiss();
            patientsService.showAddPatientsChoiceModal();
        };

        $ctrl.searchLocations = function (search) {
            patientsService.getLocations({search: search})
                .then(function (response) {
                    $ctrl.locations = response.results;
                });
        };

        $ctrl.save = function () {
            if($ctrl.form.$invalid){
                $ctrl.form.$setSubmitted();
                return;
            }
            $ctrl.isSaving = true;
            $ctrl.patient.dob = new Date($ctrl.patient.date_of_birth);
            patientsService.addPatient($ctrl.patient)
                .then(function (response) {
                    if ($ctrl.addAnother) {
                        $ctrl.alerts.success('Successfully added ' + response.display_name);
                        $ctrl.reset();
                    }
                    else {
                        Notification.success('Successfully added ' + response.display_name);
                        $scope.$close();
                    }
                })
                .catch(function () {
                    $ctrl.alerts.error('Could not add patient');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
