/**
 * @ngdoc controller
 * @name smartpager.endUser.pagingGroups:addMemberToPagingGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.pagingGroups')
    .factory('addMembersListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('addMembersListCache');
    }])
    .controller('addMembersToPagingGroupModalCtrl', ["$scope", "$log", "modalOptions", "NgTableParams", "ngTableHelper", "Notification", "userService", "pagingGroupService", "addMembersListCache", function ($scope, $log, modalOptions, NgTableParams, ngTableHelper, Notification, userService, pagingGroupService, addMembersListCache) {
        var $ctrl = this;

        $ctrl.typeChoices = [
            {name: 'All', val: ''},
            {name: 'User', val: 'user'},
            {name: 'Group', val: 'group'},
            {name: 'Department', val: 'department'},
            {name: 'Users and Groups', val: 'usergroup'}
        ];

        $ctrl.activate = function () {
            $ctrl.isLoading = true;
            $ctrl.group = angular.copy(modalOptions.group);
        };

        $ctrl.activate();

        $ctrl.addMemberTableParams = new NgTableParams(addMembersListCache.get('parameters') || {
                count: 10,
                filter: {
                    'type': ''
                },
                sorting: {'last_name': 'asc', 'name' : 'asc'}
            }, {
            getData: function (params) {
                addMembersListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;
                return userService.getAccountContactsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function (response) {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.addMemberSearchParams = {
            name: ngTableHelper.generateGetterSetter($ctrl.addMemberTableParams, 'name'),
            type: ngTableHelper.generateGetterSetter($ctrl.addMemberTableParams, 'type')
        };

        $ctrl.addMember = function (contact) {
            $ctrl.isSaving = true;

            var group = angular.copy($ctrl.group);
            group.members.push(contact);
            group.member_ids.push(contact.id);

            pagingGroupService.updateGroup(group)
                .then(function (group) {
                    $ctrl.group.members.push(contact);
                    $ctrl.group.member_ids.push(contact.id);

                    Notification.success(String.format('Successfully added {0} to the {1} group', contact.display_name, $ctrl.group.name));
                })
                .catch(function (response) {
                    $log.debug('error with status code', response.status);
                    Notification.error(String.format('Could not add {0} to {1}', contact.display_name, $ctrl.group.name));
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.removeMember = function (contact) {
            $ctrl.isSaving = true;

            var group = angular.copy($ctrl.group);
            _.remove(group.members, {id: contact.id});
            group.member_ids = _.without(group.member_ids, contact.id);

            pagingGroupService.updateGroup(group)
                .then(function () {
                    _.remove($ctrl.group.members, {id: contact.id});
                    $ctrl.group.member_ids = _.without($ctrl.group.member_ids, contact.id);

                    Notification.success(String.format('Successfully removed {0} from the {1} group', contact.display_name, $ctrl.group.name));
                })
                .catch(function (response) {
                    $log.debug('error with status code', response.status);
                    Notification.error(String.format('Could not remove {0} from {1}', contact.display_name, $ctrl.group.name));
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };


        $ctrl.inMembers = function (contactId) {
            return $ctrl.group.member_ids.indexOf(contactId) !== -1;
        };

    }]);
