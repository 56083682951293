/**
 * @ngdoc directive
 * @name smartpager.endUser.patients:notePriorityComponent
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.patients')
    .component('notePriority', {
        bindings: {
            priority: '<'
        },
        templateUrl: '/smartpager/angular/endUser/patients/components/notePriority/notePriority.html'
    });
