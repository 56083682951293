/**
 * @ngdoc controller
 * @name smartpager.endUser.main:notificationsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.main')
    .factory('notificationsCtrlCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('notificationsCtrlCache');
    }])
    .controller('notificationsCtrl', ["$scope", "NgTableParams", "userService", "notificationsCtrlCache", function ($scope, NgTableParams, userService, notificationsCtrlCache) {

        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams(notificationsCtrlCache.get('parameters') || {
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                notificationsCtrlCache.put('parameters', params.parameters());

                $scope.isLoading = true;

                return userService.getNotificationsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });
    }]);
