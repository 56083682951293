/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:usageReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .factory('usageReportCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('usageReportCache');
    }])
    .controller('usageReportCtrl', ["$scope", "reportSlug", "reportService", "NgTableParams", "ngTableHelper", "usageReportCache", function ($scope, reportSlug, reportService, NgTableParams, ngTableHelper, usageReportCache) {
        var $ctrl = this;

        $ctrl.dateRangePickerOptions = reportService.getDateRangePickerOptions();

        $ctrl.tableParams = new NgTableParams(usageReportCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                },
                filter: {
                    start: moment().startOf('month').toDate(),
                    end: moment().endOf('month').toDate()
                }

            }, {
            getData: function (params) {
                $ctrl.isLoading = true;
                usageReportCache.put('parameters', params.parameters());
                return reportService.runReport(reportSlug, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search'),
            start: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'start'),
            end: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'end'),
            department: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'department')
        };

        $ctrl.downloadCsv = function () {
            reportService.downloadReportAsCSV(reportSlug, usageReportCache.get('parameters'), 'Usage Report.csv');
        };
    }]);
