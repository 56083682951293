angular.module('smartpager.endUser.shared', [
        'smartpager.shared',

        'angular-ui-scheduler',
        'ui.calendar',
        'ui.identicon',

        'btford.markdown',
        'pubnub.angular.service',

        'monospaced.elastic'
    ])
    .config(["msdElasticConfig", function (msdElasticConfig) {
        msdElasticConfig.append = '\n\n';
    }])
    .config(['markdownConverterProvider', function (markdownConverterProvider) {

        // see: https://github.com/coreyti/showdown#extensions
        (function () {
            var links = function (converter) {
                return [
                    // http:// or https:// syntax
                    {
                        type: 'lang',
                        regex: '(\\w+):\\/\\/([\\w@][\\w.:@]+)\\/?[\\w\\.?=%&=\\-@/$,]*',
                        replace: function (match, protocol, domain) {
                            return '<a target="_blank" href="' + match + '">' + match + '</a>';
                        }
                    }
                ];
            };

            var newline = function () {
                return [{
                    type: 'lang',
                    filter: function (text) {
                        return text.replace(/^( *(\d+\.  {1,4}|[\w<'">\-*+])[^\n]*)\n{1}(?!\n| *\d+\. {1,4}| *[-*+] +|#|$)/gm, function (e) {
                            return e.trim() + '  \n';
                        });
                    }
                }];
            };

            // Client-side export
            if (typeof window !== 'undefined' && window.Showdown) {
                window.Showdown.extensions.newline = newline;
                window.Showdown.extensions.links = links;
            }
        }());


        markdownConverterProvider.config({
            extensions: ['links', 'newline']
        });
    }])
    .config(function () {
        moment.locale('en', {
            calendar: {
                nextWeek: 'ddd [at] LT',
                nextDay: '[Tomorrow at] LT',
                sameDay: 'LT',
                lastDay: 'ddd LT',
                lastWeek: 'ddd LT',
                sameElse: 'L'
            }
        });
    })
    .run(["Pubnub", "configuration", "$log", function (Pubnub, configuration, $log) {
        var pubnubConfig = configuration.getPubnubConfig();
        pubnubConfig.uuid = configuration.getUserUid();
        pubnubConfig.origin = 'pubsub.pubnub.com';
        pubnubConfig.ssl = true;

        $log.debug('pubnub init', pubnubConfig);
        Pubnub.init(pubnubConfig);
    }]);
