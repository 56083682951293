/**
 * @ngdoc service
 * @name smartpager.endUser.shared:groupsService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .service('groupsService', ["baseService", "configuration", function (baseService, configuration) {

        var Service = Object.create(baseService);

        Service.getGroups = function (query) {
            var params = $.param(query || {});

            var url = String.format('/api/v1/accounts/{0}/groups/{1}', configuration.getAccountId(), params ? '?' + params : '');
            return this.getData(url);
        };

        Service.getGroupsForGrid = function (parameters) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}', configuration.getAccountId(), this.toUrl(parameters));
            return this.getData(url);
        };

        Service.getGroup = function (groupId) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}', configuration.getAccountId(), groupId);
            return this.getData(url);
        };

        Service.getGroupMembers = function (groupId) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/members/', configuration.getAccountId(), groupId);
            return this.getData(url);
        };

        Service.getGroupMembersForGrid = function(groupId, parameters){
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/members/{2}', configuration.getAccountId(), groupId, this.toUrl(parameters));
            return this.getData(url);
        };

        Service.getGroupRoles = function (groupId) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/roles/', configuration.getAccountId(), groupId);
            return this.getData(url);
        };

        Service.getRoles = function () {
            var url = String.format('/api/v1/accounts/{0}/roles/', configuration.getAccountId());
            return this.getData(url);
        };

        Service.getAllRoles = function (allData, startFrom) {
            var url = String.format('/api/v1/accounts/{0}/roles/', configuration.getAccountId());
            return fetch(startFrom ? url + '?' + startFrom : url, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                allData = allData.concat(data.results);
                const nextPage = data.next;
                if (!nextPage){
                    return allData;
                } else {
                    return Service.getAllRoles(allData, nextPage.split('?')[1]);
                }
            });
        };

        Service.removeRole = function (groupId, id) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/roles/{2}/', configuration.getAccountId(), groupId, id);
            return this.deleteData(url);
        };

        Service.removeMember = function (groupId, id) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/members/{2}/', configuration.getAccountId(), groupId, id);
            return this.deleteData(url);
        };

        Service.addMembers = function (groupId, userIds) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/members/', configuration.getAccountId(), groupId);
            var data = _.map(userIds, function (id) {
                return {user_id: id};
            });
            return this.postData(url, data);
        };

        Service.addRoles = function (groupId, roleIds) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/roles/', configuration.getAccountId(), groupId);
            var data = _.map(roleIds, function (id) {
                return {role_id: id};
            });
            return this.postData(url, data);
        };

        Service.saveGroup = function (groupId, display_name) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/', configuration.getAccountId(), groupId);
            return this.putData(url, {display_name: display_name});
        };

        Service.createGroup = function (display_name) {
            var url = String.format('/api/v1/accounts/{0}/groups/', configuration.getAccountId());
            return this.postData(url, {display_name: display_name});
        };

        Service.deleteGroup = function (groupId) {
            var url = String.format('/api/v1/accounts/{0}/groups/{1}/', configuration.getAccountId(), groupId);
            return this.deleteData(url);
        };

        Service.getPermissionList = function () {
            var url = '/api/v1/auth/permissions/';
            return this.getData(url, {cache: true})
                .then(function (response) {
                    return _.flatten(_.map(response, 'permissions'));
                });
        };

        return Service;
    }]);
