/**
 * @ngdoc directive
 * @name filePreview
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .component('filePreview', {
        templateUrl: '/smartpager/angular/endUser/shared/components/filePreview/filePreview.html',
        bindings: {
            file: '<'
        },
        controller: ['$scope', 'modalHelper', '$log', function ($scope, modalHelper, $log) {
            var $ctrl = this;

            $ctrl.previewAttachment = function (file) {
                modalHelper.openModal({
                    templateUrl: '/smartpager/angular/endUser/shared/components/filePreview/attachmentPreviewModal.html',
                    controller: 'attachmentPreviewModalCtrl',
                    fileToView: file,
                    size: 'lg'
                });
            };

            var imgMimeTypes = ['image/png', 'image/jpeg', 'image/bmp', 'image/gif'];
            $ctrl.isImage = function (mime_type) {
                return imgMimeTypes.indexOf(mime_type) !== -1;
            };

            var audioMimeTypes = ['audio/mpeg', 'audio/x-wav', 'audio/x-m4a'];
            $ctrl.isAudio = function (mime_type) {
                return audioMimeTypes.indexOf(mime_type) !== -1;
            };

            var previewableDocument = ['application/pdf'];
            $ctrl.isPreviewableDocument = function (mime_type) {
                return previewableDocument.indexOf(mime_type) !== -1;
            };

            $ctrl.type = 'download';
            if ($ctrl.isImage($ctrl.file.mime_type)) {
                $ctrl.type = 'image';
            } else if ($ctrl.isAudio($ctrl.file.mime_type)) {
                $ctrl.type = 'audio';
            } else if ($ctrl.isPreviewableDocument($ctrl.file.mime_type)) {
                $ctrl.type = 'previewableDocument';
            }
        }]
    });
