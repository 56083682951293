/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addMessageTypeToDepartmentModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('addMessageTypeListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('addMessageTypeListCache');
    }])
    .controller('addMessageTypeToDepartmentModalCtrl', ["$scope", "modalOptions", "ngTableHelper", "NgTableParams", "userService", "messageService", "addMessageTypeListCache", "Notification", function ($scope, modalOptions, ngTableHelper, NgTableParams, userService, messageService, addMessageTypeListCache, Notification) {
        var $ctrl = this;

        $ctrl.department = modalOptions.department;
        $ctrl.message_type_ids = [];

        $ctrl.addMessageTypeTableParams = new NgTableParams(addMessageTypeListCache.get('parameters') || {
                count: 10
            }, {
            getData: function (params) {
                addMessageTypeListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;
                return messageService.getAccountMessageTypesForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.addMessageTypeSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.addMessageTypeTableParams, 'search')
        };

        $ctrl.addSelected = function (messageType) {
            userService.addDepartmentMessageType($ctrl.department.id, messageType.id)
                .then(function (response) {
                    Notification.success('Successfully added ' + messageType.name + ' to ' + $ctrl.department.name);
                    $ctrl.message_type_ids.push(response.id);
                })
                .catch(function () {
                    Notification.error('Could not add ' + messageType.name + ' to ' + $ctrl.department.name);
                });
        };

        $ctrl.removeSelected = function (messageType) {
            userService.removeDepartmentMessageType($ctrl.department.id, messageType.id)
                .then(function () {
                    Notification.success('Successfully removed ' + messageType.name + ' from ' + $ctrl.department.name);
                    $ctrl.message_type_ids = _.without($ctrl.message_type_ids, messageType.id);
                })
                .catch(function () {
                    Notification.error('Could not remove ' + messageType.name + ' from ' + $ctrl.department.name);
                });
        };

        $ctrl.inMessageTypes = function (messageTypeId) {
            return $ctrl.message_type_ids.indexOf(messageTypeId) !== -1;
        };
    }]);
