/**
 * @ngdoc service
 * @name smartpager.endUser.shared:groupsService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .service('tagsService', ["baseService", "configuration", function (baseService, configuration) {

        var Service = Object.create(baseService);

        Service.getTags = function (query, ct) {
            var params = $.param(query || {});
            var url;

            if (ct !== null && ct !== undefined){
                url = String.format('/api/v1/tagging/tags/{0}/{1}', ct, params ? '?' + params : '');
            } else {
                url = String.format('/api/v1/tagging/tags/{0}', params ? '?' + params : '');
            }
            return this.getData(url);
        };

        return Service;
    }]);
