/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:remoteIdsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('remoteIdsCtrlCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('remoteIdsCtrlCache');
    }])
    .controller('remoteIdsCtrl', ["$scope", "integrationType", "integrationsService", "remoteIdsCtrlCache", "NgTableParams", "ngTableHelper", "modalHelper", function ($scope, integrationType, integrationsService, remoteIdsCtrlCache, NgTableParams, ngTableHelper, modalHelper) {

        var $ctrl = this;

        $ctrl.integrationType = integrationType;

        $ctrl.tableParams = new NgTableParams(remoteIdsCtrlCache.get('parameters-' + integrationType) || {
                filter: {
                    type: integrationType
                },
                sorting: {
                    contact_name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                remoteIdsCtrlCache.put('parameters-' + integrationType, params.parameters());

                $scope.isLoading = true;
                return integrationsService.getRemoteIdsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.addRemoteId = function () {
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/modals/addRemoteIdModal.html',
                controller: 'addRemoteIdModalCtrl as $ctrl',
                integrationType: integrationType
            }).result
                .finally(function () {
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.removeRemoteId = function (remoteId) {
            remoteId.isDeleted = true;
            integrationsService.removeRemoteId(remoteId.id)
                .then(function () {
                    $ctrl.tableParams.reload();
                });
        };
    }]);
