/**
 * @ngdoc directive
 * @name iqContactSearch
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .constant('iqContactSearchEvents', {
        addRecipient: 'iqContactSearchEvents.addRecipient'
    })
    .controller('iqContactSearchCtrl', ["$scope", "$rootScope", "modalHelper", "userService", "$log", "$timeout", "iqContactSearchEvents", "eventsBus", "baseService", function ($scope, $rootScope, modalHelper, userService, $log, $timeout, iqContactSearchEvents, eventsBus, baseService) {
        $scope.iqContactSearchCtrl = {
            availableRecipients: [],
            recipients: []
        };
        $scope.count = 0;
        var lastQuery = '';

        // Hacky workaround to the fact that a 'single' <ui-select> returns a single object (or null), whereas a
        // 'multiple' <ui-select> returns an array. This handles both cases and returns an array every time.
        function getRecipients() {
            var recipients = $scope.iqContactSearchCtrl.recipients;

            if (recipients === null) {
                return [];
            }

            return [].concat(recipients);
        }

        function getParam(query) {
            if (query !== lastQuery) {
                $scope.iqContactSearchCtrl.availableRecipients = [];
            }

            lastQuery = query;

            var param = {
                name: query,
                page: baseService.pickPage($scope.iqContactSearchCtrl.availableRecipients.length, 25)
            };

            if ($scope.searchUsers) {
                param.type = 'user';
            }

            if ($scope.includeSelf) {
                param.self = true;
            }

            if ($scope.contactType) {
                param.allcontacts = true;
                param.type = $scope.contactType;
            }

            $scope.isLoading = true;

            var promise;

            if ($scope.account) {
                param.linked = $scope.linked;
            }

            return param;
        }

        // for regular type-ahead searches
        $scope.searchContacts = function (query) {
            var param = getParam(query);
            var promise;

            if ($scope.account) {
                promise = userService.getAccountContacts(param);
            } else {
                promise = userService.getContacts(param);
            }

            promise
                .then(function (response) {
                    $scope.count = response.count;
                    $scope.iqContactSearchCtrl.availableRecipients = _.uniq($scope.iqContactSearchCtrl.availableRecipients.concat(response.results), 'id');

                    var existingRecipients = getRecipients();

                    if(existingRecipients.length > 0 && typeof existingRecipients[0] !== 'string'){
                        existingRecipients = _.map(existingRecipients, 'id');
                    }

                    var unFetchedRecipients = _.difference(existingRecipients, _.map($scope.iqContactSearchCtrl.availableRecipients, 'id'));

                    if (unFetchedRecipients.length) {
                        _.forEach(unFetchedRecipients, function (id) {
                            userService.getContact(id)
                                .then(function (response) {
                                    $scope.iqContactSearchCtrl.availableRecipients.push(response);
                                });
                        });
                    }


                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };

        $scope.$on('$destroy', eventsBus.subscribe(iqContactSearchEvents.addRecipient, function ($event, contact) {
            if (!_.find(getRecipients(), {id: contact.id})) {

                //adding contact to list of available contacts for rendering
                if (!_.find($scope.iqContactSearchCtrl.availableRecipients, {id: contact.id})) {
                    $scope.iqContactSearchCtrl.availableRecipients.push(contact);
                }
                var recipients = getRecipients();
                recipients.push($scope.propertyToMatch ? contact[$scope.propertyToMatch] : contact);
                $scope.iqContactSearchCtrl.recipients = recipients;
            }
        }));

        $scope.showAdvancedSearch = function () {
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/shared/components/iqContactSearch/iqAdvancedContactSearchModal.html',
                controller: 'iqAdvancedContactSearchModalCtrl',
                size: 'lg',
                currentlySelected: ($scope.propertyToMatch ? angular.copy($scope.iqContactSearchCtrl.recipients) : _.map($scope.iqContactSearchCtrl.recipients, 'id')),
                account: $scope.account,
                linked: $scope.linked,
                showFavorites: $scope.showFavorites
            }).result
                .then(function (contacts) {
                    $rootScope.$broadcast(iqContactSearchEvents.searchComplete);
                    _.forEach(contacts, function (contact) {
                        eventsBus.publish(iqContactSearchEvents.addRecipient, contact);
                    });
                });
        };

        $scope.showGroupLookupModal = function () {
            var results;

            var modal = modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/shared/components/iqContactSearch/iqGroupLookupModal.html',
                controller: 'iqGroupLookupModalCtrl as $ctrl'
            });

            modal.result
                .then(function (contacts) {
                    results = contacts;
                });

            modal.closed
                .then(function (contacts) {
                    $log.info(contacts);
                    _.forEach(results, function (contact) {
                        eventsBus.publish(iqContactSearchEvents.addRecipient, contact);
                    });
                });
        };
    }]);
