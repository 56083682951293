/**
 * @ngdoc controller
 * @name scheduleGroup
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.pagingGroups')
    .controller('scheduleGroupCtrl', ["$scope", "$state", "$stateParams", "alertsHelper", "pagingGroupService", "permissionsService", function ($scope, $state, $stateParams, alertsHelper, pagingGroupService, permissionsService) {

        var self = this;
        alertsHelper.create($scope, 'alerts');

        this.activate = function () {
            pagingGroupService.getScheduleGroup($stateParams.groupId)
                .then(function (group) {
                    window.location.replace('/admin/paginggroups/schedulegroups/' + group.uid + '/');
                    $scope.originalName = group.name;
                    $scope.group = group;
                });
        };
        this.activate();

        $scope.printSchedule = function(){
            window.print();
        };

    }]);
