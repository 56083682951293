/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:noteTemplatesSettingsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('noteTemplatesSettingsCtrl', ["$scope", "$stateParams", "patientsService", function ($scope, $stateParams, patientsService) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            patientsService.requestGetTemplate($stateParams.templateId);
        };

        $scope.$onRootScope(patientsService.fluxEvents.getTemplate.key, function (event, response) {
            $ctrl.template = response;
        });
    }]);
