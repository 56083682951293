angular.module('smartpager.endUser.pagingGroups')
    .controller('broadcastGroupCtrl', ["$scope", "$state", "$stateParams", "promptsService", "pagingGroupService", "pagingGroupPermissionsService", "escalationsService", "userService", "Notification", "modalHelper", "$log", "configuration", function ($scope, $state, $stateParams, promptsService, pagingGroupService, pagingGroupPermissionsService, escalationsService, userService, Notification, modalHelper, $log, configuration) {

        var self = this;
        $scope.failOverUsers = [];

        function reloadGroup() {
            pagingGroupService.getBroadcastGroup($stateParams.groupId)
                .then(function (group) {
                    $scope.originalName = group.name;
                    $scope.group = group;
                    $scope.hasChangePermission = pagingGroupPermissionsService.hasPagingGroupChangePermission(group);
                    $scope.hasDeletePermission = pagingGroupPermissionsService.hasPagingGroupDeletePermission(group);
                });
        }

        this.activate = function () {
            promptsService.getPrompts()
                .then(function (prompts) {
                    $scope.prompts = prompts;
                });

            reloadGroup();
        };
        this.activate();

        $scope.searchFailOverUsers = function (query) {
            var param = {
                search: query,
                is_active: "True"
            };
            userService.getUsers(param)
                .then(function (response) {
                    if ($scope.failOverUsers.length === 1) {
                        $scope.failOverUsers = $scope.failOverUsers.concat(response.results);
                    } else {
                        $scope.failOverUsers = response.results;
                    }
                });
        };

        $scope.searchEscalationPolicies = function (query) {
            escalationsService.getEscalationPolicies(query)
                .then(function (response) {
                    $scope.escalationPolicies = response.results;
                });
        };

        $scope.removeGroupUser = function (removedUser) {

            $scope.isLoading = true;
            var group = angular.copy($scope.group);
            _.remove(group.members, {id: removedUser.id});
            group.member_ids = _.without(group.member_ids, removedUser.id);

            pagingGroupService.updateGroup(group)
                .then(function () {
                    _.remove($scope.group.members, {id: removedUser.id});
                    $scope.group.member_ids = _.without($scope.group.member_ids, removedUser.id);

                    Notification.success(String.format('Successfully removed {0} from the group', removedUser.display_name));
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };

        $scope.$watch('group.online_greeting', function (newValue, oldValue) {

            if (!newValue) {
                return;
            }

            $scope.online_greeting_loading = true;
            promptsService.getSnippets(newValue)
                .then(function (snippets) {
                    $scope.onlineGreetingAudio = snippets;
                })
                .finally(function () {
                    $scope.onlineGreetingLoading = false;
                });
        });

        $scope.$watch('group.offline_greeting', function (newValue, oldValue) {

            if (!newValue) {
                return;
            }

            $scope.offlineGreetingLoading = true;
            promptsService.getSnippets(newValue)
                .then(function (snippets) {
                    $scope.offlineGreetingAudio = snippets;
                })
                .finally(function () {
                    $scope.offlineGreetingLoading = false;
                });
        });

        $scope.saveGroup = function () {
            $scope.isLoading = true;

            $scope.group.allow_leave = $scope.group.allow_join;

            pagingGroupService.updateGroup($scope.group)
                .then(function () {
                    Notification.success('Successfully updated group');
                })
                .catch(function (response) {
                    $log.debug('Error with status code', response.status);
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };

        $scope.deleteGroup = function () {
            modalHelper.openModal({
                title: 'Confirm delete',
                content: String.format('Are you sure you want to delete {0} group?', $scope.group.name),
                buttons: [{id: 'yes', label: 'Delete', type: 'primary'}, {
                    id: 'cancel',
                    label: 'Cancel',
                    type: 'default'
                }]
            }).result
                .then(function () {
                    pagingGroupService.deleteGroup($scope.group)
                        .then(function (resp) {
                            $state.go('root.pagingGroups.list');
                        })
                        .catch(function (resp) {
                            var usedBy = resp.data.used_by.join(', ');
                            Notification.error(String.format('Could not delete group. Group currently used by the following items: {0}', usedBy));
                        });
                });
        };

        $scope.openAddMembersToPagingGroupModal = function () {
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/modals/addMembersToPagingGroupModal.html',
                controller: 'addMembersToPagingGroupModalCtrl as $ctrl',
                size: 'lg',
                group: $scope.group
            }).result
                .finally(function () {
                    reloadGroup();
                });
        };

        $scope.clearOnlineGreeting = function () {
            $scope.group.online_greeting = null;
            $scope.onlineGreetingAudio = [];
        };

        $scope.clearOfflineGreeting = function () {
            $scope.group.offline_greeting = null;
            $scope.offlineGreetingAudio = [];
        };

        $scope.onlineGreetingAudio = [];
        $scope.offlineGreetingAudio = [];

        $scope.availableUsers = [];
        $scope.escalationPolicies = [];
    }]);
