/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:departmentDirectoryAccessCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('departmentDirectoryAccessCtrl', ["$scope", "NgTableParams", "ngTableHelper", "directoryService", "departmentId", "modalHelper", "Notification", function($scope, NgTableParams, ngTableHelper, directoryService, departmentId, modalHelper, Notification){
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams({
            sorting: {
                name: 'asc'
            }
        }, {
            getData: function(params){
                $ctrl.isLoading = true;
                return directoryService.getScopedDirectoryAccessForGrid('users.department', departmentId, params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.openAddDirectoryAccessModal = function(){
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/modals/addDirectoryAccessToDepartmentModal.html',
                controller: 'addDirectoryAccessToDepartmentModalCtrl as $ctrl',
                department: $scope.$depCtrl.department
            }).result.finally(function(result){
                $ctrl.tableParams.reload();
            });
        };

        $ctrl.removeDirectoryAccess = function(directoryAccess){
            directoryService.removeDirectoryAccess(directoryAccess.id)
                .then(function(){
                    Notification.success('Successfully removed ' + directoryAccess.name + ' from ' + $scope.$depCtrl.department.name);
                })
                .finally(function(){
                    $ctrl.tableParams.reload();
                });
        };

}]);
