/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:messageRoutingActionsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .controller('messageRoutingActionsCtrl', ["$scope", "policyId", "messageRoutingService", "routingModalsFactory", "Notification", function ($scope, policyId, messageRoutingService, routingModalsFactory, Notification) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;
            $ctrl.reload();
        };

        $ctrl.reload = function () {
            messageRoutingService.requestGetRoutingPolicy(policyId);
        };

        $scope.$onRootScope(messageRoutingService.fluxEvents.getRoutingPolicy.key, function (event, response) {
            $ctrl.policy = response;
            $ctrl.isLoading = false;
        });

        function prepare_payload(policy, action) {
            var payload = angular.copy(policy);
            payload.action_ids = _.map(payload.actions_list, 'id');

            if (action) {
                payload.action_ids.push(action.id);
            }

            delete payload.actions_list;
            return payload;
        }

        function update_policy(payload) {
            $ctrl.isSaving = true;
            return messageRoutingService.updateRoutingPolicy(payload)
                .then(function () {
                    $ctrl.reload();
                })
                .catch(function () {

                })
                .finally(function () {
                    $ctrl.isSaving = false;
                    $ctrl.selectedAction = null;
                });
        }

        $ctrl.add = function (action) {
            if (_.find($ctrl.policy.actions_list, {id: action.id})) {
                Notification.info(String.format('Action {0} is already added.', action.name));
                $ctrl.selectedConditionSet = null;
            } else {
                var payload = prepare_payload($ctrl.policy, action);
                return update_policy(payload);
            }
        };

        $ctrl.remove = function (id) {
            var policy = angular.copy($ctrl.policy);
            _.remove(policy.actions_list, {id: id});
            var payload = prepare_payload(policy);

            return update_policy(payload);
        };

        $ctrl.edit = function (action) {
            var modalResult = routingModalsFactory.showCreateEditActionModal(action);

            modalResult
                .then(function (updatedAction) {
                    _.remove($ctrl.policy.actions_list, {id: updatedAction.id});
                    $ctrl.policy.actions_list.push(updatedAction);
                });

            return modalResult;
        };

        $ctrl.createAction = function () {
            $ctrl.edit()
                .then(function (action) {
                    $ctrl.add(action);
                    $scope.$emit('reloadMessageRoutingActions');
                });
        };
    }]);
