/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:amionConfigurationCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.messages")
  .factory("amionScheduleCtrlCache", ["$cacheFactory", function($cacheFactory) {
    return $cacheFactory("amionScheduleCtrlCache");
  }])
  .controller("amionConfigurationCtrl", ["$scope", "$state", "configId", "amionScheduleCtrlCache", "integrationsService", "Notification", "NgTableParams", "modalHelper", function(
    $scope,
    $state,
    configId,
    amionScheduleCtrlCache,
    integrationsService,
    Notification,
    NgTableParams,
    modalHelper
  ) {
    var $ctrl = this;

    $scope.timezones = [
      "America/Adak",
      "America/Anchorage",
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Chicago",
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Denver",
      "America/Detroit",
      "America/Edmonton",
      "America/Fort_Nelson",
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Indiana/Indianapolis",
      "America/Indiana/Knox",
      "America/Indiana/Marengo",
      "America/Indiana/Petersburg",
      "America/Indiana/Tell_City",
      "America/Indiana/Vevay",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Inuvik",
      "America/Iqaluit",
      "America/Juneau",
      "America/Kentucky/Louisville",
      "America/Kentucky/Monticello",
      "America/Los_Angeles",
      "America/New_York",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Phoenix",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Regina",
      "America/Resolute",
      "America/Rio_Branco",
      "America/Santarem",
      "America/Santo_Domingo",
      "America/Scoresbysund",
      "America/Sitka",
      "America/St_Barthelemy",
      "America/Thule",
      "America/Thunder_Bay",
      "America/Tijuana",
      "America/Toronto",
      "America/Tortola",
      "America/Vancouver",
      "America/Whitehorse",
      "America/Winnipeg",
      "America/Yakutat",
      "America/Yellowknife",
      "Canada/Atlantic",
      "Canada/Central",
      "Canada/Eastern",
      "Canada/Mountain",
      "Canada/Newfoundland",
      "Canada/Pacific",
      "GMT",
      "US/Alaska",
      "US/Arizona",
      "US/Central",
      "US/Eastern",
      "US/Hawaii",
      "US/Mountain",
      "US/Pacific",
      "UTC"
    ];

    $scope.isCreate = configId === "new";

    $scope.configuration = { fail_over_user: null };

    $ctrl.tableParams = new NgTableParams(
      amionScheduleCtrlCache.get("parameters") || {
        count: 10
      },
      {
        getData: function(params) {
          amionScheduleCtrlCache.put("parameters", params.parameters());

          $scope.isLoading = true;

          integrationsService.cancel($ctrl.lastProvidersRequest);

          $ctrl.lastProvidersRequest = integrationsService.getAmionSchedulesForGrid(
            configId,
            params.parameters()
          );

          return $ctrl.lastProvidersRequest
            .then(function(response) {
              params.total(response.count);
              return response.results;
            })
            .finally(function() {
              $scope.isLoading = false;
            });
        }
      }
    );

    $scope.reload = function(id) {
      integrationsService
        .getAmionConfiguration(id)
        .then(function(result) {
          $scope.configuration = result;
        })
        .catch(function() {
          window.location = "/accounts/integrations/";
        });
    };

    if ($scope.isCreate) {
    } else {
      $scope.reload(configId);
    }

    $ctrl.update = function() {
      var promise;
      if ($scope.isCreate) {
        promise = integrationsService.createAmionConfiguration(
          $scope.configuration
        );
      } else {
        promise = integrationsService.updateAmionConfiguration(
          $scope.configuration
        );
      }
      promise.then(function(result) {
        $state.go("root.accountSettings.integrations.amion", {
          configId: result.id
        });
        Notification.success("Configuration saved");
      });
    };

    $ctrl.delete = function() {
      integrationsService
        .deleteAmionConfiguration($scope.configuration.id)
        .then(function() {
          Notification.success("Configuration deleted");
          window.location = "/accounts/integrations/";
        });
    };

    $ctrl.deleteMapping = function(mapping) {
      modalHelper
        .openModal({
          title: "Delete schedule mapping",
          content: String.format(
            "Are you sure you want to delete schedule mapping {0}?",
            mapping.schedule_group_name
          ),
          buttons: [
            { id: "yes", type: "primary", label: "Delete" },
            { id: "cancel", type: "default", label: "Cancel" }
          ]
        })
        .result.then(function() {
          return integrationsService.deleteAmionScheduleConfig(
            configId,
            mapping.id
          );
        })
        .then(function() {
          Notification.success("Schedule mapping deleted");
          $ctrl.tableParams.reload();
        });
    };

    $ctrl.refreshMapping = function(mapping) {
      mapping.isRefreshed = true;
      integrationsService
        .refreshAmionSchedule(configId, mapping.id)
        .then(function() {
          Notification.success("Initiated refresh");
        })
        .finally(function() {
          mapping.isRefreshed = false;
        });
    };

    $ctrl.addSchedule = function() {
      $state.go("root.accountSettings.integrations.amionScheduleConfig", {
        configId: configId,
        scheduleId: "new"
      });
    };

    $ctrl.forceSync = function() {
      $ctrl.forceSyncInProgress = true;
      integrationsService
        .forceSyncAmion(configId)
        .then(function() {
          Notification.success("Successfully synced with amion");
        })
        .catch(function() {
          Notification.error("Error syncing with amion");
        })
        .finally(function() {
          $ctrl.forceSyncInProgress = false;
        });
    };
  }]);
