/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:roleDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('roleDetailCtrl', ["$scope", "$state", "roleId", "roleService", "Notification", "modalHelper", function ($scope, $state, roleId, roleService, Notification, modalHelper) {
        var $ctrl = this;
        $ctrl.openSection = '';

        $ctrl.reload = function () {
            $ctrl.loadingPermissionMeta = true;
            roleService.getRole(roleId)
                .then(function (response) {
                    $ctrl.role = response;

                    return roleService.getPermissions();
                })
                .then(function (response) {
                    $ctrl.allPermissions = _.mapValues(response, function (item) {

                        //get permission id from this section
                        var permission_ids = _.map(item.permissions, 'id');

                        //check which are selected
                        item.selectedPermissions = _.intersection($ctrl.role.permission_ids, permission_ids);

                        //save for comparison
                        item.originalPermissions = angular.copy(item.selectedPermissions);

                        $ctrl.updateAllSelectedCheckbox(item);

                        return item;
                    });
                })
                .catch(function (response) {
                    Notification.error('Could not load the role');
                    $state.go('root.accountSettings.roles.list');
                })
                .finally(function () {
                    $ctrl.loadingPermissionMeta = false;
                });
        };



        function openSection(sectionKey) {
            // setSelected();
            if ($ctrl.openSection === sectionKey) {
                $ctrl.openSection = '';
            } else {
                $ctrl.openSection = sectionKey;
            }
        }

        function findDifferences(section) {
            return {
                added_ids: _.difference(section.selectedPermissions, section.originalPermissions),
                removed_ids: _.difference(section.originalPermissions, section.selectedPermissions)
            };
        }

        $ctrl.toggleOpen = function (sectionKey) {
            if ($ctrl.openSection) {
                var currentlyOpenedSection = $ctrl.allPermissions[$ctrl.openSection];
                var differences = findDifferences(currentlyOpenedSection);

                if (differences.added_ids.length || differences.removed_ids.length) {
                    modalHelper.openModal({
                        'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/roles/modals/confirmNavigateModal.html'
                    }).result
                        .then(function () {
                            //reset selection
                            currentlyOpenedSection.selectedPermissions = angular.copy(currentlyOpenedSection.originalPermissions);
                            openSection(sectionKey);
                        });
                } else {
                    openSection(sectionKey);
                }
            } else {
                openSection(sectionKey);
            }
        };

        $ctrl.toggleSelectAll = function (section, selected) {
            if (selected) {
                section.selectedPermissions = _.map(section.permissions, 'id');
            } else {
                section.selectedPermissions = [];
            }
        };

        $ctrl.updateAllSelectedCheckbox = function (section) {
            section.allSelected = section.selectedPermissions.length === section.permissions.length;
        };

        $ctrl.openSaveChangesModal = function () {
            var currentlyOpenedSection = $ctrl.allPermissions[$ctrl.openSection];
            var differences = findDifferences(currentlyOpenedSection);

            differences.added = _.filter(currentlyOpenedSection.permissions, function (item) {
                return differences.added_ids.indexOf(item.id) !== -1;
            });
            differences.removed = _.filter(currentlyOpenedSection.permissions, function (item) {
                return differences.removed_ids.indexOf(item.id) !== -1;
            });

            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/roles/modals/savePermissionChangesModal.html',
                'controller': 'savePermissionChangesModalCtrl as $ctrl',
                role: $ctrl.role,
                differences: differences
            }).result
                .then(function () {
                    $ctrl.reload();
                });
        };

        $ctrl.saveRole = function () {
            $ctrl.isLoading = true;
            roleService.updateRole($ctrl.role)
                .then(function (response) {
                    Notification.success('Successfully updated ' + $ctrl.role.name);
                    $ctrl.reload();
                })
                .catch(function () {
                    Notification.error('Could not update ' + $ctrl.role.name);
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.deleteRole = function () {
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/roles/modals/deleteRoleModal.html',
                controller: 'deleteRoleModalCtrl as $ctrl',
                role: $ctrl.role
            }).result
                .then(function () {
                    $state.go('root.accountSettings.roles.list');
                });
        };

        $ctrl.reload();
    }]);
