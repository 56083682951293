/**
 * @ngdoc controller
 * @name addThreadPatient
 *
 * @description
 * This modal control manages content when displaying a thread's existing patient or adding a
 * patient to an ongoing thread.
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('addThreadPatientModalCtrl', ["$scope", "$state", "$stateParams", "messageService", "$log", "modalOptions", "alertsHelper", "permissionsService", "configuration", function ($scope, $state, $stateParams, messageService, $log, modalOptions, alertsHelper, permissionsService, configuration) {
        var $ctrl = this;
        alertsHelper.create($scope, 'alerts');

        $ctrl.thread = modalOptions.thread;
        $ctrl.threadPatientId = modalOptions.threadPatient === undefined ? undefined :  modalOptions.threadPatient.id;
        $ctrl.threadPatient = modalOptions.threadPatient;
        $ctrl.threadEncounterId = modalOptions.threadEncounterId === undefined ? undefined : modalOptions.threadEncounterId;

        $ctrl.useMenerva = permissionsService.hasFeature("use_menerva");

        $ctrl.updateEncounterId = function(encId) {
            $ctrl.threadEncounterId = encId;
        };

        $scope.attachPatientToThread = function () {

            var promise;
            if ($ctrl.useMenerva) {
                promise = messageService.setThreadEncounter($state.params.threadId, $ctrl.threadEncounterId);
            } else {
                promise = messageService.setThreadPatient($state.params.threadId, $ctrl.threadPatientId);
            }

            $scope.saving = true;
            promise
                .then(function (threadStub) {

                    $scope.$close(threadStub);
                })
                .catch(function (data) {
                    $scope.alerts.error('Unable to add subscriber');
                })
                .finally(function () {
                    $scope.saving = false;
                });
        };
    }]);
