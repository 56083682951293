/**
 * @ngdoc controller
 * @name smartpager.endUser.shared:departmentSearchCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.shared')
    .controller('departmentSearchCtrl', ["$scope", "userService", function ($scope, userService) {

        $scope.filter = {};

        $scope.reload = function () {
            $scope.searchDepartments();
        };

        $scope.searchDepartments = function (search) {
            userService.getDepartments({search: search})
                .then(function (response) {
                    $scope.departments = response.results;
                    var departmentIds = _.map($scope.departments, 'id');

                    if (Number.isInteger($scope.ngModel) && departmentIds.indexOf($scope.ngModel) < 0) {
                        userService.getDepartmentById($scope.ngModel)
                            .then(function (response) {
                                $scope.departments.push(response);
                            });
                    }
                });
        };

        $scope.reload();
    }]);
