angular.module('smartpager.endUser.shared')
    .factory('pagingGroupService', ["baseService", function (baseService) {

        var Service = Object.create(baseService);

        Service.getPagingGroups = function (parameters) {
            var url = '/api/v1/paginggroups/' + (parameters ? this.toUrl(parameters) : '');
            return this.getData(url);
        };

        Service.getNewPagingGroup = function () {
            return {
                name: '',
                type: 'ScheduleGroup'
            };
        };

        Service.addBroadcastGroup = function (groupName) {
            var url = '/api/v1/broadcastgroups/';
            return this.postData(url, {name: groupName});
        };

        Service.addScheduleGroup = function (groupName, failOverUser) {
            var url = '/api/v1/schedulegroups/';
            return this.postData(url, {name: groupName, fail_over_user: failOverUser});
        };

        Service.getBroadcastGroup = function (groupId) {
            var url = String.format('/api/v1/broadcastgroups/{0}/', groupId);
            return this.getData(url);
        };

        Service.getScheduleGroup = function (groupId) {
            var url = String.format('/api/v1/schedulegroups/{0}/', groupId);
            return this.getData(url);
        };

        Service.updateGroup = function (group) {

            if (!group.department_id) {
                group.department_id = null;
            }
            if (!group.fail_over_user) {
                group.fail_over_user = null;
            }
            if(!group.escalation_policy) {
                group.escalation_policy = null;
            }
            var url = String.format('/api/v1/{0}s/{1}/', group.type.toLowerCase(), group.id);
            return this.putData(url, group);
        };

        Service.deleteGroup = function (group) {
            var url = String.format('/api/v1/{0}s/{1}/', group.type.toLowerCase(), group.id);
            return this.deleteData(url);
        };

        Service.getOnCall = function (groupId) {
            var url = String.format('/api/v1/schedulegroups/{0}/oncall/', groupId);
            return this.getData(url)
                .then(function (result) {
                    return {
                        onCallUsers: result,
                        onCallUsersDisplay: _.map(result, 'display_name').join(', ') || 'Nobody On Call',
                        onCallUsersFetched: moment()
                    };
                });
        };

        return Service;
    }]);
