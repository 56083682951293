/**
 * @ngdoc directive
 * @name iqContactSearch
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular
  .module("smartpager.endUser.shared")
  .factory("iqAdvancedContactSearchModalCache", ["$cacheFactory", function($cacheFactory) {
    return $cacheFactory("iqAdvancedContactSearchModalCache");
  }])
  .controller("iqAdvancedContactSearchModalCtrl", ["$scope", "modalOptions", "userService", "NgTableParams", "ngTableHelper", "$filter", "iqAdvancedContactSearchModalCache", "configuration", "$log", function(
    $scope,
    modalOptions,
    userService,
    NgTableParams,
    ngTableHelper,
    $filter,
    iqAdvancedContactSearchModalCache,
    configuration,
    $log
  ) {
    var self = this;

    $scope.showFavorites = modalOptions.showFavorites;

    /*jshint newcap: false */
    $scope.tableParams = new NgTableParams(
      iqAdvancedContactSearchModalCache.get("parameters") || {
        sorting: {
          name: "asc" // initial sorting
        }
      },
      {
        getData: function(params) {
          iqAdvancedContactSearchModalCache.put(
            "parameters",
            params.parameters()
          );

          $scope.isLoading = true;

          var promise;

          if (modalOptions.account) {
            var p = params.parameters();
            p.linked = modalOptions.linked;
            promise = userService.getAccountContactsForGrid(p);
          } else {
            promise = userService.getContactsForGrid(params.parameters());
          }

          return promise
            .then(function(response) {
              params.total(response.count);

              _.forEach(response.results, function(item) {
                item.alreadySelected =
                  modalOptions.currentlySelected.indexOf(item.id) >= 0;
              });

              return response.results;
            })
            .finally(function() {
              $scope.isLoading = false;
            });
        }
      }
    );

    $scope.types = [
      { id: "User", name: "User" },
      { id: "Group", name: "Group" }
    ];

    $scope.searchParams = {
      search: ngTableHelper.generateGetterSetter($scope.tableParams, "search"),
      user__departments: ngTableHelper.generateGetterSetter(
        $scope.tableParams,
        "user__departments"
      ),
      type: ngTableHelper.generateGetterSetter($scope.tableParams, "type")
    };

    self.addSelected = function() {
      $scope.$close($scope.selected.contacts);
    };

    self.close = function() {
      $scope.$close([]);
    };

    $scope.selected = {
      contacts: [],
      all: false
    };

    // watch for check all checkbox
    $scope.$watch("selected.all", function(value) {
      if (value) {
        angular.forEach($scope.tableParams.data, function(item) {
          //add all which are not yet on the list
          if (!_.some($scope.selected.contacts, { id: item.id })) {
            $scope.selected.contacts.push(item);
          }
        });
      } else {
        $scope.selected.contacts = [];
      }
    });

    // watch for data checkboxes
    $scope.$watchCollection("selected.contacts", function() {
      if (!$scope.tableParams.data) {
        return;
      }

      var checked = $scope.selected.contacts.length,
        total = $scope.tableParams.data.length;

      // grayed checkbox
      angular
        .element(document.getElementById("select_all_contacts"))
        .prop("indeterminate", checked > 0 && checked !== total);
    });

    $scope.hideMobileNumbers = configuration.getUserProfile().hide_mobile_numbers;

    $scope.addFavorite = function(contact) {
      userService.addFavoriteContact(contact.id).then(function(result) {
        contact.is_favorite = true;
      });
    };

    $scope.removeFavorite = function(contact) {
      userService.removeFavoriteContact(contact.id).then(function(result) {
        contact.is_favorite = false;
      });
    };
  }]);
