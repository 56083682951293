/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('groupListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('groupListCache');
    }])
    .controller('groupListCtrl', ["$scope", "NgTableParams", "ngTableHelper", "groupsService", "groupListCache", "modalHelper", function ($scope, NgTableParams, ngTableHelper, groupsService, groupListCache, modalHelper) {

        $scope.tableParams = new NgTableParams(groupListCache.get('parameters') || {
                sorting: {
                    display_name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                groupListCache.put('parameters', params.parameters());

                $scope.isLoading = true;
                return groupsService.getGroupsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });

        $scope.searchParams = {
            search: ngTableHelper.generateGetterSetter($scope.tableParams, 'search')
        };

        $scope.openCreateGroupModal = function () {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/groups/modals/createGroupModal.html',
                'controller': 'createGroupModalCtrl as ctrl'
            }).result
                .then(function () {
                    $scope.tableParams.reload();
                });
        };
    }]);
