angular
  .module("smartpager.endUser.messages")

  .controller("composeMessageModalCtrl", ["$controller", "$scope", "$rootScope", "$state", "messageService", "userService", "pagingGroupService", "fileUploadHelper", "$log", "configuration", "inboxEvents", "alertsHelper", "$timeout", "modalOptions", "permissionsService", "iqContactSearchEvents", function(
    $controller,
    $scope,
    $rootScope,
    $state,
    messageService,
    userService,
    pagingGroupService,
    fileUploadHelper,
    $log,
    configuration,
    inboxEvents,
    alertsHelper,
    $timeout,
    modalOptions,
    permissionsService,
    iqContactSearchEvents
  ) {
    var $ctrl = this;
    $scope.hasFeature = permissionsService.hasFeature;
    $ctrl.useMenerva = permissionsService.hasFeature("use_menerva");

    if (modalOptions.patient) {
      $ctrl.aboutEncounter = undefined;
      $ctrl.aboutPatient = modalOptions.patient;
    } else if (modalOptions.encounter) {
      $ctrl.aboutEncounter = modalOptions.encounter;
      $ctrl.aboutPatient = modalOptions.encounter.patient;
    }

    alertsHelper.create($ctrl, "alerts");
    $controller("composeMessageBaseCtrl", { $ctrl: $ctrl });

    angular.extend($ctrl, {
      newThread: {
        requires_confirm: false,
        recipients: [],
        data: {}
      },

      selectedMessageType: null,
      uploader: angular.extend(fileUploadHelper.getAttachmentsFileUploader(), {
        autoUpload: true,
        onSuccessItem: function(item, response, status, headers) {
          item.uploadId = response.id;
        }
      })
    });

    $ctrl.checkForOffline = function(recipients) {
      if (recipients !== undefined) {
        var recipient_statuses = _.map($ctrl.recipients, "properties.status");
        if (recipient_statuses.indexOf(1) > -1) {
          setOfflineRecipientWarning();
        } else {
          setNoOfflineRecipients();
        }
      } else {
        setNoOfflineRecipients();
      }
    };

    function setNoOfflineRecipients() {
      if ($ctrl.submitButton === undefined) {
        $ctrl.submitButton = {};
      }
      $ctrl.submitButton.status = "btn-success";
      $ctrl.submitButton.message = "Send message";

      if ($ctrl.warning === undefined) {
        $ctrl.warning = {};
      }
      $ctrl.warning.message = null;
      $ctrl.warning.state = null;
    }

    function setOfflineRecipientWarning() {
      // We don't want to overwrite the warning level 2 message if's showing.
      if ($ctrl.warning.state === null) {
        $ctrl.submitButton.status = "btn-warning";
        $ctrl.warning.message = "One or more of your recipients are offline.";
        $ctrl.warning.state = 1;
      }
    }

    function setOfflineRecipientDanger() {
      $ctrl.submitButton.status = "btn-danger";
      $ctrl.submitButton.message = "Send anyway";
      $ctrl.warning.message =
        "" +
        "Are you sure you want to send your message to offline users? <br/>" +
        "They may not be alerted to your message right away.";
      $ctrl.warning.state = 2;
    }

    $scope.$on("$destroy", function() {
      $ctrl.uploader.cancelAll();
    });

    $ctrl.selectMessageType = function($model) {
      if ($model) {
        $ctrl.selectedMessageType = angular.copy($model);

        if (
          $ctrl.selectedMessageType.response_options &&
          $ctrl.selectedMessageType.response_options.length === 0
        ) {
          $ctrl.selectedMessageType.response_options = ["", "", "", ""];
        }

        $ctrl.uploader.cancelAll();
        $ctrl.uploader.clearQueue();

        $ctrl.newThread.requires_confirm = $model.requires_confirm;
        $ctrl.newThread.apply_escalations = $model.apply_escalations;
        $ctrl.newThread.ignore_user_status = $model.ignore_user_status;
        $ctrl.newThread.allow_replies = $model.allow_replies;
        $ctrl.newThread.priority = $model.priority;
        $ctrl.newThread.suppress_reply_notifications =
          $model.suppress_reply_notifications;
      }
    };

    function setSubmitted(form) {
      form.$setSubmitted();
      angular.forEach(form, function(item) {
        if (item && item.$$parentForm === form && item.$setSubmitted) {
          setSubmitted(item);
        }
      });
    }

    $ctrl.updateEncounterId = function(encId) {
      $ctrl.newThread.encounter_id = encId;
    };

    $ctrl.previewThread = _.debounce(function() {
      if (
        !permissionsService.hasFeature("routing") ||
        $ctrl.newThreadHeaderForm.$invalid
      ) {
        return;
      }

      var thread = prepareThread();
      $ctrl.isCheckingRouting = true;
      messageService
        .queryRouting(thread)
        .then(function(response) {
          $ctrl.routingResults = response;
        })
        .catch(function() {
          $ctrl.routingResults = {};
        })
        .finally(function() {
          $ctrl.isCheckingRouting = false;
        });
    }, 500);

    function prepareThread() {
      var thread = angular.copy($ctrl.newThread);

      if ($ctrl.selectedMessageType) {
        thread.message_type_id = $ctrl.selectedMessageType.id;
        thread.response_options = $ctrl.selectedMessageType.response_options;

        $ctrl.selectedMessageType.fields.forEach(function(field) {
          if (field.value !== undefined) {
            thread.data[field.slug] = field.value;
          }
        });
      }

      thread.attachment_ids = _.map($ctrl.uploader.queue, "uploadId");
      thread.recipient_ids = !$ctrl.usesDirectoryComposition
        ? _.map($ctrl.recipients, "id")
        : _.map($ctrl.recipients, "contact_id");

      if ($ctrl.aboutEncounter) {
        // This is a menerva patient, populate encounter id
        thread.encounter_id = $ctrl.aboutEncounter.id;
      } else if ($ctrl.aboutPatient) {
        thread.patient_id = $ctrl.aboutPatient.id;
      }

      return thread;
    }

    $ctrl.postThread = function() {
      setSubmitted($ctrl.newThreadForm);

      if ($ctrl.warning.state === 1) {
        setOfflineRecipientDanger();
        return;
      }

      $ctrl.newThreadForm.error = {};

      if ($ctrl.newThreadForm.$invalid) {
        console.info("New thread form invalid");
        return;
      }

      var thread = prepareThread();

      $log.debug("starting new thread", thread);

      $ctrl.sendingMessage = true;
      messageService
        .createNewThreads(thread)
        .then(function(createdThread) {
          $rootScope.$emit(inboxEvents.newThread, createdThread);
          $state.go("root.inbox.thread", {
            inboxType: "inbox",
            threadId: createdThread.id
          });

          $scope.$close();
        })
        .catch(function(response) {
          if (response.status === 406) {
            $ctrl.alerts.error(response.data.message);
          } else if (response.status === 400 && response.data) {
            if (response.data.recipient_ids) {
              _.each(response.data.recipient_ids, function(err) {
                $ctrl.alerts.error(err);
              });
            } else if (response.data.subject) {
              $ctrl.newThreadForm.error.subject = response.data.subject;
            } else if (response.data.detail) {
              $ctrl.alerts.error(response.data.detail);
            } else {
              $ctrl.alerts.error("Error sending message");
            }
          } else if (response.status === 500) {
            $ctrl.alerts.error("A server error has occurred.");
          } else {
            $ctrl.alerts.error("Error sending message");
          }
        })
        .finally(function() {
          $ctrl.sendingMessage = false;
        });
    };

    $ctrl.$onInit = function() {
      var currentUser = configuration.getUserProfile();
      $ctrl.usesDirectoryComposition = configuration.hasFeature(
        "directoryComposition"
      );

      $ctrl.isLoadingMessageTypes = true;
      messageService
        .getMessageTypes()
        .then(function(response) {
          $ctrl.messageTypes = response.results;

          if (currentUser.default_message_type_id) {
            $ctrl.selectedMessageTypeOriginal = _.find($ctrl.messageTypes, {
              id: currentUser.default_message_type_id
            });
            $ctrl.selectMessageType($ctrl.selectedMessageTypeOriginal);
          }
        })
        .finally(function() {
          $ctrl.isLoadingMessageTypes = false;
        });

      if (modalOptions.contactableIds && modalOptions.contactableIds.length) {
        _.forEach(modalOptions.contactableIds, function(contactableId) {
          userService.getContact(contactableId).then(function(contact) {
            $ctrl.addToRecipients(contact);
          });
        });
      }

      $ctrl.reloadSavedContacts();

      $ctrl.reloadLocalGroups();
    };

    $scope.$watchCollection("$ctrl.recipients", $ctrl.previewThread);
    $scope.$watchCollection("$ctrl.recipients", $ctrl.checkForOffline);
    $scope.$watch("$ctrl.selectedMessageType", $ctrl.previewThread, true);
    $scope.$watch("$ctrl.newThread", $ctrl.previewThread, true);

    $rootScope.$on(iqContactSearchEvents.searchComplete, function() {
      $ctrl.reloadSavedContacts();
    });
  }]);
