/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:reportListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .controller('reportListCtrl', ["$scope", "NgTableParams", "reportService", "$state", "modalHelper", function ($scope, NgTableParams, reportService, $state, modalHelper) {
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams({}, {
            getData: function (params) {
                $ctrl.isLoading = true;
                return reportService.getReports()
                    .then(function (response) {
                        params.total(response.length);
                        return response;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.runReport = function (slug) {
            if (slug === 'user-mt-report') {
                $ctrl.openSelectUserModal(slug)
                    .then(function (userId) {
                        $state.go('root.reports.user-mt-report', {reportSlug: slug, userId: userId});
                    });
            } else if (slug === 'user-monitoring-report') {
                 $ctrl.openSelectUserModal(slug)
                    .then(function (userId) {
                        $state.go('root.reports.user-monitoring-report', {reportSlug: slug, userId: userId});
                    });
            } else if (slug === 'device-breakdown-report') {
                $ctrl.openSelectDepartmentsModal(slug)
                    .then(function(departmentIds){
                        $state.go('root.reports.device-breakdown-report', {reportSlug: slug, departmentIds: departmentIds}, {inherit: false});
                    });
            } else {
                var state = String.format('root.reports.{0}', slug);
                $state.go(state, {reportSlug: slug});
            }
        };

        $ctrl.openSelectUserModal = function (slug) {
            return modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/reports/controllers/modals/selectUserModal.html',
                controller: 'selectUserModalCtrl as $ctrl'
            }).result;
        };

        $ctrl.openSelectDepartmentsModal = function(slug){
            return modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/reports/controllers/modals/selectDepartmentsModal.html',
                controller: 'selectDepartmentsModalCtrl as $ctrl'
            }).result;
        };
    }]);
