/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:userMtReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.reports")
  .factory("userMtReportCache", ["$cacheFactory", function($cacheFactory) {
    return $cacheFactory("userMtReportCache");
  }])
  .controller("userMtReportCtrl", ["$scope", "reportSlug", "userId", "reportService", "NgTableParams", "ngTableHelper", "userMtReportCache", "$state", function(
    $scope,
    reportSlug,
    userId,
    reportService,
    NgTableParams,
    ngTableHelper,
    userMtReportCache,
    $state
  ) {
    var $ctrl = this;

    $ctrl.$onInit = function() {
      var params = userMtReportCache.get("parameters");
      if (params) {
        params.filter.user_id = userId;
        userMtReportCache.put("parameters", params);
      }
    };

    $ctrl.tableParams = new NgTableParams(
      userMtReportCache.get("parameters") || {
        sorting: {
          name: "asc"
        },
        count: 9000,
        filter: {
          user_id: userId
        }
      },
      {
        getData: function(params) {
          $ctrl.isLoading = true;

          userMtReportCache.put("parameters", params.parameters());
          return reportService
            .runReport(reportSlug, params.parameters())
            .then(function(response) {
              params.total(response.count);
              $scope.username = response.metadata.username;
              return response.results;
            })
            .finally(function() {
              $ctrl.isLoading = false;
            });
        }
      }
    );

    $ctrl.searchParams = {
      search: ngTableHelper.generateGetterSetter($ctrl.tableParams, "search")
    };

    $ctrl.downloadCsv = function() {
      reportService.downloadReportAsCSV(
        reportSlug,
        userMtReportCache.get("parameters"),
        "User Message Type Report - " + $scope.username + ".csv"
      );
    };
  }]);
