/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addMemberToGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addSavedFilterApplicationToDepartmentModalCtrl', ["$scope", "savedFiltersService", "modalOptions", "Notification", function ($scope, savedFiltersService, modalOptions, Notification) {
        var self = this;

        self.selected = [];
        $scope.department = modalOptions.department.id;

        $scope.addSelected = function () {
            console.log(self.selected);
            savedFiltersService.addSavedFilterApplication('users.department', modalOptions.department.id, self.selected)
                .then(function (response) {
                    var addedSfs = _.map(response, 'name').join(', ');
                    Notification.success('Successfully added ' + addedSfs+ ' to the department.');
                    $scope.$close();
                });
        };
    }]);
