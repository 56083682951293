/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('savedFilterListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('savedFilterListCache');
    }])
    .controller('savedFilterListCtrl', ["$scope", "NgTableParams", "ngTableHelper", "savedFiltersService", "savedFilterListCache", "modalHelper", function ($scope, NgTableParams, ngTableHelper, savedFiltersService, savedFilterListCache, modalHelper) {

        $scope.tableParams = new NgTableParams(savedFilterListCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                savedFilterListCache.put('parameters', params.parameters());

                $scope.isLoading = true;
                return savedFiltersService.getSavedFiltersForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });

        $scope.searchParams = {
            search: ngTableHelper.generateGetterSetter($scope.tableParams, 'search')
        };

        $scope.openCreateSavedFilterModal = function () {
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/savedFilters/modals/createSavedFilterModal.html',
                'controller': 'createSavedFilterModalCtrl as ctrl'
            }).result
                .then(function () {
                    $scope.tableParams.reload();
                });
        };
    }]);
