/**
 * @ngdoc directive
 * @name iqUserSelect
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular
  .module('smartpager.endUser.shared')
  .directive('iqUserSelect', ["$log", "$compile", "$templateRequest", function($log, $compile, $templateRequest) {
    return {
      restrict: 'E',
      require: 'ngModel',
      transclude: true,
      scope: {
        label: '@',
        hideLabel: '=',
        hideStatus: '=',
        placeholder: '@',
        propertyToMatch: '@',
        onSelect: '&',
        monitorable: '='
      },
      controller: 'iqUserSelectCtrl',
      templateUrl: function(tElement, tAttrs) {
        if (tAttrs.multiple && tAttrs.multiple === 'false') {
          return '/smartpager/angular/endUser/shared/components/iqUserSelect/iqUserSelect-single.html';
        } else {
          return '/smartpager/angular/endUser/shared/components/iqUserSelect/iqUserSelect-multiple.html';
        }
      },
      link: function(scope, iElement, iAttrs, ngModelCtrl) {
        //region handle ngModelCtrl

        ngModelCtrl.$formatters.push(function(modelValue) {
          return angular.copy(modelValue);
        });

        ngModelCtrl.$parsers.push(function(viewValue) {
          return angular.copy(viewValue);
        });

        scope.$watchCollection('iqUserSelectCtrl.users', function(newUsers) {
          $log.debug('iqUserSelectCtrl updates ngModel binding', newUsers);
          ngModelCtrl.$setViewValue(angular.copy(newUsers));
        });

        scope.$watch('iqUserSelectCtrl.user', function(newUser) {
          ngModelCtrl.$setViewValue(angular.copy(newUser));
        });

        scope.placeholder = !scope.placeholder ? 'Select User' : scope.placeholder;

        ngModelCtrl.$render = function() {
          scope.iqUserSelectCtrl.users = ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue : [];
          scope.iqUserSelectCtrl.user = ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue : null;
        };

        if (iAttrs.required) {
          ngModelCtrl.$validators.required = function(modelValue, viewValue) {
            if (iAttrs.multiple && iAttrs.multiple === 'false') {
              return !!modelValue;
            } else {
              return !!(modelValue && modelValue.length !== 0);
            }
          };
        }

        scope.excludeDeactivated = iAttrs.excludeDeactivated !== undefined;
        //endregion
      }
    };
  }]);
