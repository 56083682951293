/**
 * @ngdoc service
 * @name userService
 * @description
 * _Please update the description and dependencies._
 *
 * */
angular
  .module("smartpager.endUser.shared")
  .factory("userService", ["baseService", "configuration", "$rootScope", function(baseService, configuration, $rootScope) {
    var Service = Object.create(baseService);

    Service.getUsers = function(query) {
      query = query ? query : {};
      var params = $.param(query);

      var url = "/api/v1/users/?basic=true" + (params ? "&" + params : "");

      return this.getData(url).then(function(response) {
        _.forEach(response.results, function(item) {
          item.type = "User";
        });
        return response;
      });
    };

    Service.createBulkEditSession = function (data) {
        var accountUid = configuration.getAccountUid();
        const url = '/api/v2/accounts/' + accountUid + '/users/bulkedit/session/';
        return this.postData(url, data);
    };

    Service.getUsersForGrid = function(parameters) {
      var url = String.format("/api/v1/users/{0}", this.toUrl(parameters));
      return this.getData(url);
    };

    Service.getUserById = function(id) {
      var url = String.format("/api/v1/users/{0}/", id);
      return this.getData(url);
    };

    Service.getUserPagingGroups = function(id) {
      var url = String.format("/api/v1/contacts/{0}/paging_groups/", id);
      return this.getData(url);
    };

    Service.getContacts = function(query) {
      query = query ? query : {};
      var params = $.param(query);

      var url = String.format(
        "/api/v1/contacts/{0}",
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.getContact = function(contactId) {
      var url = String.format("/api/v1/contacts/{0}/", contactId);
      return this.getData(url);
    };

    Service.getContactsForGrid = function(parameters) {
      var url = String.format("/api/v1/contacts/{0}", this.toUrl(parameters));
      return this.getData(url);
    };

    Service.getAccountContacts = function(query) {
      query = query ? query : {};
      var accountId = configuration.getAccountId();
      var params = $.param(query);

      var url = String.format(
        "/api/v1/accounts/{0}/contacts/{1}",
        accountId,
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.getAccountContactsForGrid = function(parameters) {
      var accountId = configuration.getAccountId();
      var url = String.format(
        "/api/v1/accounts/{0}/contacts/{1}",
        accountId,
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    Service.getPinnedContacts = function() {
      var url = "/api/v1/pinnedcontacts/";
      return this.getData(url);
    };

    Service.getFavoriteContacts = function() {
      var url = "/api/v1/users/profile/contacts/favorite/";
      return this.getData(url);
    };

    Service.addFavoriteContact = function(contactId) {
      var url = "/api/v1/users/profile/contacts/favorite/";
      return this.postData(url, { contactable_id: contactId });
    };

    Service.removeFavoriteContact = function(contactId) {
      var url = String.format(
        "/api/v1/users/profile/contacts/favorite/{0}/",
        contactId
      );
      return this.deleteData(url);
    };

    Service.getLocalGroups = function() {
      var url = "/api/v1/localgroups/";
      return this.getData(url);
    };

    Service.createLocalGroup = function(localGroup) {
      var url = "/api/v1/localgroups/";
      return this.postData(url, localGroup);
    };

    Service.deleteLocalGroup = function(id) {
      var url = String.format("/api/v1/localgroups/{0}/", id);
      return this.deleteData(url);
    };

    Service.createDepartment = function(name) {
      var url = "/api/v1/departments/";
      return this.postData(url, { name: name });
    };

    Service.deleteDepartment = function(id) {
      var url = String.format("/api/v1/departments/{0}/", id);
      return this.deleteData(url);
    };

    Service.getDepartments = function(query) {
      query = query ? query : {};
      var params = $.param(query);
      var url = String.format(
        "/api/v1/departments/{0}",
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.getDepartmentsForGrid = function(parameters) {
      var url = String.format(
        "/api/v1/departments/{0}",
        this.toUrl(parameters)
      );

      return this.getData(url);
    };

    Service.getDepartmentById = function(id) {
      var url = String.format("/api/v1/departments/{0}/", id);
      return this.getData(url);
    };

    Service.updateDepartment = function(department) {
      var url = String.format("/api/v1/departments/{0}/", department.id);
      return this.putData(url, department);
    };

    Service.getDepartmentMembersForGrid = function(departmentId, parameters) {
      var url = String.format(
        "/api/v1/departments/{0}/members/{1}",
        departmentId,
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    Service.addDepartmentMember = function(departmentId, user) {
      var url = String.format("/api/v1/departments/{0}/members/", departmentId);
      return this.postData(url, { user_id: user.id });
    };

    Service.removeDepartmentMember = function(departmentId, userId) {
      var url = String.format(
        "/api/v1/departments/{0}/members/{1}/",
        departmentId,
        userId
      );
      return this.deleteData(url);
    };

    Service.getDepartmentContactsForGrid = function(departmentId, parameters) {
      var url = String.format(
        "/api/v1/departments/{0}/contacts/{1}",
        departmentId,
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    Service.addDepartmentContact = function(departmentId, contactId) {
      var url = String.format(
        "/api/v1/departments/{0}/contacts/",
        departmentId
      );
      return this.postData(url, {
        department_id: departmentId,
        contactable_id: contactId
      });
    };

    Service.updateDepartmentContact = function(departmentId, contact) {
      var url = String.format(
        "/api/v1/departments/{0}/contacts/{1}/",
        departmentId,
        contact.contactable.id
      );
      return this.putData(url, contact);
    };

    Service.removeDepartmentContact = function(departmentId, contactId) {
      var url = String.format(
        "/api/v1/departments/{0}/contacts/{1}/",
        departmentId,
        contactId
      );
      return this.deleteData(url, contactId);
    };

    Service.getDepartmentMessageTypesForGrid = function(
      departmentId,
      parameters
    ) {
      var url = String.format(
        "/api/v1/departments/{0}/messagetypes/{1}",
        departmentId,
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    Service.addDepartmentMessageType = function(departmentId, messageTypeId) {
      var url = String.format(
        "/api/v1/departments/{0}/messagetypes/",
        departmentId
      );
      return this.postData(url, { message_type_id: messageTypeId });
    };

    Service.removeDepartmentMessageType = function(
      departmentId,
      messageTypeId
    ) {
      var url = String.format(
        "/api/v1/departments/{0}/messagetypes/{1}/",
        departmentId,
        messageTypeId
      );
      return this.deleteData(url);
    };

    Service.getProfile = function() {
      var url = String.format("/api/v1/users/profile/");
      return this.getData(url);
    };

    Service.updateProfile = function(user) {
      var url = String.format("/api/v1/users/profile/");
      return this.putData(url, user);
    };

    Service.fluxEvents = {
      getProfile: {
        key: "userService.getProfile"
      }
    };
    Service.requestGetProfile = function() {
      if (Service.fluxEvents.getProfile.isLoading) {
        return;
      }

      Service.fluxEvents.getProfile.isLoading = true;
      Service.getProfile()
        .then(function(result) {
          $rootScope.$emit(Service.fluxEvents.getProfile.key, result);
        })
        [
          //this file is used by tokenUser ngapp which has to be ie8 compatible for now, once ie8 support it dropped this can be changed to .finally
          "finally"
        ](function() {
          Service.fluxEvents.getProfile.isLoading = false;
        });
    };

    Service.getNotifications = function(query) {
      query = query ? query : {};
      var params = $.param(query);

      var url = String.format(
        "/api/v1/users/profile/notifications/{0}",
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.getNotificationsForGrid = function(params) {
      var url = String.format(
        "/api/v1/users/profile/notifications/{0}",
        Service.toUrl(params)
      );
      return this.getData(url);
    };

    Service.getNotificationById = function(notificationId) {
      var url = String.format(
        "/api/v1/users/profile/notifications/{0}/",
        notificationId
      );
      return this.getData(url);
    };

    Service.markAllNotificationsRead = function(lastSeenId) {
      var url = String.format("/api/v1/users/profile/notifications/read/");
      return this.postData(url, { id: lastSeenId });
    };

    Service.markNotificationRead = function(notificationId) {
      var url = String.format(
        "/api/v1/users/profile/notifications/{0}/read/",
        notificationId
      );
      return this.postData(url);
    };

    Service.deactivateUser = function(userId) {
      var url = String.format("/users/{0}/deactivate/list/", userId);
      return this.postData(url, {});
    };

    Service.activateUser = function(userId) {
      var url = String.format("/users/{0}/reactivate/list/", userId);
      return this.postData(url, {});
    };

    Service.clearUserDevices = function(userId) {
      var url = String.format("/users/{0}/cleardevices/list/", userId);
      return this.postData(url, {});
    };

    Service.toggleLockUser = function(userId) {
      var url = String.format("/users/{0}/lock/list/", userId);
      return this.postData(url, {});
    };

    Service.clearPhoto = function(userId) {
      var url = String.format("/users/{0}/clear_photo/", userId);
      return this.postData(url, {});
    };

    Service.getAccount = function() {
      var url = String.format(
        "/api/v1/accounts/{0}/",
        configuration.getAccountId()
      );
      return this.getData(url, {});
    };

    Service.changePassword = function(userId, passwords) {
      var url = String.format("/api/v1/users/{0}/password/", userId);
      return this.putData(url, passwords);
    };

    Service.changeProfilePassword = function(passwords) {
      var url = String.format("/api/v1/users/profile/password/");
      return this.putData(url, passwords);
    };

    Service.getPermissionGroups = function(query) {
      query = query ? query : {};
      var params = $.param(query);
      var url = String.format(
        "/api/v1/permissiongroups/{0}",
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.getPermissionGroupsById = function(id) {
      var url = String.format("/api/v1/permissionggroups/{0}/", id);
      return this.getData(url);
    };

    Service.getMonitorableUsers = function(query) {
      query = query ? query : {};
      var params = $.param(query);

      var url = "/api/v1/monitorableusers/" + (params ? "?" + params : "");

      return this.getData(url).then(function(response) {
        _.forEach(response.results, function(item) {
          item.type = "User";
        });
        return response;
      });
    };

    Service.monitorUser = function(monitorUserId, data) {
      var url = String.format("/api/v1/users/{0}/monitor/", monitorUserId);

      return this.postData(url, data);
    };

    Service.deleteMonitor = function(userId, monitorId) {
      var url = String.format(
        "/api/v1/users/{0}/monitor/{1}/",
        userId,
        monitorId
      );
      return this.deleteData(url);
    };

    Service.getObservedUsers = function(userId) {
      var url = String.format("/api/v1/users/{0}/observing/", userId);
      return this.getData(url);
    };

    return Service;
  }]);
