/**
 * @ngdoc controller
 * @name attachmentPreviewModalCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.shared')
    .controller('attachmentPreviewModalCtrl', ["$scope", "modalOptions", function ($scope, modalOptions) {

        $scope.modalOptions = modalOptions;

    }]);
