/**
 * @ngdoc directive
 * @name fillHeight
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('fillHeight', ["$window", "$timeout", "$log", function ($window, $timeout, $log) {
        return {
            restrict: 'A',
            link: function (scope, elem, attr) {

                function resize() {
                    var height = $($window).height() - elem.offset().top - (attr.fillHeight || 0);
                    elem.height(height);
                }

                $($window).on('resize', resize);
                $timeout(resize);

                scope.$on('$destroy', function () {
                    $($window).off('resize', resize);
                });

                scope.$on('elastic:resize', function (event, element, oldHeight, newHeight) {
                    if (oldHeight === 'auto') {
                        return;
                    }

                    var panelBody = elem.find('.panel-body');

                    var bottom = +panelBody.css('bottom').replace('px', '');

                    var newBottom = bottom + (newHeight - oldHeight) + 'px';

                    panelBody.css('bottom', newBottom);
                });
            }
        };
    }]);
