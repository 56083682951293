/**
 * @ngdoc controller
 * @name smartpager.endUser.shared:iqGroupLookupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.shared")
  .controller("iqGroupLookupModalCtrl", ["$scope", "userService", "$log", "$rootScope", function(
    $scope,
    userService,
    $log,
    $rootScope
  ) {
    var $ctrl = this;
    $ctrl.contact = undefined;
    $ctrl.usesDirectoryComposition = $rootScope.hasFeature(
      "directoryComposition"
    );

    $scope.search = function(contact_id) {
      $scope.results = [];
      $ctrl.selected = [];

      $ctrl.isLoading = true;
      userService
        .getUserPagingGroups(contact_id)
        .then(function(response) {
          $scope.results = response.results;
        })
        .finally(function() {
          $ctrl.isLoading = false;
        });
    };

    $scope.$watch("$ctrl.contact", function(newVal) {
      if (newVal) {
        $scope.search(newVal.id);
      }
    });

    $scope.$watch("$ctrl.object", function(newVal) {
      if (newVal) {
        $scope.search(newVal.contact_id);
      }
    });

    $ctrl.addSelected = function(contacts) {
      $scope.$close(contacts);
    };
  }]);
