/**
 * @ngdoc controller
 * @name rootCtrl
 *
 * @description
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.main')
    .controller('rootCtrl', ["$scope", "$rootScope", "$state", "configuration", "$log", "Pubnub", function ($scope, $rootScope, $state, configuration, $log, Pubnub) {
        $rootScope.$state = $state;

        // Subscribe to account channel
        var pcmChannelUid = configuration.getPcmChannelUid();
        // Subscribe to user channel for notification about messages when not on messages
        var userChannelUid = configuration.getUserUid();

        //first setup a handler for messages and then subscribe to a channel
        $scope.$onRootScope(Pubnub.getMessageEventNameFor(pcmChannelUid), handlePubnubAccountChannelNotification);
        $scope.$onRootScope(Pubnub.getMessageEventNameFor(userChannelUid), handlePubnubUserChannelNotification);
        Pubnub.time(function (time) {
            $log.debug('Pubnub.ngSubscribe', pcmChannelUid);
            Pubnub.subscribe({channel: pcmChannelUid, timetoken: time, triggerEvents: ['callback']});

            $log.debug('Pubnub.ngSubscribe', userChannelUid);
            Pubnub.subscribe({channel: userChannelUid, timetoken: time, triggerEvents: ['callback']});
        });

        //unsubscribe when leaving
        $scope.$on('$destroy', function () {
            $log.debug('Pubnub.unsubscribe', pcmChannelUid);
            Pubnub.unsubscribe({channel: pcmChannelUid});

            $log.debug('Pubnub.unsubscribe', userChannelUid);
            Pubnub.unsubscribe({channel: userChannelUid});
        });

        function handlePubnubAccountChannelNotification(event, message) {
            $log.debug(String.format('PubNub [Account\'s PCM Ch] {0}', message.type), message);
            $rootScope.$emit(message.type, message);
        }

        function handlePubnubUserChannelNotification(event, message) {
            $log.debug(String.format('PubNub [User Ch] {0}', message.type), message);
            $rootScope.$emit(message.type, message);
        }
    }]);
