/**
 * @ngdoc controller
 * @name iqEncounterSelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .component('iqEncounterSelect', {
        templateUrl: '/smartpager/angular/endUser/shared/components/iqEncounterSelect/iqEncounterSelect.html',
        bindings: {
            selectedEncounter: '<',
            onUpdate: '&',
        },
        controller: ['$scope', 'modalHelper', 'encountersService', function($scope, modalHelper, encountersService) {
            var $ctrl = this;

            $ctrl.availableEncounters = [];
            $ctrl.selectedEncounter = undefined;

            // for regular type-ahead searches
            $ctrl.searchEncounters = function (query) {
                $ctrl.isLoading = true;
                encountersService.getEncounters({ search: query, sorting: {"name": "asc"}, count: 100 })
                    .then(function (response) {
                        $ctrl.availableEncounters = response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            };

            $scope.$watch('$ctrl.selectedEncounter', function(newEncounterId){
                $ctrl.onUpdate({value: newEncounterId});
            });
        }]
    });
