/**
 * @ngdoc directive
 * @name iqUserSelect
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular
  .module('smartpager.endUser.shared')
  .directive('iqDirectoryAccessSelect', ["$log", "$compile", "$templateRequest", function(
    $log,
    $compile,
    $templateRequest
  ) {
    return {
      restrict: 'E',
      require: 'ngModel',
      transclude: true,
      scope: {
        label: '@',
        ct: '@',
        obj: '@',
        hideLabel: '=',
        propertyToMatch: '@',
        onSelect: '&'
      },
      controller: 'iqDirectoryAccessSelectCtrl',
      templateUrl: function(tElement, tAttrs) {
        console.log('templateUrl');
        if (tAttrs.multiple && tAttrs.multiple === 'false') {
          return '/smartpager/angular/endUser/shared/components/iqDirectorySelect/iqDirectoryAccessSelect-single.html';
        } else {
          return '/smartpager/angular/endUser/shared/components/iqDirectorySelect/iqDirectoryAccessSelect-multiple.html';
        }
      },
      link: function(scope, iElement, iAttrs, ngModelCtrl) {
        //region handle ngModelCtrl
        console.log('link');

        ngModelCtrl.$formatters.push(function(modelValue) {
          return angular.copy(modelValue);
        });

        ngModelCtrl.$parsers.push(function(viewValue) {
          return angular.copy(viewValue);
        });

        scope.$watchCollection(
          'iqDirectoryAccessSelectCtrl.directories',
          function(newDirectories) {
            console.log(
              'iqDirectoryAccessSelectCtrl updates ngModel binding',
              newDirectories
            );
            ngModelCtrl.$setViewValue(angular.copy(newDirectories));
          }
        );

        ngModelCtrl.$render = function() {
          scope.iqDirectoryAccessSelectCtrl.directories = !ngModelCtrl.$viewValue ? [] : ngModelCtrl.$viewValue;
        };

        if (iAttrs.required) {
          ngModelCtrl.$validators.required = function(modelValue, viewValue) {
            if (iAttrs.multiple && iAttrs.multiple === 'false') {
              return !!modelValue;
            } else {
              return !!(modelValue && modelValue.length !== 0);
            }
          };
        }
        //endregion
      }
    };
  }]);
