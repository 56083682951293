angular.module('smartpager.endUser.messages', [
        'smartpager.endUser.shared'
    ])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        //root state of application

        $stateProvider
            .state('root.inbox', {
                url: '{inboxType:inbox|archive|unread}',
                data: {
                    title: 'Telmediq - Inbox',
                    requiredPermissions: [],
                    pageClass: 'messages'
                },
                views: {
                    '': {
                        templateUrl: '',
                        controller: 'inboxCtrl'
                    },
                    'threadList@root.inbox': {
                        templateUrl: '/smartpager/angular/endUser/messages/controllers/threadList.html',
                        controller: 'threadListCtrl'
                    }
                }
            })
            .state('root.inbox.noThreads', {
                url: '/thread',
                views: {
                    'threadView': {
                        templateUrl: '/smartpager/angular/endUser/messages/controllers/thread-empty.html',
                        controller: 'threadListCtrl'
                    }
                }
            })
            .state('root.inbox.thread', {
                url: '/thread/:threadId',
                views: {
                    'threadView': {
                        templateUrl: '/smartpager/angular/endUser/messages/controllers/thread.html',
                        controller: 'threadCtrl'
                    },
                    'subscribers': {
                        templateUrl: '/smartpager/angular/endUser/messages/controllers/subscribers.html',
                        controller: 'subscribersCtrl as ctrl'
                    }
                },
                resolve: {
                    threadId: ["$stateParams", function ($stateParams) {
                        return +$stateParams.threadId;
                    }]
                }
            });
    }]);
