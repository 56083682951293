/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:mtUsageReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .factory('mtUsageReportCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('mtUsageReportCache');
    }])
    .controller('mtUsageReportCtrl', ["$scope", "reportSlug", "reportService", "ngTableHelper", "NgTableParams", "mtUsageReportCache", function ($scope, reportSlug, reportService, ngTableHelper, NgTableParams, mtUsageReportCache) {
        var $ctrl = this;

        $ctrl.dateRangePickerOptions = reportService.getDateRangePickerOptions();

        $ctrl.tableParams = new NgTableParams(mtUsageReportCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                },
                filter: {
                    start: moment().startOf('month').toDate(),
                    end: moment().endOf('month').toDate()
                }
            }, {
            getData: function (params) {
                $ctrl.isLoading = true;
                mtUsageReportCache.put('parameters', params.parameters());
                return reportService.runReport(reportSlug, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search'),
            start: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'start'),
            end: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'end')
        };

        $ctrl.downloadCsv = function () {
            reportService.downloadReportAsCSV(reportSlug, mtUsageReportCache.get('parameters'), 'Message Type Usage Report.csv');
        };
    }]);
