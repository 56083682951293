/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:confirmSendModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('confirmSendModalCtrl', ["$scope", "modalOptions", function($scope, modalOptions){
        var $ctrl = this;
        console.log('as');
        $ctrl.$onInit = function(){
            console.log('yua');
            $ctrl.broadcast = modalOptions.broadcast;
        };

}]);
