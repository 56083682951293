/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:assignmentsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('assignmentsCtrl', ["$scope", "patientsService", "messageService", "$log", "baseService", "Notification", "pubNubMessageTypes", function ($scope, patientsService, messageService, $log, baseService, Notification, pubNubMessageTypes) {

        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.loadPatients();
            $ctrl.loadProviders();
        };

        $scope.$on('$destroy', function () {
            patientsService.cancel($ctrl.patients.lastRequest);
            patientsService.cancel($ctrl.providers.lastRequest);
        });

        $ctrl.patients = {
            isLoading: true,
            count: 0,
            data: [],
            filter: {
                search: '',
                unassigned: false
            }
        };

        $scope.$watch('$ctrl.patients.filter', function (newVal, oldVal) {
            if (!angular.equals(newVal, oldVal)) {
                $ctrl.patients.data = [];
                $ctrl.patients.count = 0;
                $ctrl.loadPatients();
            }
        }, true);

        $ctrl.providers = {
            isLoading: true,
            count: 0,
            data: [],
            filter: {
                search: '',
                department_id: undefined
            }
        };

        $scope.$watch('$ctrl.providers.filter', function (newVal, oldVal) {
            if (!angular.equals(newVal, oldVal)) {
                $ctrl.providers.data = [];
                $ctrl.providers.count = 0;
                $ctrl.loadProviders();
            }
        }, true);

        $ctrl.loadPatientsPage = function () {
            $log.debug('checking if should load more patients');
            if ($ctrl.patients.count > $ctrl.patients.data.length) {
                $log.debug('load page patients');

                $ctrl.loadPatients();
            }
        };

        $ctrl.loadPatients = function () {
            var params = _.merge(baseService.prepareParams($ctrl.patients.data), {filter: $ctrl.patients.filter});

            $ctrl.patients.isLoading = true;

            patientsService.cancel($ctrl.patients.lastRequest);

            $ctrl.patients.lastRequest = patientsService.getPatientsForGrid(params);

            return $ctrl.patients.lastRequest
                .then(function (response) {
                    $ctrl.patients.count = response.count;
                    $ctrl.patients.data = _.uniqBy($ctrl.patients.data.concat(response.results), 'id');
                })
                .finally(function () {
                    $ctrl.patients.isLoading = false;
                });
        };

        $ctrl.loadProvidersPage = function () {
            $log.debug('checking if should load more providers');
            if ($ctrl.providers.count > $ctrl.providers.data.length) {
                $log.debug('load page providers');

                $ctrl.loadProviders();
            }
        };

        $ctrl.loadProviders = function () {
            var params = _.merge(baseService.prepareParams($ctrl.providers.data), {filter: $ctrl.providers.filter});

            $ctrl.providers.isLoading = true;

            patientsService.cancel($ctrl.providers.lastRequest);

            $ctrl.providers.lastRequest = patientsService.getProvidersForGrid(params);

            return $ctrl.providers.lastRequest
                .then(function (response) {
                    $ctrl.providers.count = response.count;
                    $ctrl.providers.data = _.uniqBy($ctrl.providers.data.concat(response.results), 'id');
                })
                .finally(function () {
                    $ctrl.providers.isLoading = false;
                });
        };


        $ctrl.assign = function (patient, provider) {
            var careTeamMember = {provider: provider};
            var user = provider.user;

            var alreadyExists = !!_.find(patient.care_team, {provider: {id: provider.id}});
            if (alreadyExists) {
                Notification.info(String.format('{0} is already assigned to patient {1}', user.display_name, patient.display_name));
                return false;
            }

            user.isBeingAssigned = true;
            patientsService.assign(patient.id, provider.id)
                .then(function (result) {
                    Notification.success(String.format('Successfully assigned {0} to patient {1}', user.display_name, patient.display_name));
                    careTeamMember.id = result[0].member_id;

                    var ctrlProvider = _.find($ctrl.providers.data, {id: provider.id});
                    if(ctrlProvider) {
                        ctrlProvider.patient_count += 1;
                    }
                })
                .catch(function (response) {
                    _.remove(patient.care_team, {id: provider.id});
                    Notification.error(String.format('Error assigning provider'));
                    $log.error(response.data);
                })
                .finally(function () {
                    user.isBeingAssigned = false;
                });

            return careTeamMember;
        };

        $ctrl.unassign = function (patient, careTeamMember) {
            if (careTeamMember.provider.user.isBeingAssigned) {
                return;
            }

            careTeamMember.provider.user.isBeingAssigned = true;
            patientsService.unassign(patient.id, careTeamMember.id)
                .then(function () {
                    _.remove(patient.care_team, {'id': careTeamMember.id});
                    Notification.success(String.format('Successfully unassigned {0} from patient {1}', careTeamMember.provider.user.display_name, patient.display_name));

                    var ctrlProvider = _.find($ctrl.providers.data, {id: careTeamMember.provider.id});
                    if(ctrlProvider) {
                        ctrlProvider.patient_count -= 1;
                    }
                })
                .catch(function (response) {
                    Notification.error(String.format('Error unassigning provider'));
                    $log.error(response.data);
                })
                .finally(function () {
                    careTeamMember.provider.user.isBeingAssigned = false;
                });
        };

        $ctrl.showComposeModal = function (contact_id, patient) {
            messageService.showComposeModal([contact_id], patient);
        };

        $ctrl.showUploadPatientsModal = function () {
            patientsService.showUploadPatientsModal();
        };

        $scope.$onRootScope(pubNubMessageTypes.ac.pcm.assign, function (event, message) {
            var patient = _.find($scope.patients.tableParams.data, {id: message.content.patient_id});
            if (patient) {
                var careTeamMember = _.find(patient.care_team, {provider: {id: message.content.provider_id}});
                if (!careTeamMember) {
                    $scope.patients.tableParams.reload();
                }
            }
        });

        $scope.$onRootScope(pubNubMessageTypes.ac.pcm.unassign, function (event, message) {
            var patient = _.find($scope.patients.tableParams.data, {id: message.content.patient_id});
            if (patient) {
                var member = _.remove(patient.care_team, {provider: {id: message.content.provider_id}});
                if (member && !member[0].provider.user.isBeingAssigned) {
                    Notification.info(String.format('{0} was unassigned from {1}', member[0].provider.user.display_name, patient.display_name));
                }
            }
        });
    }]);
