/**
 * @ngdoc controller
 * @name addSubscriberModalCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.messages")
  .controller("addSubscriberModalCtrl", ["$scope", "$rootScope", "$state", "$stateParams", "messageService", "$log", "modalOptions", "alertsHelper", function(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    messageService,
    $log,
    modalOptions,
    alertsHelper
  ) {
    var self = this;
    alertsHelper.create($scope, "alerts");

    $scope.usesDirectoryComposition = $rootScope.hasFeature(
      "directoryComposition"
    );
    $scope.addSubscriberModalCtrl = {
      newSubscriberIds: [],
      subscribedUsers: modalOptions.subscribedUsers,
      subscribedGroups: modalOptions.subscribedGroups
    };
    $scope.addSubscriberModalCtrl.subscribedUserIds = _.map(
      $scope.addSubscriberModalCtrl.subscribedUsers,
      "id"
    );
    $scope.addSubscriberModalCtrl.subscribedGroupIds = _.map(
      $scope.addSubscriberModalCtrl.subscribedGroups,
      "id"
    );

    self.activate = function() {
      messageService
        .getThreadPagingGroups($stateParams.threadId)
        .then(function(response) {
          $scope.addSubscriberModalCtrl.subscribedGroups = response.results;
          $scope.addSubscriberModalCtrl.subscribedGroupIds = _.map(
            $scope.addSubscriberModalCtrl.subscribedGroups,
            "id"
          );
        });
    };
    self.activate();

    $scope.addSubscribers = function() {
      $scope.saving = true;
      messageService
        .subscribeToThread($state.params.threadId, {
          thread_id: $state.params.threadId,
          subscribers: $scope.addSubscriberModalCtrl.newSubscriberIds
        })
        .then(function(threadStub) {
          $scope.$close(threadStub);
        })
        .catch(function(data) {
          $scope.alerts.error("Unable to add subscriber");
        })
        .finally(function() {
          $scope.saving = false;
        });
    };
  }]);
