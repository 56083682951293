/**
 * @ngdoc directive
 * @name iqPatientSelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .controller('iqPatientSelectCtrl', ["$scope", "modalHelper", "patientsService", function ($scope, modalHelper, patientsService) {
        var $ctrl = this;

        $ctrl.availablePatients = [];
        $ctrl.patients = [];

        // for regular type-ahead searches
        $ctrl.searchPatients = function (query) {

            var param = {
                search: query
            };
            $ctrl.isLoading = true;
            patientsService.getPatients(param)
                .then(function (response) {
                    $ctrl.availablePatients = response.results;
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };
    }]);
