/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addNoteTemplateModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addNoteTemplateModalCtrl', ["$scope", "patientsService", "Notification", "alertsHelper", "focus", "eventsBus", function ($scope, patientsService, Notification, alertsHelper, focus, eventsBus) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');
        $ctrl.template = {};

        $ctrl.createNoteTemplate = function () {
            if(!$ctrl.form.$valid){
                $ctrl.form.$setSubmitted();
                return;
            }
            $ctrl.isSaving = true;
            patientsService.createNoteTemplate($ctrl.template)
                .then(function (response) {
                    if ($ctrl.addAnother) {
                        $ctrl.form.$setUntouched();
                        $ctrl.alerts.success('Successfully created ' + response.name);
                        $ctrl.template = {};
                        focus('firstInput');
                    } else {
                        Notification.success('Successfully created note template');
                        $scope.$close(response);
                    }
                })
                .catch(function (response) {
                    $ctrl.serverErrors = response.data;
                    eventsBus.publish('serverError', $ctrl.serverErrors);
                    $ctrl.alerts.error('Could not create note template');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
