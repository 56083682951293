/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:messageRoutingConditionSetsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .controller('messageRoutingConditionSetsCtrl', ["$scope", "messageRoutingService", "policyId", "routingModalsFactory", "Notification", function ($scope, messageRoutingService, policyId, routingModalsFactory, Notification) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;
            $ctrl.reload();
        };

        $ctrl.reload = function () {
            messageRoutingService.requestGetRoutingPolicy(policyId);
        };

        $scope.$onRootScope(messageRoutingService.fluxEvents.getRoutingPolicy.key, function (event, response) {
            $ctrl.policy = response;
            $ctrl.isLoading = false;
        });

        function prepare_payload(policy, conditionSet) {
            var payload = angular.copy(policy);
            payload.conditionset_ids = _.map(payload.conditionsets_list, 'id');

            if (conditionSet) {
                payload.conditionset_ids.push(conditionSet.id);
            }

            delete payload.conditionsets_list;
            return payload;
        }

        function update_policy(payload) {
            $ctrl.isSaving = true;
            return messageRoutingService.updateRoutingPolicy(payload)
                .then(function () {
                    $ctrl.reload();
                })
                .catch(function () {

                })
                .finally(function () {
                    $ctrl.isSaving = false;
                    $ctrl.selectedConditionSet = null;
                });
        }

        $ctrl.add = function (conditionSet) {
            if (_.find($ctrl.policy.conditionsets_list, {id: conditionSet.id})) {
                Notification.info(String.format('Condition set {0} is already added.', conditionSet.name));
                $ctrl.selectedConditionSet = null;
            } else {
                var payload = prepare_payload($ctrl.policy, conditionSet);
                return update_policy(payload);
            }
        };

        $ctrl.remove = function (id) {
            var policy = angular.copy($ctrl.policy);
            _.remove(policy.conditionsets_list, {id: id});
            var payload = prepare_payload(policy);

            return update_policy(payload);
        };

        $ctrl.openAddConditionSetModal = function () {
            return routingModalsFactory.showAddEditConditionSetModal()
                .then(function (conditionSet) {
                    $ctrl.add(conditionSet);
                    $scope.$emit('reloadMessageRoutingConditionSets');
                });
        };
    }]);
