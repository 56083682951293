/**
 * @ngdoc controller
 * @name smartpager.endUser.users:userListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.users")
  .factory("userListCtrlCache", ["$cacheFactory", function($cacheFactory) {
    return $cacheFactory("userListCtrlCache");
  }])
  .controller("userListCtrl", ["$rootScope", "$scope", "$q", "userListCtrlCache", "NgTableParams", "ngTableHelper", "userService", "pagingGroupService", "pagerNumberService", "configuration", "Notification", "modalHelper", "alertsHelper", "groupsService", function(
    $rootScope,
    $scope,
    $q,
    userListCtrlCache,
    NgTableParams,
    ngTableHelper,
    userService,
    pagingGroupService,
    pagerNumberService,
    configuration,
    Notification,
    modalHelper,
    alertsHelper,
    groupsService
  ) {
    var $ctrl = this;

    var bulkEditBaseUrl = "/admin/bulkedit",
      bulkEditCSVUrl = "/admin/bulkedit/upload/csv/";

    $ctrl.currentUserId = configuration.getUserId();
    $ctrl.departments = [];
    $ctrl.selectedUserUids = [];
    $ctrl.visibleUserUids = [];
    alertsHelper.create($ctrl, "alerts");

    $ctrl.$onInit = function() {
      $ctrl.isBulkEditing = false;
      $ctrl.isSortDesc = false;
      $ctrl.currentSort = "last_name";

      groupsService.getGroups().then(function(response) {
        $ctrl.availableGroups = response.results;
      });
    };

    $ctrl.tableParams = new NgTableParams(
      userListCtrlCache.get("parameters") || {
        filter: {
          is_active: "True"
        },
        sorting: {
          last_name: "asc"
        }
      },
      {
        getData: function(params) {
          userListCtrlCache.put("parameters", params.parameters());
          $scope.isLoading = true;
          userService.cancel($ctrl.lastProvidersRequest);

          $ctrl.lastProvidersRequest = userService.getUsersForGrid(
            params.parameters()
          );

          return $ctrl.lastProvidersRequest
            .then(function(response) {
              params.total(response.count);
              pagerNumbersForUsers(response.results);
              $ctrl.visibleUserUids = _.map(response.results, "uid");
              $ctrl.count = response.count;
              return response.results;
            })
            .finally(function() {
              $scope.isLoading = false;
            });
        }
      }
    );

    function pagerNumbersForUsers(users) {
      var user_ids = [];
      var user_dict = {};
      _.forEach(users, function(user) {
        user.pager_numbers = [];
        user_ids.push(user.id);
        user_dict[user.id] = user;
      });
      pagerNumberService
        .getPagerNumbers({ target_ids: user_ids.join(",") })
        .then(function(response) {
          _.forEach(response.results, function(pagerNumber) {
            if (user_dict[pagerNumber.target_id]) {
              user_dict[pagerNumber.target_id].pager_numbers.push(
                pagerNumber.number
              );
            }
          });
        });
    }

    $ctrl.searchParams = {
      search: ngTableHelper.generateGetterSetter($ctrl.tableParams, "search"),
      departments: ngTableHelper.generateGetterSetter(
        $ctrl.tableParams,
        "departments"
      ),
      groups: ngTableHelper.generateGetterSetter($ctrl.tableParams, "groups"),
      device_type: ngTableHelper.generateGetterSetter(
        $ctrl.tableParams,
        "device_type"
      ),
      is_locked: ngTableHelper.generateGetterSetter(
        $ctrl.tableParams,
        "is_locked"
      ),
      is_active: ngTableHelper.generateGetterSetter(
        $ctrl.tableParams,
        "is_active"
      )
    };

    $ctrl.clearSearch = function() {
      for (var key in $ctrl.searchParams) {
        if ($ctrl.searchParams.hasOwnProperty(key)) {
          // Don't start suddenly showing all users (SC-1960)
          if (key === "is_active") {
            $ctrl.searchParams[key]("True");
          } else {
            $ctrl.searchParams[key](null);
          }
        }
      }
    };

    $ctrl.deactivateUser = function(userId) {
      userService.deactivateUser(userId).then(function() {
        Notification.success({ message: "Successfully deactivated user." });
        $ctrl.tableParams.reload();
      });
    };

    $ctrl.activateUser = function(userId) {
      userService.activateUser(userId).then(function() {
        Notification.success({ message: "Successfully activated user." });
        $ctrl.tableParams.reload();
      });
    };

    $ctrl.lockUser = function(userId) {
      userService.toggleLockUser(userId).then(function() {
        Notification.success({ message: "Successfully locked user." });
        $ctrl.tableParams.reload();
      });
    };

    $ctrl.unlockUser = function(userId) {
      userService.toggleLockUser(userId).then(function() {
        Notification.success({ message: "Successfully unlocked user." });
        $ctrl.tableParams.reload();
      });
    };

    $ctrl.clearDevices = function(userId) {
      userService.clearUserDevices(userId).then(function() {
        Notification.success({
          message: "Successfully cleared user's devices"
        });
        $ctrl.tableParams.reload();
      });
    };

    $ctrl.openChangePasswordModal = function(userId) {
      modalHelper.openModal({
        templateUrl:
          "/smartpager/angular/endUser/users/controllers/modals/changePasswordModal.html",
        controller: "changePasswordModalCtrl as $ctrl",
        userId: userId
      });
    };

    $ctrl.sort = function(sortParam) {
      $ctrl.isSortDesc = !$ctrl.isSortDesc;
      $ctrl.tableParams.sorting(sortParam, $ctrl.isSortDesc ? "desc" : "asc");
      $ctrl.currentSort = sortParam;
    };

    $ctrl.bulkEdit = function () {
        if (!$ctrl.selectedUserUids.length) {
            $ctrl.alerts.error("Users must be selected before editing.");
            return;
        }

        userService.createBulkEditSession({'user_uids': $ctrl.selectedUserUids}).then(function (respData) {
            window.location.href = String.format('{0}/{1}', bulkEditBaseUrl, respData.session_id);
        });

    };

    $ctrl.bulkEditAll = function () {
        const department = $ctrl.searchParams.departments();
        const group = $ctrl.searchParams.groups();
        userService.createBulkEditSession({
            'all_users': true, 'department_id': department, 'group_id': group
        }).then(function (respData) {
            window.location.href = String.format('{0}/{1}', bulkEditBaseUrl, respData.session_id);
        });
    };

    $ctrl.preventDefault = function (event) {
        event.preventDefault();
    };

    $ctrl.bulkEditCSV = function() {
      window.location.href = bulkEditCSVUrl;
    };

    $ctrl.allSelected = function() {
      for (var i = 0; i < $ctrl.visibleUserUids.length; i++) {
        if ($ctrl.selectedUserUids.indexOf($ctrl.visibleUserUids[i]) === -1) {
          return false;
        }
      }
      return true;
    };

    $ctrl.toggleSelectAll = function() {
      var allSelected = $ctrl.allSelected();
      $ctrl.selectedUserUids = _.union(
        $ctrl.selectedUserUids,
        $ctrl.visibleUserUids
      );

      if (allSelected) {
        $ctrl.selectedUserUids = _.xor(
          $ctrl.selectedUserUids,
          $ctrl.visibleUserUids
        );
      }
    };

    $ctrl.isSelected = function(uid) {
      return $ctrl.selectedUserUids.indexOf(uid) > -1;
    };

    $ctrl.selectUser = function(uid) {
      if ($ctrl.isSelected(uid)) {
        _.pull($ctrl.selectedUserUids, uid);
      } else {
        $ctrl.selectedUserUids = _.union($ctrl.selectedUserUids, [uid]);
      }
    };
  }]);
