/**
 * @ngdoc controller
 * @name smartpager.endUser.users:changePasswordModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.users')
    .controller('changePasswordModalCtrl', ["$scope", "modalOptions", "userService", "Notification", "alertsHelper", "eventsBus", function ($scope, modalOptions, userService, Notification, alertsHelper, eventsBus) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');
        $ctrl.userId = modalOptions.userId;
        $ctrl.ownPassword = modalOptions.ownPassword;
        $ctrl.passwords = {};

        $ctrl.save = function () {
            if ($ctrl.form.$invalid) {
                $ctrl.form.$setSubmitted();
                return;
            }
            var savePromise;
            if ($ctrl.ownPassword) {
                savePromise = userService.changeProfilePassword($ctrl.passwords);
            } else {
                savePromise = userService.changePassword($ctrl.userId, $ctrl.passwords);
            }

            $ctrl.isSaving = true;
            savePromise
                .then(function (response) {
                    Notification.success('Successfully changed password');
                    $scope.$close();
                })
                .catch(function (response) {
                    $ctrl.serverErrors = response.data;
                    eventsBus.publish('serverError', $ctrl.serverErrors);
                    $ctrl.alerts.error('Could not set password for user');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
