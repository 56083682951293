/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addContactToDepartmentModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('addContactListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('addContactListCache');
    }])
    .controller('addContactToDepartmentModalCtrl', ["$scope", "modalOptions", "addContactListCache", "userService", "NgTableParams", "ngTableHelper", "Notification", function ($scope, modalOptions, addContactListCache, userService, NgTableParams, ngTableHelper, Notification) {
        var $ctrl = this;
        $ctrl.department = modalOptions.department;
        $ctrl.department.contact_ids = [];

        $ctrl.addContactTableParams = new NgTableParams(addContactListCache.get('parameters') || {
                count: 10
            }, {
            getData: function (params) {
                addContactListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;
                return userService.getAccountContactsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.addContactSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.addContactTableParams, 'search'),
            type: ngTableHelper.generateGetterSetter($ctrl.addContactTableParams, 'type')
        };

        $ctrl.addSelected = function (contact) {
            userService.addDepartmentContact($ctrl.department.id, contact.id)
                .then(function (response) {
                    $ctrl.department.contact_ids.push(response.contactable.id);
                    Notification.success('Successfully added ' + contact.display_name + ' to ' + $ctrl.department.name);
                }).catch(function (response) {
                Notification.error('Could not add ' + contact.display_name + ' to ' + $ctrl.department.name);
            });
        };

        $ctrl.removeSelected = function (contact) {
            userService.removeDepartmentContact($ctrl.department.id, contact.id)
                .then(function (response) {
                    $ctrl.department.contact_ids = _.without($ctrl.department.contact_ids, contact.id);
                    Notification.success('Succefully removed ' + contact.display_name + ' from ' + $ctrl.department.name);
                });
        };

        $ctrl.inContacts = function (contactId) {
            return $ctrl.department.contact_ids.indexOf(contactId) !== -1;
        };


        $ctrl.types = {
            department: 'Department',
            user: 'User',
            group: 'Group'
        };
    }]);
