/**
 * @ngdoc directive
 * @name iqThreadSearch
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('iqThreadSearch', function () {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: '/smartpager/angular/endUser/messages/components/iqThreadSearch/iqThreadSearch.html',
            controller: 'iqThreadSearchCtrl'
        };
    });
