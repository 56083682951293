angular.module('smartpager.endUser.pagingGroups')
    .controller('editEventCtrl', ["$scope", "$stateParams", "eventService", "rRuleService", "$log", "escalationsService", "pagingGroupService", "pagingGroupPermissionsService", "modalHelper", "$q", "$state", "Notification", "alertsHelper", function ($scope, $stateParams, eventService, rRuleService, $log, escalationsService, pagingGroupService, pagingGroupPermissionsService, modalHelper, $q, $state, Notification, alertsHelper) {

        var self = this;
        alertsHelper.create(self, 'alerts');

        self.eventOccurrence = {};

        this.groupId = $stateParams.groupId;
        this.eventId = $stateParams.eventId;
        this.hasChangePermission = false;

        this.activate = function () {
            if ($stateParams.eventId) {
                //existing event,
                self.isLoading = true;
                eventService.getEventOccurrence($stateParams.groupId, $stateParams.eventId, $stateParams.originalStart)
                    .then(function (result) {
                        self.eventOccurrence = result;
                        self.eventOccurrence.allDay = eventService.isAllDay(self.eventOccurrence);

                        if (self.eventOccurrence.rule_id) {
                            self.initiallyRepeating = true;
                            self.eventOccurrence.repeat = true;

                            rRuleService.getRuleById(self.eventOccurrence.rule_id)
                                .then(function (rule) {
                                    self.rule = rule;
                                    self.rrule_nlp_description = RRule.fromString(rule.description).toText();
                                });

                        } else {
                            self.initiallyRepeating = false;
                        }
                    })
                    .catch(function () {
                        $state.go('root.pagingGroups.scheduleGroup.calendar', {groupId: self.group.id});
                    })
                    .finally(function () {
                        self.isLoading = false;
                    });
            } else {
                //new event
                self.initiallyRepeating = false;

                self.eventOccurrence = {
                    target_id: $stateParams.user_id,
                    escalation_policy_id: $stateParams.escalation_policy_id,
                    start: $stateParams.start || moment($stateParams.originalStart),
                    end: $stateParams.end || moment($stateParams.originalStart).add(30, 'minutes')
                };
                self.eventOccurrence.allDay = eventService.isAllDay(self.eventOccurrence);
            }

            pagingGroupService.getScheduleGroup($stateParams.groupId)
                .then(function (result) {
                    self.group = result;
                    self.hasChangePermission = pagingGroupPermissionsService.hasOnCallEventChangePermission(result);
                })
                .catch(function () {
                    $state.go('root.pagingGroups.list');
                });

            escalationsService.getEscalationPolicies()
                .then(function (response) {
                    self.escalationPolicies = response.results;
                });
        };
        this.activate();

        this.showRepeat = function () {
            if (self.eventOccurrence.repeat) {
                modalHelper.openModal({
                    templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/modals/editRecurringRuleModal.html',
                    controller: 'editRecurringRuleModalCtrl as ctrl',
                    startDate: self.eventOccurrence.start instanceof Date ? self.eventOccurrence.start : self.eventOccurrence.start.toDate(),
                    rule: self.rule
                }).result
                    .then(function (result) {
                        $log.debug(result);
                        self.rule = result;
                        self.rrule_nlp_description = RRule.fromString(result.description).toText();

                        self.eventOccurrence.rule_id = result.id;
                    })
                    .catch(function () {
                        self.eventOccurrence.repeat = false;
                    });
            }
        };


        this.saveEvent = function () {
            $log.debug(self.eventOccurrence);

            if (self.eventOccurrence.allDay) {
                var allDayOptions = {'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0};

                self.eventOccurrence.start = moment(self.eventOccurrence.start).set(allDayOptions);
                self.eventOccurrence.end = moment(self.eventOccurrence.start).add(1, 'days');
            } else if (this.startAfterEnd()) {
                Notification.error('Start date has to be before end');
                return;
            }

            eventService.getUpdateFor(self.eventOccurrence, self.initiallyRepeating)
                .then(function (update_for) {
                    self.eventOccurrence.update_for = update_for;

                    return (self.eventOccurrence.event_id ? eventService.updateEvent : eventService.createEvent)(self.group.id, self.eventOccurrence);
                })
                .then(function (result) {
                    $state.go('root.pagingGroups.scheduleGroup.calendar', {groupId: self.group.id});
                })
                .catch(function () {
                    self.alerts.error('Could not save event');
                });
        };

        this.startAfterEnd = function () {
            return moment(self.eventOccurrence.start).isAfter(moment(self.eventOccurrence.end));
        };
    }]);
