/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addNoteFieldModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addNoteFieldModalCtrl', ["$scope", "patientsService", "Notification", "alertsHelper", "focus", function ($scope, patientsService, Notification, alertsHelper, focus) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.field = {
            required: false,
            show_label: false
        };

        $ctrl.saveNoteField = function () {
            if ($ctrl.form.$invalid) {
                $ctrl.form.$setSubmitted();
                return;
            }
            $ctrl.isLoading = true;
            patientsService.createNoteField($ctrl.field)
                .then(function (response) {
                    if (!$ctrl.addAnother) {
                        Notification.success('Successfully created note field');
                        $scope.$close(response);
                    } else {
                        $ctrl.alerts.success('Successfully added note field ' + response.name);
                        $ctrl.field = {};
                        $ctrl.form.$setPristine(true);
                        focus('firstInput');
                    }
                })
                .catch(function (response) {
                    if (response && response.status === 400 && response.data){
                        _.forIn(response.data, function(v, k){
                            $ctrl.alerts.error(String.format('{0}', v));
                        });
                    }else{
                        $ctrl.alerts.error('Could not create note field');
                    }
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };
    }]);
