/**
 * @ngdoc directive
 * @name msgStatusIcon
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module("smartpager.endUser.messages").component("requireConfirm", {
  bindings: {
    message: "<",
    subscribers: "<"
  },
  templateUrl:
    "/smartpager/angular/endUser/messages/components/requireConfirm/requireConfirm.html",
  controller: ["$scope", "messageService", "$stateParams", "inboxEvents", "$log", "configuration", function(
    $scope,
    messageService,
    $stateParams,
    inboxEvents,
    $log,
    configuration
  ) {
    var $ctrl = this;
    $ctrl.myId = configuration.getUserId();

    //map subscribersIds to actual subscribers
    _.forEach($ctrl.message.history, function(item) {
      item.subscriber = _.find($ctrl.subscribers, { id: item.subscriber_id });
      if (!item.subscriber) {
        item.subscriber = { display_name: "(Name not found)" };
      }

      if (item.subscriber.user_id === $ctrl.myId) {
        $ctrl.myHistory = item;
      }
    });

    $ctrl.accept = function() {
      $scope.submitting = true;
      messageService.accept($stateParams.threadId, $ctrl.message.id);
    };

    $ctrl.reject = function() {
      $scope.submitting = true;
      messageService.reject($stateParams.threadId, $ctrl.message.id);
    };

    $scope.$onRootScope(inboxEvents.messageConfirmation, function($event, msg) {
      if (msg.message_id === $ctrl.message.id) {
        var subscriberHistory = _.find($ctrl.message.history, {
          subscriber_id: msg.subscriber_id
        });

        if (subscriberHistory) {
          subscriberHistory.accepted = msg.accepted;
          subscriberHistory.rejected = msg.rejected;
        } else {
          $log.warn("didn't find subscriber");
        }
      }
    });
  }]
});
