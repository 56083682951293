/**
 * @ngdoc controller
 * @name dynamicFieldFileCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('dynamicFieldFileCtrl', ["$scope", "fileUploadHelper", "$log", function ($scope, fileUploadHelper, $log) {
        $scope.uploader = fileUploadHelper.getAttachmentsFileUploader();
        $scope.uploader.autoUpload = true;
        $scope.uploader.queueLimit = 1;

        $scope.uploader.onSuccessItem = function (item, response, status, headers) {
            item.uploadId = response.id;
            $scope.$emit('fileUploaded', {id: item.uploadId});
        };

        $scope.$on('$destroy', function () {
            $scope.uploader.onSuccessItem = fileUploadHelper.noop;
            $scope.uploader.cancelAll();
        });

        $scope.remove = function (item) {
            $scope.$emit('fileRemoved', {id: item.uploadId});
            $scope.uploader.removeFromQueue(item);
        };
    }]);
