/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:uploadPatientsModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('uploadPatientsModalCtrl', ["$scope", "FileUploader", "configuration", "patientsService", function ($scope, FileUploader, configuration, patientsService) {
        var $ctrl = this;
        $ctrl.templateUrl = String.format('{0}/assets/patient-upload-template.csv', configuration.getStaticDir());
        $ctrl.errors = [];

        $ctrl.uploader = new FileUploader({
            url: '/api/v1/pcm/patients/upload/',
            headers: {'X-CSRFToken': configuration.getToken(), 'Accept': '*/*'},
            autoUpload: false,
            onSuccessItem: function (item, response, status, headers) {
                item.uploadId = response.id;
                $ctrl.errors = response.errors;
                $ctrl.itemsUploaded = response.itemsUploaded;
                $ctrl.successfullyAdded = response.successfullyAdded;

                item.remove();
            },
            onErrorItem: function (item, response, status, headers) {
                $ctrl.importError = response.msg;
            }
        });

        $ctrl.uploadCSV = function () {
            $ctrl.uploader.uploadAll();
        };

        $ctrl.goBack = function(){
            $scope.$dismiss();
            patientsService.showAddPatientsChoiceModal();
        };
    }]);
