/**
 * @ngdoc directive
 * @name iqUserSelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .controller('iqTagSelectCtrl', ["$scope", "modalHelper", "tagsService", "$log", "$timeout", function ($scope, modalHelper, tagsService, $log, $timeout) {
        $scope.iqTagSelectCtrl = {
            availableTags: [],
            tags: []
        };

        // for regular type-ahead searches
        $scope.searchTags = function (query) {

            var param = {
                search: query
            };
            $scope.isLoading = true;
            tagsService.getTags(param, $scope.ct)
                .then(function (response) {
                    $scope.iqTagSelectCtrl.availableTags = response.results;
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };
    }]);
