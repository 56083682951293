angular
  .module('smartpager.endUser.shared')
  .constant('inboxType', {
    inbox: 'inbox',
    archive: 'archive',
    unread: 'unread'
  })
  .constant('threadStatus', {
    active: 0,
    archived: 1
  })
  .factory('messageService', ["baseService", "$log", "configuration", "threadStatus", "inboxType", "modalHelper", function(
    baseService,
    $log,
    configuration,
    threadStatus,
    inboxType,
    modalHelper
  ) {
    var Service = Object.create(baseService);

    Service.getClearFilter = function(currentInboxType) {
      return {
        status:
          !(currentInboxType === inboxType.inbox ||
          currentInboxType === inboxType.unread) ? threadStatus.archived : threadStatus.active,
        subscriber_status:
          currentInboxType === inboxType.unread ? 'pending' : undefined
      };
    };

    Service.getMessageTypes = function() {
      var url = '/api/v1/messagetypes/?page_size=250';
      return this.getData(url);
    };

    Service.getMessageTypesForGrid = function(parameters) {
      var url = String.format(
        '/api/v1/messagetypes/{0}',
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    Service.getAccountMessageTypesForGrid = function(parameters) {
      var accountId = configuration.getAccountId();
      var url = String.format(
        '/api/v1/accounts/{0}/messagetypes/{1}',
        accountId,
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    function processThread(thread) {
      thread.groupsDisplay = _.map(thread.groups, 'name')
        .sort(function(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
        .join('\n');

      thread.departmentsDisplay = _.map(thread.departments, 'name')
        .sort(function(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
        .join('\n');

      thread.me = thread.my_subscriber;

      return thread;
    }

    Service.queryRouting = function(thread) {
      var url = '/api/v2/threads/?preview=true';
      return this.postData(url, thread);
    };

    Service.createNewThreads = function(thread, isPreview) {
      var url = '/api/v2/threads/' + (isPreview ? '?preview=true' : '');
      return this.postData(url, thread).then(processThread);
    };

    Service.getThreadList = function(ordering) {
      ordering = ordering ? ordering : {};
      var params = $.param(ordering);

      var url = '/api/v2/threads/' + (params ? '?' + params : '');

      var promise = this.getData(url);

      promise.then(function(response) {
        _.forEach(response.results, processThread);
        return response;
      });

      return promise;
    };

    Service.getUserSubscribers = function(params) {
      if (params.thread_ids && params.thread_ids instanceof Array) {
        params.thread_ids = _.join(params.thread_ids);
      }

      var url = String.format(
        '/api/v2/subscribers/{0}',
        params ? this.toUrl(params) : ''
      );
      return this.getData(url);
    };

    Service.getMessageHistory = function(threadId, messageId) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/history/',
        threadId,
        messageId
      );
      return this.getData(url);
    };

    Service.getMessageAlerts = function(threadId, messageId, query) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/alerts/?search={2}',
        threadId,
        messageId,
        query ? query : ''
      );
      return this.getData(url);
    };

    Service.shouldBeGrouped = function(item, last) {
      return (
        last.sender.id === item.sender.id &&
        new Date(item.created).getTime() - new Date(last.created).getTime() <
          1000 * 60 * 1
      );
    };

    Service.getThreadById = function(thread_id) {
      var url = String.format('/api/v2/threads/{0}/', thread_id);
      var promise = this.getData(url);

      promise.then(processThread);

      return promise;
    };

    Service.getThreadSubscriber = function(threadId, subscriberId, params) {
      var url = String.format(
        '/api/v2/threads/{0}/subscribers/{1}/{2}',
        threadId,
        subscriberId,
        params ? this.toUrl(params) : ''
      );
      return this.getData(url);
    };

    Service.getThreadSubscribers = function(threadId, params) {
      var url = String.format(
        '/api/v2/threads/{0}/subscribers/{1}',
        threadId,
        params ? this.toUrl(params) : ''
      );
      return this.getData(url);
    };

    Service.getThreadPagingGroups = function(threadId, params) {
      var url = String.format(
        '/api/v2/threads/{0}/paginggroups/{1}',
        threadId,
        params ? this.toUrl(params) : ''
      );
      return this.getData(url);
    };

    Service.getThreadPatient = function(threadId) {
      var url = String.format('/api/v1/threads/{0}/patient/', threadId);
      return this.getData(url);
    };

    Service.getThreadEncounter = function(encounterId) {
      var url = String.format('/api/patients/encounters/{0}/', encounterId);
      return this.getData(url);
    };

    Service.setThreadPatient = function(threadId, patientId) {
      var url = String.format('/api/v2/threads/{0}/', threadId);
      return this.patchData(url, { patient_id: patientId });
    };

    Service.setThreadEncounter = function(threadId, encounterId) {
      var url = String.format('/api/v2/threads/{0}/', threadId);
      return this.patchData(url, { encounter_id: encounterId });
    };

    Service.setMessagesRead = function(
      thread_id,
      first_message_id,
      last_message_id
    ) {
      var url = String.format('/api/v1/threads/{0}/read/', thread_id);
      return this.postData(url, {
        first_message_id: first_message_id,
        last_message_id: last_message_id
      }).then(function(response) {
        $log.debug(
          String.format(
            'thread {0} marking messages from {1} to {2} as read, {3} marked as read',
            thread_id,
            first_message_id,
            last_message_id,
            response.messages_read
          )
        );
        return response;
      });
    };

    Service.setMessageRead = function(thread_id, message_id) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/read/',
        thread_id,
        message_id
      );
      return this.postData(url);
    };

    Service.subscribeToThread = function(thread_id, data) {
      var url = String.format('/api/v1/threads/{0}/subscribers/', thread_id);
      return this.postData(url, data);
    };

    Service.replyToThread = function(thread_id, message, attachments) {
      var url = String.format('/api/v1/threads/{0}/messages/', thread_id);
      return this.postData(url, {
        message: message,
        attachment_ids: attachments
      });
    };

    Service.forwardMessage = function(threadId, messageId, recipients) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/forward/',
        threadId,
        messageId
      );
      return this.postData(url, { recipients: recipients });
    };

    Service.forwardThread = function(threadId, recipients) {
      var url = String.format('/api/v1/threads/{0}/forward/', threadId);
      return this.postData(url, { recipients: recipients });
    };

    Service.triggerEMRWriteback = function(threadUid) {
      var url = String.format('/api/v2/threads/{0}/mdm_writeback/', threadUid);
      return this.postData(url, {});
    };

    Service.getMessages = function(threadId, page_size, page) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/?page_size={1}&page={2}&sorting=-id',
        threadId,
        page_size,
        page || '1'
      );
      return this.getData(url).then(function(response) {
        var last;
        _.forEachRight(response.results, function(item) {
          //this will skip the first item
          if (last) {
            item.grouped = Service.shouldBeGrouped(item, last);
          }
          last = item;
        });

        return response;
      });
    };

    Service.getMessageById = function(threadId, messageId) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/',
        threadId,
        messageId
      );
      return this.getData(url);
    };

    Service.accept = function(threadId, messageId) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/accept/',
        threadId,
        messageId
      );
      return this.postData(url);
    };

    Service.reject = function(threadId, messageId) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/reject/',
        threadId,
        messageId
      );
      return this.postData(url);
    };

    Service.recallThread = function(threadId) {
      var url = String.format('/api/v1/threads/{0}/recall/', threadId);
      return this.postData(url);
    };

    Service.respond = function(threadId, messageId, option) {
      var url = String.format(
        '/api/v1/threads/{0}/messages/{1}/respond/',
        threadId,
        messageId
      );
      return this.postData(url, { response: option });
    };

    Service.getQueryStringValue = function(url, key) {
      var result = null;

      if (url) {
        var start = url.indexOf('?');
        if (start > -1) {
          var qstr = url.substring(start + 1);
          var pairs = qstr.slice(0).split('&');

          result = {};
          pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
          });

          result = JSON.parse(JSON.stringify(result));
        }
      }

      return result[key];
    };

    Service.newGuid = function() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      return (
        s4() +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        s4() +
        s4()
      );
    };

    Service.archiveThreadById = function(id) {
      var url = String.format('/api/v1/threads/{0}/archive/', id);

      return this.postData(url);
    };

    Service.showComposeModal = function(contactableIds, patient, encounter) {
      return modalHelper.openModal({
        templateUrl:
          '/smartpager/angular/endUser/messages/controllers/modals/composeMessageModal.html',
        controller: 'composeMessageModalCtrl as $ctrl',
        backdrop: 'static',
        size: 'lg',
        contactableIds: contactableIds,
        patient: patient,
        encounter: encounter
      }).result;
    };

    Service.showMonitorModal = function() {
      return modalHelper.openModal({
        templateUrl:
          '/smartpager/angular/endUser/messages/controllers/modals/monitorModal.html',
        controller: 'monitorModalCtrl as $ctrl',
        backdrop: 'static'
      }).result;
    };

    return Service;
  }]);
