/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:adoptionTrackingCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .controller('adoptionTrackingCtrl', ["$scope", "$log", "reportSlug", "reportService", function ($scope, $log, reportSlug, reportService) {
        var $ctrl = this;

        $ctrl.reload = function () {
            $ctrl.isLoading = true;
            setTimeout(function(){
                reportService.runReport(reportSlug, $ctrl.searchParams)
                    .then(function (response) {
                        $ctrl.isEmptyReport = !response.installations || !response.last_active;
                        $ctrl.data = response;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }, 2500);
        };
        $ctrl.reload();
    }]);
