/**
 * @ngdoc directive
 * @name smarpager.endUser.shared:iqDirectoryObjectSearchDirective
 *
 * @description
 *
 *
 * @restrict E
 * */
angular
  .module('smartpager.endUser.shared')
  .directive('iqDirectoryObjectSearch', function() {
    return {
      restrict: 'E',
      require: 'ngModel',
      transclude: true,
      scope: {
        label: '@',
        showAdvSearch: '=?',
        showFavorites: '=?',
        showGroupLookup: '=?',
        propertyToMatch: '@',
        onSelect: '&',
        searchUsers: '=?',
        includeSelf: '=?',
        account: '=?'
      },
      controller: 'iqDirectoryObjectSearchCtrl',
      templateUrl: function(tElement, tAttrs) {
        var single = tAttrs.multiple && tAttrs.multiple === 'false';
        if (single) {
          return '/smartpager/angular/endUser/shared/components/iqDirectoryObjectSearch/iqDirectoryObjectSearch-single.html';
        } else {
          return '/smartpager/angular/endUser/shared/components/iqDirectoryObjectSearch/iqDirectoryObjectSearch-multiple.html';
        }
      },
      link: function(scope, elem, attr, ngModelCtrl) {
        //default values
        scope.showAdvSearch =
          typeof scope.showAdvSearch === 'undefined' ? true : scope.showAdvSearch;
        scope.showGroupLookup =
          typeof scope.showGroupLookup === 'undefined' ? false : scope.showGroupLookup;
        scope.searchUsers =
          typeof scope.searchUsers !== 'undefined' ? scope.searchUsers : false;
        scope.includeSelf =
          typeof scope.includeSelf !== 'undefined' ? scope.includeSelf : false;
        scope.showFavorites =
          typeof scope.showFavorites === 'undefined' ? false : scope.showFavorites;

        //region handle ngModelCtrl

        ngModelCtrl.$formatters.push(function(modelValue) {
          return angular.copy(modelValue);
        });

        ngModelCtrl.$parsers.push(function(viewValue) {
          return angular.copy(viewValue);
        });

        scope.$watchCollection(
          'iqDirectoryObjectSearchCtrl.dirObjects',
          function(newdirObjects) {
            ngModelCtrl.$setViewValue(angular.copy(newdirObjects));
          }
        );

        ngModelCtrl.$render = function() {
          if (ngModelCtrl.$viewValue) {
              scope.iqDirectoryObjectSearchCtrl.dirObjects = ngModelCtrl.$viewValue;
          } else {
              scope.iqDirectoryObjectSearchCtrl.dirObjects = attr.multiple && attr.multiple === 'false' ? null : [];
          }
        };

        if (attr.required) {
          ngModelCtrl.$validators.required = function(modelValue, viewValue) {
            return modelValue && modelValue.length !== 0;
          };
        }
        //endregion
      }
    };
  });
