/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:addConditionModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .controller('addConditionModalCtrl', ["$scope", "messageRoutingService", "Notification", "modalOptions", "eventsBus", "alertsHelper", function ($scope, messageRoutingService, Notification, modalOptions, eventsBus, alertsHelper) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.labels = {
            MessageContentCondition: {subject__ilike: 'Subject contains', body__ilike: 'Message body contains', priority__in: 'Message priority'},
            MessageTypeCondition: {message_type_id__in: ''},
            RecipientCondition: {user_groups__in: '', id__in: ''},
            ScheduleCondition: {time__gte: 'Time after', time__lte: 'Time before', day_of_week__in: 'Day of week', day_of_month__in: 'Day of month'},
            SenderCondition: {group__in: '', id__in: '', department__in: ''}
        };

        $ctrl.help_links = {
            MessageContentCondition: {
                subject__ilike: 'https://support.telmediq.com/hc/en-us/articles/115000099043',
                body__ilike: 'https://support.telmediq.com/hc/en-us/articles/115000099043'
            },
            MessageTypeCondition: {},
            RecipientCondition: {},
            ScheduleCondition: {},
            SenderCondition: {}
        };

        $ctrl.daysOfMonth = _.range(1, 32);

        $ctrl.resetCondition = function () {
            $ctrl.condition = {};
        };

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;
            messageRoutingService.getConditionTypes()
                .then(function (response) {
                    $ctrl.available_condition_types = response;
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });

            if (modalOptions.condition) {
                $ctrl.isEdit = true;

                messageRoutingService.getConditionById(modalOptions.condition.url_prefix, modalOptions.condition.id)
                    .then(function (response) {
                        $ctrl.condition = response;
                        $ctrl.condition_type = $ctrl.condition.type;
                    });
            }

            $ctrl.title = $ctrl.isEdit ? 'Edit Condition' : 'Create Condition';
        };

        function cleanSubjectCondition(condition) {
            /**
             * A customer experienced an issue (SC-6828) where threads sent via the mobile apps
             * received different routing than threads sent via the web console. It was determined
             * that this is due to the apps sending the Message Type name as the "subject" of the
             * message, in contrast to the web console leaving this out.
             *
             * To mitigate this issue, it is necessary to allow message content matching to
             * leave out the subject, which is defined on the server as nullable but not blank-able.
             * Since the routing code on the server is utterly unmaintainable, this function exists
             * to turn an empty subject condition into a null subject condition.
             */
            if (condition.hasOwnProperty("subject__ilike") && condition.subject__ilike === "") {
                condition["subject__ilike"] = null;
            }

            return condition;
        }

        $ctrl.updateCondition = function (condition) {
            condition = cleanSubjectCondition(condition);

            $ctrl.isSaving = true;
            messageRoutingService.updateCondition(condition)
                .then(function (response) {
                    Notification.success('Successfully updated ' + condition.name);
                    $scope.$close(response);
                })
                .catch(function () {
                    Notification.error('could not update condition');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.createCondition = function (condition_type, condition) {
            if (!$ctrl.form.$valid) {
                $ctrl.form.$setSubmitted();
                return;
            }

            condition = cleanSubjectCondition(condition);

            $ctrl.isSaving = true;
            messageRoutingService.createCondition(condition_type, condition)
                .then(function (response) {
                    Notification.success('Successfully created ' + condition.name);
                    $scope.$close(response);
                })
                .catch(function (response) {
                    $ctrl.serverErrors = response.data;
                    eventsBus.publish('serverError', $ctrl.serverErrors);
                    $ctrl.alerts.error('Could not create condition');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
