/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:callLogReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .factory('callLogReportCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('callLogReportCache');
    }])
    .filter('callType', function () {
        return function (input) {

            switch (input) {
                case 'message_notification':
                    return 'Message Notification';
                case 'incoming':
                    return 'Incoming';
                case 'callback':
                    return 'Callback';

                default:
                    return input;
            }
        };
    })
    .controller('callLogReportCtrl', ["$scope", "reportSlug", "reportService", "NgTableParams", "ngTableHelper", "callLogReportCache", function ($scope, reportSlug, reportService, NgTableParams, ngTableHelper, callLogReportCache) {
        var $ctrl = this;

        $ctrl.dateRangePickerOptions = reportService.getDateRangePickerOptions();

        $ctrl.tableParams = new NgTableParams(callLogReportCache.get('parameters') || {
                sorting: {
                    created: 'asc'
                },
                filter: {
                    start: moment().startOf('month').toDate(),
                    end: moment().endOf('month').toDate()
                }
            }, {
            getData: function (params) {
                var start = $ctrl.searchParams.start();
                $ctrl.hasDateFilter = String(start).indexOf('Jan 01 2000 00:00:00') === -1 && start;

                $ctrl.isLoading = true;
                callLogReportCache.put('parameters', params.parameters());
                return reportService.runReport(reportSlug, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search'),
            start: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'start'),
            end: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'end')
        };

        $ctrl.downloadCsv = function () {
            reportService.downloadReportAsCSV(reportSlug, callLogReportCache.get('parameters'), 'Call Log Report.csv');
        };
    }]);
