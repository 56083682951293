/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:qgendaScheduleConfigurationCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('qgendaScheduleConfigurationCtrlCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('qgendaScheduleConfigurationCtrlCache');
    }])
    .controller('qgendaScheduleConfigurationCtrl', ["$scope", "$state", "configId", "scheduleId", "integrationsService", "Notification", "NgTableParams", "ngTableHelper", "qgendaScheduleConfigurationCtrlCache", "modalHelper", function($scope, $state, configId, scheduleId, integrationsService, Notification, NgTableParams, ngTableHelper, qgendaScheduleConfigurationCtrlCache, modalHelper){
        var $ctrl = this;

        $ctrl.$onInit = function(){
            $ctrl.configId = configId;
            $ctrl.isCreate = scheduleId === 'new' || scheduleId === undefined;
            $ctrl.scheduleConfig = {};

            if(!$ctrl.isCreate){
                integrationsService.getQgendaScheduleConfig(configId, scheduleId)
                    .then(function(response){
                        $ctrl.scheduleConfig = response;
                    });
            }
        };

        $ctrl.createScheduleMapping = function(){
            $ctrl.isSaving = true;
            integrationsService.createQgendaScheduleConfig(configId, $ctrl.scheduleConfig)
                .then(function(result){
                    Notification.success('Successfully created schedule mapping');
                    $state.go('root.accountSettings.integrations.qgendaScheduleConfig', {
                        configId: configId,
                        scheduleId: result.id
                    });
                })
                .catch(function(result){
                    var errorMsg = 'Could not create schedule mapping';

                    if(result.data.schedule_group_name){
                        errorMsg = result.data.schedule_group_name.join(' ');
                    }
                    Notification.error(errorMsg);
                })
                .finally(function(){
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.tableParams = new NgTableParams(qgendaScheduleConfigurationCtrlCache.get('parameters') || {
                count: 10,
                sorting: {
                    name: 'asc'
                }
            }, {
            getData: function(params){
                qgendaScheduleConfigurationCtrlCache.put('parameters', params.parameters());

                $ctrl.isLoading = true;

                integrationsService.cancel($ctrl.lastRequest);

                $ctrl.lastRequest = integrationsService.getQgendaTaskMappingsForGrid(configId, scheduleId, params.parameters());

                return $ctrl.lastRequest
                    .then(function(response){
                        params.total(response.count);
                        $ctrl.addedTasks = _.map(response.results, 'id');
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.deleteTaskMapping = function(taskId){
            $ctrl.isLoading = true;
            integrationsService.deleteQgendaTaskMapping(configId, scheduleId, taskId)
                .then(function(){
                    Notification.success('Successfully removed task from schedule mapping');
                })
                .catch(function(){
                    Notification.error('Could not remove task from schedule mapping');
                })
                .finally(function(){
                    $ctrl.tableParams.reload();
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.openAddTaskModal = function(){
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/qgenda/modals/addTaskMappingModal.html',
                controller: 'addTaskMappingModalCtrl as $ctrl',
                scheduleId: scheduleId,
                configId: configId,
                addedTasks: $ctrl.addedTasks ? $ctrl.addedTasks : []
            }).result
                .finally(function(){
                    $ctrl.tableParams.reload();
                });
        };
}]);
