angular
  .module("smartpager.endUser.messages")

  .controller("inboxCtrl", ["$rootScope", "$scope", "$state", "$location", "messageService", "Pubnub", "$log", "inboxEvents", "configuration", "inboxType", "pubNubMessageTypes", "soundService", "baseService", "patientsService", "userService", function(
    $rootScope,
    $scope,
    $state,
    $location,
    messageService,
    Pubnub,
    $log,
    inboxEvents,
    configuration,
    inboxType,
    pubNubMessageTypes,
    soundService,
    baseService,
    patientsService,
    userService
  ) {
    var self = this;

    // Loads the new messaging experience
    window.location.replace("/v2/messaging/");

    $scope.getCurrentInboxType = function() {
      return $state.params.inboxType;
    };

    $scope.inboxCtrl = {
      threads: [],
      observedUserIds: []
    };

    function prepareParams(searchParams) {
      var pageSize = 20;

      var pageToLoad = baseService.pickPage(
        $scope.inboxCtrl.threads.length,
        pageSize
      );
      return _.merge(
        messageService.getClearFilter($scope.getCurrentInboxType()),
        {
          ordering: "-last_message_date",
          page: pageToLoad,
          page_size: pageSize
        },
        searchParams
      );
    }

    $scope.inboxCtrl.refreshThreadsUsingCurrentFilter = function() {
      return $scope.inboxCtrl.refreshThreads($scope.inboxCtrl.searchParams);
    };

    $scope.inboxCtrl.refreshThreads = function(searchParams) {
      $scope.inboxCtrl.loading = true;

      var params = prepareParams(searchParams);

      lastRequest = messageService.getThreadList(params);

      lastRequest
        .then(function(response) {
          $scope.inboxCtrl.count = response.count;
          $scope.inboxCtrl.threads = _.uniqBy(
            $scope.inboxCtrl.threads.concat(response.results),
            "id"
          );
        })
        .then(function(response) {
          userService
            .getObservedUsers(configuration.getUserId())
            .then(markObservedThreads);
        })
        .finally(function() {
          $scope.inboxCtrl.loading = false;
        });

      return lastRequest;
    };

    $rootScope.$on(inboxEvents.observedUsersChange, function(ev, observed) {
      markObservedThreads(observed);
    });

    function markObservedThreads(observed) {
      // If any subscribers in the threads are being observed,
      // mark the thread as monitored.
      _.forEach($scope.inboxCtrl.threads, function(thread) {
        var addedDueToMonitoring =
          thread.my_subscriber && thread.my_subscriber.reason === "monitoring";
        thread.monitored = addedDueToMonitoring;
      });
    }

    $scope.$onRootScope(inboxEvents.threadPatientLoaded, function(event, data) {
      var updatedThread = _.find($scope.inboxCtrl.threads, { id: data.id });
      if (updatedThread) {
        updatedThread.patient = data.patient;
        updatedThread.patient_id = data.patient_id;
        updatedThread.encounter_id = data.encounter_id;
      }
    });
    $scope.$onRootScope(inboxEvents.newThread, function(event, thread) {
      // auto response can happen so fast that pubnub notification will for reply will be here before post new thread completes...
      // we need to check if thread has not been created already
      if (!_.some($scope.inboxCtrl.threads, { id: thread.id })) {
        $log.debug(String.format("adding new thread ({0}) to list", thread.id));
        $scope.inboxCtrl.threads.unshift(thread);
        $scope.inboxCtrl.count++;
      }
    });

    $scope.$onRootScope(inboxEvents.newMessage, function(event, data) {
      var updatedThread = _.find($scope.inboxCtrl.threads, {
        id: data.threadId
      });
      if (updatedThread) {
        updatedThread.last_message = data.last_message;
      }
    });

    $scope.$onRootScope(inboxEvents.threadSearch, function(event, params) {
      $scope.inboxCtrl.searchParams = params;
      $scope.inboxCtrl.threads = [];
      $scope.inboxCtrl.refreshThreads(params);
    });

    $scope.$onRootScope(inboxEvents.threadInView, function(event, data) {
      var updatedThread = _.find($scope.inboxCtrl.threads, { id: data.id });
      if (updatedThread) {
        //refresh subscribers upon thread view as they might have changed when we were not looking at it
        updatedThread.subscribers = data.subscribers;
      }
    });

    $scope.$onRootScope("readMessages", function(event, data) {
      var updatedThread = _.find($scope.inboxCtrl.threads, {
        id: +data.thread_id
      });
      if (updatedThread) {
        if (updatedThread.me) {
          updatedThread.me.unread_message_count = 0;
        }
      }
    });

    function removeThreadFromList(threadId) {
      var removed = _.remove($scope.inboxCtrl.threads, { id: threadId });
      $scope.inboxCtrl.count -= removed.length;
      $scope.$digest();

      /* jslint eqeq: true */
      if ($state.params.threadId == threadId) {
        $state.go("root.inbox.noThreads");
      }
    }

    function addThreadToList(content) {
      //check if message is already on the list (it might be because of unarchive and view)
      if (!_.find($scope.inboxCtrl.threads, { id: content.thread.id })) {
        messageService.getThreadById(content.thread.id).then(function(thread) {
          if (
            $scope.inboxCtrl.threads.length === 0 ||
            moment(thread.created).isAfter(
              $scope.inboxCtrl.threads[0].last_message.created
            )
          ) {
            $scope.inboxCtrl.count++;
          }
          //insert at the position that will maintain sort order
          $scope.inboxCtrl.threads.splice(
            _.sortedIndex(
              $scope.inboxCtrl.threads,
              thread,
              "last_message.created"
            ),
            0,
            thread
          );
        });
      }
    }

    function fetchThread(thread, increment_read_count) {
      // A fix for double pubnub notifications.
      if (thread.id === null || thread.id === undefined) {
        return;
      }
      var updatedThread = _.find($scope.inboxCtrl.threads, { id: thread.id });
      if (updatedThread) {
        updatedThread.last_message = thread.last_message;
        if (increment_read_count && updatedThread.me.unread_message_count !== undefined) {
          updatedThread.me.unread_message_count++;
        }
        $scope.$digest();
        if (!updatedThread.suppress_reply_notifications) {
          soundService.playNewMessage(updatedThread.priority);
        }
      } else {
        $log.debug(String.format("loading thread {0}", thread.id));
        messageService.getThreadById(thread.id).then(function(thread) {
          //auto response happens so fast that we get pubnub notification before post thread completes... so we start fetching, in the mean time post completes and add thread the
          // list of thread and then get thread completes and we have a duplicate thread on the list
          if (!_.some($scope.inboxCtrl.threads, { id: thread.id })) {
            if (
              $scope.inboxCtrl.threads.length === 0 ||
              moment(thread.created).isAfter(
                $scope.inboxCtrl.threads[0].last_message.created
              )
            ) {
              $scope.inboxCtrl.count++;
            }
            $log.debug(String.format("loaded thread"));

            $scope.inboxCtrl.threads.unshift(thread);

            var addedDueToMonitoring =
              thread.my_subscriber &&
              thread.my_subscriber.reason === "monitoring";
            thread.monitored = addedDueToMonitoring;
            soundService.playNewMessage(thread.priority);
          }
        });
      }
    }

    self.viewingThisThread = function(content) {
      /* jslint eqeq: true */
      var viewingThisThread = content.thread.id == $state.params.threadId;
      return viewingThisThread;
    };

    self.viewingArchive = function() {
      var viewingArchive = $scope.getCurrentInboxType() === inboxType.archive;
      return viewingArchive;
    };

    $scope.$onRootScope(pubNubMessageTypes.uc.new_thread_subscriber, function(
      event,
      message
    ) {
      var content = message.content;

      var viewingThisThread = content.thread_id === $state.params.threadId;
      var viewingArchive = self.viewingArchive();

      // If notification is about a currently viewed thread ignore as will get another notification via threadChannel
      // add message only when were inbox or unread (that means not do not when we're in the archive)
      if (viewingThisThread || viewingArchive) {
        return;
      }

      var updatedThread = _.find($scope.inboxCtrl.threads, {
        id: content.thread_id
      });
      if (updatedThread) {
        var subscriber = _.find(updatedThread.subscribers, { id: content.id });
        if (!subscriber) {
          updatedThread.subscribers.push(content);
        }
        $scope.$digest();
      } else {
        $log.debug(String.format("loading thread {0}", content.thread_id));
        fetchThread(content.thread_id, true);
      }
    });

    $scope.$onRootScope(pubNubMessageTypes.uc.new_thread, function(
      event,
      message
    ) {
      var content = message.content;

      var viewingThisThread = self.viewingThisThread(content);
      var viewingArchive = self.viewingArchive();

      // If notification is about a currently viewed thread ignore as will get another notification via threadChannel
      // add message only when were inbox or unread (that means not do not when we're in the archive)
      if (viewingThisThread || viewingArchive) {
        return;
      } else {
        //if it's a new message on a thread that is not currently viewed and we're not in the archive tap api to mark message as delivered
        messageService.getMessageById(content.thread.id, content.id);
      }
      fetchThread(content.thread, false);
    });

    $scope.$onRootScope(pubNubMessageTypes.uc.new_thread_message, function(
      event,
      message
    ) {
      var content = message.content;

      var viewingThisThread = self.viewingThisThread(content);
      var viewingArchive = self.viewingArchive();

      // If notification is about a currently viewed thread ignore as will get another notification via threadChannel
      // add message only when were inbox or unread (that means not do not when we're in the archive)
      if (viewingThisThread || viewingArchive) {
        return;
      } else {
        //if it's a new message on a thread that is not currently viewed and we're not in the archive tap api to mark message as delivered
        messageService.getMessageById(content.thread.id, content.id);
      }

      fetchThread(content.thread, true);
    });

    $scope.$onRootScope(pubNubMessageTypes.uc.thread_recall, function(
      event,
      message
    ) {
      var content = message.content;
      removeThreadFromList(content.thread.id);
    });

    $scope.$onRootScope(pubNubMessageTypes.uc.thread_archived, function(
      event,
      message
    ) {
      var content = message.content;
      if (
        $scope.getCurrentInboxType() === inboxType.inbox ||
        $scope.getCurrentInboxType() === inboxType.unread
      ) {
        //when looking at inbox and we're archiving message or when in archive and we're unarchiving message remove it from current list and remove selection if not removed yet
        removeThreadFromList(content.thread.id);
      } else if ($scope.getCurrentInboxType() === inboxType.archive) {
        //when looking at archive and message was archive add it to the list
        addThreadToList(content);
      }
    });

    var lastRequest;
    this.$onInit = function() {
      //load thread list, got first one if navigated directly to this state
      lastRequest = $scope.inboxCtrl.refreshThreads();

      //ensure current alert tone globals are accurate
      userService.requestGetProfile();

      //unsubscribe when leaving inbox
      $scope.$on("$destroy", function() {
        messageService.cancel(lastRequest);
      });

      // Immediately show compose modal if a recipient was specified in the query params
      var search = $location.search();
      if (search.cid) {

        var contactIds = search.cid ? search.cid.split(",") : [];
        var pid = search.pid;
        var eid = search.eid;

        $location.search("cid", null);
        if (pid) {
          $location.search("pid", null);
          patientsService.getPatientById(pid).then(function (patient) {
            messageService.showComposeModal(contactIds, patient);
          });
        } else if (eid) {
          // Eid mubarak!
          $location.search("eid", null);
          messageService.getThreadEncounter(eid).then(function (encounter) {
            messageService.showComposeModal(contactIds, undefined, encounter);
          });
        } else {
          messageService.showComposeModal(contactIds);
        }
      }
    };
  }]);
