/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:noteFieldsListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('noteFieldListCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('noteFieldListCache');
    }])
    .controller('noteFieldsListCtrl', ["$scope", "$log", "$state", "noteFieldListCache", "NgTableParams", "ngTableHelper", "patientsService", "modalHelper", function($scope, $log, $state, noteFieldListCache, NgTableParams, ngTableHelper, patientsService, modalHelper){
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams(noteFieldListCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                noteFieldListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;

                return patientsService.getNoteFields(params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        return response.results;
                    })
                    .catch(function(response){
                        $log.debug('Error with status code ', response.status);
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.openAddFieldModal = function(){
            return modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/modals/addNoteFieldModal.html',
                'controller': 'addNoteFieldModalCtrl as $ctrl',
                'size': 'lg'
            }).result
                .then(function(response){
                    $state.go('root.accountSettings.noteFields.detail', {fieldId: response.id});
                })
                .catch(function () {
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.clearSearch = function(){
            $ctrl.searchParams.search(null);
        };
}]);
