/**
 * @ngdoc service
 * @name pagingGroupPermissionsService
 * @description
 * Utilities for determining the user's permissions for a paging group.
 * */
angular.module('smartpager.endUser.pagingGroups')
    .service('pagingGroupPermissionsService', ["$log", "configuration", "permissionsService", function ($log, configuration, permissionsService) {
        var currentUser = configuration.getUserProfile();
        var hasPagingGroupPermission = function (global_permission, department_permission, group) {
            if (permissionsService.hasPermission(global_permission)) {
                return true;
            }

            if (!permissionsService.hasPermission(department_permission)) {
                return false;
            }

            return currentUser.departments.indexOf(group.department_id) >= 0;
        };

        return {
            hasPagingGroupChangePermission: function (group) {
                return hasPagingGroupPermission(
                    'paginggroups.change_paginggroup',
                    'paginggroups.change_department_paginggroups',
                    group);
            },

            hasPagingGroupDeletePermission: function (group) {
                return hasPagingGroupPermission(
                    'paginggroups.delete_paginggroup',
                    'paginggroups.delete_department_paginggroups',
                    group);
            },

            hasOnCallEventAddPermission: function (group) {
                return hasPagingGroupPermission(
                    'scheduling.add_oncallevent',
                    'scheduling.add_department_oncallevents',
                    group);
            },

            hasOnCallEventChangePermission: function (group) {
                return hasPagingGroupPermission(
                    'scheduling.change_oncallevent',
                    'scheduling.change_department_oncallevents',
                    group);
            },

            hasOnCallEventDeletePermission: function (group) {
                return hasPagingGroupPermission(
                    'scheduling.delete_oncallevent',
                    'scheduling.delete_department_oncallevents',
                    group);
            }
        };
    }]);
