/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:integrationsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .constant('availableIntegrationTypes', {
        qgenda: 'qgenda',
        amion: 'amion',
        mailgun: 'mailgun',
        wctp: 'wctp',
        shiftadmin: 'shiftadmin',
        tangier: 'tangier'
    })
    .controller('integrationsCtrl', ["$scope", "availableIntegrationTypes", "integrationsService", "$log", function ($scope, availableIntegrationTypes, integrationsService, $log) {

        //initialize hashmap of available integrations
        $scope.allIntegrations = {};
        for(var item in availableIntegrationTypes) {
            $scope.allIntegrations[availableIntegrationTypes[item]] = [];
        }

        $scope.reload = function () {
            integrationsService.getConfiguredIntegrations()
                .then(function (response) {
                    _.forEach(response.results, function (item) {
                        if (!$scope.allIntegrations.hasOwnProperty(item.type)) {
                            $log.warn('Unknown integration type');
                        } else {
                            $scope.allIntegrations[item.type].push(item);
                        }
                    });
                });
        };

        $scope.reload();
    }]);
