/**
 * @ngdoc service
 * @name smartpager.endUser.shared:fileUploadHelper
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .service('fileUploadHelper', ["FileUploader", "configuration", function (FileUploader, configuration) {
        // this is affecting file uploader globally
        FileUploader.FileSelect.prototype.isEmptyAfterSelection = function () {
            return true;
        };

        var tenMB = 10 * 1024 * 1024; // 10 MiB to bytes
        var halfMB = 0.5 * 1024 * 1024; // 10 MiB to bytes

        var Service = this;
        this.noop = function () {
        };

        this.getGeneralFilesUploader = function () {
            var uploader = Service.getBaseFileUploader('/api/v1/files/', true);
            uploader.filters.push(this.getFileSizeFilter(tenMB));
            return uploader;
        };

        this.getProfilePictureUploaded = function (user_id) {
            var uploader = Service.getBaseFileUploader(String.format('/users/{0}/photo/', user_id), false);
            uploader.allowedExtensions = '.jpg,.png,.gif,.jpeg';
            uploader.filters.push(this.getFileSizeFilter(halfMB));
            uploader.filters.push({
                name: 'invalidFileType',
                fn: function (item, options) {
                    var type = item.type.slice(item.type.lastIndexOf('/') + 1);
                    return uploader.allowedExtensions.indexOf(type) !== -1;
                }
            });
            uploader.onAfterAddingFile = function (item) {
                item.alias = 'photo';
                item.formData.push({csrfmiddlewaretoken: configuration.getToken()});
            };
            return uploader;
        };

        this.getAttachmentsFileUploader = function () {
            var uploader = Service.getBaseFileUploader('/api/v1/attachments/', false);
            uploader.allowedExtensions = '.jpg,.png,.gif,.jpeg,.bmp,.pdf,.mp3';
            uploader.filters.push(this.getFileSizeFilter(tenMB));
            uploader.filters.push({
                name: 'invalidFileType',
                fn: function (item, options) {
                    var type = item.type.slice(item.type.lastIndexOf('/') + 1);
                    return uploader.allowedExtensions.indexOf(type) !== -1 || type === 'mpeg';
                }
            });
            return uploader;
        };

        this.getBaseFileUploader = function (url, autoUpload) {
            return new FileUploader({
                url: url,
                headers: {'X-CSRFToken': configuration.getToken(), 'Accept': '*/*'},
                autoUpload: autoUpload
            });
        };

        this.getFileSizeFilter = function (size) {
            return {
                'name': 'enforceMaxFileSize',
                'fn': function (item) {
                    return item.size <= size;
                }
            };
        };
    }]);
