/**
 * @ngdoc directive
 * @name iqUserSelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular
  .module('smartpager.endUser.shared')
  .controller('iqUserSelectCtrl', ["$scope", "modalHelper", "userService", "$log", "$timeout", function(
    $scope,
    modalHelper,
    userService,
    $log,
    $timeout
  ) {
    $scope.iqUserSelectCtrl = {
      availableUsers: [],
      users: [],
      user: null
    };

    var getUsers;
      if ($scope.monitorable) {
          getUsers = userService.getMonitorableUsers.bind(userService);
      } else {
          getUsers = userService.getUsers.bind(userService);
      }

    function getUnfetchedUsers(previouslyUnfetchedUsersCount) {
      var users = $scope.iqUserSelectCtrl.users.slice(0);
      var availableUsers = $scope.iqUserSelectCtrl.availableUsers.slice(0);

      if (users.length && typeof users[0] === 'object') {
        users = _.map(users, 'id');
      }

      if (availableUsers.length && typeof availableUsers[0] === 'object') {
        availableUsers = _.map(availableUsers, 'id');
      }
      var usersToFetch = users.filter(function(id) {
        return availableUsers.indexOf(id) === -1;
      });

      // If, for some reason, the number of unfetched users hasn't changed since the last request,
      //   exit early to avoid spamming
      if (
        typeof previouslyUnfetchedUsersCount === 'number' &&
        previouslyUnfetchedUsersCount === usersToFetch.length
      ) {
        return;
      }

      if (usersToFetch.length > 0) {
        getUsers({ ids: usersToFetch.join(',') }).then(function(resp) {
          $scope.iqUserSelectCtrl.availableUsers = $scope.iqUserSelectCtrl.availableUsers.concat(
            resp.results
          );
          getUnfetchedUsers(usersToFetch.length);
        });
      }
    }

    // for regular type-ahead searches
    $scope.searchContacts = function(query) {
      var param = {
        search: query
      };
      if ($scope.excludeDeactivated) {
        param.is_active = "True";
      }
      $scope.isLoading = true;
      getUsers(param)
        .then(function(response) {
          $scope.iqUserSelectCtrl.availableUsers = response.results;

          // If there are selected users that are not in availableUsers, fetch them and add them to the list.
          getUnfetchedUsers();
        })
        .finally(function() {
          $scope.isLoading = false;
        });
    };
  }]);
