/**
 * @ngdoc controller
 * @name subscribersCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('subscribersCtrl', ["$scope", "threadId", "inboxEvents", "modalHelper", "configuration", "$log", "messageService", "reportService", function ($scope, threadId, inboxEvents, modalHelper, configuration, $log, messageService, reportService) {

        var self = this;

        self.groups = [];
        self.showSubscriberStats = false;
        self.subscriberSummary = {read: 0, delivered: 0, pending: 0};
        self.subscriberChartConfig = {};

        function updateSubscriberChartConfig(newState, isNewSubscriber){
            var READ = 'read';
            var DELIVERED = 'delivered';
            var SENT = 'pending';

            switch(newState){
                case READ:
                    self.subscriberSummary.read++;
                    self.subscriberSummary.delivered = isNewSubscriber ? self.subscriberSummary.delivered : self.subscriberSummary.delivered - 1;
                    break;
                case DELIVERED:
                    self.subscriberSummary.delivered++;
                    self.subscriberSummary.pending = isNewSubscriber ? self.subscriberSummary.pending : self.subscriberSummary.pending - 1;
                    break;
                case SENT:
                    self.subscriberSummary.pending++;
                    break;
                default:
                    break;
            }
            self.subscriberChartConfig.series[0].data = reportService.getThreadSubscriberChartData(self.subscriberSummary);
            self.subscriberChartConfig = Object.assign({}, self.subscriberChartConfig);
        }

        $scope.$onRootScope(inboxEvents.threadInView, function (event, data) {
            self.groupsLoading = true;
            self.subscribersLoading = true;

            self.threadAllowsReplies = data.allow_replies;
            self.subscriberSummary = data.subscriber_summary;
            self.subscriberChartConfig = reportService.getThreadSubscriberChartConfig(self.subscriberSummary);

            messageService.getThreadPagingGroups(data.id)
                .then(function(response){
                    self.groups = response.results;
                    self.groupsLoading = false;
                    $log.debug('Fetched groups for thread in view', self.groups);
                });
        });

        $scope.$onRootScope(inboxEvents.subscribersLoaded, function(event, data){
            self.me = data.me;
            self.subscribers = data.subscribers;
            self.subscribersCount = data.subscribersCount;
            self.subscribersPage = 1;
            self.subscribersLoading = false;
        });

        $scope.$onRootScope(inboxEvents.subscriberStateChange, function (event, data) {
            var s = _.find(self.subscribers, function(subscriber){
                if(!subscriber.user) {
                    return false;
                }
                return subscriber.user.id === data.user.id;
            });

            if (!s) {
                //$scope.subscribers.push(data);
                $log.warn('getting status update about subscriber that does not exist yet');
            } else {
                s.state = data.state;
                s.verbose_state = data.verbose_state;
                updateSubscriberChartConfig(data.state);
            }
        });

        $scope.$onRootScope(inboxEvents.newThreadSubscriber + threadId, function (event, subscriber) {
            var s = _.find(self.subscribers, {id: subscriber.id});
            if (!s) {
                messageService.getThreadSubscriber(threadId, subscriber.id)
                    .then(function(subscriber){
                        self.subscribers.push(subscriber);
                        updateSubscriberChartConfig(subscriber.state, true);
                    });
            }
        });

        $scope.toggleSubscriberStats = function(){
            self.showSubscriberStats = !self.showSubscriberStats;
        };

        $scope.incrementDelivered = function(){
            updateSubscriberChartConfig('delivered', true);
        };

        $scope.addSubscriber = function () {

            var subscribedUsers = _.map(self.subscribers, 'user');

            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/messages/controllers/modals/addSubscriberModal.html',
                controller: 'addSubscriberModalCtrl',
                subscribedUsers: subscribedUsers,
                subscribedGroups: self.groups
            }).result
                .then(function (threadStub) {
                    $log.debug('currently subscribed', threadStub);
                    self.groups = threadStub.groups;
                    // Works because the subscribe endpoint is not paginated.
                    self.subscribersCount = threadStub.subscribers ? threadStub.subscribers.length : 0;
                });
        };

        $scope.loadMoreSubscribers = function(){
            $scope.loadingMore = true;
            if(self.subscribersCount > self.subscribers.length){
                messageService.getThreadSubscribers(threadId, {count: 50, page: self.subscribersPage+1})
                    .then(function(response){
                        self.subscribersPage += 1;
                        self.subscribersCount = response.count;
                        self.subscribers = _.union(self.subscribers, response.results);
                    })
                    .finally(function(){
                        $scope.loadingMore = false;
                    });
            } else {
                $scope.loadingMore = false;
            }
        };
    }]);
