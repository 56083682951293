/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:patientDetailNotesCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('patientDetailNotesCtrl', ["$scope", "patientId", "patientsService", "modalHelper", "Notification", "$log", "baseService", "configuration", function ($scope, patientId, patientsService, modalHelper, Notification, $log, baseService, configuration) {
        var $ctrl = this;
        $ctrl.notes = [];
        $ctrl.count = 0;

        $ctrl.currentUserId = configuration.getUserId();

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;
            patientsService.getPatientNotes(patientId, baseService.prepareParams($ctrl.notes))
                .then(function (response) {
                    $ctrl.count = response.count;
                    $ctrl.notes = _.uniqBy($ctrl.notes.concat(response.results), 'id');
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.loadPage = function () {
            $log.debug('checking if should loadMore');
            if ($ctrl.count > $ctrl.notes.length) {
                $log.debug('load page');
                $ctrl.$onInit();
            }
        };

        $scope.$on('reloadNotes', $ctrl.$onInit);

        $ctrl.deleteNote = function (note) {
            modalHelper.openModal({
                title: 'Delete note',
                content: 'Are you sure you want to delete note?',
                buttons: [
                    {id: 'delete', type: 'primary', label: 'Delete'},
                    {id: 'cancel', type: 'default', label: 'Cancel'}
                ]

            }).result
                .then(function () {
                    note.isDeleting = true;
                    return patientsService.deleteNote(patientId, note.id);
                })
                .then(function () {
                    _.remove($ctrl.notes, {id: note.id});
                    Notification.success('Successfully deleted note');
                })
                .finally(function () {
                    note.isDeleting = false;
                });
        };

        $ctrl.editNote = function (noteToUpdate) {
            modalHelper.openModal({
                controller: 'editPatientNoteModalCtrl as $ctrl',
                templateUrl: '/smartpager/angular/endUser/patients/controllers/modals/editPatientNoteModal.html',
                note: angular.copy(noteToUpdate)
            }).result
                .then(function (result) {
                    var note = _.find($ctrl.notes, {id: noteToUpdate.id});
                    if(note) {
                        _.merge(note, result);
                    }

                    Notification.success('Successfully edited note');
                });
        };
    }]);
