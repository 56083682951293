/**
 * @ngdoc service
 * @name smartpager.endUser.shared:pagerNumberService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .factory('pagerNumberService', ["baseService", function(baseService){
        var Service = Object.create(baseService);

        Service.getPagerNumbers = function(query){
            query = (query ? query : {});
            var params = $.param(query);
            var url = String.format('/api/v1/pagernumbers/{0}', params ? '?' + params : '');
            return this.getData(url);
        };

        return Service;

}]);
