/**
 * @ngdoc directive
 * @name profilePicture
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.shared')
    .directive('profilePicture', ["md5", function (md5) {
        return {
            restrict: 'E',
            templateUrl: '/smartpager/angular/endUser/shared/components/profilePicture/profilePicture.html',
            scope: {
                pictureSize: '=',
                user: '=',
                profilePictureProperty: '@'
            },
            link: function (scope) {
                if (scope.user) {
                    scope.username = scope.user.email || scope.user.id + '';
                }
            }
        };
    }]);
