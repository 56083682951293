/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:noteTemplatesFieldsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('noteTemplatesFieldsCtrl', ["$scope", "$stateParams", "patientsService", function ($scope, $stateParams, patientsService) {
        var $ctrl = this;
        $ctrl.template = {};

        $ctrl.$onInit = function () {
            patientsService.requestGetTemplate($stateParams.templateId);
        };

        $scope.$onRootScope(patientsService.fluxEvents.getTemplate.key, function (event, response) {
            $ctrl.template.fields = response.fields;
            $ctrl.template.field_ids = _.map(response.fields, function (field) {
                return field.id;
            });
        });

        $ctrl.addForm = function () {
            $ctrl.template.fields.push({});
        };

        $ctrl.removeForm = function (index) {
            $ctrl.template.fields.splice(index, 1);
        };

        $ctrl.searchFields = function (search) {
            // search for fields, disabling any that already exist in the note template
            var params = {search: search ? search : ''};
            patientsService.getNoteFields(params)
                .then(function (response) {
                    $ctrl.noteFields = response.results;
                    _.forEach($ctrl.noteFields, function (field) {
                        var fieldExistsForTemplate = _.find($ctrl.template.fields, ['id', field.id]);

                        if (fieldExistsForTemplate) {
                            field.disabled = true;
                        }
                    });
                });
        };
    }]);
