/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addRoleToGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addRoleToGroupModalCtrl', ["$scope", "groupsService", "modalOptions", function ($scope, groupsService, modalOptions) {
        var self = this;

        self.selected = [];

        $scope.reload = function () {
            groupsService.getAllRoles([], null)
                .then(function(results){
                    self.availableRoles = results;
            });
        };

        $scope.reload();

        $scope.addSelected = function () {
            groupsService.addRoles(modalOptions.groupId, _.map(self.selected, 'id'))
                .then(function () {
                    $scope.$close();
                });
        };
    }]);
