angular
  .module("smartpager.endUser.shared")
  .directive("iqDirectoryObjectSelectItem", function() {
    return {
      restrict: "E",
      require: "ngModel",
      transclude: true,
      scope: {
        object: "=",
        avatarHeight: "@",
        avatarWidth: "@",
        showStatus: "@"
      },
      controller: "iqDirectoryObjectSelectItemCtrl",
      templateUrl:
        "/smartpager/angular/endUser/shared/components/iqDirectoryObjectSearch/iqDirectoryObjectSelectItem.html"
    };
  });
