/**
 * @ngdoc service
 * @name smartpager.endUser.shared:idpService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .service('idpService', ["baseService", function (baseService) {

        var Service = Object.create(baseService);

        Service.getIdpSettings = function () {
            var url = '/api/v1/.../';
            return Service.getData(url);
        };

        Service.getIdpSettings = function (data) {
            var url = '/api/v1/.../';
            return Service.postData(url, data);
        };

        return Service;
    }]);
