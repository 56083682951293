/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:departmentDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('departmentDetailCtrl', ["$scope", "departmentId", "userService", "$state", "Notification", "modalHelper", function ($scope, departmentId, userService, $state, Notification, modalHelper) {

        var $depCtrl = this;

        $depCtrl.activate = function () {
            $depCtrl.isLoading = true;
            userService.getDepartmentById(departmentId)
                .then(function (department) {
                    $depCtrl.originalName = department.name;
                    $depCtrl.department = department;
                })
                .catch(function () {
                    $state.go('root.accountSettings.departments.list');
                    Notification.warning(String.format('Department with id `{0}` does not exist', departmentId));
                })
                .finally(function () {
                    $depCtrl.isLoading = false;
                });

            userService.getAccount()
                .then(function (response) {
                    $depCtrl.account = response;
                });
        };
        $depCtrl.activate();

        $scope.$onRootScope('departmentDetail.updated', function() {
            $depCtrl.activate();
        });

        $depCtrl.saveDepartment = function () {
            userService.updateDepartment($depCtrl.department)
                .then(function (response) {
                    Notification.success('Successfully saved ' + $depCtrl.department.name);
                    $depCtrl.department = response;
                })
                .catch(function (response) {
                    Notification.error('Could not update department');
                });
        };

        $depCtrl.deleteDepartment = function () {
            var response = modalHelper.openModal({
                title: 'Delete department',
                content: String.format('Are you sure you want to delete department {0}?', $depCtrl.department.name),
                buttons: [
                    {id: 'yes', type: 'primary', label: 'Delete department'},
                    {id: 'cancel', type: 'default', label: 'Cancel'}
                ]
            }).result
                .then(function () {
                    return userService.deleteDepartment($depCtrl.department.id);
                })
                .then(function () {
                    Notification.success(String.format('Successfully deleted department {0}', $depCtrl.department.name));
                    $state.go('root.accountSettings.departments.list');
                })
                .catch(function (response) {
                    Notification.error(String.format('Cannot delete department {0}', $depCtrl.department.name));
                });
        };
    }]);
