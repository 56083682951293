/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:tangierConfigurationCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.accountSettings")
  .factory("tangierScheduleCtrlCache", ["$cacheFactory", function($cacheFactory) {
    return $cacheFactory("tangierScheduleCtrlCache");
  }])
  .controller("tangierConfigurationCtrl", ["$scope", "$state", "tangierScheduleCtrlCache", "configId", "integrationsService", "Notification", "NgTableParams", "ngTableHelper", "modalHelper", function(
    $scope,
    $state,
    tangierScheduleCtrlCache,
    configId,
    integrationsService,
    Notification,
    NgTableParams,
    ngTableHelper,
    modalHelper
  ) {
    var $ctrl = this;

    $scope.timezones = [
      "America/Adak",
      "America/Anchorage",
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Chicago",
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Denver",
      "America/Detroit",
      "America/Edmonton",
      "America/Fort_Nelson",
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Indiana/Indianapolis",
      "America/Indiana/Knox",
      "America/Indiana/Marengo",
      "America/Indiana/Petersburg",
      "America/Indiana/Tell_City",
      "America/Indiana/Vevay",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Inuvik",
      "America/Iqaluit",
      "America/Juneau",
      "America/Kentucky/Louisville",
      "America/Kentucky/Monticello",
      "America/Los_Angeles",
      "America/New_York",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Phoenix",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Regina",
      "America/Resolute",
      "America/Rio_Branco",
      "America/Santarem",
      "America/Santo_Domingo",
      "America/Scoresbysund",
      "America/Sitka",
      "America/St_Barthelemy",
      "America/Thule",
      "America/Thunder_Bay",
      "America/Tijuana",
      "America/Toronto",
      "America/Tortola",
      "America/Vancouver",
      "America/Whitehorse",
      "America/Winnipeg",
      "America/Yakutat",
      "America/Yellowknife",
      "Canada/Atlantic",
      "Canada/Central",
      "Canada/Eastern",
      "Canada/Mountain",
      "Canada/Newfoundland",
      "Canada/Pacific",
      "GMT",
      "US/Alaska",
      "US/Arizona",
      "US/Central",
      "US/Eastern",
      "US/Hawaii",
      "US/Mountain",
      "US/Pacific",
      "UTC"
    ];

    $ctrl.$onInit = function() {
      $ctrl.isCreate = configId === "new" || configId === undefined;
      $ctrl.configuration = { fail_over_user: null };

      if (!$ctrl.isCreate) {
        $ctrl.reload(configId);
      }
    };

    $ctrl.tableParams = new NgTableParams(
      tangierScheduleCtrlCache.get("parameters") || {
        count: 10
      },
      {
        getData: function(params) {
          tangierScheduleCtrlCache.put("parameters", params.parameters());
          $ctrl.isLoading = true;

          integrationsService.cancel($ctrl.lastProvidersRequest);

          $ctrl.lastProvidersRequest = integrationsService.getTangierSchedulesForGrid(
            configId,
            params.parameters()
          );

          return $ctrl.lastProvidersRequest
            .then(function(response) {
              params.total(response.count);
              return response.results;
            })
            .catch(function() {
              return [];
            })
            .finally(function() {
              $ctrl.isLoading = false;
            });
        }
      }
    );

    $ctrl.reload = function(id) {
      integrationsService
        .getTangierConfiguration(id)
        .then(function(result) {
          $ctrl.configuration = result;
        })
        .catch(function() {
          window.location = "/accounts/integrations/";
        });
    };

    $ctrl.update = function() {
      var promise;
      if ($ctrl.isCreate) {
        promise = integrationsService.createTangierConfiguration(
          $ctrl.configuration
        );
      } else {
        promise = integrationsService.updateTangierConfiguration(
          $ctrl.configuration
        );
      }

      promise
        .then(function(result) {
          $state.go("root.accountSettings.integrations.tangier", {
            configId: result.id
          });
          Notification.success("Configuration Saved");
        })
        .catch(function() {
          Notification.error("Could not save configuration");
        });
    };

    $ctrl.delete = function() {
      console.log("In the tangier delete function");
      console.log("foobar");
      integrationsService
        .deleteTangierConfiguration($ctrl.configuration.id)
        .then(function() {
          Notification.success("Configuration deleted");
          window.location = "/accounts/integrations/";
        })
        .catch(function() {
          Notification.error("Could not delete configuration.");
        });
    };

    $ctrl.deleteMapping = function(mapping) {
      modalHelper
        .openModal({
          title: "Delete schedule mapping",
          content: String.format(
            "Are you sure you want to delete the schedule mapping {0}?",
            mapping.schedule_group_name
          ),
          buttons: [
            { id: "yes", type: "danger", label: "Delete" },
            { id: "cancel", type: "default", label: "Cancel" }
          ]
        })
        .result.then(function() {
          return integrationsService.deleteTangierScheduleConfig(
            configId,
            mapping.id
          );
        })
        .then(function() {
          Notification.success("Schedule mapping deleted");
          $ctrl.tableParams.reload();
        });
    };

    $ctrl.refreshMapping = function(mapping) {
      mapping.isRefreshed = true;
      integrationsService
        .refreshTangierSchedule(configId, mapping.id)
        .then(function() {
          Notification.success("Schedule mapping refreshed");
        })
        .catch(function(response) {
          if (response.data.error) {
            Notification.error(
              "Could not refresh schedule mapping: " + response.data.error
            );
          } else {
            Notification.error("Could not refresh schedule mapping");
          }
        })
        .finally(function() {
          mapping.isRefreshed = false;
        });
    };

    $ctrl.addSchedule = function() {
      $state.go("root.accountSettings.integrations.tangierScheduleConfig", {
        configId: configId,
        scheduleId: "new"
      });
    };

    $ctrl.forceSync = function() {
      $ctrl.forceSyncInProgress = true;
      integrationsService
        .forceSyncTangier(configId)
        .then(function() {
          Notification.success("Successfully synced with Tangier");
        })
        .catch(function(response) {
          if (response.data.error) {
            Notification.error(
              "Error syncing with Tangier: " + response.data.error
            );
          } else {
            Notification.error("Error syncing with Tangier");
          }
        })
        .finally(function() {
          $ctrl.forceSyncInProgress = false;
        });
    };
  }]);
