/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:amionScheduleConfigurationCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .factory('amionScheduleConfigurationCtrlCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('amionScheduleConfigurationCtrl');
    }])
    .controller('amionScheduleConfigurationCtrl', ["$scope", "$state", "configId", "scheduleId", "integrationsService", "Notification", "$q", "NgTableParams", "ngTableHelper", "amionScheduleConfigurationCtrlCache", function ($scope, $state, configId, scheduleId, integrationsService, Notification, $q, NgTableParams, ngTableHelper, amionScheduleConfigurationCtrlCache ) {
        var $ctrl = this;

        $ctrl.configId = configId;

        $ctrl.isCreate = scheduleId === 'new';

        $ctrl.scheduleConfig = {};

        function reloadShiftMappings(configId, scheduleId) {
            return integrationsService.getAmionShiftMappings(configId, scheduleId)
                .then(function (result) {
                    $ctrl.shiftMappings = _.keyBy(result, 'assignment_id');
                });
        }

        $ctrl.reload = function (configId, scheduleId) {
            $q.all([
                    integrationsService.getAmionScheduleConfig(configId, scheduleId)
                        .then(function (result) {
                            $ctrl.scheduleConfig = result;
                        }),

                    reloadShiftMappings(configId, scheduleId)
                ])
                .catch(function () {
                    $state.go('root.accountSettings.integrations.list');
                });
        };

        $ctrl.createScheduleMapping = function () {
            $ctrl.isSaving = true;
            integrationsService.createAmionScheduleConfig(configId, $ctrl.scheduleConfig)
                .then(function (result) {
                    Notification.success('Successfully created schedule mapping');
                    $state.go('root.accountSettings.integrations.amionScheduleConfig', {configId: configId, scheduleId: result.id});
                })
                .catch(function (result) {
                    var errorMsg = 'Could not create schedule mapping';
                    if (result.data.schedule_group_name) {
                        errorMsg = result.data.schedule_group_name.join(' ');
                    }
                    Notification.error(errorMsg);
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.tableParams = new NgTableParams(amionScheduleConfigurationCtrlCache.get('parameters') || {
                count: 10,
                sorting: {
                    division: 'asc',
                    name: 'asc'
                }
            }, {
            getData: function (params) {
                amionScheduleConfigurationCtrlCache.put('parameters', params.parameters());

                $ctrl.isLoading = true;

                integrationsService.cancel($ctrl.lastRequest);

                $ctrl.lastRequest = integrationsService.getAmionAssignmentOptionsForGrid(configId, params.parameters());

                return $ctrl.lastRequest
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.addShiftMapping = function (assignment) {
            integrationsService.createAmionShiftMapping(configId, scheduleId, assignment.id)
                .then(function () {
                    return reloadShiftMappings(configId, scheduleId);
                });
        };

        $ctrl.deleteShiftMapping = function (mapping) {
            integrationsService.deleteAmionShiftMapping(configId, scheduleId, mapping.id)
                .then(function () {
                    return reloadShiftMappings(configId, scheduleId);
                });
        };

        $ctrl.init = function () {
            if ($ctrl.isCreate) {

            } else {
                integrationsService.getAmionConfiguration(configId)
                .then(function (result) {
                    $ctrl.configuration = result;
                })
                .catch(function () {
                    $state.go('root.accountSettings.integrations.list');
                });

                $ctrl.reload(configId, scheduleId);
            }
        };

        $ctrl.init();
    }]);
