/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:dischargePatientModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('dischargePatientModalCtrl', ["$scope", "patientsService", "modalHelper", "modalOptions", "Notification", function($scope, patientsService, modalHelper, modalOptions, Notification){
        var $ctrl = this;

        $ctrl.discharge = function(){
            $ctrl.isDeleting = true;
            patientsService.dischargePatient(modalOptions.patientId)
                .then(function(response){
                    $scope.$close(response);
                })
                .catch(function(){
                    Notification.error('Could not discharge patient');
                })
                .finally(function(){
                    $ctrl.isDeleting = false;
                });
        };
}]);
