/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addTaskMappingModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('addTaskMappingCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('addTaskMappingCache');
    }])
    .controller('addTaskMappingModalCtrl', ["$scope", "Notification", "integrationsService", "modalOptions", "NgTableParams", "ngTableHelper", "addTaskMappingCache", function($scope, Notification, integrationsService, modalOptions, NgTableParams, ngTableHelper, addTaskMappingCache){
        var $ctrl = this;
        var configId = modalOptions.configId;
        var scheduleId = modalOptions.scheduleId;
        var addedTasks = modalOptions.addedTasks;

        $ctrl.$onInit = function(){
            integrationsService.getQgendaTasks(configId)
                .then(function(response){
                    $ctrl.tasks = response.results;
                });
        };

        $ctrl.inAddedTasks = function(id){
            return addedTasks.indexOf(id) !== -1;
        };

        $ctrl.addTaskTableParams = new NgTableParams(addTaskMappingCache.get('parameters') || {
                count: 10
            }, {
                getData: function(params){
                    addTaskMappingCache.put('parameters', params.parameters());
                    $ctrl.isLoading = true;
                    return integrationsService.getQgendaTasks(configId, params.parameters())
                        .then(function(response){
                            params.total(response.count);
                            return response.results;
                        })
                        .finally(function(){
                            $ctrl.isLoading = false;
                        });
                }
        });

        $ctrl.addTaskSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.addTaskTableParams, 'search')
        };

        $ctrl.addTaskMapping = function(taskId){
            $ctrl.isLoading = true;
            integrationsService.createQgendaTaskMapping(configId, scheduleId, taskId)
                .then(function(){
                    Notification.success('Successfully added task to schedule mapping');
                    addedTasks.push(taskId);
                })
                .catch(function(){
                    Notification.error('Could not add task to schedule mapping');
                })
                .finally(function(){
                    $ctrl.isLoading = false;
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.deleteShiftMapping = function(taskId){
            $ctrl.isLoading = true;
            integrationsService.deleteQgendaTaskMapping(configId, scheduleId, taskId)
                .then(function(){
                    Notification.success('Successfully removed task from schedule mapping');
                    var idx = addedTasks.indexOf(taskId);

                    if (idx > -1){
                        addedTasks.splice(idx, 1);
                    }
                })
                .catch(function(){
                    Notification.error('Could not remove task from schedule mapping');
                })
                .finally(function(){
                    $ctrl.tableParams.reload();
                    $ctrl.isLoading = false;
                });
        };
}]);
