/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:userMonitoringReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .factory('userMonitoringReportCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('userMonitoringReportCache');
    }])
    .controller('userMonitoringReportCtrl', ["$scope", "reportSlug", "userId", "reportService", "NgTableParams", "ngTableHelper", "userMonitoringReportCache", "$state", function ($scope, reportSlug, userId, reportService, NgTableParams, ngTableHelper, userMonitoringReportCache, $state) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            var params = userMonitoringReportCache.get('parameters');
            if (params) {
                params.filter.user_id = userId;
                userMonitoringReportCache.put('parameters', params);
            }
        };

        $ctrl.tableParams = new NgTableParams(userMonitoringReportCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                },
                filter: {
                    user_id: userId
                }
            }, {
            getData: function (params) {
                $ctrl.isLoading = true;

                userMonitoringReportCache.put('parameters', params.parameters());
                return reportService.runReport(reportSlug, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        $scope.username = response.metadata.username;
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.downloadCsv = function () {
            reportService.downloadReportAsCSV(reportSlug, userMonitoringReportCache.get('parameters'), 'User Monitoring Report - ' + $scope.username + '.csv');
        };
    }]);
