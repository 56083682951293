/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:noteTemplatesListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('noteTemplatesListCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('noteTemplateListCache');
    }])
    .controller('noteTemplatesListCtrl', ["$scope", "$state", "NgTableParams", "ngTableHelper", "modalHelper", "noteTemplatesListCache", "patientsService", function($scope, $state, NgTableParams, ngTableHelper, modalHelper, noteTemplatesListCache, patientsService){
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams(noteTemplatesListCache.get('parameters') || {
            sorting: {
                name: 'asc'
            }
        }, {
            defaultSort: 'asc',
            getData: function(params){
                noteTemplatesListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;

                return patientsService.getNoteTemplates(params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.openAddTemplateModal = function(){
            return modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/modals/addNoteTemplateModal.html',
                'controller': 'addNoteTemplateModalCtrl as $ctrl',
                'size': 'lg'
            }).result
                .then(function(response){
                    $state.go('root.accountSettings.noteTemplates.detail.settings', {templateId: response.id});
                })
                .catch(function () {
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.clearSearch = function(){
            $ctrl.searchParams.search(null);
        };
}]);
