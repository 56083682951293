angular.module('smartpager.endUser.messages')
    .constant('soundUrls', {
        'path': '/audio/notifications/'
    })
    .factory('soundService', ["configuration", "soundUrls", function (configuration, soundUrls) {
        var tones = configuration.getNotificationTones();
        var audio = new Audio();

        var rootPath = configuration.getStaticDir() + soundUrls.path;

        function getSoundUrl(tone) {
            if (audio && audio.canPlayType && !!audio.canPlayType('audio/ogg; codecs="vorbis"')) {
                return rootPath + tone.replace('.mp3', '.ogg');
            } else {
                return rootPath + tone;
            }
        }

        var low = new Audio(getSoundUrl(tones.low));
        var med = new Audio(getSoundUrl(tones.medium));
        var high = new Audio(getSoundUrl(tones.high));

        //let audio be garbage collected
        audio = null;

        return {
            playNewMessage: function (priority) {
                switch (priority) {
                    case 0:
                        low.play();
                        break;
                    case 1:
                        med.play();
                        break;
                    case 2:
                        high.play();
                        break;
                }

            }
        };
    }]);
