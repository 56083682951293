/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:createLocalGroupModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.messages")
  .controller("createLocalGroupModalCtrl", ["$scope", "alertsHelper", "userService", "modalOptions", function(
    $scope,
    alertsHelper,
    userService,
    modalOptions
  ) {
    var $ctrl = this;

    function init() {
      $ctrl.useDirectory = modalOptions.useDirectory;
      if (modalOptions.useDirectory) {
        $ctrl.members = modalOptions.members;
      } else {
        $ctrl.members = _.filter(modalOptions.members, function(contact) {
          return contact.type === "User";
        });
      }
    }

    alertsHelper.create(this, "alerts");

    $ctrl.createLocalGroup = function() {
      $ctrl.saving = true;

      $ctrl.group.member_ids = [];

      if ($ctrl.useDirectory) {
        $ctrl.group.member_ids = _.map($ctrl.members, "contact_id");
      } else {
        $ctrl.group.member_ids = _.map($ctrl.members, "id");
      }

      userService
        .createLocalGroup($ctrl.group)
        .then(function() {
          $scope.$close();
        })
        .catch(function() {
          $ctrl.alerts.error("Cannot create local group");
        })
        .finally(function() {
          $ctrl.saving = false;
        });
    };

    init();
  }]);
