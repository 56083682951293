/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:locationSearchCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('locationSearchCtrl', ["$scope", "patientsService", "Notification", function ($scope, patientsService, Notification) {
        var $ctrl = this;
        $ctrl.newLocation = {};

        $scope.locationSearchCtrl = {
            location: null
        };

        $ctrl.searchLocations = function (query) {
            var param = {};
            if (query) {
                param.search = query;
            }
            $ctrl.isLoading = true;
            patientsService.getLocations(param)
                .then(function (response) {
                    $ctrl.locations = response.results;
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.createLocation = function () {
            $ctrl.locationCreating = true;
            patientsService.createLocation($ctrl.newLocation)
                .then(function (response) {
                    Notification.success('Successfully created location');
                    $ctrl.locations.push(response);
                    $scope.locationSearchCtrl.location = response.id;

                    $scope.create = false;
                    $ctrl.newLocation = {};
                })
                .catch(function () {
                    Notification.error('Could not create location');
                })
                .finally(function () {
                    $ctrl.locationCreating = false;
                });
        };
    }]);
