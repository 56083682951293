/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:addPatientsNoteModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('addPatientsNoteModalCtrl', ["$scope", "patientsService", "alertsHelper", "modalOptions", function ($scope, patientsService, alertsHelper, modalOptions) {

        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.note = {
            type: 'shared',
            priority: 1,
            data : {}
        };

        $ctrl.notePriorities = {
            '0': 'Low',
            '1': 'Normal',
            '2': 'High'
        };

        $ctrl.noteType = [
            {label: 'Medical Record', value: 'record'},
            {label: 'Shared', value: 'shared'},
            {label: 'Private', value: 'private'}
        ];

        $ctrl.$onInit = function () {
            patientsService.getNoteTemplates({})
                .then(function (response) {
                    $ctrl.noteTemplates = response.results;
                });
        };

        $ctrl.selectNoteType = function (noteType) {
            $ctrl.selectedNoteType = angular.copy(noteType);
            $ctrl.note.note_template_id = noteType.id;
        };

        $ctrl.createNote = function () {

            _.forEach($ctrl.selectedNoteType.fields, function (field) {
                $ctrl.note.data[field.slug] = field.value;
            });
            $ctrl.isSaving = true;
            patientsService.createNote(modalOptions.patientId, $ctrl.note)
                .then(function () {
                    $scope.$close();
                })
                .catch(function () {
                    $ctrl.alerts.error('Could not create note');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
