/**
 * @ngdoc directive
 * @name iqGroupSelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .controller('iqGroupSelectCtrl', ["$scope", "groupsService", function ($scope, groupsService) {
        var $ctrl = this;

        $ctrl.availableGroups = [];
        $ctrl.groups = [];

        // for regular type-ahead searches
        $ctrl.searchGroups = function (query) {

            var param = {
                display_name: query
            };

            $ctrl.isLoading = true;
            groupsService.getGroups(param)
                .then(function (response) {
                    $ctrl.availableGroups = response.results;
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };
    }]);
