angular.module('smartpager.endUser.shared')
    .constant('pubNubMessageTypes', {
        // thread channel
        tch: {
            new_message: 'new_thread_message',
            startTyping: 'startTyping',
            stopTyping: 'stopTyping',
            subscriber_state_change: 'subscriber_state_change',
            new_thread_subscriber: 'new_thread_subscriber',
            message_accepted: 'message_accepted',
            message_rejected: 'message_rejected',
            message_responded: 'message_responded'
        },
        // user channel
        uc: {
            //inbox
            new_thread: 'new_thread',
            new_thread_message: 'new_thread_message',
            thread_archived: 'thread_archived',
            thread_recall: 'thread_recall',
            new_thread_subscriber: 'new_thread_subscriber'
        },

        // account channel
        ac: {
            pcm: {
                assign: 'assign',
                unassign: 'unassign',
                handoff: 'handoff',
                take: 'take'
            }
        },

        // notification channel - has only one type that is not being passed
        nc: {}
    });
