/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:departmentMembersCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('memberListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('memberListCache');
    }])
    .controller('departmentMembersCtrl', ["$scope", "memberListCache", "NgTableParams", "ngTableHelper", "departmentId", "userService", "modalHelper", "Notification", function ($scope, memberListCache, NgTableParams, ngTableHelper, departmentId, userService, modalHelper, Notification) {
        var $ctrl = this;

        $ctrl.memberTableParams = new NgTableParams(memberListCache.get('parameters') || {
                count: 10
            }, {
            getData: function (params) {
                memberListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;
                return userService.getDepartmentMembersForGrid(departmentId, params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        $ctrl.member_ids = _.map(response.results, 'id');
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.memberSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.memberTableParams, 'search')
        };

        $ctrl.removeMember = function (member) {
            userService.removeDepartmentMember(departmentId, member.id)
                .then(function () {
                    Notification.success('Successfully removed ' + member.display_name + ' from ' + $scope.$depCtrl.department.name);
                    $ctrl.member_ids = _.without($ctrl.member_ids, member.id);
                })
                .catch(function () {
                    Notification.error('Could not remove ' + member.display_name + ' from ' + $scope.$depCtrl.department.name);
                })
                .finally(function () {
                    $ctrl.memberTableParams.reload();
                });
        };


        $ctrl.openAddMemberModal = function () {
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/modals/addMemberToDepartmentModal.html',
                controller: 'addMemberToDepartmentModalCtrl as $ctrl',
                department: $scope.$depCtrl.department
            }).result.finally(function (result) {
                $ctrl.memberTableParams.reload();
            });
        };

    }]);
