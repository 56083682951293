(function () {
    'use strict';
    angular.module('smartpager.endUser.shared')
        .directive('messageRoutingConditionSelect', generateSelectDirective('messageRoutingConditionSelect', '/smartpager/angular/endUser/shared/components/'))
        .controller('messageRoutingConditionSelectCtrl', generatePaginatedSelectCtrl('messageRoutingService', 'getConditions', 'reloadMessageRoutingConditions'))
        .directive('messageRoutingConditionSetSelect', generateSelectDirective('messageRoutingConditionSetSelect', '/smartpager/angular/endUser/shared/components/'))
        .controller('messageRoutingConditionSetSelectCtrl', generatePaginatedSelectCtrl('messageRoutingService', 'getConditionSets', 'reloadMessageRoutingConditionSets'))
        .directive('messageRoutingActionSelect', generateSelectDirective('messageRoutingActionSelect', '/smartpager/angular/endUser/shared/components/'))
        .controller('messageRoutingActionSelectCtrl', generatePaginatedSelectCtrl('messageRoutingService', 'getActions', 'reloadMessageRoutingActions'))
        .directive('escalationPolicySelect', generateSelectDirective('escalationPolicySelect', '/smartpager/angular/endUser/shared/components/'))
        .controller('escalationPolicySelectCtrl', generatePaginatedSelectCtrl('escalationsService', 'getEscalationPolicies', 'reloadEscalationPolicy'))
        .directive('departmentSelect', generateSelectDirective('departmentSelect', '/smartpager/angular/endUser/shared/components/'))
        .controller('departmentSelectCtrl', generatePaginatedSelectCtrl('userService', 'getDepartments', 'reloadDepartmentSelect'));


    function generateSelectDirective(basename, templateRoot) {
        var ctrlName = basename + 'Ctrl';

        return ['$log', '$compile', '$templateRequest', function ($log, $compile, $templateRequest) {
            return {
                restrict: 'E',
                require: 'ngModel',
                transclude: true,
                bindToController: true,
                scope: {
                    label: '@',
                    hideLabel: '@',
                    placeholder: '@',
                    propertyToMatch: '@',
                    allowClear: '@',
                    onSelect: '&',
                    tagging: '&',
                    horizontal: '@'
                },
                controller: ctrlName + ' as $ctrl',
                templateUrl: function (tElement, tAttrs) {
                    if (tAttrs.multiple && tAttrs.multiple === 'true') {
                        return templateRoot + basename + '/' + basename + '-multiple.html';
                    } else {
                        return templateRoot + basename + '/' + basename + '-single.html';
                    }
                },
                link: function (scope, iElement, iAttrs, ngModelCtrl) {
                    //region handle ngModelCtrl

                    ngModelCtrl.$formatters.push(function (modelValue) {
                        return angular.copy(modelValue);
                    });

                    ngModelCtrl.$parsers.push(function (viewValue) {
                        return angular.copy(viewValue);
                    });

                    scope.$watchCollection('$ctrl.items', function (newItems) {
                        $log.debug(ctrlName + ' updates ngModel binding', newItems);
                        ngModelCtrl.$setViewValue(angular.copy(newItems));
                    });

                    ngModelCtrl.$render = function () {
                        if (iAttrs.multiple) {
                            scope.$ctrl.items = ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue : [];
                        } else {
                            scope.$ctrl.items = ngModelCtrl.$viewValue;
                        }
                    };

                    if (iAttrs.required) {
                        ngModelCtrl.$validators.required = function (modelValue, viewValue) {
                            if (iAttrs.multiple && iAttrs.multiple === 'true') {
                                return !!(modelValue && modelValue.length !== 0);
                            } else {
                                return !!modelValue;
                            }
                        };
                    }
                    //endregion
                }
            };
        }];
    }

    function generateSelectCtrl(serviceName, methodName, reloadOnEventName) {
        return ['$injector', 'baseService', '$scope', function ($injector, baseService, $scope) {
            var $ctrl = this;

            $ctrl.availableItems = [];
            $ctrl.items = [];

            var service = $injector.get(serviceName);

            // for regular type-ahead searches
            $ctrl.init = function (query) {
                $ctrl.isLoading = true;
                service[methodName]()
                    .then(function (response) {
                        $ctrl.availableItems = response;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            };
            $ctrl.init();

            $scope.$onRootScope(reloadOnEventName, function () {
                $ctrl.init();
            });
        }];
    }

    function generatePaginatedSelectCtrl(serviceName, methodName, reloadOnEventName) {
        return ['$injector', 'baseService', '$scope', function ($injector, baseService, $scope) {
            var $ctrl = this;

            $ctrl.availableItems = [];
            $ctrl.items = [];

            $ctrl.count = 0;
            var lastQuery = '';

            var service = $injector.get(serviceName);

            // for regular type-ahead searches
            $ctrl.search = function (query) {
                if (query !== lastQuery) {
                    $ctrl.availableItems = [];
                }

                var param = {
                    search: query,
                    page: baseService.pickPage($ctrl.availableItems.length, 25)
                };

                $ctrl.isLoading = true;

                service[methodName](param)
                    .then(function (response) {
                        $ctrl.count = response.count;
                        //order of concatenation is important, as uniqBy will keep first element with id it encounteres, we want to keep freshly fetched item not stale one
                        $ctrl.availableItems = _.uniqBy(response.results.concat($ctrl.availableItems), 'id');
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            };

            $scope.$onRootScope(reloadOnEventName, function () {
                $ctrl.search(lastQuery);
            });
        }];
    }
}());
