/**
 * @ngdoc service
 * @name smartpager.endUser.shared:encountersService
 *
 * A place to put menerva data access
 * */
angular
    .module("smartpager.endUser.shared")
    .service("encountersService", ["$rootScope", "baseService", "modalHelper", "configuration", function(
        $rootScope,
        baseService,
        modalHelper,
        configuration
    ) {
        var Service = Object.create(baseService);

        Service.getEncounters = function(params) {
            var url = "/api/patients/encounters/" + baseService.toUrl(params);
            return this.getData(url);
        };

        return Service;
    }]);
