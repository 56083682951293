angular.module('smartpager.endUser.shared')
    .factory('rRuleService', ["baseService", function (baseService) {

        var Service = Object.create(baseService);

        Service.getRuleById = function (ruleId) {
            var url = String.format('/api/v1/rrules/{0}', ruleId);
            return this.getData(url);
        };

        Service.create = function (rule) {
            var url = '/api/v1/rrules/';

            return this.postData(url, {
                rrule: rule.rrule,
                frequency: rule.options.frequency.toUpperCase() || 'DAILY',
                interval: rule.options.interval || 1,
                week_days: rule.options.weekDays,
                occurrenceCount: rule.options.occurrenceCount
            });
        };

        return Service;
    }]);
