angular.module('smartpager.endUser.reports')
.controller('deviceBreakdownReportCtrl', ["$scope", "reportSlug", "departmentIds", "reportService", function($scope, reportSlug, departmentIds, reportService){
    var $ctrl = this;

    departmentIds = departmentIds || '';

    $ctrl.searchParams = {
        department_ids: departmentIds
    };

    $ctrl.reload = function (){
        $ctrl.isLoading = true;
        reportService.runReport(reportSlug, $ctrl.searchParams)
            .then(function(response){
                $ctrl.isEmptyReport = !response;
                $ctrl.options = reportService.getDeviceBreakdownChartData(response);
            });
    };
    $ctrl.reload();
    $scope.$watch('$ctrl.searchParams.department_ids', $ctrl.reload, true);
}]);
