/**
 * @ngdoc service
 * @name smartpager.endUser.messages:subscriberStatusTextFilter
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.messages')
    .filter('subscriberStatusText', function () {
        return function (input) {
            switch (input) {
                case 'pending':
                    return 'Sent';
                default:
                    //capitalize first letter
                    return input.substr(0, 1).toUpperCase() + input.substr(1);
            }
        };
    });
