/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addDirectoryAccessToDepartmentModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.accountSettings")
  .controller("addDirectoryAccessToDepartmentModalCtrl", ["$scope", "directoryService", "modalOptions", "Notification", function(
    $scope,
    directoryService,
    modalOptions,
    Notification
  ) {
    var self = this;

    self.selected = [];
    $scope.department = modalOptions.department.id;
    console.log("addDirectoryAccessToDepartmentModalCtrl");

    $scope.addSelected = function() {
      console.log(self.selected);
      directoryService
        .addDirectoryAccess(
          "users.department",
          modalOptions.department.id,
          self.selected
        )
        .then(function(response) {
          var addedSfs = _.map(response, "name").join(", ");
          Notification.success(
            "Successfully added " + addedSfs + " to the department."
          );
          $scope.$close();
        });
    };
  }]);
