/**
 * @ngdoc directive
 * @name iqUserSelect
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .directive('iqPatientSelect', ["$log", "$compile", "$templateRequest", function ($log, $compile, $templateRequest) {
        return {
            restrict: 'E',
            require: 'ngModel',
            transclude: true,
            bindToController: true,
            scope: {
                label: '@',
                propertyToMatch: '@',
                onSelect: '&'
            },
            controller: 'iqPatientSelectCtrl as $ctrl',
            templateUrl: function (tElement, tAttrs) {
                if (tAttrs.multiple && tAttrs.multiple === 'true') {
                    return '/smartpager/angular/endUser/shared/components/iqPatientSelect/iqPatientSelect-multiple.html';
                } else {
                    return '/smartpager/angular/endUser/shared/components/iqPatientSelect/iqPatientSelect-single.html';
                }
            },
            link: function (scope, iElement, iAttrs, ngModelCtrl) {
                //region handle ngModelCtrl

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return angular.copy(modelValue);
                });

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return angular.copy(viewValue);
                });

                scope.$watchCollection('$ctrl.patients', function (newPatients) {
                    $log.debug('iqPatientSelectCtrl updates ngModel binding', newPatients);
                    ngModelCtrl.$setViewValue(angular.copy(newPatients));
                });

                ngModelCtrl.$render = function () {
                    scope.$ctrl.patients = ngModelCtrl.$viewValue;
                };

                if (iAttrs.required) {
                    ngModelCtrl.$validators.required = function (modelValue, viewValue) {
                        if(iAttrs.multiple && iAttrs.multiple === 'true') {
                            return !!(modelValue && modelValue.length !== 0);
                        } else {
                            return !!modelValue;
                        }
                    };
                    scope.$ctrl.required = iAttrs.required;
                }
                //endregion
            }
        };
    }]);
