/**
 * @ngdoc controller
 * @name smartpager.endUser.shared:iqDirectoryObjectSelectItemCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.shared")
  .controller("iqDirectoryObjectSelectItemCtrl", ["$scope", "contentTypes", "pagingGroupService", function(
    $scope,
    contentTypes,
    pagingGroupService
  ) {
    var self = this;
    var obj = $scope.object;
    $scope.ctrl = self;

    // To be shown under the directory object's title
    self.isLoading = false;

    function init() {
      if ($scope.object) {
        if (
          obj.type === contentTypes.directories.group &&
          obj.content_type_string === contentTypes.pagingGroups.schedule
        ) {
          // If the object is a schedule group, fetch the on call user
          self.isLoading = true;
          var groupId = obj.object_id;
          pagingGroupService
            .getOnCall(groupId)
            .then(function(result) {
              switch (result.onCallUsers.length) {
                case 0:
                  obj.meta = "(Nobody on Call)";
                  break;
                case 1:
                  obj.meta = String.format(
                    "({0})",
                    result.onCallUsers[0].display_name
                  );
                  break;
                case 2:
                  obj.meta = String.format(
                    "({0} and one other)",
                    result.onCallUsers[0].display_name
                  );
                  break;
                default:
                  obj.meta = String.format(
                    "({0} and {1} others)",
                    result.onCallUsers[0].display_name,
                    result.onCallUsers.length - 1
                  );
                  break;
              }
              self.isLoading = false;
            })
            .finally(function() {
              self.isLoading = false;
            });
        } else if (obj.type === contentTypes.directories.person) {
          // If the object is a person, display their title
          obj.meta = obj.properties ? obj.properties.title : "";
        }
      }
    }

    init();
  }]);
