/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:broadcastCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('broadcastCtrl', ["$scope", "modalHelper", "Notification", "broadcastService", function($scope, modalHelper, Notification, broadcastService){
        var $ctrl = this;

        $ctrl.$onInit = function(){
            // get types
            $ctrl.types = [{
                'label': 'SMS',
                'value': 0
            }];
            $ctrl.broadcast = {
                type: 0,
                exclude_installed: false
            };
        };

        $ctrl.openConfirmSendModal = function(){
            modalHelper.openModal({
                title: 'Confirm Broadcast Message',
                content: String.format('Are you sure you want to send this message?'),
                buttons: [{id: 'yes', label: 'Send', type: 'primary'}, {id: 'cancel', label: 'Cancel', type: 'default'}]
            }).result
                .then(function(){
                    sendMessage();
                });
        };

        function sendMessage(){
            $ctrl.isSending = true;

            broadcastService.createBroadcastMessage($ctrl.broadcast)
                .then(function(response){
                    return broadcastService.sendBroadcastMessage(response.id);
                })
                .then(function(){
                    Notification.success('Successfully sent broadcast message');
                })
                .catch(function(response){
                    var msg = 'Could not send broadcast message';
                    if (response.error){
                        msg += String.format(': {0}', response.error);
                    }
                    Notification.error(msg);
                })
                .finally(function(){
                    $ctrl.isSending = false;
                });
        }

}]);
