/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:patientThreadsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('patientThreadsCtrl', ["$scope", "$log", "patientId", "baseService", "patientsService", function ($scope, $log, patientId, baseService, patientsService) {

        var $ctrl = this;

        $ctrl.threads = [];
        $ctrl.count = 0;

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;
            patientsService.getPatientThreads(patientId, baseService.prepareParams($ctrl.threads))
                .then(function (response) {
                    $ctrl.count = response.count;
                    $ctrl.threads = _.uniqBy($ctrl.threads.concat(response.results), 'id');
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.loadPage = function () {
            $log.debug('checking if should loadMore');
            if ($ctrl.count > $ctrl.threads.length) {
                $log.debug('load page');
                $ctrl.$onInit();
            }
        };
    }]);
