/**
 * @ngdoc directive
 * @name iqContactSearch
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .directive('iqContactSearch', ["$log", "$compile", "$templateRequest", function ($log, $compile, $templateRequest) {
        return {
            restrict: 'E',
            require: 'ngModel',
            transclude: true,
            scope: {
                label: '@',
                showAdvSearch: '=?',
                showFavorites: '=?',
                showGroupLookup: '=?',
                propertyToMatch: '@',
                onSelect: '&',
                searchUsers: '=?',
                includeSelf: '=?',
                account: '=?',
                linkedContacts: '=?',
                contactType: '@'
            },
            controller: 'iqContactSearchCtrl',
            templateUrl: function (tElement, tAttrs) {
                if (tAttrs.multiple && tAttrs.multiple === 'false') {
                    return '/smartpager/angular/endUser/shared/components/iqContactSearch/iqContactSearch-single.html';
                } else {
                    return '/smartpager/angular/endUser/shared/components/iqContactSearch/iqContactSearch-multiple.html';
                }
            },
            link: function (scope, iElement, iAttrs, ngModelCtrl) {

                //default values
                scope.showAdvSearch = typeof (scope.showAdvSearch) !== 'undefined' ? scope.showAdvSearch : true;
                scope.showGroupLookup = typeof (scope.showGroupLookup) !== 'undefined' ? scope.showGroupLookup : false;
                scope.searchUsers = typeof (scope.searchUsers) !== 'undefined' ? scope.searchUsers : false;
                scope.includeSelf = typeof (scope.includeSelf) !== 'undefined' ? scope.includeSelf : false;
                scope.showFavorites = typeof (scope.showFavorites) !== 'undefined' ? scope.showFavorites : false;

                //region handle ngModelCtrl

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return angular.copy(modelValue);
                });

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return angular.copy(viewValue);
                });

                scope.$watchCollection('iqContactSearchCtrl.recipients', function (newRecipents) {
                    $log.debug('iqContactSearchCtrl updates ngModel binding', newRecipents);
                    ngModelCtrl.$setViewValue(angular.copy(newRecipents));
                });

                ngModelCtrl.$render = function () {
                    scope.iqContactSearchCtrl.recipients = ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue : iAttrs.multiple && iAttrs.multiple === 'false' ? null : [];
                };

                if (iAttrs.required) {
                    ngModelCtrl.$validators.required = function (modelValue, viewValue) {
                        return modelValue && modelValue.length !== 0;
                    };
                }
                //endregion
            }
        };
    }]);
