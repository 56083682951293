angular
  .module("smartpager.endUser.patients", ["smartpager.endUser.shared"])
  .config(["$stateProvider", "$urlRouterProvider", function($stateProvider, $urlRouterProvider) {
    //root state of application
    $stateProvider
      .state("root.patients", {
        url: "patient",
        template: "<div ui-view></div>",
        abstract: true,
        data: {
          title: "Telmediq - Patients",
          requiredPermissions: [],
          pageClass: "patients"
        }
      })
      .state("root.patients.assignments", {
        url: "/assignments",
        templateUrl:
          "/smartpager/angular/endUser/patients/controllers/assignments.html",
        controller: "assignmentsCtrl as $ctrl"
      })
      .state("root.patients.detail", {
        url: "/:patientId?thread_id",
        templateUrl:
          "/smartpager/angular/endUser/patients/controllers/patientDetail.html",
        controller: "patientDetailCtrl as $ctrl",
        abstract: true,
        resolve: {
          patientId: ["$stateParams", function($stateParams) {
            return $stateParams.patientId;
          }]
        }
      })
      .state("root.patients.detail.updates", {
        url: "",
        templateUrl:
          "/smartpager/angular/endUser/patients/controllers/patientDetailUpdates.html",
        controller: "patientDetailUpdatesCtrl as $ctrl"
      })
      .state("root.patients.detail.notes", {
        url: "/notes",
        templateUrl:
          "/smartpager/angular/endUser/patients/controllers/patientDetailNotes.html",
        controller: "patientDetailNotesCtrl as $ctrl"
      })
      .state("root.patients.detail.messages", {
        url: "/messages",
        templateUrl:
          "/smartpager/angular/endUser/patients/controllers/patientThreads.html",
        controller: "patientThreadsCtrl as $ctrl"
      });
  }]);
