/**
 * @ngdoc directive
 * @name iqMessageTypeSelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .controller('iqMessageTypeSelectCtrl', ["$scope", "messageService", function ($scope, messageService) {
        var $ctrl = this;

        $ctrl.availableItems = [];
        $ctrl.items = [];

        // for regular type-ahead searches
        $ctrl.searchMessageType = function (query) {

            var param = {
                name: query
            };
            $ctrl.isLoading = true;
            messageService.getMessageTypes()
                .then(function (response) {
                    $ctrl.availableItems = response.results;
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };
    }]);
