angular.module('smartpager.endUser.messageRouting', [
    'smartpager.endUser.shared'
])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        //root state of application
        $stateProvider
            .state('root.messageRouting', {
                abstract: true,
                url: 'messageRouting',
                templateUrl: '/smartpager/angular/endUser/messageRouting/messageRouting.html'
            })
            .state('root.messageRouting.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/messageRoutingList.html',
                controller: 'messageRoutingListCtrl as $ctrl'
            })
            .state('root.messageRouting.detail', {
                abstract: true,
                url: '/{policyId:int}',
                controller: 'messageRoutingDetailCtrl as $policyCtrl',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/messageRoutingDetail.html',
                resolve: {
                    policyId: ["$stateParams", function ($stateParams) {
                        return $stateParams.policyId;
                    }]
                }
            })
            .state('root.messageRouting.detail.conditionSets', {
                url: '',
                controller: 'messageRoutingConditionSetsCtrl as $ctrl',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/messageRoutingConditionSets.html',
                resolve: {
                    policyId: ["$stateParams", function ($stateParams) {
                        return $stateParams.policyId;
                    }]
                }
            })
            .state('root.messageRouting.detail.conditions', {
                url: '/conditions',
                controller: 'messageRoutingConditionsCtrl as $ctrl',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/messageRoutingConditions.html',
                resolve: {
                    policyId: ["$stateParams", function ($stateParams) {
                        return $stateParams.policyId;
                    }]
                }
            })
            .state('root.messageRouting.detail.actions', {
                url: '/actions',
                controller: 'messageRoutingActionsCtrl as $ctrl',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/messageRoutingActions.html',
                resolve: {
                    policyId: ["$stateParams", function ($stateParams) {
                        return $stateParams.policyId;
                    }]
                }
            })
            .state('root.messageRouting.conditionSetList', {
                url: '/conditionSetList',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/conditionSetList.html',
                controller: 'conditionSetListCtrl as $ctrl'
            })
            .state('root.messageRouting.conditionList', {
                url: '/conditionsList',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/conditionList.html',
                controller: 'conditionListCtrl as $ctrl'
            })
            .state('root.messageRouting.actionList', {
                url: '/actionList',
                templateUrl: '/smartpager/angular/endUser/messageRouting/controllers/actionList.html',
                controller: 'actionListCtrl as $ctrl'
            });
    }]);
