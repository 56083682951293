/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupPermissionsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('groupPermissionsCtrl', ["$scope", "groupsService", "$stateParams", "$q", function ($scope, groupsService, $stateParams, $q) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            var rolesPromise = groupsService.getGroupRoles($stateParams.groupId)
                .then(function (response) {
                    return response.results;
                });
            var permissionsPromise = groupsService.getPermissionList();
            reloadPermissions(rolesPromise, permissionsPromise);
        };

        $ctrl.showAllPermissions = false;

        $ctrl.toggleShowAllPermissions = function () {
            $ctrl.showAllPermissions = !$ctrl.showAllPermissions;
        };

        // when true return truthy, when false return all
        $scope.comparator = function (actual, showAllPermissions) {
            return showAllPermissions ? true : !!actual;
        };

        function reloadPermissions(rolesPromise, permissionPromise) {
            $ctrl.isLoading = true;
            $q.all([rolesPromise, permissionPromise])
                .then(function (result) {

                    var permissions = angular.copy(result[1]);
                    var roles = _.map(result[0], function (role) {
                        role.role.permissions = _.filter(permissions, function (permission) {
                            return role.role.permission_ids.indexOf(permission.id) !== -1;
                        });
                        return role;
                    });

                    var permissionMap = _.keyBy(permissions, function (object) {
                        return object.app_name + object.codename;
                    });

                    _.forEach(_.sortBy(roles, 'name'), function (role) {
                        _.forEach(role.role.permissions, function (permission) {
                            var tmp = permissionMap[permission.app_name + permission.codename];
                            tmp.from = (tmp.from ? tmp.from.concat([role.role.name]) : [role.role.name]);
                        });
                    });

                    $ctrl.permissions = _.values(permissionMap);
                    $ctrl.isLoading = false;
                });
        }
    }]);
