/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:roleListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('roleListCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('roleListCache');
    }])
    .controller('roleListCtrl', ["$scope", "$state", "ngTableHelper", "NgTableParams", "roleListCache", "modalHelper", "roleService", "configuration", function($scope, $state, ngTableHelper, NgTableParams, roleListCache, modalHelper, roleService, configuration){
        var $ctrl = this;
        $ctrl.tableParams = new NgTableParams(roleListCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function(params){
                roleListCache.put('parameters', params.parameters());

                $ctrl.isLoading = true;
                return roleService.getRolesForAccount(configuration.getAccountId(), params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.openCreateRoleModal = function(){
            modalHelper.openModal({
                'templateUrl': '/smartpager/angular/endUser/accountSettings/controllers/roles/modals/createRoleModal.html',
                'controller': 'createRoleModalCtrl as $ctrl'
            }).result
                .then(function(id){
                    if (id){
                        $state.go('root.accountSettings.roles.detail', {roleId: id});
                    }
                    $ctrl.tableParams.reload();
                });
        };
}]);
