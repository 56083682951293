/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:noteFieldDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('noteFieldsDetailCtrl', ["$scope", "$state", "fieldId", "patientsService", "Notification", "modalHelper", function ($scope, $state, fieldId, patientsService, Notification, modalHelper) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;
            patientsService.getNoteField(fieldId)
                .then(function (response) {
                    $ctrl.field = response;
                })
                .catch(function (response) {
                    Notification.error('Could not load note field');
                    $state.go('root.accountSettings.noteFields.list');
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.save = function () {
            if ($scope.fieldForm.$invalid || $scope.fieldForm.$pristine) {
                $scope.fieldForm.$setSubmitted();
                return;
            }
            $ctrl.isLoading = true;
            patientsService.updateNoteField($ctrl.field)
                .then(function (response) {
                    Notification.success('Note field updated successfully');
                })
                .catch(function (response) {
                    Notification.error('Could not update note field');
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.openDeleteFieldModal = function () {
            return modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/modals/deleteFieldModal.html',
                controller: 'deleteFieldModalCtrl as $ctrl',
                backdrop: 'static',
                field: $ctrl.field
            }).result
                .then(function () {
                    $state.go('root.accountSettings.noteFields.list');
                });
        };
    }]);
