/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:msgVolumeOverTimeCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .controller('msgVolumeOverTimeCtrl', ["$scope", "reportSlug", "reportService", function ($scope, reportSlug, reportService) {
        var $ctrl = this;

        $ctrl.dateRangePickerOptions = reportService.getDateRangePickerOptions();
        $ctrl.searchParams = {
            filter: {
                start: moment().startOf('month').toDate(),
                end: moment().endOf('month').toDate()
            }
        };

        $ctrl.reload = function () {
            $ctrl.isLoading = true;
            reportService.runReport(reportSlug, $ctrl.searchParams)
                .then(function (response) {
                    $ctrl.isEmptyReport = !response.length;
                    $ctrl.options = reportService.getMessageVolumeOverTimeChartConfig(response);
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };
        $ctrl.reload();

        $scope.$watch('$ctrl.searchParams.filter', $ctrl.reload, true);

        $ctrl.downloadCsv = function () {
            reportService.downloadReportAsCSV(reportSlug, $ctrl.searchParams, 'Message Volume Over Time Report.csv');
        };
    }]);
