/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:addActionModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .controller('addActionModalCtrl', ["$scope", "messageRoutingService", "Notification", "modalOptions", "alertsHelper", "eventsBus", function ($scope, messageRoutingService, Notification, modalOptions, alertsHelper, eventsBus) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;

            messageRoutingService.getActionTypes()
                .then(function (results) {
                    $ctrl.available_action_types = results;
                });

            if (modalOptions.action) {
                $ctrl.isEdit = true;

                messageRoutingService.getActionById(modalOptions.action.url_prefix, modalOptions.action.id)
                    .then(function (response) {
                        $ctrl.action = response;
                        $ctrl.action_type = $ctrl.action.type;
                    });
            }

            $ctrl.title = $ctrl.isEdit ? 'Edit Action' : 'Create Action';
        };

        $ctrl.resetAction = function () {
            $ctrl.action = {};
        };

        $ctrl.update = function (action_type, action) {
            $ctrl.isSaving = true;
            return messageRoutingService.updateAction(action)
                .then(function (response) {
                    $scope.$close(response);
                })
                .catch(function () {

                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.save = function (action_type, action) {
            if(!$ctrl.form.$valid){
                $ctrl.form.$setSubmitted();
                return;
            }

            $ctrl.isSaving = true;
            return messageRoutingService.createAction(action_type, action)
                .then(function (response) {
                    $scope.$close(response);
                })
                .catch(function (response) {
                    $ctrl.serverErrors = response.data;
                    eventsBus.publish('serverError', $ctrl.serverErrors);
                    $ctrl.alerts.error('Could not create routing policy');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
