/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:iqThreadSearchCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('iqThreadSearchCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "$log", "inboxEvents", "$timeout", "permissionsService", function ($scope, $rootScope, $state, $stateParams, $log, inboxEvents, $timeout, permissionsService) {

        $scope.iqThreadSearchCtrl = {};
        $scope.hasFeature = permissionsService.hasFeature;

        var self = this;

        self.searchHelper = {
            newFilter: function () {
                return {
                    tmp: {
                        within: '1',
                        of: ''
                    },
                    to: []
                };
            },
            filterToQueryObj: function (filter) {
                var tmp = angular.copy(filter);

                if (tmp.tmp.of) {
                    tmp.after = moment(tmp.tmp.of).startOf('day').add(-tmp.tmp.within, 'days').toISOString();
                    tmp.before = moment(tmp.tmp.of).endOf('day').add(tmp.tmp.within, 'days').toISOString();
                }
                delete tmp.tmp;

                tmp.department = tmp.department ? tmp.department.id : undefined;

                tmp.patient_id = tmp.patient ? tmp.patient.id : undefined;
                delete tmp.patient;

                tmp.sender = tmp.sender ? tmp.sender.id : undefined;
                tmp.to = _.map(tmp.to, 'id').join(',');

                return tmp;
            }
        };

        $scope.filter = self.searchHelper.newFilter();
        $scope.searchQuery = '';

        $scope.findHint = function ($event) {
            //need a timeout to get current value of search query
            $timeout(function () {
                if ($scope.searchQuery) {
                    $scope.filter.content = $scope.searchQuery;
                }
            });
        };

        //-------------------

        $scope.searchButton = function () {
            $scope.iqThreadSearchCtrl.showAdvancedSearch = false;

            $scope.searchQuery = $scope.filter.content;

            var filter = self.searchHelper.filterToQueryObj($scope.filter);
            $rootScope.$emit(inboxEvents.threadSearch, filter);
        };

        $scope.searchEnter = function () {
            $scope.iqThreadSearchCtrl.showAdvancedSearch = false;

            var filter = self.searchHelper.filterToQueryObj($scope.filter);
            $rootScope.$emit(inboxEvents.threadSearch, filter);
        };

        $scope.clear = function (what) {
            switch (what) {

                case 'subject':
                case 'content':
                case 'from':
                case 'patient':
                case 'department':
                    $scope.filter[what] = undefined;
                    break;
                case 'of':
                    $scope.filter.tmp.of = undefined;
                    break;
                case 'to':
                    $scope.filter.to = [];
                    break;
                default:
                    $scope.filter = self.searchHelper.newFilter();
                    break;
            }

            $scope.searchQuery = '';
            var filter = self.searchHelper.filterToQueryObj($scope.filter);
            $rootScope.$emit(inboxEvents.threadSearch, filter);
        };

    }]);
