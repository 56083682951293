/**
 * @ngdoc directive
 * @name iqUserSelect
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .directive('iqSavedFilterSelect', ["$log", "$compile", "$templateRequest", function ($log, $compile, $templateRequest) {
        return {
            restrict: 'E',
            require: 'ngModel',
            transclude: true,
            scope: {
                label: '@',
                ct: '@',
                obj: '@',
                hideLabel: '=',
                propertyToMatch: '@',
                onSelect: '&'
            },
            controller: 'iqSavedFilterSelectCtrl',
            templateUrl: function (tElement, tAttrs) {
                if (tAttrs.multiple && tAttrs.multiple === 'false') {
                    return '/smartpager/angular/endUser/shared/components/iqSavedFilterSelect/iqSavedFilterSelect-single.html';
                } else {
                    return '/smartpager/angular/endUser/shared/components/iqSavedFilterSelect/iqSavedFilterSelect-multiple.html';
                }
            },
            link: function (scope, iElement, iAttrs, ngModelCtrl) {
                //region handle ngModelCtrl

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return angular.copy(modelValue);
                });

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return angular.copy(viewValue);
                });

                scope.$watchCollection('iqSavedFilterSelectCtrl.savedFilters', function (newSavedFilters) {
                    console.log('iqSavedFilterSelectCtrl updates ngModel binding', newSavedFilters);
                    ngModelCtrl.$setViewValue(angular.copy(newSavedFilters));
                });

                ngModelCtrl.$render = function () {
                    scope.iqSavedFilterSelectCtrl.savedFilters = ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue: [];
                };

                if (iAttrs.required) {
                    ngModelCtrl.$validators.required = function (modelValue, viewValue) {
                        if(iAttrs.multiple && iAttrs.multiple === 'false') {
                            return !!modelValue;
                        } else {
                            return !!(modelValue && modelValue.length !== 0);
                        }
                    };
                }
                //endregion
            }
        };
    }]);
