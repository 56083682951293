/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:messageRoutingDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .controller('messageRoutingDetailCtrl', ["$scope", "$state", "policyId", "messageRoutingService", "Notification", "modalHelper", function ($scope, $state, policyId, messageRoutingService, Notification, modalHelper) {
        var $policyCtrl = this;

        $policyCtrl.$onInit = function () {
            messageRoutingService.requestGetRoutingPolicy(policyId);
        };

        $scope.$onRootScope(messageRoutingService.fluxEvents.getRoutingPolicy.key, function (event, response) {
            $policyCtrl.policy = response;
        });

        $policyCtrl.updatePolicy = function (updatedFields) {
            $policyCtrl.isLoading = true;
            updatedFields.id = $policyCtrl.policy.id;
            var policy = {};
            _.assign(policy, $policyCtrl.policy, updatedFields);
            messageRoutingService.updateRoutingPolicy(policy)
                .then(function (response) {
                    Notification.success('Successfully updated ' + response.name);
                    messageRoutingService.requestGetRoutingPolicy(policyId);
                })
                .catch(function (response) {
                    Notification.error('Could not update routing policy');
                })
                .finally(function () {
                    $policyCtrl.isLoading = false;
                });
        };

        $policyCtrl.deletePolicy = function (policy) {
            messageRoutingService.deleteRoutingPolicy(policy.id)
                .then(function () {
                    $state.go('root.messageRouting.list');
                })
                .catch(function() {
                    Notification.error('Routing policies can only be delete if Routing Policy has not been used. Once it has been used it can only be deactivated');
                });
        };
    }]);
