/**
 * @ngdoc service
 * @name smartpager.endUser.shared:reportService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .factory('reportService', ["baseService", function (baseService) {
        var Service = new Object(baseService);

        Service.getReports = function () {
            var url = String.format('/api/v1/reports/');
            return this.getData(url);
        };

        Service.runReport = function (slug, params) {
            var url = String.format('/api/v1/reports/{0}/{1}', slug, this.toUrl(params || {}));
            return this.getData(url);
        };

        Service.downloadReportAsCSV = function (slug, params, filename) {
            Service.runReport(slug, _.merge({format: 'csv'}, params))
                .then(function (response) {

                    var file = new Blob([response], {type: 'text/plain;charset=utf-8'});

                    /* global saveAs */
                    saveAs(file, filename);

                });
        };

        Service.getDateRangePickerOptions = function () {
            return angular.copy({
                autoApply: true,
                ranges: {
                    'Clear': [moment('2000-01-01'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Last 3 Months': [moment().subtract(2, 'month').startOf('month'), moment().endOf('month')],
                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
                },
                opens: 'right'
            });
        };

        function getDefaultOptions() {
            return {
                credits: {
                    enabled: false
                },
                chart: {},
                title: {
                    style: {
                        display: 'none'
                    }
                }
            };
        }

        Service.getRolloutReportChartConfig = function (series) {
            series = _.map(series, function (serie) {
                serie.data = _.map(serie.data, function (point) {
                    return point[1];
                });
                serie.pointWidth = 5;
                serie.pointPadding = 1;
                return serie;
            });

            var stackedArea = _.merge(getDefaultOptions(), {
                chart: {
                    type: 'bar',
                    height: series.length * 100
                },
                xAxis: {
                    categories: ['5', '10', '15', '30', '60', '90', '180'],
                    title: {
                        text: 'Days'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Messages sent'
                    },
                    allowDecimals: false
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 1,
                        marker: {
                            enabled: false

                        }
                    }
                }
            });
            stackedArea.series = _.sortBy(series, 'name');

            return stackedArea;
        };

        Service.getMessageVolumeOverTimeChartConfig = function (series) {
            var lineChart = _.merge(getDefaultOptions(), {
                chart: {
                    type: 'line'
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    x: 0,
                    y: 25
                },
                xAxis: {
                    type: 'datetime',
                    title: {
                        text: 'Date'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Volume'
                    }
                },
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormat: '<b>Date</b>: {point.x:%e. %b %Y}<br /><b>Messages</b>: {point.y:.0f}'
                }
            });

            var stackedArea = _.merge(getDefaultOptions(), {
                chart: {
                    type: 'area'
                },
                xAxis: {
                    type: 'datetime',
                    tickmarkPlacement: 'on',
                    title: {
                        enabled: false
                    }
                },
                yAxis: {
                    title: {
                        text: 'Volume'
                    },
                    allowDecimals: false
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 1,
                        marker: {
                            enabled: false

                        }
                    }
                }
            });

            stackedArea.series = _.sortBy(series, 'name');

            return stackedArea;
        };

        Service.getThreadSubscriberChartConfig = function(subscriberSummary){
            var options = _.merge(getDefaultOptions(), {
                legend: {
                    enabled: true,
                    layout: 'vertical',
                    labelFormatter: function () {
                        return String.format('{0}: {1}', this.name, this.y);
                    }
                },
                chart: {
                    type: 'pie',
                    animation: false
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}</b>'
                },
                plotOptions: {
                    pie: {
                        colors: [
                            '#357EDD',
                            '#FF725C',
                            '#19A974'
                        ],
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    },
                    series: {animation: false}
                },
                series: [{
                    name: '# of Subscribers',
                    colorByPoint: true,
                    data: [{
                        name: 'Delivered',
                        y: subscriberSummary.delivered
                    }, {
                        name: 'Sent',
                        y: subscriberSummary.pending
                    }, {
                        name: 'Read',
                        y: subscriberSummary.read
                    }]
                }]
            });
            return options;
        };
        Service.getDeviceBreakdownChartData = function(results){
            var options = _.merge(getDefaultOptions(), {
                legend: {
                    enabled: true,
                    layout: 'vertical',
                    labelFormatter: function () {
                        return String.format('{0}: {1}', this.name, this.y);
                    }
                },
                chart: {
                    type: 'pie',
                    animation: true
                },
                tooltip: {
                    pointFormat: 'Number of devices: <b>{point.y}</b>'
                },
                plotOptions: {
                    pie: {
                        colors: [
                            '#42b3e3',
                            '#1e5065',
                            '#42D3AB',
                            '#FF731C',
                            '#999999',
                        ],
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    },
                    series: {animation: true}
                },
                series: [{
                    name: 'Device Breakdown',
                    colorByPoint: true,
                    data: [{
                        name: 'Apple',
                        y: results.apple
                    }, {
                        name: 'Android',
                        y: results.android
                    }, {
                        name: 'SMS',
                        y: results.sms
                    }, {
                        name: 'Legacy Pager',
                        y: results.pager
                    }, {
                        name: 'No Device',
                        y: results.console
                    }]
                }]
            });
            return options;
        };
        Service.getThreadSubscriberChartData = function(subscriberSummary){
            return [
                {name: 'Delivered', y: subscriberSummary.delivered},
                {name: 'Sent', y: subscriberSummary.pending},
                {name: 'Read', y: subscriberSummary.read}
            ];
        };

        return Service;
    }]);
