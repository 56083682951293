/**
 * @ngdoc directive
 * @name smartpager.endUser.patients:noteTypeComponent
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.patients')
    .component('noteType', {
        bindings: {
            type: '<'
        },
        templateUrl: '/smartpager/angular/endUser/patients/components/noteType/noteType.html'
    });
