angular
  .module("smartpager.endUser.pagingGroups")
  .controller("scheduleGroupSettingsCtrl", ["$scope", "$state", "$stateParams", "$log", "Notification", "promptsService", "pagingGroupService", "pagingGroupPermissionsService", "modalHelper", "userService", "escalationsService", "configuration", function(
    $scope,
    $state,
    $stateParams,
    $log,
    Notification,
    promptsService,
    pagingGroupService,
    pagingGroupPermissionsService,
    modalHelper,
    userService,
    escalationsService,
    configuration
  ) {
    var self = this;
    $scope.failOverUsers = [];
    function reloadGroup() {
      $scope.hasChangePermission = $scope.hasPermission(
        "paginggroups.change_paginggroup"
      );
      $scope.hasDeletePermission = $scope.hasPermission(
        "paginggroups.delete_paginggroup"
      );

      pagingGroupService
        .getScheduleGroup($stateParams.groupId)
        .then(function(group) {
          $scope.group = group;

          $scope.hasChangePermission = pagingGroupPermissionsService.hasPagingGroupChangePermission(
            group
          );
          $scope.hasDeletePermission = pagingGroupPermissionsService.hasPagingGroupDeletePermission(
            group
          );

          if (group.fail_over_user) {
            return userService.getUserById(group.fail_over_user);
          }
        })
        .then(function(fail_over_user) {
          if (fail_over_user) {
            $scope.failOverUsers.push(fail_over_user);
          }
        });
    }

    this.activate = function() {
      promptsService.getPrompts().then(function(prompts) {
        $scope.prompts = prompts;
      });

      reloadGroup();
    };
    this.activate();

    $scope.searchEscalationPolicies = function(query) {
      escalationsService.getEscalationPolicies(query).then(function(response) {
        $scope.escalationPolicies = response.results;
      });
    };

    $scope.searchFailOverUsers = function(query) {
      var param = {
        search: query,
        is_active: "True"
      };
      userService.getUsers(param).then(function(response) {
        if ($scope.failOverUsers.length === 1) {
          $scope.failOverUsers = $scope.failOverUsers.concat(response.results);
        } else {
          $scope.failOverUsers = response.results;
        }
      });
    };

    $scope.removeGroupUser = function(removedUser) {
      var group = angular.copy($scope.group);
      _.remove(group.members, { id: removedUser.id });
      group.member_ids = _.without(group.member_ids, removedUser.id);

      $scope.isLoading = true;
      pagingGroupService
        .updateGroup(group)
        .then(function() {
          _.remove($scope.group.members, { id: removedUser.id });
          $scope.group.member_ids = _.without(
            $scope.group.member_ids,
            removedUser.id
          );

          Notification.success(
            String.format(
              "Successfully removed {0} from the group",
              removedUser.display_name
            )
          );
        })
        .finally(function() {
          $scope.isLoading = false;
        });
    };

    $scope.$watch("group.online_greeting", function(newValue, oldValue) {
      if (!newValue) {
        return;
      }

      $scope.online_greeting_loading = true;
      promptsService
        .getSnippets(newValue)
        .then(function(snippets) {
          $scope.onlineGreetingAudio = snippets;
        })
        .finally(function() {
          $scope.onlineGreetingLoading = false;
        });
    });

    $scope.$watch("group.offline_greeting", function(newValue, oldValue) {
      if (!newValue) {
        return;
      }

      $scope.offlineGreetingLoading = true;
      promptsService
        .getSnippets(newValue)
        .then(function(snippets) {
          $scope.offlineGreetingAudio = snippets;
        })
        .finally(function() {
          $scope.offlineGreetingLoading = false;
        });
    });

    $scope.$watch("group.allow_shift_takeover", function(newValue, oldValue) {
      if (!newValue) {
        $scope.group.allow_non_member_shift_takeover = false;
      }
    });

    $scope.saveGroup = function() {
      $scope.isLoading = true;

      pagingGroupService
        .updateGroup($scope.group)
        .then(function() {
          Notification.success("Successfully updated group");
        })
        .catch(function(response) {
          $log.debug("Error with status code", response.status);
        })
        .finally(function() {
          $scope.isLoading = false;
        });
    };

    $scope.deleteGroup = function() {
      modalHelper
        .openModal({
          title: "Confirm delete",
          content: String.format(
            "Are you sure you want to delete {0} group?",
            $scope.group.name
          ),
          buttons: [
            { id: "yes", label: "Delete", type: "primary" },
            {
              id: "cancel",
              label: "Cancel",
              type: "default"
            }
          ]
        })
        .result.then(function() {
          pagingGroupService
            .deleteGroup($scope.group)
            .then(function(resp) {
              Notification.success("Successfully deleted schedule group");
              $state.go("root.pagingGroups.list");
            })
            .catch(function(resp) {
              var msg = "Could not delete group. ";
              if (resp.status === 400 && resp.data) {
                msg += resp.data.used_by
                  ? "It is currently in use by the following items: " +
                    resp.data.used_by.join(", ")
                  : _.values(resp.data).join(", ");
              }
              Notification.error(msg);
            });
        });
    };

    $scope.openAddMembersToPagingGroupModal = function() {
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/pagingGroups/controllers/modals/addMembersToPagingGroupModal.html",
          controller: "addMembersToPagingGroupModalCtrl as $ctrl",
          group: $scope.group,
          size: "lg"
        })
        .result.finally(function() {
          reloadGroup();
        });
    };

    $scope.clearOnlineGreeting = function() {
      $scope.group.online_greeting = null;
      $scope.onlineGreetingAudio = [];
    };

    $scope.clearOfflineGreeting = function() {
      $scope.group.offline_greeting = null;
      $scope.offlineGreetingAudio = [];
    };

    $scope.pagingDialogueOptions = [
      { label: "Group Greeting", value: "group_dialogue" },
      { label: "User Greeting", value: "user_dialogue" }
    ];

    $scope.multipleRecipientOptions = [
      { label: "Message all", value: "allgroupmembers" },
      { label: "Enumerate users", value: "specificgroupmember" },
      { label: "Message all voice users", value: "allinboundvoicegroupmembers" }
    ];

    $scope.onlineGreetingAudio = [];
    $scope.offlineGreetingAudio = [];

    $scope.availableUsers = [];
    $scope.escalationPolicies = [];
  }]);
