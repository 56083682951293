/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:patientDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.patients")
  .controller("patientDetailCtrl", ["$scope", "$state", "$log", "$location", "patientId", "patientsService", "Notification", "modalHelper", "configuration", "pubNubMessageTypes", function(
    $scope,
    $state,
    $log,
    $location,
    patientId,
    patientsService,
    Notification,
    modalHelper,
    configuration,
    pubNubMessageTypes
  ) {
    var $ctrl = this;
    $ctrl.patientId = patientId;

    $ctrl.$onInit = function() {
      $ctrl.isLoading = true;

      // If there is a thread id in the query parameters,
      // fetch the patient from the thread rather than the
      // patient API (to avoid permission issues)
      var threadId = $location.search().thread_id;
      var promise;

      if (threadId) {
        threadId = parseInt(threadId);
        promise = patientsService.getPatientFromThreadId(threadId);
      } else {
        promise = patientsService.getPatientById(patientId);
      }
      promise
        .then(function(response) {
          $ctrl.patient = response;

          $ctrl.myCareTeamMember = _.find($ctrl.patient.care_team, {
            provider: { id: configuration.getProviderId() }
          });
          $ctrl.myFollower = _.find($ctrl.patient.followers, {
            user: { id: configuration.getUserId() }
          });
        })
        .finally(function() {
          $ctrl.isLoading = false;
        });

      patientsService.getCareTeamRequests().then(function(response) {
        $ctrl.careTeamRequests = response.results;
      });
    };

    $ctrl.follow = function() {
      $ctrl.isBeingFollowed = true;
      var request = patientsService
        .follow(patientId)
        .then(function(response) {
          var exists = _.find($ctrl.patient.followers, { id: response.id });
          if (exists) {
            Notification.info(
              String.format(
                "You are already following `{0}`",
                $ctrl.patient.display_name
              )
            );
          } else {
            Notification.success(
              String.format(
                "You are now following `{0}`",
                $ctrl.patient.display_name
              )
            );
            $ctrl.patient.followers.push(response);
            $ctrl.myFollower = response;
          }
        })
        .catch(function() {
          Notification.error(
            String.format("Unable to follow {0}", $ctrl.patient.display_name)
          );
        })
        .finally(function() {
          $ctrl.isBeingFollowed = false;
        });
    };

    $ctrl.unfollowYourself = function(follower) {
      $ctrl.isBeingUnfollowed = true;
      return $ctrl.unfollow($ctrl.myFollower).finally(function() {
        $ctrl.isBeingUnfollowed = false;
      });
    };

    $ctrl.unfollow = function(follower) {
      return patientsService
        .unfollow(patientId, follower.id)
        .then(function(response) {
          Notification.success(
            String.format(
              "{0} is no longer following `{1}`",
              follower.user.display_name,
              $ctrl.patient.display_name
            )
          );
          _.remove($ctrl.patient.followers, { id: follower.id });
          $ctrl.myFollower = null;
        })
        .catch(function() {
          Notification.error(
            String.format(
              "Unable to unfollow `{0}`",
              $ctrl.patient.display_name
            )
          );
        });
    };

    $ctrl.assignToYourself = function(patient) {
      $ctrl.isBeingAssigned = true;

      patientsService
        .assign(patient.id, configuration.getUserProfile().providerId)
        .then(function(result) {
          Notification.success(
            String.format(
              "Successfully assigned patient {0}",
              patient.display_name
            )
          );
        })
        .catch(function(response) {
          Notification.error(String.format("Error assigning provider"));
        })
        .finally(function() {
          $ctrl.isBeingAssigned = false;
        });
    };

    $ctrl.unassignFromYourself = function() {
      $ctrl.isBeingAssigned = true;
      return $ctrl.unassign($ctrl.myCareTeamMember).finally(function() {
        $ctrl.isBeingAssigned = false;
      });
    };

    $ctrl.unassign = function(careTeamMember) {
      return patientsService
        .unassign(patientId, careTeamMember.id)
        .then(function() {
          _.remove($ctrl.patient.care_team, { id: careTeamMember.id });
          $ctrl.myCareTeamMember = _.find($ctrl.patient.care_team, {
            provider: { id: configuration.getProviderId() }
          });
          Notification.success(
            String.format(
              "Successfully unassigned {0} from patient {1}",
              careTeamMember.provider.user.display_name,
              $ctrl.patient.display_name
            )
          );
        });
    };

    $ctrl.take = function(provider) {
      $ctrl.takeInProgress = true;
      patientsService
        .take(patientId, provider.id)
        .then(function(result) {
          Notification.success(
            String.format("`{0}` is your patient", $ctrl.patient.display_name)
          );
        })
        .catch(function() {
          Notification.error(
            String.format(
              "Unable to take patient `{0}`",
              $ctrl.patient.display_name
            )
          );
        })
        .finally(function() {
          $ctrl.takeInProgress = false;
        });
    };

    $ctrl.handoff = function() {
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/patients/controllers/modals/handoffModal.html",
          controller: "handoffModalCtrl as $ctrl",
          patient: $ctrl.patient
        })
        .result.then(function() {
          $scope.$broadcast("reloadNotes");
        });
    };

    $ctrl.showAddPatientsNoteModal = function() {
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/patients/controllers/modals/addPatientsNoteModal.html",
          controller: "addPatientsNoteModalCtrl as $ctrl",
          patientId: patientId
        })
        .result.then(function() {
          $scope.$broadcast("reloadNotes");
        });
    };

    $ctrl.openEditPatientModal = function() {
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/patients/controllers/modals/editPatientModal.html",
          controller: "editPatientModalCtrl as $ctrl",
          patientId: patientId
        })
        .result.then(function(response) {
          $ctrl.patient = response;
        });
    };

    $ctrl.discharge = function() {
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/patients/controllers/modals/dischargePatientModal.html",
          controller: "dischargePatientModalCtrl as $ctrl",
          patientId: patientId
        })
        .result.then(function(response) {
          Notification.success(
            String.format(
              "Successfully discharged patient {0}",
              $ctrl.patient.display_name
            )
          );
          $state.go("root.patients.assignments");
        });
    };

    $ctrl.getTimeDiff = function(admitted, discharged) {
      var ret = "";
      if (!discharged) {
        discharged = new Date();
      } else {
        discharged = new Date(discharged);
      }

      admitted = new Date(admitted);

      if (isNaN(admitted) || isNaN(discharged)) {
        return ret;
      } else if (admitted > discharged) {
        return ret;
      }

      var admitMoment = moment(admitted);
      var dischargeMoment = moment(discharged);
      admitMoment.startOf("day");
      dischargeMoment.startOf("day");
      var days = dischargeMoment.diff(admitMoment, "days") + 1;
      ret = days + " days";
      return ret;
    };

    $scope.$onRootScope(pubNubMessageTypes.ac.pcm.assign, function(
      event,
      payload
    ) {
      $ctrl.$onInit();
    });

    $scope.$onRootScope(pubNubMessageTypes.ac.pcm.unassign, function(
      event,
      payload
    ) {
      $ctrl.$onInit();
    });

    $scope.$onRootScope(pubNubMessageTypes.ac.pcm.handoff, function(
      event,
      payload
    ) {});

    $scope.$onRootScope(pubNubMessageTypes.ac.pcm.take, function(
      event,
      payload
    ) {});
  }]);
