/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:addEditConditionSetModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .controller('addEditConditionSetModalCtrl', ["$scope", "$rootScope", "messageRoutingService", "Notification", "modalOptions", "routingModalsFactory", "eventsBus", "alertsHelper", function ($scope, $rootScope, messageRoutingService, Notification, modalOptions, routingModalsFactory, eventsBus, alertsHelper) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.$onInit = function () {
            $ctrl.conditionSet = {
                clause_join: 'AND',
                conditions_list: []
            };

            if (modalOptions.conditionSet) {
                $ctrl.isEdit = true;
                $ctrl.conditionSet = angular.copy(modalOptions.conditionSet);
            }
            $ctrl.title = $ctrl.isEdit ? 'Edit Condition Set' : 'Create Condition Set';
        };

        $ctrl.addCondition = function (condition) {
            if (_.find($ctrl.conditionSet.conditions_list, {id: condition.id})) {
                $ctrl.alerts.info(String.format('Condition {0} is already added.', condition.name));
            } else {
                $ctrl.conditionSet.conditions_list.push(condition);
            }
            $ctrl.selectCondition = null;
        };

        $ctrl.removeCondition = function (condition) {
            _.remove($ctrl.conditionSet.conditions_list, {id: condition.id});
        };

        $ctrl.editCondition = function (condition) {
            routingModalsFactory.showCreateEditConditionModal(condition)
                .then(function (condition) {
                    _.remove($ctrl.conditionSet.conditions_list, {id: condition.id});
                    $ctrl.conditionSet.conditions_list.push(condition);

                    $rootScope.$emit('reloadMessageRoutingConditions');
                });
        };

        $ctrl.update = function (conditionSet) {
            var payload = angular.copy(conditionSet);
            payload.condition_ids = _.map(payload.conditions_list, 'id');
            delete payload.conditions_list;

            $ctrl.isSaving = true;
            messageRoutingService.updateConditionSet(payload)
                .then(function (response) {
                    $scope.$close(response);
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.save = function (conditionSet) {
            if (!$ctrl.form.$valid) {
                $ctrl.form.$setSubmitted();
                return;
            }

            var payload = angular.copy(conditionSet);
            payload.condition_ids = _.map(payload.conditions_list, 'id');
            delete payload.conditions_list;

            $ctrl.isSaving = true;
            messageRoutingService.createConditionSet(payload)
                .then(function (response) {
                    $scope.$close(response);
                })
                .catch(function (response) {
                    $ctrl.serverErrors = response.data;
                    eventsBus.publish('serverError', $ctrl.serverErrors);
                    $ctrl.alerts.error('Could not create condition');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };

        $ctrl.showCreateConditionModal = function () {
            routingModalsFactory.showCreateEditConditionModal()
                .then(function (condition) {
                    $ctrl.addCondition(condition);
                    $scope.$emit('reloadMessageRoutingConditions');
                });
        };

    }]);
