/**
 * @ngdoc controller
 * @name smartpager.endUser.messages:uploadFileModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messages')
    .controller('uploadFileModalCtrl', ["$scope", "modalOptions", "$timeout", "alertsHelper", "fileUploadHelper", function ($scope, modalOptions, $timeout, alertsHelper, fileUploadHelper) {

        alertsHelper.create(this, 'alerts');

        var $ctrl = this;
        $ctrl.uploader = modalOptions.uploader;
        $ctrl.successfullyUploaded = [];
        $ctrl.uploader.onSuccessItem = function (item, response, status, headers) {
            $ctrl.successfullyUploaded.push(response.id);
        };

        $ctrl.uploader.onErrorItem = function () {
            $ctrl.preventClose = true;
            $ctrl.forceSend = true;
        };

        $ctrl.uploader.onCompleteAll = function () {
            if ($ctrl.preventClose) {
                $ctrl.preventClose = false;
            } else {
                $timeout(function () {
                    $scope.$close({message: $ctrl.desc, attachments: $ctrl.successfullyUploaded});
                }, 500);
            }
        };

        $ctrl.uploadAttachments = function () {
            $ctrl.uploader.uploadAll();
        };

        $scope.$on('$destroy', function () {
            $ctrl.uploader.onSuccessItem = fileUploadHelper.noop;
            $ctrl.uploader.onErrorItem = fileUploadHelper.noop;
            $ctrl.uploader.onCompleteAll = fileUploadHelper.noop;
        });
    }]);
