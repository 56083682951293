/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addRemoteIdModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addRemoteIdModalCtrl', ["$scope", "$log", "integrationsService", "alertsHelper", "modalOptions", "Notification", function ($scope, $log, integrationsService, alertsHelper, modalOptions, Notification) {

        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.searchIntegrations = function (name) {
            $ctrl.isLoadingIntergrations = true;
            integrationsService.getConfiguredIntegrations(modalOptions.integrationType, name)
                .then(function (response) {
                    $ctrl.availableIntegrations = response.results;
                })
                .finally(function () {
                    $ctrl.isLoadingIntergrations = false;
                });
        };

        $ctrl.searchDivisions = function (search) {
            $ctrl.isLoadingDivisions = true;
            integrationsService.getAmionDivisions($ctrl.selectedIntegration.id, {search: search})
                .then(function (response) {
                    $ctrl.availableDivisions = response.divisions;
                })
                .finally(function () {
                    $ctrl.isLoadingDivisions = false;
                });
        };

        $ctrl.addRemoteId = function () {
            $ctrl.isSaving = true;
            integrationsService.addRemoteId(
                modalOptions.integrationType,
                $ctrl.selectedContact.id,
                $ctrl.remoteId,
                $ctrl.remoteBuid,
                $ctrl.selectedIntegration.id,
                $ctrl.selectedDivision
            )
            .then(function () {

                //close it if not supposed to add another
                if (!$ctrl.addAnother) {
                    Notification.success(String.format('Successfully assigned remote id `{0}` to {1}', $ctrl.remoteId, $ctrl.selectedContact.display_name));
                    $scope.$close();
                } else {
                    $ctrl.alerts.success(String.format('Successfully assigned remote id `{0}` to {1}', $ctrl.remoteId, $ctrl.selectedContact.display_name));

                    //clear
                    $ctrl.selectedContact = null;
                    $ctrl.remoteId = null;
                    $ctrl.selectedDivision = null;
                }
            })
            .catch(function () {
                $ctrl.alerts.error('Could not add Remote Id');
            })
            .finally(function () {
                $ctrl.isSaving = false;
            });
        };

    }]);
