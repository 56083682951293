/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addEditAdAgentModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addEditCasAgentModalCtrl', ["$scope", "integrationsService", "alertsHelper", "Notification", "modalOptions", function ($scope, integrationsService, alertsHelper, Notification, modalOptions) {

        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');
        $ctrl.config = modalOptions.config ? modalOptions.config : {};
        $ctrl.edit = typeof modalOptions.config !== 'undefined';

        $ctrl.saveCasAgent = function () {
            $ctrl.isSaving = true;
            if ($ctrl.edit){
                integrationsService.updateCasAgentConfiguration($ctrl.config)
                    .then(function(){
                        Notification.success('Successfully updated configuration');
                        $scope.$close();
                    })
                    .catch(function(){
                        $ctrl.alerts.error('Error updating agent configuration');
                    })
                    .finally(function(){
                        $ctrl.isSaving = false;
                    });
            }else{
                integrationsService.createCasAgentConfiguration($ctrl.config)
                    .then(function () {
                        Notification.success('Successfully created configuration');
                        $scope.$close();
                    })
                    .catch(function () {
                        $ctrl.alerts.error('Error creating agent configuration');
                    })
                    .finally(function () {
                        $ctrl.isSaving = false;
                    });
            }
        };
    }]);
