/**
 * @ngdoc controller
 * @name messageAlertModalCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular
  .module('smartpager.endUser.messages')
  .controller('messageAlertModalCtrl', ["$scope", "$state", "messageService", "modalOptions", "$log", function(
    $scope,
    $state,
    messageService,
    modalOptions,
    $log
  ) {
    $scope.loading = true;
    $scope.query = '';

    $scope.loadData = function(query) {
      $scope.loading = true;
      messageService
        .getMessageAlerts($state.params.threadId, modalOptions.messageId, query)
        .then(function(response) {
          for (var i = 0; i < response.results.length; i++) {
            switch (response.results[i].user_status) {
              case 'online':
                response.results[i].user_status_display = 'Online';
                break;
              case 'dnd':
                response.results[i].user_status_display = 'Do not disturb';
                break;
              case 'uk':
                break;
              default:
                response.results[i].user_status_display = 'Unknown';
                break;
            }
            switch (response.results[i].alert_type) {
              case 'push':
                response.results[i].alert_type_display = 'Push';
                break;
              case 'apns':
                response.results[i].alert_type_display = 'Push (APNS)';
                break;
              case 'gcm':
                response.results[i].alert_type_display = 'Push (FCM)';
                break;
              case 'sms':
                response.results[i].alert_type_display = 'SMS';
                break;
              case 'phone':
                response.results[i].alert_type_display = 'Phone (Mobile)';
                break;
              case 'alt_phone':
                response.results[i].alert_type_display = 'Phone (Alternate)';
                break;
              case 'email':
                response.results[i].alert_type_display = 'Email (Pager)';
                break;
              case 'user_email':
                response.results[i].alert_type_display = 'Email';
                break;
              default:
                response.results[i].alert_type_display = 'Unknown';
                break;
            }
          }

          $scope.messageAlerts = _.groupBy(response.results, function(n) {
            return n.user.id;
          });
          $scope.count = response.count;
        })
        .finally(function() {
          $scope.loading = false;
        });
    };

    $scope.applyFilter = function(value) {
      $scope.loadData(value);
    };

    $scope.loadData("");
  }]);
