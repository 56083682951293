/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:agentListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('agentListCtrlCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('agentListCtrlCache');
    }])
    .controller('agentListCtrl', ["$scope", "agentListCtrlCache", "integrationsService", "modalHelper", "NgTableParams", "ngTableHelper", function ($scope, agentListCtrlCache, integrationsService, modalHelper, NgTableParams, ngTableHelper) {
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams(agentListCtrlCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                agentListCtrlCache.put('parameters', params.parameters());

                $ctrl.isLoading = true;
                return integrationsService.getAdAgentsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.deleteConfiguration = function (config) {
            modalHelper.openModal({
                title: 'Confirm delete',
                content: String.format('Are you sure you want to delete {0} configuration?', config.name),
                buttons: [
                    {id: 'yes', label: 'Delete', type: 'primary'},
                    {id: 'cancel', label: 'Cancel', type: 'default'}
                ]
            }).result
                .then(function () {
                    return integrationsService.deleteAdAgentConfig(config.id);
                })
                .then(function () {
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.openAddAdAgentModal = function () {
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/agents/modals/addEditAdAgentModal.html',
                controller: 'addEditAdAgentModalCtrl as $ctrl'
            }).result
                .then(function () {
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.openEditAgentModal = function(config){
            modalHelper.openModal({
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/agents/modals/addEditAdAgentModal.html',
                controller: 'addEditAdAgentModalCtrl as $ctrl',
                config: config
            }).result
                .then(function(){
                    $ctrl.tableParams.reload();
                });
        };
    }]);
