angular.module('smartpager.endUser.pagingGroups')
    .controller('pagingGroupAddModalCtrl', ["$scope", "pagingGroupService", "$log", "alertsHelper", function ($scope, pagingGroupService, $log, alertsHelper) {
        var self = this;
        alertsHelper.create($scope, 'alerts');

        $scope.group = pagingGroupService.getNewPagingGroup();

        $scope.createGroup = function () {
            self.saving = true;
            var failOverId = $scope.group.type === 'ScheduleGroup' ? $scope.group.fail_over_user.id : null;
            pagingGroupService['add' + $scope.group.type]($scope.group.name, failOverId)
                .then(function (group) {
                    $scope.$close(group);
                })
                .catch(function () {
                    $scope.alerts.error('Error creating paging group');
                })
                .finally(function () {
                    self.saving = false;
                });
        };
    }]);
