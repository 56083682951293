/**
 * @ngdoc service
 * @name smartpager.endUser.shared:focusFactory
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .directive('focusOn', function () {
        return function (scope, elem, attr) {
            scope.$on('focusOn', function (e, name) {
                if (name === attr.focusOn) {
                    setTimeout(function () {
                        elem[0].focus();
                    }, 0);
                }
            });
        };
    })
    .factory('focus', ["$rootScope", "$timeout", function ($rootScope, $timeout) {
        return function (name) {
            $timeout(function () {
                $rootScope.$broadcast('focusOn', name);
            });
        };
    }]);
