/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:handoffModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('handoffModalCtrl', ["$scope", "alertsHelper", "patientsService", "modalOptions", "Notification", function ($scope, alertsHelper, patientsService, modalOptions, Notification) {
        var $ctrl = this;

        $ctrl.title = String.format('Handoff {0}', modalOptions.patient.display_name);

        alertsHelper.create($ctrl, 'alerts');

        $ctrl.handoff = function () {
            $ctrl.inProgress = true;
            patientsService.handoff(modalOptions.patient.id, $ctrl.handingOffTo.id)
                .then(function () {
                    Notification.success(String.format('Successfully handed off patient `{0}` to {1}', modalOptions.patient.display_name, $ctrl.handingOffTo.display_name));
                    $scope.$close();
                })
                .catch(function () {
                    $ctrl.alerts.error('Cannot hand off');
                })
                .finally(function () {
                    $ctrl.inProgress = false;
                });
        };
    }]);
