/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addShiftModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('addShiftModalCtrl', ["$scope", "Notification", "integrationsService", "modalOptions", "NgTableParams", "ngTableHelper", "$log", function ($scope, Notification, integrationsService, modalOptions, NgTableParams, ngTableHelper, $log) {
        var $ctrl = this;
        var configId = modalOptions.configId;
        var scheduleId = modalOptions.scheduleId;
        var addedShifts = modalOptions.addedShifts;

        $ctrl.newShifts = [];
        $ctrl.shifts = [];
        $ctrl.isLoading = false;

        $ctrl.fetchShifts = function($select){
            $ctrl.loadShifts($select.search);
        };

        $ctrl.$onInit = function () {
            $ctrl.loadShifts();
        };

        $ctrl.inAddedShifts = function (id) {
            return addedShifts.indexOf(id) !== -1;
        };

        $ctrl.addShiftSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.addShiftTableParams, 'search')
        };


        $ctrl.loadShifts = function(searchTerm){

            var params = {
                sorting: {
                    'name': 'asc'
                },
                excluding: _.join(_.map($ctrl.newShifts, 'id')),
                available_for_config: scheduleId
            };

            if(searchTerm){ params.search = searchTerm; }

            integrationsService.getShiftadminShifts(configId, params)
                .then(function(data){
                    $ctrl.shifts = data.results;
                });
        };


        $ctrl.addShifts = function(shifts){
            $ctrl.isLoading = true;
            var shiftIds = _.map(shifts, 'id');

            //latency for feels
            setTimeout(function(){
                integrationsService.addShiftadminScheduleShifts(configId, scheduleId, shiftIds)
                    .then(function(){
                        Notification.success('Successfully added shifts to schedule');
                        $ctrl.newShifts = [];
                        $scope.$dismiss();
                    })
                    .catch(function () {
                        Notification.error('Could not add shifts to schedule');
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }, 500);
        };

    }]);
