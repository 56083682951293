angular.module('smartpager.endUser.pagingGroups')
    .factory('pagingGroupListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('pagingGroupListCache');
    }])
    .controller('pagingGroupListCtrl', ["$scope", "$state", "pagingGroupService", "modalHelper", "$filter", "NgTableParams", "ngTableHelper", "userService", "pagingGroupListCache", function ($scope, $state, pagingGroupService, modalHelper, $filter, NgTableParams, ngTableHelper, userService, pagingGroupListCache) {

        var self = this;
        $scope.addEventGroup = function () {
            modalHelper.openModal({
                controller: 'pagingGroupAddModalCtrl',
                templateUrl: '/smartpager/angular/endUser/pagingGroups/controllers/modals/pagingGroupAddModal.html'
            }).result
                .then(function (result) {
                    if (result.type === 'BroadcastGroup') {
                        $state.go('root.pagingGroups.broadcastGroup', {groupId: result.id});
                    } else {
                        $state.go('root.pagingGroups.scheduleGroup.settings', {groupId: result.id});
                    }
                });
        };

        $scope.tableParams = new NgTableParams(pagingGroupListCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            getData: function (params) {
                pagingGroupListCache.put('parameters', params.parameters());

                $scope.isLoading = true;
                return pagingGroupService.getPagingGroups(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $scope.isLoading = false;
                    });
            }
        });

        $scope.searchParams = {
            name: ngTableHelper.generateGetterSetter($scope.tableParams, 'name'),
            department_id: ngTableHelper.generateGetterSetter($scope.tableParams, 'department_id')
        };
    }]);
