/**
 * @ngdoc controller
 * @name threadCtrl
 *
 * @description
 * _Please update the description and dependencies._
 *
 * @requires $scope
 * */
angular
  .module("smartpager.endUser.messages")
  .controller("threadCtrl", ["$rootScope", "$scope", "$state", "$window", "$document", "threadId", "messageService", "$q", "$log", "Pubnub", "$timeout", "modalHelper", "inboxEvents", "pubNubMessageTypes", "configuration", "Notification", "threadStatus", "inboxType", "soundService", "userService", "heartbeat", "fileUploadHelper", function(
    $rootScope,
    $scope,
    $state,
    $window,
    $document,
    threadId,
    messageService,
    $q,
    $log,
    Pubnub,
    $timeout,
    modalHelper,
    inboxEvents,
    pubNubMessageTypes,
    configuration,
    Notification,
    threadStatus,
    inboxType,
    soundService,
    userService,
    heartbeat,
    fileUploadHelper
  ) {
    var ctrl = this;
    // page_size has to be big enough to fill out entire screen height, otherwise infinite scroll won't work
    ctrl.page_size = 30;

    $scope.thread = {
      isLoading: true,
      messages: []
    };
    $scope.enterEnabled = true;
    $scope.typingUsers = [];

    var lastRequest;
    ctrl.activate = function() {
      $log.debug("threadCtrl loading thread", threadId);

      lastRequest = messageService.getThreadById(threadId);

      lastRequest
        .then(function(activeThread) {
          if (
            (activeThread.status === threadStatus.active &&
              $scope.getCurrentInboxType() === inboxType.archive) ||
            (activeThread.status === threadStatus.archived &&
              ($scope.getCurrentInboxType() === inboxType.inbox ||
                $scope.getCurrentInboxType() === inboxType.unread))
          ) {
            $state.go("root.inbox");
          }

          $scope.thread = _.merge($scope.thread, activeThread);

          loadSubscribers();
          loadPatient();
          loadEncounter();

          $rootScope.$emit(
            inboxEvents.threadInView,
            angular.copy(activeThread)
          );

          // Subscribe to new thread & setup handlers
          $log.debug("Pubnub.subscribe", activeThread.uid);

          $scope.$onRootScope(
            Pubnub.getMessageEventNameFor(activeThread.uid),
            handlePubnubThreadChannelNotification
          );
          Pubnub.time(function(time) {
            Pubnub.subscribe({
              channel: activeThread.uid,
              timetoken: time,
              triggerEvents: ["callback"]
            });
          });

          // unsubscribe on destroy
          $scope.$on("$destroy", function() {
            $log.debug("Pubnub.unsubscribe", activeThread.uid);
            Pubnub.unsubscribe({ channel: activeThread.uid });
          });

          return $scope.loadMessages();
        })
        .catch(function(result) {
          if (result.status !== -1) {
            $log.error(String.format("thread {0} not found", threadId), result);
            $state.go("root.inbox.noThreads");
          }
        })
        .finally(function() {
          $scope.thread.isLoading = false;
        });
    };

    function loadSubscribers() {
      $scope.thread.isLoadingSubscribers = true;
      messageService
        .getThreadSubscribers($scope.thread.id, { count: 50 })
        .then(function(response) {
          $scope.thread.subscribers = response.results;
          $scope.thread.subscribersCount = response.count;
          $rootScope.$emit(
            inboxEvents.subscribersLoaded,
            angular.copy($scope.thread)
          );
        })
        .catch(function() {
          $log.error("Could not load subscribers for thread");
        })
        .finally(function() {
          $scope.thread.isLoadingSubscribers = false;
        });
    }

    function loadEncounter() {
      if (!$scope.thread.encounter_id) {
        return;
      }

      $scope.thread.isLoadingPatient = true;

      messageService
          .getThreadEncounter($scope.thread.encounter_id)
          .then(function(resp) {
            $scope.thread.patient = resp.patient;
            $rootScope.$emit(
                inboxEvents.threadPatientLoaded,
                angular.copy($scope.thread)
            );
          })
          .catch(function() {
            $log.debug("Could not load patient for thread");
          })
          .finally(function() {
            $scope.thread.isLoadingPatient = false;
          });
    }

    function loadPatient() {
      if (!$scope.thread.patient_id) {
        return;
      }

      $scope.thread.isLoadingPatient = true;

      messageService
        .getThreadPatient($scope.thread.id)
        .then(function(response) {
          $scope.thread.patient = response;
          $rootScope.$emit(
            inboxEvents.threadPatientLoaded,
            angular.copy($scope.thread)
          );
        })
        .catch(function() {
          $log.debug("Could not load patient for thread");
        })
        .finally(function() {
          $scope.thread.isLoadingPatient = false;
        });
    }

    $rootScope.$on(inboxEvents.observedUsersChange, function(
      ev,
      observedUsers
    ) {
      var addedDueToMonitoring =
        $scope.thread.my_subscriber &&
        $scope.thread.my_subscriber.reason === "monitoring";
      $scope.thread.monitored = addedDueToMonitoring;
    });

    function loadObservedUsers() {
      userService
        .getObservedUsers(configuration.getUserId())
        .then(function(response) {
          $rootScope.$broadcast(inboxEvents.observedUsersChange, response);
        });
    }

    $scope.$onRootScope(inboxEvents.subscribersLoaded, function(ev, thread) {
      loadObservedUsers();
    });

    $scope.attachPatient = function attachPatient(thread) {
      modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/messages/controllers/modals/addThreadPatientModal.html",
          controller: "addThreadPatientModalCtrl",
          threadPatient: thread.patient,
          thread: thread
        })
        .result.then(function(threadStub) {
          $scope.thread.patient = threadStub.patient;
          $scope.thread.patient_id = threadStub.patient_id;
          $scope.thread.encounter_id = threadStub.encounter_id;
          loadPatient();
          loadEncounter();
        });
    };

    ctrl.lockScroll = function() {
      $rootScope.$emit(inboxEvents.lockScroll);
    };

    ctrl.restoreScroll = function() {
      $rootScope.$emit(inboxEvents.restoreScroll);
    };

    ctrl.scrollDown = _.once(function() {
      $rootScope.$emit(inboxEvents.scrollBottom);
    });

    ctrl.pickPage = function(length, pageSize) {
      length = length && length > 0 ? length : 1;
      return Math.ceil((length + 1) / pageSize);
    };

    $scope.loadMessages = function() {
      $log.debug("checking if should loadMessages");

      if (
        !ctrl.currentMessageCount ||
        ctrl.currentMessageCount > $scope.thread.messages.length
      ) {
        $log.debug("loading messages");
        ctrl.loadingMessages = true;

        var pageToLoad = ctrl.pickPage(
          $scope.thread.messages.length,
          ctrl.page_size
        );
        return messageService
          .getMessages(threadId, ctrl.page_size, pageToLoad)
          .then(function(response) {
            ctrl.currentMessageCount = response.count;

            if (pageToLoad > 1) {
              ctrl.lockScroll();
            }

            $scope.thread.messages = _.uniqBy(
              $scope.thread.messages.concat(response.results),
              "id"
            );

            if (pageToLoad > 1) {
              ctrl.restoreScroll();
            } else {
              ctrl.scrollDown();
            }

            // if user is a subscriber of this thread, mark thread as read
            if ($scope.thread.my_subscriber) {
              messageService
                .setMessagesRead(
                  threadId,
                  _.last(response.results).id,
                  _.head(response.results).id
                )
                .then(function(response) {
                  $rootScope.$emit("readMessages", response);
                });
            }
          })
          .finally(function() {
            ctrl.loadingMessages = false;
          });
      } else {
        return $q.when([]);
      }
    };

    ctrl.activate();

    $scope.$on("$destroy", function() {
      messageService.cancel(lastRequest);
    });

    function sendReply(tmpMsg, attachments) {
      var messageReply = tmpMsg.data[0].data;

      return messageService
        .replyToThread(threadId, messageReply, attachments)
        .then(function(response) {
          //updating tmp message with values from the server
          //$log.debug('time correction (ms)', moment(tmpMsg.created).diff(moment(response.created)));
          tmpMsg.id = response.id;
          tmpMsg.created = response.created;
          tmpMsg.attachments = response.attachments;

          delete tmpMsg.id2;

          return tmpMsg;
        })
        .catch(function(reason) {
          $log.error("Could not sent reply", reason);
          tmpMsg.error = true;
        })
        .finally(function() {
          tmpMsg.submitting = false;
        });
    }

    $scope.replyButton = function(messageReply) {
      ctrl.reply(messageReply);
    };

    $scope.replyEnter = function($event, messageReply, enterEnabled) {
      //prevent new line from being added
      if (!enterEnabled) {
        return;
      }

      $event.preventDefault();

      ctrl.reply(messageReply);
    };

    ctrl.reply = function(messageReply, attachments) {
      if (!messageReply && !attachments) {
        return;
      }

      var currentUser = configuration.getUserProfile();
      var lastMessage = _.head($scope.thread.messages);
      var tmpMsg = {
        id: lastMessage.id + 1,
        id2: lastMessage.id2 ? lastMessage.id2 - 1 : -1,
        created: moment()
          .utc()
          .toISOString(),
        sender: {
          id: currentUser.id,
          email: currentUser.email,
          profile_picture: currentUser.profile_picture,
          display_name: currentUser.display_name
        },
        submitting: true,
        data: [
          {
            field_type: "text",
            data: messageReply
          }
        ]
      };
      tmpMsg.grouped = messageService.shouldBeGrouped(tmpMsg, lastMessage);

      $scope.thread.messages.unshift(tmpMsg);
      $scope.messageReply = "";

      sendReply(tmpMsg, attachments);

      //immediately refresh modified time on thread list
      refreshThreadListItem(tmpMsg);
    };

    $scope.$onRootScope(inboxEvents.responseSelected, function(
      $event,
      message
    ) {
      message.grouped = messageService.shouldBeGrouped(
        message,
        _.head($scope.thread.messages)
      );

      //TODO insert at the position that will maintain sort order
      $scope.thread.messages.unshift(message);
      $scope.thread.messages.sort().reverse();

      //immediately refresh modified time on thread list //todo are there any side effects ?
      refreshThreadListItem(message);
    });

    $scope.resendMessage = function(id) {
      $log.debug("resendMessage", id);

      var toResend = _.find($scope.thread.messages, { id: id });
      toResend.error = false;
      toResend.submitting = true;

      sendReply(toResend)
        //refresh after successful resend
        .then(refreshThreadListItem);
    };

    $scope.forwardMessage = function(messageId) {
      return modalHelper
        .openModal({
          templateUrl:
            "/smartpager/angular/endUser/messages/controllers/modals/forwardMessageModal.html",
          controller: "forwardMessageModalCtrl as $ctrl",
          backdrop: "static",
          size: "lg",
          threadId: threadId,
          messageId: messageId
        })
        .result.then(function(thread) {
          $rootScope.$emit(inboxEvents.newThread, thread);
          $state.go("root.inbox.thread", { threadId: thread.id });
        });
    };

    $scope.forwardThread = function() {
      $scope.forwardMessage();
    };

    function refreshThreadListItem(message) {
      $rootScope.$emit(inboxEvents.newMessage, {
        threadId: threadId,
        last_message: { created: message.created }
      });
    }

    function handlePubnubThreadChannelNotification(
      ngEvent,
      message,
      envelope,
      channel
    ) {
      $log.debug(
        String.format("Pubnub [Thread Ch] {0}", message.type),
        message
      );

      // Ignore my own messages as actions that that triggers are done locally by javascript to improve responsiveness
      if (message.sender_id === configuration.getUserId()) {
        return;
      }

      if (message.type === pubNubMessageTypes.tch.new_message) {
        handleNewMessage(message.content);
      } else if (message.type === pubNubMessageTypes.tch.startTyping) {
        handleStartTypingNotification(message.content);
      } else if (message.type === pubNubMessageTypes.tch.stopTyping) {
        handleStopTypingNotification(message.content);
      } else if (
        message.type === pubNubMessageTypes.tch.subscriber_state_change
      ) {
        handleSubscriberStateChange(message.content);
      } else if (
        message.type === pubNubMessageTypes.tch.new_thread_subscriber
      ) {
        handleNewThreadSubscriber(message.content);
      } else if (
        message.type === pubNubMessageTypes.tch.message_accepted ||
        message.type === pubNubMessageTypes.tch.message_rejected
      ) {
        handleMessageConfirmation(message.content);
      } else if (message.type === pubNubMessageTypes.tch.message_responded) {
        handleMessageResponse(message.content);
      } else {
        $log.error("unknown pubnub message type", message.type, message);
      }
    }

    $window.onfocus = function() {
      if (
        $scope.thread.messages.length > 0 &&
        $scope.thread.my_subscriber &&
        $scope.thread.my_subscriber.state !== "read"
      ) {
        var lastMessage = $scope.thread.messages[0];
        var threadId = $scope.thread.id;

        messageService.setMessageRead(threadId, lastMessage.id);
      }
    };

    function handleNewMessage(newMessage) {
      //handle only messages from others
      if (newMessage.sender.id !== configuration.getUserId()) {
        messageService
          .getMessageById(threadId, newMessage.id)
          .then(function(message) {
            if (
              $scope.thread.id === message.thread &&
              !_.find($scope.thread.messages, { id: newMessage.id })
            ) {
              message.grouped = messageService.shouldBeGrouped(
                message,
                _.head($scope.thread.messages)
              );

              //TODO insert at the position that will maintain sort order
              $scope.thread.messages.unshift(message);

              if (!$scope.thread.suppress_reply_notifications) {
                soundService.playNewMessage($scope.thread.priority);
              }

              // Only set the message as read if the document is in focus
              if ($document[0].hasFocus()) {
                messageService.setMessageRead(threadId, message.id);
              }
            }
            refreshThreadListItem(message);
          });
      }
    }

    function handleStartTypingNotification(user) {
      _.remove($scope.typingUsers, { id: user.id });
      $scope.typingUsers.push(user);
      $scope.$digest();
    }

    function handleStopTypingNotification(user) {
      _.remove($scope.typingUsers, { id: user.id });
      $scope.$digest();
    }

    function handleSubscriberStateChange(msg) {
      $scope.$apply(function() {
        $rootScope.$emit(inboxEvents.subscriberStateChange, msg);
      });
    }

    function handleNewThreadSubscriber(subscriber) {
      $scope.$apply(function() {
        //if subscriber already exists ignore - this happens for new threads because thread got loaded
        if (_.find($scope.thread.subscribers, { id: subscriber.id })) {
          return;
        }

        $scope.thread.subscriber_data.push({
          id: subscriber.id,
          user_id: subscriber.user.id,
          display_name: subscriber.user.display_name
        });

        $rootScope.$emit(
          inboxEvents.newThreadSubscriber + threadId,
          subscriber
        );
      });
    }
    function handleMessageConfirmation(msg) {
      $scope.$apply(function() {
        $rootScope.$emit(inboxEvents.messageConfirmation, msg);
      });
    }

    function handleMessageResponse(msg) {
      $scope.$apply(function() {
        $rootScope.$emit(inboxEvents.messageResponse, msg);
      });
    }

    //region thread actions
    $scope.viewMessageHistory = function(message) {
      modalHelper.openModal({
        templateUrl:
          "/smartpager/angular/endUser/messages/controllers/modals/messageHistoryModal.html",
        controller: "messageHistoryModalCtrl",
        messageId: message.id
      });
    };

    $scope.viewMessageAlerts = function(message) {
      modalHelper.openModal({
        templateUrl:
          "/smartpager/angular/endUser/messages/controllers/modals/messageAlert/messageAlertModal.html",
        controller: "messageAlertModalCtrl",
        messageId: message.id
      });
    };

    $scope.archiveThread = function(thread) {
      $scope.archiving = true;
      messageService
        .archiveThreadById(thread.id)
        .then(function(response) {
          Notification.success("Message archived");
          $state.go("root.inbox.noThreads");
        })
        .finally(function() {
          $scope.archiving = false;
        });
    };

    $scope.hasPatient = function() {
      if ($scope.thread.patient === undefined) {
        return false;
      }
      return true;
    };
    $scope.canRecallThread = function(thread) {
      if (!thread || !thread.me) {
        return false;
      }
      if (thread.me.reason !== "sender") {
        return false;
      }
      if ($scope.isBeingRecalled === true) {
        return false;
      }
      if (!$scope.hasPermission("messaging.recall_thread")) {
        return false;
      }

      return true;
    };

    $scope.recallThread = function() {
      $log.debug("recalling thread " + threadId);
      $scope.isBeingRecalled = true;
      messageService
        .recallThread(threadId)
        .then(function() {
          Notification.success("Messages successfully recalled");
        })
        .catch(function(response) {
          var msg = "This thread could not be recalled";
          if (response.data && response.data.detail) {
            msg = response.data.detail;
          }
          Notification.info(msg);
        })
        .finally(function() {
          $scope.isBeingRecalled = false;
        });
    };

    $scope.triggerEMRWriteback = function(thread) {
      $log.debug("triggering EMR writeback" + thread.uid);
      messageService
          .triggerEMRWriteback(thread.uid)
          .then(function() {
            Notification.success("EMR writeback succeeded")
          })
          .catch(function(response) {
            Notification.info("Error triggering EMR writeback")
          });
    };

    //endregion

    //region typing notification
    var sendStoppedTypingDefer;

    $scope.typingReply = function() {
      var currentUser = configuration.getUserProfile();

      var userId = configuration.getUserId();
      var userUid = configuration.getUserUid();

      if (sendStoppedTypingDefer) {
        $timeout.cancel(sendStoppedTypingDefer);
      } else {
        Pubnub.publish({
          channel: $scope.thread.uid,
          message: {
            type: pubNubMessageTypes.tch.startTyping,
            sender_id: userId,
            sender_uid: userUid,
            content: {
              id: userId,
              first_name: currentUser.first_name,
              last_name: currentUser.last_name
            }
          }
        });
      }

      sendStoppedTypingDefer = $timeout(function() {
        Pubnub.publish({
          channel: $scope.thread.uid,
          message: {
            type: pubNubMessageTypes.tch.stopTyping,
            sender_id: userId,
            sender_uid: userUid,
            content: {
              id: userId,
              first_name: currentUser.first_name,
              last_name: currentUser.last_name
            }
          }
        });

        sendStoppedTypingDefer = null;
      }, 1500);
    };
    //endregion

    //region monitoring
    $scope.manageMonitoring = function() {
      messageService.showMonitorModal().finally(function() {
        loadObservedUsers();
      });
    };
    //endregion

    //region fileupload

    var uploadModal;
    $scope.fileUploader = fileUploadHelper.getAttachmentsFileUploader();
    $scope.fileUploader.onAfterAddingAll = function(addedItems) {
      if (!uploadModal) {
        $scope.fileUploader.onWhenAddingFileFailed = fileUploadHelper.noop;
        uploadModal = modalHelper.openModal({
          templateUrl:
            "/smartpager/angular/endUser/messages/controllers/modals/uploadFileModal.html",
          controller: "uploadFileModalCtrl as $ctrl",
          backdrop: "static",
          addedItems: addedItems,
          uploader: $scope.fileUploader
        });

        uploadModal.result
          .then(function(result) {
            ctrl.reply(result.message, result.attachments);
          })
          .finally(function() {
            $scope.fileUploader.clearQueue();
            uploadModal = null;
          });

        uploadModal.closed.finally(function() {
          registerFailureHandler();
        });
      }
    };
    function registerFailureHandler() {
      $scope.fileUploader.onWhenAddingFileFailed = function(
        item,
        filter,
        options
      ) {
        $log.warn("could not add file", item);
        if (filter.name === "enforceMaxFileSize") {
          Notification.error("File size limit is 10mb");
        } else if (filter.name === "invalidFileType") {
          Notification.error(
            String.format(
              "Only {0} are allowed",
              $scope.fileUploader.allowedExtensions
            )
          );
        }
      };
    }

    registerFailureHandler();

    //endregion
  }]);
