/**
 * @ngdoc directive
 * @name smartpager.endUser.reports:iqChart
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.reports')
    .directive('iqChart', function () {
        return {
            restrict: 'E',
            scope: {
                options: '='
            },
            replace: true,
            template: '<div class="chart"></div>',
            link: function (scope, elem, attr) {
                var chart;

                scope.$watch('options', function (newOptions, oldOptions) {
                    if (newOptions) {
                        if (chart) {
                            chart.destroy();
                        }

                        scope.options.chart.renderTo = elem[0];
                        chart = new Highcharts.Chart(scope.options);
                    }
                });

                scope.$on('$destroy', function () {
                    if (chart) {
                        chart.destroy();
                    }
                });
            }
        };
    });
