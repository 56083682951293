/**
 * @ngdoc controller
 * @name smartpager.endUser.reports:onboardingReportCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.reports')
    .factory('onboardingReportCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('onboardingReportCache');
    }])
    .controller('onboardingReportCtrl', ["$scope", "reportSlug", "reportService", "NgTableParams", "ngTableHelper", "onboardingReportCache", "$log", function($scope, reportSlug, reportService, NgTableParams, ngTableHelper, onboardingReportCache, $log){
        var $ctrl = this;

        $ctrl.onInit = function(){
            $ctrl.showInstalled = onboardingReportCache.get('showInstalled') || false;
        };

        $ctrl.dateRangePickerOptions = reportService.getDateRangePickerOptions();

        $ctrl.tableParams = new NgTableParams(onboardingReportCache.get('parameters') || {}, {
            getData: function(params){
                var start = $ctrl.searchParams.start();
                $ctrl.hasDateFilter = String(start).indexOf('Jan 01 2000 00:00:00') === -1 && start;

                $ctrl.isLoading = true;
                onboardingReportCache.put('parameters', params.parameters());
                return reportService.runReport(reportSlug, params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search'),
            department: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'department'),
            group: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'group'),
            showInstalled: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'show_installed'),
            start: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'start'),
            end: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'end')
        };

        $ctrl.downloadCsv = function(){
            reportService.downloadReportAsCSV(reportSlug, onboardingReportCache.get('parameters'), 'Onboarding Report.csv');
        };

        $ctrl.onShowInstalledChange = function(){
            $ctrl.showInstalled = $ctrl.showInstalled ? false : true;
            onboardingReportCache.put('showInstalled', $ctrl.showInstalled);
        };

}]);
