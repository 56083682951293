/**
 * @ngdoc service
 * @name smartpager.endUser.shared:directoryService
 *
 * @description
 *
 *
 * */
angular
  .module("smartpager.endUser.shared")
  .service("directoryService", ["baseService", "configuration", function(baseService, configuration) {
    var Service = Object.create(baseService);

    Service.getDirectoriesAssignableToObject = function(ct, obj, query) {
      var params = $.param(query || {});

      var url = String.format(
        "/api/v1/directories/assignable_to/{0}/{1}/{2}",
        ct,
        obj,
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.getScopedDirectoryAccessForGrid = function(
      scope,
      objectId,
      parameters
    ) {
      var url = String.format(
        "/api/v1/directories/directory_access/{0}/{1}/{2}",
        scope,
        objectId,
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    Service.addDirectoryAccess = function(scope, objectId, sfs) {
      var url = String.format(
        "/api/v1/directories/directory_access/",
        scope,
        objectId
      );
      var data = _.map(sfs, function(sf) {
        return { directory: sf.id, object_id: objectId, content_type: scope };
      });
      return this.postData(url, data);
    };

    Service.removeDirectoryAccess = function(id) {
      var url = String.format("/api/v1/directories/directory_access/{0}/", id);
      return this.deleteData(url);
    };

    Service.getDirectoryObjects = function(query) {
      query = query ? query : {};
      var params = $.param(query);
      var url = "/api/v1/directories/objects/" + (params ? "?" + params : "");

      return this.getData(url);
    };

    Service.getUserDirectoryEntries = function(query) {
      query = query ? query : {};
      query.is_contactable = "True";
      var params = $.param(query);
      var url = String.format(
        "/api/v1/directory/{0}",
        params ? "?" + params : ""
      );
      return this.getData(url);
    };

    Service.getDirectoryObjectsForGrid = function(parameters) {
      var url = String.format(
        "/api/v1/directories/objects/{0}",
        this.toUrl(parameters)
      );
      return this.getData(url);
    };

    Service.getUserDirectoryObjectsForGrid = function(parameters) {
      parameters.is_contactable = "True";
      var url = String.format("/api/v1/directory/{0}", this.toUrl(parameters));
      return this.getData(url);
    };

    Service.getFavoritedDirectoryObjects = function() {
      var url = "/api/v1/directory/favourites/";
      return this.getData(url);
    };

    Service.favoriteDirectoryObject = function(objectId) {
      var url = "/api/v1/directory/favourites/";
      return this.postData(url, { object_id: objectId });
    };

    Service.unfavoriteDirectoryObject = function(favoriteId) {
      var url = String.format("/api/v1/directory/favourites/{0}/", favoriteId);
      return this.deleteData(url);
    };

    Service.getDirectoryObjectsForContactableIds = function(contactableIds) {
      var url = String.format(
        "/api/v1/directory/?contact_ids={0}",
        contactableIds.join()
      );
      return this.getData(url);
    };

    Service.getDirectoryObject = function(id) {
      var url = "/api/v1/directories/objects/" + id;
      return this.getData(url);
    };

    Service.getDirectoryEntry = function(id) {
      var url = String.format("/api/v1/directories/{0}/", id);
      return this.getData(url);
    };

    Service.getDirectoryObjectForContactable = function(contactableId) {
      var url = String.format(
        "/api/v1/directory/?contact_ids={0}",
        contactableId
      );
      return this.getData(url).then(function(resp) {
        if (resp.results.length !== 1) {
          return null;
        }
        return resp.results[0];
      });
    };

    Service.getDirectoryObjectForCareTeamContactId = function(id) {
      var url = String.format("api/v1/contacts/{0}/", id);
      return this.getData(url).then(function(contact) {
        var directoryObject = {
          id: "",
          name: contact.display_name,
          type: contact.type,
          contact_id: contact.id,
          avatar: null,
          managed: false // Managed is false so a status indicator isn't shown
        };
        return directoryObject;
      });
    };

    return Service;
  }]);
