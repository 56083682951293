/**
 * @ngdoc directive
 * @name smarpager.endUser.messages:avatarDirective
 *
 * @description
 *
 *
 * @restrict E
 * */

var PERSON = 'directories.person';
var GROUP = 'directories.group';
var LOCATION = 'directories.location';
var CARE_TEAM = 'CareTeam'; // Faked from directory service
var COLORS = [
  '#4674ca', // blue
  '#315cac', // blue_dark
  '#57be8c', // green
  '#3fa372', // green_dark
  '#f9a66d', // yellow_orange
  '#ec5e44', // red
  '#e63717', // red_dark
  '#f868bc', // pink
  '#6c5fc7', // purple
  '#4e3fb4', // purple_dark
  '#57b1be', // teal
  '#847a8c' // gray
];

function getStatusIndicatorScope(width, height, obj, showStatus) {
  var OFFLINE = 1;
  var ONLINE = 2;
  var color = '#dddddd';
  var showStatusIndicator = true;

  if (
    obj.type === LOCATION ||
    obj.type === GROUP ||
    !obj.properties ||
    !showStatus ||
    !obj.managed
  ) {
    return { showStatusIndicator: false };
  }

  if (obj.properties.status === OFFLINE) {
    color = 'red';
  } else if (obj.properties.status === ONLINE) {
    color = 'green';
  }

  var cx = width - width * 0.15;
  var cy = height - height * 0.15;
  var r = width * 0.15;
  return {
    cx: cx,
    cy: cy,
    r: r,
    showStatusIndicator: showStatusIndicator,
    statusColor: color
  };
}

function getColor(identifier) {
  identifier += '';
  var hash = 0;

  for (var i = 0; i < identifier.length; i++) {
    hash += identifier.charCodeAt(i);
  }
  return COLORS[hash % COLORS.length];
}

function getInitials(name) {
  name = name ? name : '';
  var names = (name.trim() || '?').split(' ');
  return (
    names[0][0] + (names.length > 1 ? names[names.length - 1][0] : '')
  ).toUpperCase();
}

function getIcon(obj) {
  switch (obj.type) {
    case GROUP:
      return getGroupIcon(obj);
    case LOCATION:
      return String.fromCharCode(0xf041);
    case CARE_TEAM:
      return String.fromCharCode(0xf0c0);
    default:
      return null;
  }
}

function getGroupIcon(obj) {
  switch (obj.content_type_string) {
    case 'paginggroups.schedulegroup':
      return String.fromCharCode(0xf073);
    case 'paginggroups.broadcastgroup':
      return String.fromCharCode(0xf1eb);
    default:
      return String.fromCharCode(0xf0c0);
  }
}

angular.module('smartpager.endUser.messages').directive('avatar', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      height: '=',
      width: '=',
      object: '=',
      showStatus: '='
    },
    template: '<ng-include src="getTemplateUrl()"/>',
    controller: ["$scope", function($scope) {
      $scope.$watch('object', function(newVal, oldVal) {
        if (newVal) {
          updateScopeVars(newVal);
        }
      });

      $scope.getTemplateUrl = function() {
        var baseUrl =
          '/smartpager/angular/endUser/messages/components/avatar/avatar-';
        var postfix = 'empty.html';
        if ($scope.object && $scope.object.avatar) {
          postfix = 'img.html';
        } else if ($scope.object) {
          postfix = 'text.html';
        }
        return baseUrl + postfix;
      };

      if (!$scope.object) {
        return;
      }

      updateScopeVars($scope.object);

      function updateScopeVars(obj) {
        var height = $scope.height;
        var width = $scope.width;
        var showStatus = $scope.showStatus;
        var identifier = !obj.properties ?
            obj.name ||
            obj.id : obj.properties.name ||
            String.format(
                '{0}, {1}',
                obj.properties.last_name,
                obj.properties.first_name
            );

        if (showStatus) {
          $scope = Object.assign(
            $scope,
            getStatusIndicatorScope(width, height, obj, showStatus)
          );
        }
        var fontSize = Math.ceil(width / 2);
        $scope.textStyle = { fontSize: fontSize, dominantBaseline: 'central' };
        $scope.style = { margin: '0 auto', height: height, width: width };
        $scope.viewBox = String.format('0 0 {0} {1}', width, height);
        $scope.color = getColor(identifier);
        $scope.text =
          obj.type === PERSON ? getInitials(identifier) : getIcon(obj);
        $scope.classname = obj.type !== PERSON ? 'svg-icon' : '';
      }
    }]
  };
});
