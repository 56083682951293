/**
 * @ngdoc directive
 * @name audioPlayer
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.shared')
    .directive('audioPlayer', ["$sce", function ($sce) {
        return {
            restrict: 'E',
            templateUrl: '/smartpager/angular/endUser/shared/components/audioPlayer/audioPlayer.html',
            scope: {
                audioSrc: '='
            },
            link : function (scope, elem, attr) {
                scope.audioSrc.secureUrl = $sce.trustAsResourceUrl(scope.audioSrc.url);
            }
        };
    }]);
