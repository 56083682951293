/**
 * @ngdoc service
 * @name smartpager.endUser.shared:groupsService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .service('savedFiltersService', ["baseService", "configuration", function (baseService, configuration) {

        var Service = Object.create(baseService);

        Service.getSavedFilters = function (query) {
            var params = $.param(query || {});

            var url = String.format('/api/v1/tagging/saved_filter/{0}', params ? '?' + params : '');
            return this.getData(url);
        };

        Service.getSavedFiltersAssignableToObject = function (ct, obj, query) {
            var params = $.param(query || {});

            var url = String.format('/api/v1/tagging/saved_filter/assignable_to/{0}/{1}/{2}', ct, obj, params ? '?' + params : '');
            return this.getData(url);
        };

        Service.getSavedFiltersForGrid = function (parameters) {
            var url = String.format('/api/v1/tagging/saved_filter/{0}', this.toUrl(parameters));
            return this.getData(url);
        };

        Service.getSavedFilter = function (savedFilterId) {
            var url = String.format('/api/v1/tagging/saved_filter/{0}/', savedFilterId);
            return this.getData(url);
        };

        Service.getSavedFilterTags = function (savedFilterId) {
            var url = String.format('/api/v1/tagging/saved_filter/{1}/tags/', savedFilterId);
            return this.getData(url);
        };

        Service.getSavedFilterTagsForGrid = function(savedFilterId, parameters){
            var url = String.format('/api/v1/tagging/saved_filter/{0}/tags/{1}/', savedFilterId, this.toUrl(parameters));
            return this.getData(url);
        };

        Service.removeTag = function (savedFilterId, id) {
            var url = String.format('/api/v1/tagging/saved_filter/{0}/tags/{1}/', savedFilterId, id);
            return this.deleteData(url);
        };

        Service.addTags = function (savedFilterId, tags) {
            var url = String.format('/api/v1/tagging/saved_filter/{0}/tags/', savedFilterId);
            var data = _.map(tags, function (tag) {
                return {id: tag.id};
            });
            return this.postData(url, data);
        };

        Service.saveSavedFilter = function (savedFilterId, name) {
            var url = String.format('/api/v1/tagging/saved_filter/{0}/', savedFilterId);
            return this.putData(url, {name: name});
        };

        Service.createSavedFilter = function (name, contentType) {
            var url = '/api/v1/tagging/saved_filter/';
            return this.postData(url, {name: name, content_type: contentType});
        };

        Service.deleteSavedFilter = function (savedFilterId) {
            var url = String.format('/api/v1/tagging/saved_filter/{0}/', savedFilterId);
            return this.deleteData(url);
        };

        Service.getPermissionList = function () {
            var url = '/api/v1/auth/permissions/';
            return this.getData(url, {cache: true})
                .then(function (response) {
                    return _.flatten(_.map(response, 'permissions'));
                });
        };

        Service.getScopedSavedFilterApplicationsForGrid = function (scope, objectId, parameters) {
            var url = String.format('/api/v1/tagging/saved_filter_application/{0}/{1}/{2}', scope, objectId, this.toUrl(parameters));
            return this.getData(url);
        };

        Service.getContentTypes = function () {
            var url = '/api/v1/tagging/ct/';
            return this.getData(url);
        };

        Service.addSavedFilterApplication = function (scope, objectId, sfs) {
            var url = String.format('/api/v1/tagging/saved_filter_application/', scope, objectId);
            var data = _.map(sfs, function(sf) {
                return {saved_filter: sf.id, object_id: objectId, content_type: scope};
            });
            return this.postData(url, data);
        };

        Service.removeSavedFilterApplication = function (id) {
            var url = String.format('/api/v1/tagging/saved_filter_application/{0}/', id);
            return this.deleteData(url);
        };

        return Service;
    }]);
