/**
 * @ngdoc directive
 * @name iqUserSelect
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .directive('iqGroupSelect', ["$log", "$compile", "$templateRequest", function ($log, $compile, $templateRequest) {
        return {
            restrict: 'E',
            require: 'ngModel',
            transclude: true,
            bindToController: true,
            scope: {
                label: '@',
                propertyToMatch: '@',
                onSelect: '&',
                noLabel: '@'
            },
            controller: 'iqGroupSelectCtrl as $ctrl',
            templateUrl: function (tElement, tAttrs) {
                if (tAttrs.multiple && tAttrs.multiple === 'true') {
                    return '/smartpager/angular/endUser/shared/components/iqGroupSelect/iqGroupSelect-multiple.html';
                } else {
                    return '/smartpager/angular/endUser/shared/components/iqGroupSelect/iqGroupSelect-single.html';
                }
            },
            link: function (scope, iElement, iAttrs, ngModelCtrl) {
                //region handle ngModelCtrl

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return angular.copy(modelValue);
                });

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return angular.copy(viewValue);
                });

                scope.$watchCollection('$ctrl.groups', function (newGroups) {
                    $log.debug('iqGroupSelectCtrl updates ngModel binding', newGroups);
                    ngModelCtrl.$setViewValue(angular.copy(newGroups));
                });

                ngModelCtrl.$render = function () {
                    scope.$ctrl.groups = ngModelCtrl.$viewValue;
                };

                if (iAttrs.required) {
                    ngModelCtrl.$validators.required = function (modelValue, viewValue) {
                        if(iAttrs.multiple && iAttrs.multiple === 'true') {
                            return !!(modelValue && modelValue.length !== 0);
                        } else {
                            return !!modelValue;
                        }
                    };
                }
                //endregion
            }
        };
    }]);
