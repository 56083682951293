/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:deleteRoleModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('deleteRoleModalCtrl', ["$scope", "modalOptions", "roleService", "Notification", function ($scope, modalOptions, roleService, Notification) {
        var $ctrl = this;
        $ctrl.role = modalOptions.role;

        $ctrl.deleteRole = function () {
            $ctrl.isDeleting = true;
            roleService.deleteRole($ctrl.role.id)
                .then(function (response) {
                    Notification.success('Successfully deleted role');
                    $scope.$close();
                })
                .catch(function (response) {
                    Notification.error('Could not delete role');
                })
                .finally(function () {
                    $ctrl.isDeleting = false;
                });
        };
    }]);
