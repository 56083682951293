/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:editPatientNoteModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('editPatientNoteModalCtrl', ["$scope", "modalOptions", "patientsService", "alertsHelper", function ($scope, modalOptions, patientsService, alertsHelper) {

        var $ctrl = this;

        alertsHelper.create($ctrl, 'alerts');

        $ctrl.note = modalOptions.note;

        $ctrl.makeMedicalRecord = function () {
            $ctrl.note.type = 'record';
        };

        $ctrl.makeShared = function () {
            $ctrl.note.type = 'shared';
        };

        $ctrl.updateNote = function () {
            $ctrl.isSaving = true;
            patientsService.updateNote($ctrl.note)
                .then(function (result) {
                    $scope.$close(result);
                })
                .catch(function () {
                    $ctrl.alerts.error('Could not save note');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
