angular
  .module('smartpager.endUser.pagingGroups')
  .factory('scheduleGroupScheduleCtrlCache', ["$cacheFactory", function($cacheFactory) {
    return $cacheFactory('scheduleGroupScheduleCtrlCache');
  }])
  .controller('scheduleGroupScheduleCtrl', ["$scope", "$state", "$stateParams", "uiCalendarConfig", "$log", "configuration", "pagingGroupService", "pagingGroupPermissionsService", "modalHelper", "escalationsService", "eventService", "permissionsService", "Notification", "scheduleGroupScheduleCtrlCache", "userService", function(
    $scope,
    $state,
    $stateParams,
    uiCalendarConfig,
    $log,
    configuration,
    pagingGroupService,
    pagingGroupPermissionsService,
    modalHelper,
    escalationsService,
    eventService,
    permissionsService,
    Notification,
    scheduleGroupScheduleCtrlCache,
    userService
  ) {
    $scope.events = [];

    var hasEventEditPermissions = false;

    //this method is called by calendar itself, do not call manually
    $scope.loadEvents = function(start, end, timezone, callback) {
      $log.debug('loading events', start, end, timezone);
      $scope.calendarLoading = true;
      eventService
        .getEvents(
          $stateParams.groupId,
          eventService.getNativeMoment(start),
          eventService.getNativeMoment(end)
        )
        .then(function(eventsData) {
          angular.forEach(eventsData, function(item) {
            $scope.events.push(item);
          });

          //picking up events from $scope.events for easy manipulation, hence resolving calendar callback with empty array
          callback([]);
        })
        .finally(function() {
          $scope.calendarLoading = false;
        });
    };

    $scope.activate = function() {
      escalationsService
        .getEscalationPolicies()
        .then(function(response) {
          $scope.escalationPolicies = response.results;

          return pagingGroupService.getScheduleGroup($stateParams.groupId);
        })
        .then(function(group) {
          var groupEscallationPolicy = _.find($scope.escalationPolicies, {
            id: group.escalation_policy
          });
          if (groupEscallationPolicy) {
            eventService.escallationPolicyNameForCurrentlySelectedBroadcastGroup =
              groupEscallationPolicy.name;
          } else {
            eventService.escallationPolicyNameForCurrentlySelectedBroadcastGroup =
              '';
          }
          $scope.group = group;

          angular.forEach(group.members, function(member) {
            member.color = eventService.assignColorToUser(member.id);
          });

          hasEventEditPermissions =
            pagingGroupPermissionsService.hasOnCallEventChangePermission(
              $scope.group
            ) ||
            pagingGroupPermissionsService.hasOnCallEventAddPermission(
              $scope.group
            );

          $scope.uiConfig = getCalendarConfig({
            calendar: {
              editable: hasEventEditPermissions,
              selectable: hasEventEditPermissions,
              eventStartEditable: hasEventEditPermissions,
              eventDurationEditable: hasEventEditPermissions
            }
          });
        });
    };
    $scope.activate();

    //region calendar events
    function showAddOrSeeDetailsModal(event) {
      var hasSubmitPermission = !event.id ? pagingGroupPermissionsService.hasOnCallEventAddPermission(
          $scope.group
      ) : pagingGroupPermissionsService.hasOnCallEventChangePermission(
          $scope.group
      );
        var hasDeletePermission = pagingGroupPermissionsService.hasOnCallEventDeletePermission(
        $scope.group
      );

      return modalHelper.openModal({
        templateUrl:
          '/smartpager/angular/endUser/pagingGroups/controllers/modals/scheduleNewEventModalCtrl.html',
        controller: 'scheduleNewEventModalCtrl',
        event: event,
        group: $scope.group,
        escalationPolicies: $scope.escalationPolicies,
        hasSubmitPermission: hasSubmitPermission,
        hasDeletePermission: hasDeletePermission
      }).result;
    }

    $scope.addEventFromSelection = function(start, end) {
      var newEvent = {
        start: eventService.getNativeMoment(start),
        end: eventService.getNativeMoment(end)
      };
      newEvent.allDay = eventService.isAllDay(newEvent);

      showAddOrSeeDetailsModal(newEvent).then(function(result) {
        $scope.events.push(result);
      });
    };

    $scope.moveEvent = function(event, delta, revertFunc, jsEvent, ui, view) {
      $log.debug(
        'moveEvent',
        event.start.toISOString(),
        event.original_start.toISOString()
      );

      event.editable = event.startEditable = event.durationEditable = false;

      var update_for;

      eventService
        .getUpdateFor(event)
        .then(function(selected_update_for) {
          update_for = event.update_for = selected_update_for;

          return eventService.updateEvent($scope.group.id, event);
        })
        .then(function(modifiedEvent) {
          if (update_for === 'once') {
            updateSingleEvent(event, modifiedEvent);
          } else {
            updateRepeatingEvent(event, update_for);
          }
          Notification.success('Successfully updated event(s)');
        })
        .catch(function(response) {
          Notification.error('Could not update event');
          $log.debug('error, reverting event', response);
          revertFunc();
        })
        .finally(function() {
          event.editable = event.startEditable = event.durationEditable = true;
        });
    };

    $scope.eventClick = function(event, jsEvent, view) {
      $log.debug('eventClick', event.start, event.original_start);

      showAddOrSeeDetailsModal(angular.copy(event)).then(function(
        modifiedEvent
      ) {
        if (modifiedEvent.deleted) {
          $log.debug('event deleted', modifiedEvent);

          _.remove($scope.events, {
            id: event.event_id,
            original_start: event.original_start
          });

          if (modifiedEvent.update_for !== 'once') {
            deleteRepeatingEvent(modifiedEvent, modifiedEvent.update_for);
          }
        } else {
          $log.debug('event updated', modifiedEvent);
          if (modifiedEvent.update_for === 'once') {
            updateSingleEvent(event, modifiedEvent);
          } else {
            updateRepeatingEvent(modifiedEvent, modifiedEvent.update_for);
          }
        }
      });
    };

    $scope.viewRender = function(view, element) {
      $scope.calendarViewStart = eventService.getNativeMoment(view.start);
      $scope.calendarViewEnd = eventService.getNativeMoment(view.end);

      scheduleGroupScheduleCtrlCache.put('defaultView', view.name);
      scheduleGroupScheduleCtrlCache.put(
        'defaultDate',
        view.intervalStart.toISOString()
      );
    };

    //endregion

    $scope.eventSources = [$scope.loadEvents, $scope.events];

    function getCalendarConfig(settings) {
      return _.merge(
        {
          calendar: {
            timezone: 'local',
            defaultView:
              scheduleGroupScheduleCtrlCache.get('defaultView') || 'agendaWeek',
            forceEventDuration: true,
            editable: permissionsService.hasPermission(
              'scheduling.change_oncallevent'
            ),
            selectable: permissionsService.hasPermission(
              'scheduling.change_oncallevent'
            ),
            defaultDate:
              scheduleGroupScheduleCtrlCache.get('defaultDate') || moment(),
            header: {
              left: 'month,agendaWeek,agendaDay',
              center: 'title',
              right: 'today prev,next'
            },
            nowIndicator: true,
            height: 'auto',
            eventClick: $scope.eventClick,
            select: $scope.addEventFromSelection,
            eventDrop: $scope.moveEvent,
            eventResize: $scope.moveEvent,
            eventRender: eventService.eventRender,
            viewRender: $scope.viewRender
          }
        },
        settings
      );
    }

    function updateSingleEvent(event, modifiedEvent) {
      _.remove($scope.events, {
        id: event.event_id,
        original_start: eventService.getNativeMoment(event.original_start)
      });
      $scope.events.push(modifiedEvent);
    }

    function updateRepeatingEvent(updatedEvent, updateFor) {
      eventService
        .getEvents(
          $scope.group.id,
          $scope.calendarViewStart,
          $scope.calendarViewEnd
        )
        .then(function(occurrences) {
          //when updating following a new event_id is created, since i don't know it's i need to reload all events
          if (updateFor === 'following') {
            //remove itself from displayed calendar
            _.remove($scope.events, {
              event_id: updatedEvent.event_id,
              original_start: updatedEvent.original_start
            });

            //remove following with same event_id from calendar
            deleteRepeatingEvent(updatedEvent, updateFor);

            //remove events that remained displayed from list of received (only those with new event_id will remain)
            $scope.events.forEach(function(event) {
              _.remove(occurrences, { event_id: event.event_id });
            });

            addRepeatingEvent(occurrences);
          } else if (updateFor === 'all') {
            //keep only occurrences from this event
            occurrences = _.remove(occurrences, {
              event_id: updatedEvent.event_id
            });

            deleteRepeatingEvent(updatedEvent, updateFor);
            addRepeatingEvent(occurrences);
          }
        });
    }

    function addRepeatingEvent(occurrences) {
      angular.forEach(occurrences, function(occ) {
        $scope.events.push(occ);
      });
    }

    function deleteRepeatingEvent(updatedEvent, updateFor) {
      if (updateFor === 'all') {
        _.remove($scope.events, function(event) {
          return event.event_id === updatedEvent.event_id;
        });
      } else if (updateFor === 'following') {
        _.remove($scope.events, function(event) {
          return (
            event.event_id === updatedEvent.event_id &&
            event.original_start.isAfter(updatedEvent.original_start)
          );
        });
      }
    }
  }]);
