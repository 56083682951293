/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:addMemberToDepartmentModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .factory('addMemberListCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('addMemberListCache');
    }])
    .controller('addMemberToDepartmentModalCtrl', ["$scope", "userService", "modalOptions", "NgTableParams", "ngTableHelper", "addMemberListCache", "Notification", function ($scope, userService, modalOptions, NgTableParams, ngTableHelper, addMemberListCache, Notification) {
        var $ctrl = this;

        $ctrl.department = modalOptions.department;
        $ctrl.member_ids = [];

        $ctrl.addMemberTableParams = new NgTableParams(addMemberListCache.get('parameters') || {
                count: 10
            }, {
            getData: function (params) {
                var p = params.parameters();
                p.linked = true;
                p.is_active = 'True';

                addMemberListCache.put('parameters', p);
                $ctrl.isLoading = true;
                return userService.getUsersForGrid(p)
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.addMemberSearchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.addMemberTableParams, 'search')
        };

        $ctrl.addSelected = function (member) {
            userService.addDepartmentMember($ctrl.department.id, member)
                .then(function (response) {
                    Notification.success('Successfully added ' + member.display_name + ' to ' + $ctrl.department.name);
                    $ctrl.member_ids.push(response.id);
                })
                .catch(function (error) {
                    if (error.data.user_id) {
                        Notification.success('Successfully added ' + member.display_name + ' to ' + $ctrl.department.name);
                        $ctrl.member_ids.push(member.id);
                    } else {
                        Notification.error('Could not add ' + member.display_name + ' to ' + $ctrl.department.name);
                    }
                });
        };

        $ctrl.removeSelected = function (member) {
            userService.removeDepartmentMember($ctrl.department.id, member.id)
                .then(function () {
                    Notification.success('Successfully removed ' + member.display_name + ' from ' + $ctrl.department.name);
                    $ctrl.member_ids = _.without($ctrl.member_ids, member.id);
                })
                .catch(function () {
                    Notification.error('Could not remove ' + member.display_name + ' from ' + $ctrl.department.name);
                });
        };

        $ctrl.inMembers = function (memberId) {
            return $ctrl.member_ids.indexOf(memberId) !== -1;
        };
    }]);
