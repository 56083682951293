/**
 * @ngdoc directive
 * @name subscriberStatusIcon
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.endUser.messages')
    .directive('subscriberStatusIcon', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                state: '='
            },
            template: '<i class="fa" ng-class="{\'fa-check\' : state == \'read\', \'fa-reply\' : state == \'replied\'}"></i>'
        };
    });
