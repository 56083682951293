  angular.module('smartpager.endUser.users', [
        'smartpager.endUser.shared'
    ])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        //root state of application
        $stateProvider
            .state('root.users', {
                url: 'users/',
                template: '<div ui-view></div>',
                abstract: true,
                data: {
                    title: 'Telmediq - Users',
                    requiredPermissions: [],
                    pageClass: 'users'
                }
            })
            .state('root.users.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/users/controllers/userList.html',
                controller: 'userListCtrl as $ctrl'
            });
    }]);
