/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:savePermissionChangesModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('savePermissionChangesModalCtrl', ["$scope", "Notification", "modalOptions", "roleService", "alertsHelper", function ($scope, Notification, modalOptions, roleService, alertsHelper) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.activate = function () {
            $ctrl.role = modalOptions.role;
            $ctrl.differences = modalOptions.differences;
        };
        $ctrl.activate();

        $ctrl.saveChanges = function () {
            $ctrl.isSaving = true;
            $ctrl.role.permission_ids = $ctrl.role.permission_ids.concat($ctrl.differences.added_ids);
            $ctrl.role.permission_ids = _.difference($ctrl.role.permission_ids, $ctrl.differences.removed_ids);

            roleService.updateRole($ctrl.role)
                .then(function (response) {
                    Notification.success(String.format('Successfully updated {0}', $ctrl.role.name));
                    $scope.$close();
                })
                .catch(function () {
                    $ctrl.alerts.error('Could not update role');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
