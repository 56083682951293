/**
 * @ngdoc service
 * @name smartpager.endUser.shared:messageRoutingService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .service('messageRoutingService', ["$rootScope", "baseService", "modalHelper", "configuration", function ($rootScope, baseService, modalHelper, configuration) {
        var Service = Object.create(baseService);

        Service.fluxEvents = {
            getRoutingPolicy: {
                key: 'messageRoutingService.getRoutingPolicy'
            }
        };

        Service.getRoutingPoliciesForGrid = function (params) {
            var url = String.format('/api/v1/routing/routing_policy/{0}', baseService.toUrl(params));
            return this.getData(url);
        };

        Service.createRoutingPolicy = function (policy) {
            var url = String.format('/api/v1/routing/routing_policy/');
            return this.postData(url, policy);
        };

        Service.requestGetRoutingPolicy = function (policyId) {
            if (Service.fluxEvents.getRoutingPolicy.isLoading) {
                return;
            }
            Service.fluxEvents.getRoutingPolicy.isLoading = true;
            Service.getRoutingPolicy(policyId)
                .then(function (result) {
                    $rootScope.$emit(Service.fluxEvents.getRoutingPolicy.key, result);
                })
                .finally(function () {
                    Service.fluxEvents.getRoutingPolicy.isLoading = false;
                });
        };

        Service.getRoutingPolicy = function (policyId) {
            var url = String.format('/api/v1/routing/routing_policy/{0}/', policyId);
            return this.getData(url);
        };

        Service.updateRoutingPolicy = function (policy) {
            var url = String.format('/api/v1/routing/routing_policy/{0}/', policy.id);
            return this.putData(url, policy)
                .then(function (response) {
                    $rootScope.$emit(Service.fluxEvents.getRoutingPolicy.key, response);
                    return response;
                });
        };

        Service.deleteRoutingPolicy = function (id) {
            var url = String.format('/api/v1/routing/routing_policy/{0}/', id);
            return this.deleteData(url);
        };

        Service.getConditionSetsForGrid = function (params) {
            var url = String.format('/api/v1/routing/condition_set/{0}', baseService.toUrl(params));
            return this.getData(url);
        };

        Service.getConditionSets = function (query) {
            var params = $.param(query ? query : {});
            var url = String.format('/api/v1/routing/condition_set/{0}', (params ? '?' + params : ''));
            return this.getData(url);
        };

        Service.createConditionSet = function (conditionSet) {
            var url = String.format('/api/v1/routing/condition_set/');
            return this.postData(url, conditionSet);
        };

        Service.updateConditionSet = function (conditionSet) {
            var url = String.format('/api/v1/routing/condition_set/{0}', conditionSet.id);
            return this.putData(url, conditionSet);
        };

        Service.deleteConditionSet = function (conditionSetId) {
            var url = String.format('/api/v1/routing/condition_set/{0}', conditionSetId);
            return this.deleteData(url);
        };


        Service.getConditionsForGrid = function (params) {
            var url = String.format('/api/v1/routing/condition/{0}', baseService.toUrl(params));
            return this.getData(url);
        };

        Service.getConditions = function (query) {
            var params = $.param(query ? query : {});
            var url = String.format('/api/v1/routing/condition/{0}', (params ? '?' + params : ''));
            return this.getData(url);
        };

        Service.getConditionById = function (url_prefix, id) {
            var url = String.format('/api/v1/routing/{0}/{1}', url_prefix, id);
            return this.getData(url);
        };

        Service.createCondition = function (condition_type, condition) {
            var url = String.format('/api/v1/routing/{0}/', _.snakeCase(condition_type));
            return this.postData(url, condition);
        };

        Service.updateCondition = function (condition) {
            var url = String.format('/api/v1/routing/{0}/{1}/', condition.url_prefix, condition.id);
            return this.putData(url, condition);
        };

        Service.deleteCondition = function (url_prefix, conditionId) {
            var url = String.format('/api/v1/routing/{1}/{0}', conditionId, url_prefix);
            return this.deleteData(url);
        };

        Service.getActions = function (query) {
            var params = $.param(query ? query : {});
            var url = String.format('/api/v1/routing/action/{0}', (params ? '?' + params : ''));
            return this.getData(url);
        };

        Service.getActionsForGrid = function (params) {
            var url = String.format('/api/v1/routing/action/{0}', baseService.toUrl(params));
            return this.getData(url);
        };

        Service.getActionById = function (url_prefix, id) {
            var url = String.format('/api/v1/routing/{0}/{1}', url_prefix, id);
            return this.getData(url);
        };


        Service.getActionTypes = function () {
            var url = '/api/v1/routing/action_types/';
            return this.getData(url);
        };

        Service.createAction = function (action_type, action) {
            var url = String.format('/api/v1/routing/{0}/', _.snakeCase(action_type));
            return this.postData(url, action);
        };

        Service.updateAction = function (action) {
            var url = String.format('/api/v1/routing/{0}/{1}/', action.url_prefix, action.id);
            return this.putData(url, action);
        };

        Service.deleteAction = function (url_prefix, actionId) {
            var url = String.format('/api/v1/routing/{1}/{0}/', actionId, url_prefix);
            return this.deleteData(url);
        };

        Service.getConditionTypes = function () {
            var url = '/api/v1/routing/condition_types/';

            function label(val) {
                var first = val.split('__')[0];
                var last = val.split('__').slice(-1)[0];

                switch (last) {
                    case 'ilike':
                        last = 'contains';
                        break;
                    case 'in':
                        last = 'is one of';
                        break;
                    case 'gte':
                        last = 'is greater than or equal to';
                        break;
                    case 'lte':
                        last = 'is less than or equal to';
                        break;
                }

                return String.format('{0} {1}', first.replace(/_/g, ' '), last);
            }

            function getMeta(val) {
                var meta = [];

                _.forIn(val, function (value, key) {
                    meta.push({
                        type: key.split(/(__)/).slice(-1)[0],
                        label: _.capitalize(label(key)),
                        original: key,
                        model: value.split('.').slice(-1)[0]
                    });
                });
                return meta;
            }

            return this.getData(url)
                .then(function (response) {
                    // var results = [];
                    // _.forIn(response, function (value, key) {
                    //     results.push({original: key, meta: getMeta(value), name: key.split(/(?=[A-Z])/).join(' ')});
                    // });

                    return response;
                });
        };

        return Service;
    }]);
