/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:messageRoutingListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .factory('messageRoutingListCache', ["$cacheFactory", function($cacheFactory){
        return $cacheFactory('messageRoutingListCache');
    }])
    .controller('messageRoutingListCtrl', ["$scope", "messageRoutingListCache", "NgTableParams", "messageRoutingService", "ngTableHelper", "modalHelper", "$state", "routingModalsFactory", function($scope, messageRoutingListCache, NgTableParams, messageRoutingService, ngTableHelper, modalHelper, $state, routingModalsFactory){
        var $ctrl = this;

        $ctrl.tableParams = new NgTableParams(messageRoutingListCache.get('parameters') || {
                sorting: {
                    priority: 'asc'
                },
                filter: {
                    is_active: 'True'
                }
            }, {
            defaultSort: 'asc',
            getData: function(params){
                messageRoutingListCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;

                return messageRoutingService.getRoutingPoliciesForGrid(params.parameters())
                    .then(function(response){
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function(){
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search'),
            is_active: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'is_active')
        };

        $ctrl.clearSearch = function () {
            $ctrl.searchParams.search(null);
        };

        $ctrl.openAddMessageRoutingPolicyModal = function(){
            return routingModalsFactory.showAddMessageRoutingModal()
                .then(function(response){
                    $state.go('root.messageRouting.detail.conditionSets', {policyId: response.id});
                })
                .catch(function(){
                    $ctrl.tableParams.reload();
                });
        };
}]);
