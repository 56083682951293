angular.module("smartpager.endUser.shared").constant("contentTypes", {
  directories: {
    person: "directories.person",
    group: "directories.group",
    location: "directories.location"
  },
  pagingGroups: {
    broadcast: "paginggroups.broadcastgroup",
    schedule: "paginggroups.schedulegroup"
  },
  contactables: {
    careTeam: "CareTeam"
  }
});
