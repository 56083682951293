angular.module('smartpager.endUser.accountSettings', [
    'smartpager.endUser.shared'
])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        //root state of application
        $stateProvider
            .state('root.accountSettings', {
                url: 'accountSettings',
                abstract: true,
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/accountSettings.html',
                controller: 'accountSettingsCtrl',
                data: {
                    title: 'Telmediq - Account Settings',
                    requiredPermissions: []
                }
            })

            //region messaging
            .state('root.accountSettings.noteTemplates', {
                url: '/noteTemplates',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('root.accountSettings.noteTemplates.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/noteTemplatesList.html',
                controller: 'noteTemplatesListCtrl as $ctrl'
            })
            .state('root.accountSettings.noteTemplates.detail', {
                url: '/:templateId',
                abstract: true,
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/noteTemplatesDetail.html',
                controller: 'noteTemplatesDetailCtrl as $templateCtrl',
                resolve: {
                    templateId: ["$stateParams", function ($stateParams) {
                        return $stateParams.templateId;
                    }]
                }
            })
            .state('root.accountSettings.noteTemplates.detail.settings', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/noteTemplatesSettings.html',
                controller: 'noteTemplatesSettingsCtrl as $ctrl'
            })
            .state('root.accountSettings.noteTemplates.detail.fields', {
                url: '/fields',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/noteTemplatesFields.html',
                controller: 'noteTemplatesFieldsCtrl as $ctrl'
            })
            .state('root.accountSettings.noteFields', {
                url: '/noteFields',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('root.accountSettings.noteFields.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/noteFieldsList.html',
                controller: 'noteFieldsListCtrl as $ctrl'
            })
            .state('root.accountSettings.noteFields.detail', {
                url: '/:fieldId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/messaging/notes/noteFieldsDetail.html',
                controller: 'noteFieldsDetailCtrl as $ctrl',
                resolve: {
                    fieldId: ["$stateParams", function ($stateParams) {
                        return $stateParams.fieldId;
                    }]
                }
            })
            //endregion

            //region user sync
            .state('root.accountSettings.agentList', {
                url: '/adAgents',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/agents/agentList.html',
                controller: 'agentListCtrl as $ctrl'
            })

            .state('root.accountSettings.userSync', {
                url: '/userSync',
                abstract: true,
                template: '<ui-view></ui-view>'
            })
            .state('root.accountSettings.userSync.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/userSync/userSyncList.html',
                controller: 'userSyncListCtrl as $ctrl'
            })
            .state('root.accountSettings.userSync.detail', {
                url: '/:configId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/userSync/userSyncDetail.html',
                controller: 'userSyncDetailCtrl as $ctrl',
                resolve: {
                    configId: ["$stateParams", function ($stateParams) {
                        return $stateParams.configId;
                    }]
                }
            })
            //endregion

            //region cas
            .state('root.accountSettings.casAgentList', {
                url: '/casAgents',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/agents/casAgentList.html',
                controller: 'casAgentListCtrl as $ctrl'
            })
            //endregion

            //region externalIdp
            .state('root.accountSettings.externalIdp', {
                url: '/externalIdp',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/externalIdp/externalIdp.html',
                controller: 'externalIdpCtrl'
            })
            //endregion

            //region permission groups
            .state('root.accountSettings.groups', {
                url: '/groups',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('root.accountSettings.groups.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/groups/groupList.html',
                controller: 'groupListCtrl'
            })
            .state('root.accountSettings.groups.detail', {
                abstract: true,
                url: '/:groupId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/groups/groupDetail.html',
                controller: 'groupDetailCtrl'
            })
            .state('root.accountSettings.groups.detail.members', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/groups/groupMembers.html',
                controller: 'groupMembersCtrl as $ctrl'
            })
            .state('root.accountSettings.groups.detail.roles', {
                url: '/roles',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/groups/groupRoles.html',
                controller: 'groupRolesCtrl as $ctrl'
            })
            .state('root.accountSettings.groups.detail.permissions', {
                url: '/permissions',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/groups/groupPermissions.html',
                controller: 'groupPermissionsCtrl as $ctrl'
            })
            //endregion

            //region saved filters
            .state('root.accountSettings.savedFilters', {
                url: '/savedFilters',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('root.accountSettings.savedFilters.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/savedFilters/savedFilterList.html',
                controller: 'savedFilterListCtrl'
            })
            .state('root.accountSettings.savedFilters.detail', {
                abstract: true,
                url: '/:savedFilterId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/savedFilters/savedFilterDetail.html',
                controller: 'savedFilterDetailCtrl'
            })
            .state('root.accountSettings.savedFilters.detail.items', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/savedFilters/savedFilterItems.html',
                controller: 'savedFilterItemsCtrl as $ctrl'
            })
            //endregion

            //region roles
            .state('root.accountSettings.roles', {
                url: '/roles',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('root.accountSettings.roles.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/roles/roleList.html',
                controller: 'roleListCtrl as $ctrl'
            })
            .state('root.accountSettings.roles.detail', {
                url: '/:roleId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/roles/roleDetail.html',
                controller: 'roleDetailCtrl as $ctrl',
                resolve: {
                    roleId: ["$stateParams", function ($stateParams) {
                        return $stateParams.roleId;
                    }]
                }
            })
            //endregion

            //region integrations
            .state('root.accountSettings.integrations', {
                url: '/integrations',
                abstract: true,
                template: '<div ui-view></div>'
            })

            .state('root.accountSettings.integrations.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/integrations.html',
                controller: 'integrationsCtrl'
            })

            .state('root.accountSettings.integrations.remoteIds', {
                url: '/remoteIds/{integrationType:amion|qgenda|tangier|shiftadmin}',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/remoteIds.html',
                controller: 'remoteIdsCtrl as $ctrl',
                resolve: {
                    integrationType: ["$stateParams", function ($stateParams) {
                        return $stateParams.integrationType;
                    }]
                }
            })

            //endregion

            //region qgenda
            .state('root.accountSettings.integrations.qgenda', {
                url: '/qgenda/:configId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/qgenda/qgendaConfiguration.html',
                controller: 'qgendaConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['qgenda.view_qgendaconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function($stateParams){
                        return $stateParams.configId;
                    }]
                }
            })
            .state('root.accountSettings.integrations.qgendaScheduleConfig', {
                url: '/qgenda/:configId/schedule/:scheduleId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/qgenda/qgendaScheduleConfiguration.html',
                controller: 'qgendaScheduleConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['qgenda.view_qgendaconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function($stateParams){
                        return $stateParams.configId;
                    }],
                    scheduleId: ["$stateParams", function($stateParams){
                        return $stateParams.scheduleId;
                    }]
                }
            })
            //endregion

            //region amion
            .state('root.accountSettings.integrations.amion', {
                url: '/amion/:configId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/amion/amionConfiguration.html',
                controller: 'amionConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['amion.view_amionconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function ($stateParams) {
                        return $stateParams.configId;
                    }]
                }
            })
            .state('root.accountSettings.integrations.amionScheduleConfig', {
                url: '/amion/:configId/schedule/:scheduleId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/amion/amionScheduleConfiguration.html',
                controller: 'amionScheduleConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['amion.view_amionconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function ($stateParams) {
                        return $stateParams.configId;
                    }],
                    scheduleId: ["$stateParams", function ($stateParams) {
                        return $stateParams.scheduleId;
                    }]
                }
            })
            //endregion

            //region shiftadmin
            .state('root.accountSettings.integrations.shiftadmin', {
                url: '/shiftadmin/:configId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/shiftadmin/shiftadminConfiguration.html',
                controller: 'shiftadminConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['base.view_integrationconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function($stateParams){
                        return $stateParams.configId;
                    }]
                }
            })
            .state('root.accountSettings.integrations.shiftadminScheduleConfig', {
                url: '/shiftadmin/:configId/schedule/:scheduleId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/shiftadmin/shiftadminScheduleConfiguration.html',
                controller: 'shiftadminScheduleConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['base.view_integrationconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function($stateParams){
                        return $stateParams.configId;
                    }],
                    scheduleId: ["$stateParams", function($stateParams){
                        return $stateParams.scheduleId;
                    }]
                }
            })
            //endregion

            //region tangier
            .state('root.accountSettings.integrations.tangier', {
                url: '/tangier/:configId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/tangier/tangierConfiguration.html',
                controller: 'tangierConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['tangier.view_tangierconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function($stateParams){
                        return $stateParams.configId;
                    }]
                }
            })
            .state('root.accountSettings.integrations.tangierScheduleConfig', {
                url: '/tangier/:configId/schedule/:scheduleId',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/integrations/tangier/tangierScheduleConfiguration.html',
                controller: 'tangierScheduleConfigurationCtrl as $ctrl',
                data: {
                    requiredPermissions: ['tangier.view_tangierconfiguration']
                },
                resolve: {
                    configId: ["$stateParams", function($stateParams){
                        return $stateParams.configId;
                    }],
                    scheduleId: ["$stateParams", function($stateParams){
                        return $stateParams.scheduleId;
                    }]
                }
            })
            //endregion


            //region departments
            .state('root.accountSettings.departments', {
                url: '/departments',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('root.accountSettings.departments.list', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/departmentList.html',
                controller: 'departmentListCtrl'
            })
            .state('root.accountSettings.departments.detail', {
                url: '/:departmentId',
                abstract: true,
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/departmentDetail.html',
                controller: 'departmentDetailCtrl as $depCtrl',
                resolve: {
                    departmentId: ["$stateParams", function ($stateParams) {
                        return $stateParams.departmentId;
                    }]
                }
            })
            .state('root.accountSettings.departments.detail.members', {
                url: '',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/departmentMembers.html',
                controller: 'departmentMembersCtrl as $ctrl',
                resolve: {
                    departmentId: ["$stateParams", function ($stateParams) {
                        return $stateParams.departmentId;
                    }]
                }
            })
            .state('root.accountSettings.departments.detail.contacts', {
                url: '/contacts',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/departmentContacts.html',
                controller: 'departmentContactsCtrl as $ctrl',
                resolve: {
                    departmentId: ["$stateParams", function ($stateParams) {
                        return $stateParams.departmentId;
                    }]
                }
            })
            .state('root.accountSettings.departments.detail.messagetypes', {
                url: '/messagetypes',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/departmentMessageTypes.html',
                controller: 'departmentMessageTypesCtrl as $ctrl',
                resolve: {
                    departmentId: ["$stateParams", function ($stateParams) {
                        return $stateParams.departmentId;
                    }]
                }
            })
            .state('root.accountSettings.departments.detail.savedfilters', {
                url: '/savedfilters',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/departmentSavedFilterApplications.html',
                controller: 'departmentSavedFilterApplicationsCtrl as $ctrl',
                resolve: {
                    departmentId: ["$stateParams", function ($stateParams) {
                        return $stateParams.departmentId;
                    }]
                }
            })
            .state('root.accountSettings.departments.detail.directoryaccess', {
                url: '/directoryaccess',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/departments/departmentDirectoryAccess.html',
                controller: 'departmentDirectoryAccessCtrl as $ctrl',
                resolve: {
                    departmentId: ["$stateParams", function ($stateParams) {
                        return $stateParams.departmentId;
                    }]
                }
            })
            //endregion

            //region broadcasts
            .state('root.accountSettings.broadcast', {
                url: '/broadcastMessage',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/broadcast/broadcast.html',
                controller: 'broadcastCtrl as $ctrl'
            })
            //endregion

            //region downloads
            .state('root.accountSettings.downloads', {
                url: '/downloads',
                templateUrl: '/smartpager/angular/endUser/accountSettings/controllers/downloads/downloads.html',
                controller: 'downloadsCtrl',
                data: {
                    title: 'Telmediq - downloads',
                    requiredPermissions: []
                }
            });
            //endregion
    }]);
