/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:createDepartmentModalCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('createDepartmentModalCtrl', ["$scope", "userService", "Notification", "alertsHelper", function ($scope, userService, Notification, alertsHelper) {
        var $ctrl = this;
        alertsHelper.create($ctrl, 'alerts');

        $ctrl.reset = function(){
            $ctrl.departmentName = '';
        };

        $ctrl.createDepartment = function () {
            $ctrl.isSaving = true;
            userService.createDepartment($ctrl.departmentName)
                .then(function () {
                    if(!$ctrl.addAnother){
                        Notification.success(String.format('Successfully created department {0}', $ctrl.departmentName));
                        $scope.$close();
                    }else{
                        $ctrl.alerts.success(String.format('Successfully created department {0}', $ctrl.departmentName));
                        $ctrl.reset();
                    }
                })
                .catch(function () {
                    $ctrl.alerts.error('Could not create department');
                })
                .finally(function () {
                    $ctrl.isSaving = false;
                });
        };
    }]);
