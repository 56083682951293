/**
 * @ngdoc controller
 * @name smartpager.endUser.patients:patientDetailUpdatesCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.patients')
    .controller('patientDetailUpdatesCtrl', ["$scope", "patientId", "patientsService", "baseService", "$log", function ($scope, patientId, patientsService, baseService, $log) {
        var $ctrl = this;
        $ctrl.updates = [];
        $ctrl.count = 0;

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;

            patientsService.getPatientUpdates(patientId, baseService.prepareParams($ctrl.updates))
                .then(function (response) {
                    $ctrl.count = response.count;
                    $ctrl.updates = _.uniqBy($ctrl.updates.concat(response.results), 'id');
                })
                .finally(function () {
                    $ctrl.isLoading = false;
                });
        };

        $ctrl.loadPage = function () {
            $log.debug('checking if should loadMore');
            if ($ctrl.count > $ctrl.updates.length) {
                $log.debug('load page');
                $ctrl.$onInit();
            }
        };
    }]);
