/**
 * @ngdoc service
 * @name smartpager.endUser.messageRouting:routingModalsFactory
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.messageRouting')
    .service('routingModalsFactory', ["modalHelper", function (modalHelper) {

        var Service = {
            showAddMessageRoutingModal: function () {
                return modalHelper.openModal({
                    'templateUrl': '/smartpager/angular/endUser/messageRouting/controllers/modals/addMessageRoutingPolicyModal.html',
                    'controller': 'addMessageRoutingPolicyModalCtrl as $ctrl',
                    'size': 'lg'
                }).result;
            },
            showAddEditConditionSetModal: function (conditionSet) {
                return modalHelper.openModal({
                    'templateUrl': '/smartpager/angular/endUser/messageRouting/controllers/modals/addEditConditionSetModal.html',
                    'controller': 'addEditConditionSetModalCtrl as $ctrl',
                    'size': 'lg',
                    'conditionSet': conditionSet
                }).result;
            },
            showCreateEditActionModal: function (action) {
                return modalHelper.openModal({
                    'templateUrl': '/smartpager/angular/endUser/messageRouting/controllers/modals/addActionModal.html',
                    'controller': 'addActionModalCtrl as $ctrl',
                    'action': action
                }).result;
            },
            showCreateEditConditionModal: function (condition) {
                return modalHelper.openModal({
                    'templateUrl': '/smartpager/angular/endUser/messageRouting/controllers/modals/addConditionModal.html',
                    'controller': 'addConditionModalCtrl as $ctrl',
                    'condition': condition
                }).result;
            }
        };

        return Service;
    }]);
