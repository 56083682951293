/**
 * @ngdoc service
 * @name smartpager.endUser.shared:broadcastService
 *
 * @description
 *
 *
 * */
angular.module('smartpager.endUser.shared')
    .factory('broadcastService', ["baseService", function(baseService){
        var Service = Object.create(baseService);

        Service.createBroadcastMessage = function(message){
            var url = '/api/v1/broadcasts/';

            return this.postData(url, message);
        };

        Service.sendBroadcastMessage = function(messageId){
            var url = String.format('/api/v1/broadcasts/{0}/send/', messageId);

            return this.postData(url);
        };

        return Service;
}]);
