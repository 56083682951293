/**
 * @ngdoc controller
 * @name smartpager.endUser.messageRouting:conditionListCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.messageRouting')
    .factory('conditionListCtrlCache', ["$cacheFactory", function ($cacheFactory) {
        return $cacheFactory('conditionListCtrl');
    }])
    .controller('conditionListCtrl', ["$scope", "conditionListCtrlCache", "NgTableParams", "messageRoutingService", "ngTableHelper", "routingModalsFactory", "Notification", function ($scope, conditionListCtrlCache, NgTableParams, messageRoutingService, ngTableHelper, routingModalsFactory, Notification) {
        var $ctrl = this;

        $ctrl.labels = {
            MessageContentCondition: 'Message Content',
            MessageTypeCondition: 'Message Type',
            RecipientCondition: 'Recipient',
            ScheduleCondition: 'Schedule',
            SenderCondition: 'Sender'
        };

        $ctrl.tableParams = new NgTableParams(conditionListCtrlCache.get('parameters') || {
                sorting: {
                    name: 'asc'
                }
            }, {
            defaultSort: 'asc',
            getData: function (params) {
                conditionListCtrlCache.put('parameters', params.parameters());
                $ctrl.isLoading = true;

                return messageRoutingService.getConditionsForGrid(params.parameters())
                    .then(function (response) {
                        params.total(response.count);
                        return response.results;
                    })
                    .finally(function () {
                        $ctrl.isLoading = false;
                    });
            }
        });

        $ctrl.searchParams = {
            search: ngTableHelper.generateGetterSetter($ctrl.tableParams, 'search')
        };

        $ctrl.clearSearch = function () {
            $ctrl.searchParams.search(null);
        };

        $ctrl.openCreateConditionModal = function (condition) {
            return routingModalsFactory.showCreateEditConditionModal(condition)
                .finally(function () {
                    $ctrl.tableParams.reload();
                });
        };

        $ctrl.delete = function (condition) {
            messageRoutingService.deleteCondition(condition.url_prefix, condition.id)
                .then(function () {
                    Notification.success(String.format('Successfully deleted {0}', condition.name));
                    $ctrl.tableParams.reload();
                })
                .catch(function() {
                    Notification.error(String.format('Could not deleted {0}, it is in use', condition.name));
                });
        };
    }]);
