/**
 * @ngdoc controller
 * @name smartpager.endUser.accountSettings:groupDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('smartpager.endUser.accountSettings')
    .controller('savedFilterDetailCtrl', ["$scope", "$stateParams", "savedFiltersService", "Notification", "modalHelper", "NgTableParams", "ngTableHelper", "$q", "$log", "$state", function ($scope, $stateParams, savedFiltersService, Notification, modalHelper, NgTableParams, ngTableHelper, $q, $log, $state) {

        $scope.savedFilter = {};
        $scope.contentTypes = [];

        this.$onInit = function () {
            $scope.isLoading = true;
            savedFiltersService.getSavedFilter($stateParams.savedFilterId)
                .then(function (savedFilter) {
                    $scope.originalName = savedFilter.name;
                    $scope.savedFilter = _.merge($scope.savedFilter, savedFilter);
                    savedFiltersService.getContentTypes()
                        .then(function (response) {
                            $scope.contentTypes = response;
                        });
                })
                .catch(function () {
                    $state.go('root.accountSettings.savedFilters.list');
                    Notification.warning(String.format('Saved filter with id `{0}` does not exist', $stateParams.savedFilterId));
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };

        $scope.saveSavedFilter = function () {
            savedFiltersService.saveSavedFilter($scope.savedFilter.id, $scope.savedFilter.name)
                .then(function () {
                    Notification.success(String.format('Successfully saved changes to saved filter `{0}`', $scope.savedFilter.name));
                });
        };

        $scope.deleteSavedFilter= function () {
            var response = modalHelper.openModal({
                title: 'Delete Assignable Filter',
                content: String.format('Are you sure you want to delete Assignable Filter {0}?', $scope.originalName),
                buttons: [
                    {id: 'yes', type: 'primary', label: 'Delete Assignable Filter'},
                    {id: 'cancel', type: 'default', label: 'Cancel'}
                ]
            }).result
                .then(function () {
                    return savedFiltersService.deleteSavedFilter($scope.savedFilter.id);
                })
                .then(function () {
                    $state.go('root.accountSettings.savedFilters.list');
                });
        };
    }]);
