/**
 * @ngdoc directive
 * @name iqUserSelectCtrl
 *
 * @description
 * http://www.chroder.com/2014/02/01/using-ngmodelcontroller-with-custom-directives/ see comments,
 *
 * @restrict E
 * */
angular.module('smartpager.endUser.shared')
    .controller('iqSavedFilterSelectCtrl', ["$scope", "modalHelper", "savedFiltersService", "$log", "$timeout", function ($scope, modalHelper, savedFiltersService, $log, $timeout) {
        $scope.iqSavedFilterSelectCtrl = {
            availableSavedFilters: [],
            savedFilters: []
        };

        // for regular type-ahead searches
        $scope.searchSavedFilters = function (query) {

            var param = {
                search: query
            };
            $scope.isLoading = true;
            savedFiltersService.getSavedFiltersAssignableToObject($scope.ct, $scope.obj, param)
                .then(function (response) {
                    $scope.iqSavedFilterSelectCtrl.availableSavedFilters = response.results;
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };
    }]);
