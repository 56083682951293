/**
 * @ngdoc service
 * @name promptsService
 * @description
 * _Please update the description and dependencies._
 *
 * */
angular.module('smartpager.endUser.shared')
    .factory('promptsService', ["baseService", function (baseService) {

        var Service = Object.create(baseService);

        Service.getPrompts = function () {
            var url = '/api/v1/prompts/';
            return this.getData(url);
        };

        Service.getSnippets = function (promptId) {
            var url = String.format('/api/v1/prompts/{0}/snippets/', promptId);
            return this.getData(url)
                .then(function (response) {
                    return _.map(response, function (item) {

                        //TODO: refactor api and change file_url to url
                        item.url = item.file_url;
                        return item;
                    });
                });
        };
        return Service;
    }]);
